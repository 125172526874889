import React, { FC, memo, useMemo } from 'react';
import { Card, Form as AntdForm, Typography, Tooltip } from 'antd';
import { Check2, X } from 'react-bootstrap-icons';

import Button from '../../button/Button';
import { ButtonType } from '../../button/enums/buttonType';
import Form from '../../form/Form';
import { HtmlType } from '../../button/enums/htmlType';
import Input from '../../input/Input';
import { FormSize } from '../../form/enums/FormSize';
import { AnnotationCommentFormFields } from './enums/AnnotationCommentFormFields';
import { IAnnotationCommentFormData } from './interfaces/IAnnotationCommentFormData';
import TextArea from '../../textArea/TextArea';
import { IAnnotationComment } from '../interfaces/IAnnotationComment';
import {
  AnnotationCardFormFields,
  AnnotationCardFormLabels,
} from '../../annotationCard/annotationCardForm/enums/AnnotationCardFormFields';
import { TOOLTIPS_MAP } from '../../../constants/application';

const { Text } = Typography;

interface IAnnotationCommentFormProps extends IAnnotationComment {
  creationDate: string;
  onSubmit(formData: IAnnotationCommentFormData): void;
  onCancel(): void;
}

const AnnotationCommentForm: FC<IAnnotationCommentFormProps> = (props) => {
  const formInitialValues = useMemo(() => {
    return {
      [AnnotationCommentFormFields.Title]: props.annotation.title,
      [AnnotationCommentFormFields.Content]: props.annotation.content,
    };
  }, [props.annotation]);

  const onChange = (e: React.ChangeEvent) => {
    e.stopPropagation();
  };

  return (
    <Form
      name={`annotation-comment-form-${props.annotation.id}`}
      onSubmit={props.onSubmit}
      className="annotation-comment-form"
      size={FormSize.Small}
      initialValues={formInitialValues}
    >
      <Card
        className="annotation-comment-card"
        extra={
          <AntdForm.Item noStyle>
            <Button
              htmlType={HtmlType.Submit}
              icon={
                <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.SAVE}>
                  <Check2 size={22} />
                </Tooltip>
              }
              type={ButtonType.Text}
            />
            <Button
              icon={
                <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.CANCEL}>
                  <X size={22} />
                </Tooltip>
              }
              type={ButtonType.Text}
              onClick={props.onCancel}
            />
          </AntdForm.Item>
        }
        title={
          <>
            <div className="annotation-comment__title">
              <AntdForm.Item name={AnnotationCardFormFields.Title}>
                <Input placeholder={AnnotationCardFormLabels.Title} onChange={onChange} />
              </AntdForm.Item>
            </div>
            <Text className="annotation-comment__description" type="secondary">
              {props.creationDate}
            </Text>
          </>
        }
      >
        <AntdForm.Item noStyle name={AnnotationCommentFormFields.Content}>
          <TextArea placeholder={AnnotationCommentFormFields.Content} onChange={onChange} />
        </AntdForm.Item>
      </Card>
    </Form>
  );
};

export default memo(AnnotationCommentForm);
