import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { CustomAny } from '../shared/types/generics';
import { PageAction } from './enums/pageAction';
import { IPageAction } from './interfaces/IPageActions';
import { IAnnotationEntity } from './interfaces/IAnnotation';
import { ActionType } from '../store/enums/actionType';
import { ITreeNode } from '../shared/components/tree/interfaces/ITreeNode';
import { updateTreeNodePage } from '../shared/helpers/outlineHelper';

export const movePagesTo = (numOfPages: number, indicesToMove: number[], to: number): IPageAction => {
  const permutation = [...Array(numOfPages).keys()];
  indicesToMove.forEach((index) => (permutation[index] = -1));
  permutation.splice(to, 0, ...indicesToMove);
  _.remove(permutation, (index) => index === -1);

  return {
    pageActionType: PageAction.Move,
    movePageList: permutation,
  };
};

export const copyPagesTo = (numOfPages: number, indicesToCopy: number[], to: number): IPageAction => {
  const permutation = [...Array(numOfPages).keys()];
  permutation.splice(to + 1, 0, ...indicesToCopy);
  _.remove(permutation, (index) => index === -1);

  return {
    pageActionType: PageAction.Duplicate,
    duplicatePageList: permutation,
    indicesToCopy,
    copyTo: to,
  };
};

export const applyCurrentHistory = (
  pagesList: CustomAny[],
  history: IPageAction[],
  historyIndex: number,
): CustomAny[] => {
  return applyActions(pagesList, history.slice(0, historyIndex + 1));
};

export const applyActions = (pagesList: CustomAny[], actions: IPageAction[]): CustomAny[] => {
  let newPagesList = [...pagesList];
  actions.forEach((action) => {
    newPagesList = applyAction(newPagesList, action);
  });
  return newPagesList;
};

export const applyAction = (pagesList: CustomAny[], action: IPageAction): CustomAny[] => {
  let newPagesList: CustomAny[];
  switch (action.pageActionType) {
    case PageAction.Move:
      return action.movePageList?.map((index: number) => pagesList[index]) || [];
    case PageAction.Duplicate:
      return action.duplicatePageList?.map((index: number) => pagesList[index]) || [];
    case PageAction.Delete:
      return [...pagesList].filter((page, index) => !action.pages?.includes(index));
    case PageAction.Create:
      newPagesList = [...pagesList];
      newPagesList.splice(action.page || pagesList.length - 1, 0, {
        action: PageAction.Create,
        width: pagesList[action?.modelPageNumber || pagesList.length - 1].width,
        height: pagesList[action?.modelPageNumber || pagesList.length - 1].height,
      });
      return newPagesList;
    default:
      break;
  }
  return [...pagesList];
};

export const applyActionsToAnnotations = (
  annotations: IAnnotationEntity[],
  actions: IPageAction[],
): IAnnotationEntity[] => {
  let newAnnotations = [...annotations];
  actions.forEach((action) => {
    newAnnotations = applyActionToAnnotations(newAnnotations, action);
  });
  return newAnnotations;
};

export const applyActionToAnnotations = (
  annotations: IAnnotationEntity[],
  action: IPageAction,
): IAnnotationEntity[] => {
  let newAnnotations = [...annotations];
  switch (action.pageActionType) {
    case PageAction.Move:
      newAnnotations = newAnnotations.map((annotation) => {
        const newPage = action.movePageList?.indexOf(annotation.page);
        return {
          ...annotation,
          page: newPage !== undefined && newPage !== -1 ? newPage : annotation.page,
        };
      });
      break;
    case PageAction.Delete:
      newAnnotations = newAnnotations
        .filter((annotation) => !action.pages?.includes(annotation.page))
        .map((annotation) => {
          if (action.pages?.some((page) => annotation.page > page)) {
            return {
              ...annotation,
              page: annotation.page - action.pages.filter((page) => page < annotation.page)?.length,
            };
          }
          return annotation;
        });
      break;
    case PageAction.Create:
      newAnnotations = newAnnotations.map((annotation) => {
        if (annotation.page >= (action.page || Number.MAX_SAFE_INTEGER)) {
          return {
            ...annotation,
            page: annotation.page + 1,
          };
        }
        return annotation;
      });
      break;
    case PageAction.Duplicate:
      newAnnotations = [];
      action.duplicatePageList?.forEach((page: number, index: number) => {
        const annotationForPage = annotations.filter((annotation) => annotation.page === page);
        const newAnnotation = page !== index;
        newAnnotations.push(
          ...annotationForPage.map((annotation) => ({
            ...annotation,
            id: newAnnotation && annotation.actionType !== ActionType.Delete ? uuid() : annotation.id,
            actionType:
              newAnnotation && annotation.actionType !== ActionType.Delete ? ActionType.Create : annotation.actionType,
            page: index,
          })),
        );
      });
      break;
    default:
      break;
  }
  return newAnnotations;
};

export const applyActionsToOutlines = (
  outlines: ITreeNode[],
  actions: IPageAction[],
  numPages: number,
): ITreeNode[] => {
  let newOutlines = [...outlines];
  actions.forEach((action) => {
    newOutlines = applyActionToOutlines(newOutlines, action, numPages);
  });
  return newOutlines;
};

export const applyActionToOutlines = (outlines: ITreeNode[], action: IPageAction, numPages: number): ITreeNode[] => {
  let newPages;
  switch (action.pageActionType) {
    case PageAction.Delete:
      return updateTreeNodePage(
        outlines,
        [...Array(numPages).keys()].filter((page) => !action.pages?.includes(page)),
      );
    case PageAction.Move:
      return updateTreeNodePage(outlines, action.movePageList);
    case PageAction.Create:
      newPages = [...Array(numPages - 1).keys()];
      if (action.page) {
        newPages.splice(action.page, 0, -1);
      }
      return updateTreeNodePage(outlines, newPages);
    case PageAction.Duplicate:
      newPages = _.uniq(action.duplicatePageList) as number[];
      newPages.splice((action.copyTo || 0) + 1, 0, ..._.fill(Array((action.indicesToCopy || []).length), -1));
      return updateTreeNodePage(outlines, newPages);
    default:
      return outlines;
  }
};
