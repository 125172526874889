import React, { FC } from 'react';

interface IToolbarCenter {
  children?: JSX.Element;
}

const ToolbarCenter: FC<IToolbarCenter> = (props) => {
  return (
    <div className="toolbar-center">
      <div className="toolbar-center__annotate-panel">{props.children}</div>
    </div>
  );
};

export default ToolbarCenter;
