import { fabric } from 'fabric';
import { v4 as uuid } from 'uuid';

import { IAnnotationImage } from './interfaces/IAnnotation';
import { AnnotationType } from '../store/enums/annotationType';
import {
  getImageFile,
  getObjectId,
  getReverseRotationAngle,
  getScaleAndPointToFitViewport,
  rotatePointOnCanvas,
  setImageFile,
} from '../shared/helpers/canvasHelper';
import { convertY, getObjectRectangleWithoutRotation } from '../shared/helpers/fabricCanvasHelper';
import { base64ToHexUrl } from '../shared/helpers/fileHelper';
import { adjustRectangleWithViewport, rotateRectangle } from './annotationService';
import { IImageDrawServiceProps } from './interfaces/IFabric';

export const CASHED_IMAGES_CONTAINER_ID = 'image-loader';

export class ImageDrawService {
  handleImageLoading: (loading: boolean, error?: boolean) => void;
  _rotationAngle: number;

  constructor(props: IImageDrawServiceProps) {
    this.handleImageLoading = props.handleImageLoading;
    this._rotationAngle = props.rotationAngle;
  }

  setRotationAngle(angle: number): void {
    this._rotationAngle = angle;
  }

  async createImageObject(annotation: IAnnotationImage, viewport: number[]): Promise<fabric.Image | null> {
    let rectangle = rotateRectangle(annotation.rectangle, viewport, this._rotationAngle, false);
    rectangle = adjustRectangleWithViewport(rectangle, viewport, false, this._rotationAngle);
    if (!annotation.file) {
      return null;
    }
    let image: HTMLImageElement | null = document.getElementById(annotation.id || '') as HTMLImageElement;
    if (!image) {
      const container = document.getElementById(CASHED_IMAGES_CONTAINER_ID);
      if (container) {
        for (const item of container.getElementsByTagName('img')) {
          if ((item as HTMLImageElement).dataset.file === annotation.file) {
            image = item;
          }
        }
      }
    }
    if (!image) {
      const url = base64ToHexUrl(annotation.file);
      image = await this._loadImage(url);
      if (!image) {
        return null;
      }
      image.id = annotation.id || '';
      image.dataset.file = annotation.file;
      document.getElementById(CASHED_IMAGES_CONTAINER_ID)?.appendChild(image);
    }
    const width = Math.abs(annotation.rectangle.upperRightX - annotation.rectangle.lowerLeftX);
    const height = Math.abs(annotation.rectangle.upperRightY - annotation.rectangle.lowerLeftY);
    let leftOffset = 0;
    let topOffset = 0;
    if (this._rotationAngle == 90) {
      leftOffset = height;
    }
    if (this._rotationAngle == 180) {
      leftOffset = width;
      topOffset = height;
    }
    if (this._rotationAngle == 270) {
      topOffset = width;
    }
    const object = new fabric.Image(image, {
      left: rectangle.lowerLeftX + leftOffset,
      top: convertY(rectangle.upperRightY, viewport, this._rotationAngle) + topOffset,
      angle: this._rotationAngle,
    });
    object.scaleToHeight(rectangle.upperRightY - rectangle.lowerLeftY);
    object.scaleToWidth(rectangle.upperRightX - rectangle.lowerLeftX);
    setImageFile(object, annotation.file);
    return object;
  }

  async createImageObjectByBase64(
    imageBase64: string,
    cursorX: number,
    cursorY: number,
    page: number,
    viewport: number[],
  ): Promise<IAnnotationImage | null> {
    const id = uuid();
    let image = null;
    const container = document.getElementById(CASHED_IMAGES_CONTAINER_ID);
    if (container) {
      for (const item of container.getElementsByTagName('img')) {
        if ((item as HTMLImageElement).dataset.file === imageBase64) {
          image = item;
          this.handleImageLoading(false);
        }
      }
    }
    if (!image) {
      image = await this._loadImage(base64ToHexUrl(imageBase64));
    }
    if (!image) {
      return null;
    }
    image.id = id;
    image.dataset.file = imageBase64;
    document.getElementById(CASHED_IMAGES_CONTAINER_ID)?.appendChild(image);
    const width = image.width || 1;
    const height = image.height || 1;
    const coordinates = rotatePointOnCanvas(
      getReverseRotationAngle(this._rotationAngle),
      [cursorX, cursorY],
      viewport,
      true,
    );
    const [scale, [left, top]] = getScaleAndPointToFitViewport(
      [[coordinates[0], coordinates[1], coordinates[0] + width, coordinates[1] + height]],
      [coordinates[0], coordinates[1]],
      viewport,
      this._rotationAngle,
    );
    return {
      id,
      page: page - 1,
      annotationType: AnnotationType.Stamp,
      content: '',
      rectangle: {
        lowerLeftX: left + viewport[0],
        upperRightX: left + width * scale + viewport[0],
        lowerLeftY: convertY(top + height * scale, viewport) + viewport[1],
        upperRightY: convertY(top, viewport) + viewport[1],
      },
      opacity: 1,
      colorRGB: [0, 0, 0],
      file: imageBase64,
    };
  }

  createImageAnnotation(object: fabric.Image, page: number, viewport: number[]): IAnnotationImage {
    const annotation: IAnnotationImage = {
      page: page - 1,
      annotationType: AnnotationType.Stamp,
      content: '',
      rectangle: getObjectRectangleWithoutRotation(
        object,
        viewport,
        true,
        getReverseRotationAngle(this._rotationAngle),
      ),
      opacity: 1,
      colorRGB: [0, 0, 0],
      file: getImageFile(object),
    };
    const id = getObjectId(object);
    if (id) {
      annotation.id = id;
    }
    return annotation;
  }

  _loadImage(url: string): Promise<HTMLImageElement | null> {
    return new Promise<HTMLImageElement | null>((resolve) => {
      const image = new Image();
      image.onload = () => {
        this.handleImageLoading(false);
        resolve(image);
      };
      image.onerror = () => {
        this.handleImageLoading(false, true);
        resolve(null);
      };
      image.onabort = () => {
        this.handleImageLoading(false, true);
        resolve(null);
      };
      image.src = url;
    });
  }
}

export const clearCachedImages = (): void => {
  const container = document.getElementById(CASHED_IMAGES_CONTAINER_ID);
  if (container) {
    container.innerHTML = '';
  }
};
