import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import Button from '../button/Button';
import { ButtonType } from '../button/enums/buttonType';
import FavoritesColorsDialog from '../colorDropDown/FavoritesColorsDialog';

import './ColorPicker.less';

interface IColorPickerProps {
  color?: string;
  icon: React.ReactNode;
  button?: React.ReactNode;
  disabled?: boolean;
  hideOpacity?: boolean;
  className?: string;
  onChange?(color: string): void;
  onSave?(color: string): void;
  onCancel?(): void;
}

const ColorPicker: FC<IColorPickerProps> = (props) => {
  const { color = 'rgba(255,255,255,1)' } = props;
  const tooltip = 'Pick color';
  const pickerRef = useRef(null);
  const [displayPicker, setDisplayPicker] = useState(false);
  const [prevColor, setPrevColor] = useState('');
  useEffect(() => {
    setPrevColor(color);
  }, []);
  const togglePicker = useCallback(() => {
    setDisplayPicker(!displayPicker);
  }, [displayPicker]);
  const onColorChange = useCallback(
    (newColor: string) => {
      props.onChange?.(newColor);
    },
    [props.onChange],
  );
  const onSave = useCallback(
    (color: string) => {
      props.onSave?.(color);
      setDisplayPicker(false);
    },
    [props.onSave],
  );
  const onCancel = useCallback(() => {
    if (props.onCancel) {
      props.onCancel();
    } else if (props.onSave) {
      props.onSave?.(prevColor);
    } else {
      onColorChange(prevColor);
    }
    setDisplayPicker(false);
  }, [prevColor, props.onCancel]);
  useClickAway(pickerRef, () => setDisplayPicker(false));
  return (
    <div className={`color-picker ${props.className || ''}`}>
      <Button
        type={ButtonType.Text}
        icon={props.icon}
        onClick={togglePicker}
        disabled={props.disabled}
        tooltip={tooltip}
      />
      <FavoritesColorsDialog
        color={color}
        onOk={onSave}
        onCancel={onCancel}
        onChange={onColorChange}
        visible={displayPicker}
        hideOpacity={props.hideOpacity}
      />
    </div>
  );
};

export default ColorPicker;
