import React, { FC, useState, useCallback } from 'react';

import { ButtonType } from '../button/enums/buttonType';
import Button from '../button/Button';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';
import ShapeStyleEditor from '../shapeStyleEditor/ShapeStyleEditor';

interface IShapeStylePicker {
  id?: string;
  icon: React.ReactNode;
  className?: string;
  hideBackground?: boolean;
  maxLineWidth?: number;
  onChange?(style: IShapeStyle): void;
  onCancel?(): void;
}

const ShapeStylePicker: FC<IShapeStylePicker> = (props) => {
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const toggleDialog = useCallback(() => {
    setDisplayDialog(!displayDialog);
  }, [displayDialog]);
  const onClose = useCallback(() => {
    setDisplayDialog(false);
    props.onCancel?.();
  }, []);
  return (
    <div className={`color-picker ${props.className || ''}`}>
      <Button type={ButtonType.Text} icon={props.icon} onClick={toggleDialog} />
      <ShapeStyleEditor
        id={props.id || null}
        visible={displayDialog}
        maxLineWidth={props.maxLineWidth}
        onClose={onClose}
        hideBackground={props.hideBackground}
        onChange={props.onChange}
      />
    </div>
  );
};

export default ShapeStylePicker;
