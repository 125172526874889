import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/button/Button';
import { ButtonType } from '../button/enums/buttonType';
import { getShapeStyle, getShapeType } from '../../../store/annotateSettings/selectors';
import { selectShapeType, setShapeStyle } from '../../../store/annotateSettings/actions';
import { ShapeType } from '../../../store/enums/shapeType';
import ShapeStyleDialog from '../shapeStyleDialog/ShapeStyleDialog';

import { ReactComponent as ShapeLine } from '../../../assets/ShapeLine.svg';
import { ReactComponent as Square } from '../../../assets/Square.svg';
import { ReactComponent as ArrowLine } from '../../../assets/ArrowLine.svg';
import { ReactComponent as TwoArrowsLine } from '../../../assets/TwoArrowsLine.svg';
import { ReactComponent as Circle } from '../../../assets/ShapeCircle.svg';
import { ReactComponent as Triangle } from '../../../assets/Triangle.svg';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';

const ShapeStylePanel: FC = () => {
  const dispatch = useDispatch();
  const shapeType: ShapeType = useSelector(getShapeType);
  const shapeStyle = useSelector(getShapeStyle);
  const [isDialogVisible, setDialogVisible] = useState<boolean>(false);

  const toggleShapeStyle = (type: ShapeType) => {
    dispatch(selectShapeType(type));
  };
  const getButtonType = useCallback(
    (type: ShapeType) => {
      return type === shapeType ? ButtonType.Primary : ButtonType.Text;
    },
    [shapeType],
  );
  const editShapeStyle = (type: ShapeType) => {
    dispatch(selectShapeType(type));
    setDialogVisible(true);
  };
  const onSave = () => {
    setDialogVisible(false);
  };
  const onStyleChange = useCallback(
    (style: IShapeStyle) => {
      dispatch(setShapeStyle(style));
    },
    [shapeType],
  );
  const onCancel = useCallback(
    (style: IShapeStyle) => {
      dispatch(setShapeStyle(style));
      setDialogVisible(false);
    },
    [shapeType],
  );
  const strokeColor = useCallback(
    (type: ShapeType) => {
      return shapeStyle[type].list[shapeStyle[type].activeIndex].lineColor;
    },
    [shapeStyle],
  );
  const fillColor = useCallback(
    (type: ShapeType) => {
      return shapeStyle[type].list[shapeStyle[type].activeIndex].backgroundColor;
    },
    [shapeStyle],
  );

  const selectedStyle = useMemo(
    () => shapeStyle[shapeType].list[shapeStyle[shapeType].activeIndex],
    [shapeStyle, shapeType],
  );

  return (
    <>
      <div className="note-tools-panel">
        <Button
          ghost={getButtonType(ShapeType.Line) === ButtonType.Primary}
          type={getButtonType(ShapeType.Line)}
          icon={
            <ShapeLine
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': 'none',
                  '--stroke-color': strokeColor(ShapeType.Line),
                } as React.CSSProperties
              }
            />
          }
          onClick={() => toggleShapeStyle(ShapeType.Line)}
          onDoubleClick={() => editShapeStyle(ShapeType.Line)}
        />
        <Button
          ghost={getButtonType(ShapeType.ArrowLine) === ButtonType.Primary}
          type={getButtonType(ShapeType.ArrowLine)}
          icon={
            <ArrowLine
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': 'none',
                  '--stroke-color': strokeColor(ShapeType.ArrowLine),
                } as React.CSSProperties
              }
            />
          }
          onClick={() => toggleShapeStyle(ShapeType.ArrowLine)}
          onDoubleClick={() => editShapeStyle(ShapeType.ArrowLine)}
        />
        <Button
          ghost={getButtonType(ShapeType.TwoArrowsLine) === ButtonType.Primary}
          type={getButtonType(ShapeType.TwoArrowsLine)}
          icon={
            <TwoArrowsLine
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': 'null',
                  '--stroke-color': strokeColor(ShapeType.TwoArrowsLine),
                } as React.CSSProperties
              }
            />
          }
          onClick={() => toggleShapeStyle(ShapeType.TwoArrowsLine)}
          onDoubleClick={() => editShapeStyle(ShapeType.TwoArrowsLine)}
        />
        <Button
          ghost={getButtonType(ShapeType.Square) === ButtonType.Primary}
          type={getButtonType(ShapeType.Square)}
          icon={
            <Square
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': fillColor(ShapeType.Square),
                  '--stroke-color': strokeColor(ShapeType.Square),
                } as React.CSSProperties
              }
            />
          }
          onClick={() => toggleShapeStyle(ShapeType.Square)}
          onDoubleClick={() => editShapeStyle(ShapeType.Square)}
        />
        <Button
          ghost={getButtonType(ShapeType.Circle) === ButtonType.Primary}
          type={getButtonType(ShapeType.Circle)}
          icon={
            <Circle
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': fillColor(ShapeType.Circle),
                  '--stroke-color': strokeColor(ShapeType.Circle),
                } as React.CSSProperties
              }
            />
          }
          onClick={() => toggleShapeStyle(ShapeType.Circle)}
          onDoubleClick={() => editShapeStyle(ShapeType.Circle)}
        />
        <Button
          ghost={getButtonType(ShapeType.Triangle) === ButtonType.Primary}
          type={getButtonType(ShapeType.Triangle)}
          icon={
            <Triangle
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': fillColor(ShapeType.Triangle),
                  '--stroke-color': strokeColor(ShapeType.Triangle),
                } as React.CSSProperties
              }
            />
          }
          onClick={() => toggleShapeStyle(ShapeType.Triangle)}
          onDoubleClick={() => editShapeStyle(ShapeType.Triangle)}
        />
      </div>
      <ShapeStyleDialog
        visible={isDialogVisible}
        styles={selectedStyle}
        type={shapeType}
        onCancel={onCancel}
        onSave={onSave}
        onStyleChange={onStyleChange}
      />
    </>
  );
};

export default ShapeStylePanel;
