import { createSelector } from 'reselect';

import { IRootState } from '../interfaces/IRootState';
import { IAnnotateSettings } from '../interfaces/IAnnotateSettings';
const getSettings = (state: IRootState): IAnnotateSettings => state.annotateSettings;

export const getActiveAction = createSelector(getSettings, (settings: IAnnotateSettings) => settings.activeAction);
export const getColor = createSelector(getSettings, (settings: IAnnotateSettings) => settings.color);
export const getDefaultTitle = createSelector(getSettings, (settings: IAnnotateSettings) => settings.defaultTitle);
export const isGenerateAStream = createSelector(
  getSettings,
  (settings: IAnnotateSettings) => settings.generateAppearanceStream,
);
export const getFont = createSelector(getSettings, (settings: IAnnotateSettings) => settings.font);
export const getFontSize = createSelector(getSettings, (settings: IAnnotateSettings) => settings.fontSize);
export const getFontStyle = createSelector(getSettings, (settings: IAnnotateSettings) => settings.style);
export const getIsEditing = createSelector(getSettings, (settings: IAnnotateSettings) => settings.isEditing);
export const getRemovingAnnotation = createSelector(
  getSettings,
  (settings: IAnnotateSettings) => settings.removingAnnotation,
);
export const getLineStyle = createSelector(getSettings, (settings: IAnnotateSettings) => settings.lineStyle);
export const getShapeType = createSelector(getSettings, (settings: IAnnotateSettings) => settings.shapeType);
export const getShapeStyle = createSelector(getSettings, (settings: IAnnotateSettings) => settings.shapeStyle);
export const getImageBase64 = createSelector(getSettings, (settings: IAnnotateSettings) => settings.imageBase64);
export const getIsImageEmpty = createSelector(getSettings, (settings: IAnnotateSettings) => !settings.imageBase64);
export const getDrawingStyle = createSelector(getSettings, (settings: IAnnotateSettings) => settings.drawingStyle);
export const getTypesToRemove = createSelector(getSettings, (settings: IAnnotateSettings) => settings.typesToRemove);
