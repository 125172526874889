import React from 'react';
import { CheckCircle, CircleFill } from 'react-bootstrap-icons';
import _ from 'lodash';

import { ContextMenuItem } from './interfaces/CanvasContextMenu';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';
import { AnnotateActionType, AnnotateActionTypeLabel } from '../../../store/enums/annotateActionType';
import { ContextMenuItemType } from './enum/ContextMenuItemType';
import { AnnotationType } from '../../../store/enums/annotationType';
import { ShapeType } from '../../../store/enums/shapeType';
import { MAX_COLOR_LIST_LENGTH, MAX_LINE_WIDTH, TEXT_MAP, TOOLTIPS_MAP } from '../../constants/application';
import ColorPicker from '../colorPicker/ColorPicker';
import ShapeStylePicker from '../shapeStylePicker/ShapeStylePicker';
import DrawStylePicker from '../drawStylePicker/DrawStylePicker';
import ShapeIcon from '../shapeIcon/ShapeIcon';

import { ReactComponent as FreeText } from '../../../assets/FreeText.svg';
import { ReactComponent as Image } from '../../../assets/Top_Image.svg';
import { ReactComponent as StickyNote } from '../../../assets/StickyNoteStroke.svg';
import { ReactComponent as Paste } from '../../../assets/Top_paste.svg';
import { ReactComponent as UploadIcon } from '../../../assets/Upload.svg';
import { ReactComponent as GoogleDrive } from '../../../assets/GoogleDrive.svg';
import { ReactComponent as ZoomIN } from '../../../assets/ZoomIN.svg';
import { ReactComponent as Copy } from '../../../assets/Top_copy.svg';
import { ReactComponent as Delete } from '../../../assets/delete.svg';
import { ReactComponent as Outline } from '../../../assets/Menubar_addoutline.svg';
import { ReactComponent as Underline1 } from '../../../assets/Underline1.svg';
import { ReactComponent as Underline2 } from '../../../assets/Underline2.svg';
import { ReactComponent as Highlight } from '../../../assets/Highlight.svg';
import { ReactComponent as CancelLine } from '../../../assets/CancelLine.svg';
import { rgbAdjustOpacity } from '../../helpers/colorHelper';

interface BuildDefaultMenuOptions {
  hasPaste: boolean;
  disableGoogleDriveUpload: boolean;
  getColorsList: (type: AnnotateActionType) => string[];
  getActionColor: (type: AnnotateActionType) => string;
  onPaste: () => void;
  onFreeTextClick: () => void;
  onImageFileUpload: () => void;
  onInitGooglePicker: () => void;
  onImageClick: () => void;
  onStickyNoteClick: () => void;
  onAddStickyNoteColorClick: (color: string) => void;
  onStickyNoteColorClick: (color: string, index: number) => void;
}

interface BuildAnnotationContextMenuOptions {
  annotationType: AnnotationType | undefined;
  shapeType: ShapeType | null;
  annotateActionType: AnnotateActionType | undefined;
  currentStyle: string | undefined;
  colorList: string[] | undefined;
  shapesList: IShapeStyle[] | undefined;
  drawingList: IShapeStyle[];
  annotationId: string | undefined;
  drawStyleToAdd: IShapeStyle;
  onChangeColor: (color: string) => void;
  onChangeDrawStyle: (style: IShapeStyle) => void;
  onAddColor: (color: string) => void;
  onSelectColor: (color: string, index: number) => void;
  onDrawStyleSelect: (style: IShapeStyle, index: number) => void;
  onAddShapeStyle: (style: IShapeStyle) => void;
  onAddDrawStyle: (style: IShapeStyle) => void;
  onSelectShapeStyle: (style: IShapeStyle) => void;
  getContrastedColor: (color: string) => string;
  onCopy: () => void;
  onDelete: () => void;
  onCancel: () => void;
  onCancelFreeTextChanges: () => void;
}

interface BuildTextSelectionContextMenuOptions {
  onCopy: () => void;
  onCreateOutline: () => void;
  getColorsList: (type: AnnotateActionType) => string[];
  getActionColor: (type: AnnotateActionType) => string;
  onAddTextMarkupColorClick: (type: AnnotateActionType) => (color: string) => void;
  onTextMarkupColorClick: (type: AnnotateActionType) => (color: string, index: number) => void;
  onTextMarkupClick: (type: AnnotateActionType) => () => void;
}

export const buildDefaultContextMenuItems = (options: BuildDefaultMenuOptions): ContextMenuItem[] => {
  const menu: ContextMenuItem[] = [];
  if (options.hasPaste) {
    menu.push(
      {
        type: ContextMenuItemType.ITEM,
        title: TOOLTIPS_MAP.TOOLBAR.PASTE,
        icon: (
          <Paste
            style={
              {
                color: 'white',
              } as React.CSSProperties
            }
          />
        ),
        onClick: options.onPaste,
      },
      {
        type: ContextMenuItemType.DIVIDER,
      },
    );
  }
  menu.push(
    {
      type: ContextMenuItemType.ITEM,
      title: AnnotateActionTypeLabel.FreeText,
      icon: <FreeText />,
      onClick: options.onFreeTextClick,
    },
    {
      type: ContextMenuItemType.SUBMENU,
      title: AnnotateActionTypeLabel.Image,
      icon: <Image />,
      menu: [
        {
          type: ContextMenuItemType.ITEM,
          title: TEXT_MAP.IMAGE.FROM_COMPUTER,
          icon: <UploadIcon />,
          onClick: options.onImageFileUpload,
        },
        {
          type: ContextMenuItemType.ITEM,
          title: TEXT_MAP.IMAGE.FROM_GOOGLE_DRIVE,
          icon: <GoogleDrive />,
          disabled: options.disableGoogleDriveUpload,
          onClick: options.onInitGooglePicker,
        },
      ],
      itemsHaveContent: true,
    },
    {
      type: ContextMenuItemType.SUBMENU,
      title: AnnotateActionTypeLabel.StickyNote,
      icon: (
        <StickyNote
          className="assets-icon_colorized"
          style={
            {
              '--fill-color': options.getActionColor(AnnotateActionType.StickyNote),
              '--stroke-color': 'white',
              width: '25px',
            } as React.CSSProperties
          }
        />
      ),
      menu: buildColorPickerMenu(
        options.getColorsList(AnnotateActionType.StickyNote),
        options.onStickyNoteColorClick,
        options.onAddStickyNoteColorClick,
      ),
      onClick: options.onStickyNoteClick,
    },
  );
  return menu;
};

export const buildColorPickerMenu = (
  colorList: string[],
  onColorClick: (color: string, index: number) => void,
  onAddColor: (color: string) => void,
): ContextMenuItem[] => {
  const menu: ContextMenuItem[] = [];
  menu.push(
    ...colorList.map((color, index) => ({
      type: ContextMenuItemType.ITEM,
      icon: (
        <div className="canvas-context-menu__submenu_item-icon">
          <CircleFill size={24} color={rgbAdjustOpacity(color, 1)} />
        </div>
      ),
      onClick: () => onColorClick(color, index),
    })),
  );
  if (colorList.length < MAX_COLOR_LIST_LENGTH) {
    menu.push({
      type: ContextMenuItemType.ITEM,
      icon: (
        <ColorPicker
          className="canvas-context-menu__color-picker"
          icon={<ZoomIN className="assets-icon assets-icon_center" />}
          onSave={onAddColor}
        />
      ),
    });
  }
  return menu;
};

export const buildAnnotationContextMenuOptions = (options: BuildAnnotationContextMenuOptions): ContextMenuItem[] => {
  const menu: ContextMenuItem[] = [];
  if (
    options.colorList &&
    (options.annotationType === AnnotationType.TextMarkup ||
      options.annotationType === AnnotationType.FreeText ||
      options.annotationType === AnnotationType.Text)
  ) {
    menu.push(
      ...options.colorList?.map((color, index) => ({
        type: ContextMenuItemType.ITEM,
        icon: (
          <div className="settings-bar__circle">
            <CircleFill size={24} color={rgbAdjustOpacity(color, 1)} />
            {options.currentStyle === rgbAdjustOpacity(color) && (
              <CheckCircle size={24} color={options.getContrastedColor(color)} />
            )}
          </div>
        ),
        onClick: () => options.onSelectColor(color, index),
      })),
    );
    if (options.colorList?.length < MAX_COLOR_LIST_LENGTH) {
      menu.push({
        type: ContextMenuItemType.ITEM,
        icon: (
          <ColorPicker
            className="canvas-context-menu__color-picker"
            icon={<ZoomIN className="assets-icon assets-icon_center" />}
            onChange={options.onChangeColor}
            color={options.currentStyle}
            onSave={options.onAddColor}
            onCancel={options.annotationType === AnnotationType.FreeText ? options.onCancelFreeTextChanges : undefined}
            hideOpacity={
              options.annotateActionType !== AnnotateActionType.Highlight &&
              options.annotateActionType !== AnnotateActionType.StickyNote
            }
          />
        ),
      });
    }
  } else if (
    options.shapesList &&
    options.shapeType &&
    (options.annotationType === AnnotationType.Line ||
      options.annotationType === AnnotationType.SquareCircle ||
      options.annotationType === AnnotationType.Triangle)
  ) {
    menu.push(
      ...options.shapesList.map((style) => ({
        type: ContextMenuItemType.ITEM,
        icon: (
          <ShapeIcon
            className="annotation-icon assets-icon assets-icon_colorized"
            type={options.shapeType}
            style={style}
          />
        ),
        onClick: () => options.onSelectShapeStyle(style),
      })),
    );
    if (options.shapesList?.length < MAX_COLOR_LIST_LENGTH && options.annotationType) {
      menu.push({
        type: ContextMenuItemType.ITEM,
        icon: (
          <ShapeStylePicker
            className="canvas-context-menu__color-picker"
            id={options.annotationId}
            maxLineWidth={MAX_LINE_WIDTH[options.annotationType]}
            icon={<ZoomIN className="assets-icon assets-icon_center" />}
            onChange={options.onAddShapeStyle}
            onCancel={options.onCancel}
          />
        ),
      });
    }
  } else if (options.drawingList && options.annotationType === AnnotationType.Ink) {
    menu.push(
      ...options.drawingList?.map((shapeStyle, index) => ({
        type: ContextMenuItemType.ITEM,
        icon: (
          <div className="settings-bar__circle">
            <CircleFill size={24} color={rgbAdjustOpacity(shapeStyle?.lineColor, 1)} />
            {_.isEqual(options.drawStyleToAdd, shapeStyle) && (
              <CheckCircle size={24} color={options.getContrastedColor(shapeStyle?.lineColor)} />
            )}
          </div>
        ),
        onClick: () => options.onDrawStyleSelect(shapeStyle, index),
      })),
    );
    if (options.drawingList?.length < MAX_COLOR_LIST_LENGTH) {
      menu.push({
        type: ContextMenuItemType.ITEM,
        icon: (
          <DrawStylePicker
            className="canvas-context-menu__color-picker"
            icon={<ZoomIN className="assets-icon assets-icon_center" />}
            style={options.drawStyleToAdd}
            onChange={options.onChangeDrawStyle}
            onSave={options.onAddDrawStyle}
          />
        ),
      });
    }
  }
  menu.push(
    {
      type: ContextMenuItemType.ITEM,
      title: TOOLTIPS_MAP.TOOLBAR.COPY_ANNOTATION,
      icon: (
        <Copy
          style={
            {
              color: 'white',
            } as React.CSSProperties
          }
        />
      ),
      onClick: options.onCopy,
    },
    {
      type: ContextMenuItemType.ITEM,
      title: TOOLTIPS_MAP.SIDE_MENU.DELETE,
      icon: (
        <Delete
          style={
            {
              color: 'white',
              width: '20px',
              height: '20px',
            } as React.CSSProperties
          }
        />
      ),
      onClick: options.onDelete,
    },
  );
  return menu;
};

export const buildTextSelectionContextMenuOptions = (
  options: BuildTextSelectionContextMenuOptions,
): ContextMenuItem[] => {
  return [
    {
      type: ContextMenuItemType.SUBMENU,
      title: AnnotateActionTypeLabel.Highlight,
      icon: (
        <Highlight
          className="assets-icon_colorized"
          style={
            {
              '--fill-color': rgbAdjustOpacity(options.getActionColor(AnnotateActionType.Highlight), 1),
              '--stroke-color': 'none',
            } as React.CSSProperties
          }
        />
      ),
      menu: buildColorPickerMenu(
        options.getColorsList(AnnotateActionType.Highlight),
        options.onTextMarkupColorClick(AnnotateActionType.Highlight),
        options.onAddTextMarkupColorClick(AnnotateActionType.Highlight),
      ),
      onClick: options.onTextMarkupClick(AnnotateActionType.Highlight),
    },
    {
      type: ContextMenuItemType.SUBMENU,
      title: AnnotateActionTypeLabel.Underline,
      icon: (
        <Underline1
          className="assets-icon_colorized"
          style={
            {
              '--fill-color': rgbAdjustOpacity(options.getActionColor(AnnotateActionType.Underline), 1),
              '--stroke-color': rgbAdjustOpacity(options.getActionColor(AnnotateActionType.Underline), 1),
            } as React.CSSProperties
          }
        />
      ),
      menu: buildColorPickerMenu(
        options.getColorsList(AnnotateActionType.Underline),
        options.onTextMarkupColorClick(AnnotateActionType.Underline),
        options.onAddTextMarkupColorClick(AnnotateActionType.Underline),
      ),
      onClick: options.onTextMarkupClick(AnnotateActionType.Underline),
    },
    {
      type: ContextMenuItemType.SUBMENU,
      title: AnnotateActionTypeLabel.Strikeout,
      icon: (
        <CancelLine
          className="assets-icon_colorized"
          style={
            {
              '--fill-color': rgbAdjustOpacity(options.getActionColor(AnnotateActionType.Strikeout), 1),
              '--stroke-color': 'none',
            } as React.CSSProperties
          }
        />
      ),
      menu: buildColorPickerMenu(
        options.getColorsList(AnnotateActionType.Strikeout),
        options.onTextMarkupColorClick(AnnotateActionType.Strikeout),
        options.onAddTextMarkupColorClick(AnnotateActionType.Strikeout),
      ),
      onClick: options.onTextMarkupClick(AnnotateActionType.Strikeout),
    },
    {
      type: ContextMenuItemType.SUBMENU,
      title: AnnotateActionTypeLabel.Squiggly,
      icon: (
        <Underline2
          className="assets-icon_colorized"
          style={
            {
              '--fill-color': rgbAdjustOpacity(options.getActionColor(AnnotateActionType.Squiggly), 1),
              '--stroke-color': rgbAdjustOpacity(options.getActionColor(AnnotateActionType.Squiggly), 1),
            } as React.CSSProperties
          }
        />
      ),
      menu: buildColorPickerMenu(
        options.getColorsList(AnnotateActionType.Squiggly),
        options.onTextMarkupColorClick(AnnotateActionType.Squiggly),
        options.onAddTextMarkupColorClick(AnnotateActionType.Squiggly),
      ),
      onClick: options.onTextMarkupClick(AnnotateActionType.Squiggly),
    },
    {
      type: ContextMenuItemType.ITEM,
      title: TOOLTIPS_MAP.TOOLBAR.COPY_ANNOTATION,
      icon: (
        <Copy
          style={
            {
              color: 'white',
            } as React.CSSProperties
          }
        />
      ),
      onClick: options.onCopy,
    },
    {
      type: ContextMenuItemType.ITEM,
      title: TOOLTIPS_MAP.TOOLBAR.CREATE_OUTLINE,
      icon: <Outline />,
      onClick: options.onCreateOutline,
    },
  ];
};
