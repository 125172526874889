import React, { memo, FC, useCallback, useState, useMemo } from 'react';
import { Badge, Skeleton } from 'antd';
import { Page, PDFPageProxy } from 'react-pdf';
import classNames from 'classnames';

import './PageDragPreview.less';

interface IPageDragPreview {
  coverPageNumber: number;
  numberOfSelectedPages: number;
  width: number;
  height: number;
  top: number;
  left: number;
}

const PageDragPreview: FC<IPageDragPreview> = (props) => {
  const [width, setWidth] = useState(props.width || 0);
  const [height, setHeight] = useState(props.height || 0);

  const onPageLoaded = useCallback(async (page: PDFPageProxy) => {
    const canvasView = [...page.view];
    let [width, height] = [canvasView[2], canvasView[3]];
    if ([90, 270].includes(page.rotate)) {
      [width, height] = [canvasView[3], canvasView[2]];
    }
    setWidth(width);
    setHeight(height);
  }, []);

  const classes = useMemo(() => {
    return classNames('page-drag-preview', {
      'page-drag-preview_landscape': props.coverPageNumber ? width > height : (props.width || 0) > (props.height || 0),
      'page-drag-preview_portrait': props.coverPageNumber ? width <= height : (props.width || 0) <= (props.height || 0),
    });
  }, [width, height, props.coverPageNumber, props.width, props.height]);

  return props.coverPageNumber !== -1 ? (
    <div className={classes} style={{ top: props.top + 'px', left: props.left + 'px' }}>
      {props.numberOfSelectedPages > 2 ? (
        <canvas className="page-drag-preview_third-page" width={width} height={height} />
      ) : null}
      {props.numberOfSelectedPages > 1 ? (
        <canvas className="page-drag-preview_second-page" width={width} height={height} />
      ) : null}
      <Page
        className="page-drag-preview_first-page"
        loading={
          <Skeleton.Input
            className="page-placeholder"
            style={{ width: props.width + 'px', height: props.height + 'px' }}
          />
        }
        noData={
          <Skeleton.Input
            className="page-placeholder"
            style={{ width: props.width + 'px', height: props.height + 'px', backgroundColor: 'white' }}
          />
        }
        pageNumber={props.coverPageNumber}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        onLoadSuccess={onPageLoaded}
      />
      <Badge count={props.numberOfSelectedPages} overflowCount={Number.MAX_SAFE_INTEGER} />
    </div>
  ) : null;
};

export default memo(PageDragPreview);
