import React from 'react';

import { CustomAny } from '../../types/generics';

interface IErrorBoundaryProps {
  children: CustomAny;
}

interface IErrorBoundaryState {
  error: Error | null;
  info: React.ErrorInfo | null;
  visible: boolean;
}

export default class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { error: null, info: null, visible: process.env.NODE_ENV && process.env.NODE_ENV === 'development' };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({ error, info });
  }

  render(): CustomAny {
    if (this.state.visible && this.state.error) {
      return (
        <div>
          <div>{this.state.error.message}</div>
          <div>{this.state.info?.componentStack}</div>
        </div>
      );
    }

    return this.props.children;
  }
}
