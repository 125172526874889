import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { FONT_FAMILY_DEFAULT } from '@samuelmeuli/font-manager';

import {
  addColor,
  addDrawingStyle,
  addLineStyle,
  selectDrawingStyle,
  selectLineStyle,
  selectLineStyleByStyle,
  selectShapeType,
  setActiveAction,
  setColor,
  setColorByString,
  setDefaultTitle,
  setDrawingStyle,
  setFont,
  setFontSize,
  setFontStyle,
  setGenerateAStream,
  setImageBase64,
  setIsEditing,
  setLineStyle,
  setTypesToRemove,
  setRemovingAnnotation,
  setShapeStyle,
  toggleStyle,
  updateColor,
  setColorByAction,
  addColorByAction,
  selectShapeStyleByType,
  addShapeStyleByType,
} from './actions';
import { IAnnotateSettings } from '../interfaces/IAnnotateSettings';
import { AnnotateActionType, AnnotateActionTypeName } from '../enums/annotateActionType';
import { getItem, setItem } from '../../services/localStorageService';
import {
  LS_COLOR_MAP,
  LS_DEFAULT_ANNOTATION_TITLE,
  LS_DRAWING_STYLE,
  LS_FONT,
  LS_FONT_SIZE,
  LS_GENERATE_STREAM,
  LS_LINE_STYLE,
  LS_SHAPE_STYLE,
  LS_SHAPE_TYPE,
} from '../../shared/constants/localStorageKeys';
import {
  restoreColors,
  restoreDrawingStyles,
  restoreLineStyles,
  restoreShapeStyles,
} from '../../shared/helpers/colorHelper';
import { AnnotateStyle } from '../enums/annotateStyle';
import { ShapeType } from '../enums/shapeType';
import { AnnotationType } from '../enums/annotationType';

const initialState: IAnnotateSettings = {
  activeAction: undefined,
  defaultTitle: getItem(LS_DEFAULT_ANNOTATION_TITLE) || 'New annotation',
  generateAppearanceStream: getItem(LS_GENERATE_STREAM) || true,
  color: {
    [AnnotateActionType.Highlight]: restoreColors(AnnotateActionTypeName.Highlight),
    [AnnotateActionType.Underline]: restoreColors(AnnotateActionTypeName.Underline),
    [AnnotateActionType.Strikeout]: restoreColors(AnnotateActionTypeName.Strikeout),
    [AnnotateActionType.Squiggly]: restoreColors(AnnotateActionTypeName.Squiggly),
    [AnnotateActionType.FreeText]: restoreColors(AnnotateActionTypeName.FreeText),
    [AnnotateActionType.StickyNote]: restoreColors(AnnotateActionTypeName.StickyNote),
    [AnnotateActionType.Shape]: restoreColors(AnnotateActionTypeName.Shape),
    [AnnotateActionType.Image]: restoreColors(AnnotateActionTypeName.Image),
    [AnnotateActionType.Drawing]: restoreColors(AnnotateActionTypeName.Drawing),
    [AnnotateActionType.Eraser]: restoreColors(AnnotateActionTypeName.Eraser),
  },
  font: FONT_FAMILY_DEFAULT,
  fontSize: 14,
  style: {
    bold: false,
    italic: false,
    underline: false,
  },
  isEditing: false,
  removingAnnotation: false,
  lineStyle: {
    [AnnotateActionType.FreeText]: restoreLineStyles(AnnotateActionTypeName.FreeText),
  },
  shapeType: getItem(LS_SHAPE_TYPE) || ShapeType.Line,
  shapeStyle: {
    [ShapeType.Line]: restoreShapeStyles(ShapeType.Line),
    [ShapeType.ArrowLine]: restoreShapeStyles(ShapeType.ArrowLine),
    [ShapeType.TwoArrowsLine]: restoreShapeStyles(ShapeType.TwoArrowsLine),
    [ShapeType.Square]: restoreShapeStyles(ShapeType.Square),
    [ShapeType.Circle]: restoreShapeStyles(ShapeType.Circle),
    [ShapeType.Triangle]: restoreShapeStyles(ShapeType.Triangle),
  },
  drawingStyle: restoreDrawingStyles(),
  imageBase64: null,
  typesToRemove: [AnnotationType.Ink, AnnotationType.Line, AnnotationType.Triangle, AnnotationType.SquareCircle],
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setActiveAction, (state, action) => {
      state.activeAction = action.payload;
    })
    .addCase(setDefaultTitle, (state, action) => {
      state.defaultTitle = action.payload;
      setItem(LS_DEFAULT_ANNOTATION_TITLE, state.defaultTitle);
    })
    .addCase(setGenerateAStream, (state, action) => {
      state.generateAppearanceStream = action.payload;
      setItem(LS_GENERATE_STREAM, state.generateAppearanceStream);
    })
    .addCase(updateColor, (state, action) => {
      if (state.activeAction) {
        let activeIndex = state.color[state.activeAction].activeIndex;
        let colorList = state.color[state.activeAction].list;
        colorList[activeIndex] = action.payload;
        if (colorList.filter((color) => color === action.payload).length > 1) {
          colorList = _.uniq(colorList);
          activeIndex = colorList.indexOf(action.payload);
        }
        state.color = {
          ...state.color,
          [state.activeAction]: {
            activeIndex,
            list: [...colorList],
          },
        };
      }
      setItem(LS_COLOR_MAP, state.color);
    })
    .addCase(addColor, (state, action) => {
      if (state.activeAction) {
        let colorList = state.color[state.activeAction].list;
        let activeIndex = colorList.length;
        if (colorList.includes(action.payload)) {
          activeIndex = colorList.indexOf(action.payload);
        } else {
          colorList = [...colorList, action.payload];
        }
        state.color = {
          ...state.color,
          [state.activeAction]: {
            activeIndex,
            list: [...colorList],
          },
        };
      }
      setItem(LS_COLOR_MAP, state.color);
    })
    .addCase(setColor, (state, action) => {
      if (state.activeAction) {
        const list = state.color[state.activeAction].list;
        state.color = {
          ...state.color,
          [state.activeAction]: {
            activeIndex: action.payload,
            list,
          },
        };
      }
      setItem(LS_COLOR_MAP, state.color);
    })
    .addCase(setColorByAction, (state, action) => {
      const list = state.color[action.payload.actionType].list;
      const activeIndex = list.indexOf(action.payload.color);
      state.color = {
        ...state.color,
        [action.payload.actionType]: {
          activeIndex,
          list,
        },
      };
      setItem(LS_COLOR_MAP, state.color);
    })
    .addCase(addColorByAction, (state, action) => {
      const { actionType, color } = action.payload;
      if (actionType) {
        let colorList = state.color[actionType].list;
        let activeIndex = colorList.length;
        if (colorList.includes(color)) {
          activeIndex = colorList.indexOf(color);
        } else {
          colorList = [...colorList, color];
        }
        state.color = {
          ...state.color,
          [actionType]: {
            activeIndex,
            list: [...colorList],
          },
        };
      }
      setItem(LS_COLOR_MAP, state.color);
    })
    .addCase(setColorByString, (state, action) => {
      if (state.activeAction) {
        const list = state.color[state.activeAction].list;
        const index = list.indexOf(action.payload);
        const activeIndex = index != -1 ? index : list.length;
        const newList = index != -1 ? list : [...list, action.payload];
        state.color = {
          ...state.color,
          [state.activeAction]: {
            activeIndex,
            list: newList,
          },
        };
      }
      setItem(LS_COLOR_MAP, state.color);
    })
    .addCase(setFont, (state, action) => {
      if (state.activeAction) {
        state.font = action.payload;
      }
      setItem(LS_FONT, state.font);
    })
    .addCase(setFontSize, (state, action) => {
      if (state.activeAction) {
        state.fontSize = Number(action.payload);
      }
      setItem(LS_FONT_SIZE, state.font);
    })
    .addCase(toggleStyle, (state, action) => {
      if (state.activeAction) {
        state.style = {
          ...state.style,
          [action.payload]: !state.style[action.payload as AnnotateStyle],
        };
      }
    })
    .addCase(setFontStyle, (state, action) => {
      if (state.activeAction) {
        state.style = action.payload;
      }
    })
    .addCase(setIsEditing, (state, action) => {
      state.isEditing = action.payload;
    })
    .addCase(setRemovingAnnotation, (state, action) => {
      state.removingAnnotation = action.payload;
    })
    .addCase(addLineStyle, (state, action) => {
      if (state.activeAction && state.activeAction === AnnotateActionType.FreeText) {
        let lineStyleList = state.lineStyle[state.activeAction].list;
        const activeIndex = lineStyleList.length;
        lineStyleList = [...lineStyleList, action.payload];
        state.lineStyle = {
          ...state.lineStyle,
          [state.activeAction]: {
            activeIndex,
            list: [...lineStyleList],
          },
        };
      }
      setItem(LS_LINE_STYLE, state.lineStyle);
    })
    .addCase(setLineStyle, (state, action) => {
      if (state.activeAction && state.activeAction === AnnotateActionType.FreeText) {
        const lineStyleList = state.lineStyle[state.activeAction].list;
        const activeIndex = action.payload.index;
        lineStyleList[activeIndex] = action.payload.style;
        state.lineStyle = {
          ...state.lineStyle,
          [state.activeAction]: {
            activeIndex,
            list: [...lineStyleList],
          },
        };
      }
      setItem(LS_LINE_STYLE, state.lineStyle);
    })
    .addCase(selectLineStyle, (state, action) => {
      if (state.activeAction && state.activeAction === AnnotateActionType.FreeText) {
        const lineStyleList = state.lineStyle[state.activeAction].list;
        const activeIndex = action.payload;
        state.lineStyle = {
          ...state.lineStyle,
          [state.activeAction]: {
            activeIndex,
            list: [...lineStyleList],
          },
        };
      }
      setItem(LS_LINE_STYLE, state.lineStyle);
    })
    .addCase(selectLineStyleByStyle, (state, action) => {
      if (state.activeAction && state.activeAction === AnnotateActionType.FreeText) {
        const list = state.lineStyle[state.activeAction].list;
        const index = _.findIndex(
          list,
          (item) =>
            _.isEqual(item, action.payload) ||
            ((!item.borderWidth || !item.borderStyle || !item.borderColor) &&
              (!action.payload.borderWidth || !action.payload.borderStyle || !action.payload.borderColor)),
        );
        const activeIndex = index != -1 ? index : list.length;
        const newList = index != -1 ? list : [...list, action.payload];
        state.lineStyle = {
          ...state.lineStyle,
          [state.activeAction]: {
            activeIndex,
            list: newList,
          },
        };
      }
      setItem(LS_LINE_STYLE, state.lineStyle);
    })
    .addCase(selectShapeType, (state, action) => {
      if (state.activeAction && state.activeAction === AnnotateActionType.Shape) {
        state.shapeType = action.payload;
      }
      setItem(LS_SHAPE_TYPE, state.shapeType);
    })
    .addCase(setShapeStyle, (state, action) => {
      if (state.activeAction && state.activeAction === AnnotateActionType.Shape) {
        const list = [...state.shapeStyle[state.shapeType].list];
        const index = _.findIndex(list, (item) => _.isEqual(item, action.payload));
        const activeIndex = index != -1 ? index : list.length;
        const newList = index != -1 ? list : [...list, action.payload];
        state.shapeStyle = {
          ...state.shapeStyle,
          [state.shapeType]: {
            activeIndex,
            list: newList,
          },
        };
      }
      setItem(LS_SHAPE_STYLE, state.shapeStyle);
    })
    .addCase(selectShapeStyleByType, (state, action) => {
      const shapeStyleList = [...state.shapeStyle[action.payload.shapeType].list];
      const activeIndex = _.findIndex(shapeStyleList, (item) => _.isEqual(item, action.payload.style));
      state.shapeStyle = {
        ...state.shapeStyle,
        [action.payload.shapeType]: {
          activeIndex,
          list: shapeStyleList,
        },
      };
      setItem(LS_SHAPE_STYLE, state.shapeStyle);
    })
    .addCase(addShapeStyleByType, (state, action) => {
      const list = [...state.shapeStyle[action.payload.shapeType].list];
      const index = _.findIndex(list, (item) => _.isEqual(item, action.payload.style));
      const activeIndex = index != -1 ? index : list.length;
      const newList = index != -1 ? list : [...list, action.payload.style];
      state.shapeStyle = {
        ...state.shapeStyle,
        [action.payload.shapeType]: {
          activeIndex,
          list: newList,
        },
      };
      setItem(LS_SHAPE_STYLE, state.shapeStyle);
    })
    .addCase(setImageBase64, (state, action) => {
      state.imageBase64 = action.payload;
    })
    .addCase(setDrawingStyle, (state, action) => {
      if (state.activeAction && state.activeAction === AnnotateActionType.Drawing) {
        const drawingStyleList = state.drawingStyle.list;
        const activeIndex = action.payload.index;
        drawingStyleList[activeIndex] = action.payload.style;
        state.drawingStyle = {
          activeIndex,
          list: [...drawingStyleList],
        };
      }
      setItem(LS_DRAWING_STYLE, state.drawingStyle);
    })
    .addCase(addDrawingStyle, (state, action) => {
      const list = state.drawingStyle.list;
      const index = _.findIndex(list, (item) => _.isEqual(item, action.payload));
      const activeIndex = index != -1 ? index : list.length;
      const newList = index != -1 ? list : [...list, action.payload];
      state.drawingStyle = {
        activeIndex,
        list: [...newList],
      };
      setItem(LS_DRAWING_STYLE, state.drawingStyle);
    })
    .addCase(selectDrawingStyle, (state, action) => {
      state.drawingStyle = {
        ...state.drawingStyle,
        activeIndex: action.payload,
      };
      setItem(LS_DRAWING_STYLE, state.drawingStyle);
    })
    .addCase(setTypesToRemove, (state, action) => {
      state.typesToRemove = action.payload;
    });
});
