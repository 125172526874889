import { createAction } from '@reduxjs/toolkit';

import { IAnnotationEntity } from '../../services/interfaces/IAnnotation';
import { OrNull } from '../../shared/types/generics';

export const initAnnotations = createAction<IAnnotationEntity[]>('annotations/init');
export const addAnnotation = createAction<IAnnotationEntity>('annotations/addAnnotation');
export const setActiveAnnotation = createAction<OrNull<number>>('annotations/activeAnnotation');
export const setCopiedAnnotation = createAction<IAnnotationEntity | undefined>('annotations/copiedAnnotation');
export const updateAnnotation =
  createAction<{ annotation: IAnnotationEntity; index: number }>('annotations/updateAnnotation');
export const deleteAnnotation = createAction<number>('annotations/deleteAnnotation');
export const deleteAllAnnotations = createAction<void>('annotations/deleteAll');
