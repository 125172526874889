import { createAction } from '@reduxjs/toolkit';

import { AnnotateActionType } from '../enums/annotateActionType';
import { IFontStyle } from '../interfaces/IFontStyle';
import { ILineStyle } from '../../shared/components/lineStyleSelect/interfaces/ILineStyle';
import { ShapeType } from '../enums/shapeType';
import { IShapeStyle } from '../interfaces/IShapeStyle';
import { SquareCircleType } from '../enums/squareCircleType';

export const setActiveAction = createAction<AnnotateActionType | undefined>('annotate/activeAction');
export const updateColor = createAction<string>('annotate/updateColor');
export const addColor = createAction<string>('annotate/addColor');
export const setColor = createAction<number>('annotate/setColor');
export const setColorByString = createAction<string>('annotate/setColorByString');
export const setColorByAction =
  createAction<{ actionType: AnnotateActionType; color: string }>('annotate/setColorByAction');
export const addColorByAction =
  createAction<{ actionType: AnnotateActionType; color: string }>('annotate/addColorByAction');
export const setDefaultTitle = createAction<string>('annotate/defaultTitle');
export const setGenerateAStream = createAction<boolean>('annotate/setGenerateAppearanceStream');
export const setFont = createAction<string>('annotate/setFont');
export const setFontSize = createAction<number>('annotate/setFontSize');
export const toggleStyle = createAction<string>('annotate/toggleStyle');
export const setFontStyle = createAction<IFontStyle>('annotate/setFontStyle');
export const setIsEditing = createAction<boolean>('annotate/setIsEditing');
export const setRemovingAnnotation = createAction<boolean>('annotate/setRemovingAnnotation');
export const addLineStyle = createAction<ILineStyle>('annotate/addLineStyle');
export const setLineStyle = createAction<{ style: ILineStyle; index: number }>('annotate/setLineStyle');
export const selectLineStyle = createAction<number>('annotate/selectLineStyle');
export const selectLineStyleByStyle = createAction<ILineStyle>('annotate/selectLineStyleByStyle');
export const selectShapeType = createAction<ShapeType>('annotate/selectShapeType');
export const setShapeStyle = createAction<IShapeStyle>('annotate/setShapeStyle');
export const selectShapeStyleByType =
  createAction<{ shapeType: ShapeType | SquareCircleType; style: IShapeStyle }>('annotate/setShapeStyleByType');
export const addShapeStyleByType =
  createAction<{ shapeType: ShapeType | SquareCircleType; style: IShapeStyle }>('annotate/addShapeStyleByType');
export const setImageBase64 = createAction<string | null>('annotate/setImageBase64');
export const setDrawingStyle = createAction<{ style: IShapeStyle; index: number }>('annotate/setDrawingStyle');
export const addDrawingStyle = createAction<IShapeStyle>('annotate/addDrawingStyle');
export const selectDrawingStyle = createAction<number>('annotate/selectDrawingStyle');
export const setTypesToRemove = createAction<string[]>('annotate/setTypesToRemove');
