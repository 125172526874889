import React, { FC, useContext, useEffect, useCallback, useState, memo } from 'react';
import { Document, pdfjs } from 'react-pdf';
import { ListRowProps } from 'react-virtualized/dist/es/List';
import { List } from 'react-virtualized';
import { useDispatch, useSelector } from 'react-redux';

import { FileContext } from '../fileContext/FileContext';
import { getNumPages, getPage, isSinglePageView } from '../../../store/viewerSettings/selectors';
import CustomScroll from '../../components/customScroll/CustomScroll';
import PageThumbnail from '../../components/pageThumbnail/PageThumbnail';
import { scrollThumbnailsTo } from '../../helpers/pdfViewerHelper';
import { setPage, setScrollIntoPage } from '../../../store/viewerSettings/actions';
import FileLoading from '../../components/fileLoading/FileLoading';
import NoData from '../../components/noData/NoData';
import { TEXT_MAP } from '../../constants/application';

import './ThumbnailsPanel.less';

const ThumbnailsPanel: FC = () => {
  const { file } = useContext(FileContext);
  const dispatch = useDispatch();
  const singlePageView: boolean = useSelector(isSinglePageView);
  const numPages: number = useSelector(getNumPages);
  const page: number = useSelector(getPage);
  const [listScrollTop, setListScrollTop] = useState(0);
  const [listHeight, setListHeight] = useState(250);
  const [isForcePageUpdate, setIsForcePageUpdate] = useState(false);
  const [visiblePages, setVisiblePages] = useState<number[]>([]);
  const [activeThumbnail, setActiveThumbnail] = useState(page);

  const onContainerScroll = useCallback((e) => {
    setListScrollTop(e.target.scrollTop);
    setListHeight(e.target.offsetHeight);
  }, []);

  const onRowsRendered = useCallback((renderedRows) => {
    const { startIndex, stopIndex } = renderedRows;
    const visibleArray = Array.from(new Array(stopIndex - startIndex + 1), (_el, index) => startIndex + index + 1);
    setVisiblePages(visibleArray);
  }, []);

  useEffect(
    useCallback(() => {
      if (!isForcePageUpdate) {
        setActiveThumbnail(page);
      }

      if (!isForcePageUpdate && !visiblePages.includes(page)) {
        setTimeout(() => {
          scrollThumbnailsTo(250 * (page - 1));
        }, 1000);
      } else if (page === activeThumbnail) {
        setIsForcePageUpdate(false);
      }
    }, [visiblePages, page, isForcePageUpdate, activeThumbnail]),
    [page],
  );

  useEffect(() => {
    scrollThumbnailsTo(250 * (activeThumbnail - 1));
  }, []);

  const changePage = useCallback(
    (pageNumber: number) => {
      setActiveThumbnail(pageNumber);
      setIsForcePageUpdate(true);
      if (!singlePageView) {
        dispatch(setScrollIntoPage(pageNumber));
      } else {
        dispatch(setPage(pageNumber));
      }
    },
    [singlePageView],
  );

  function cellRenderer(cellData: ListRowProps) {
    return <PageThumbnail activeThumbnail={activeThumbnail} changePage={changePage} {...cellData} />;
  }

  return (
    <section className="thumbnails-panel">
      <CustomScroll onScroll={onContainerScroll}>
        <Document
          loading={<FileLoading className="thumbnails-panel__loading" />}
          noData={<NoData description={TEXT_MAP.ERROR.NO_FILE_SPECIFIED} />}
          file={file}
          options={{
            cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
        >
          {file && numPages === 0 ? <NoData description={TEXT_MAP.ERROR.EMPTY_FILE} /> : null}
          <List
            rowRenderer={cellRenderer}
            autoContainerWidth
            autoHeight
            height={listHeight}
            overscanRowCount={3}
            rowCount={numPages}
            rowHeight={250}
            width={350}
            scrollTop={listScrollTop}
            onRowsRendered={onRowsRendered}
            scrollToIndex={page - 1}
          />
        </Document>
      </CustomScroll>
    </section>
  );
};

export default memo(ThumbnailsPanel);
