import React, { memo, useCallback, useState, forwardRef, useMemo } from 'react';
import { Page, PDFPageProxy } from 'react-pdf';
import { Badge, Skeleton } from 'antd';
import classNames from 'classnames';

import './PageThumbnailControllable.less';

interface IPageThumbnailControllable {
  pageNumber: number;
  active: boolean;
  index: number;
  page?: number;
  width?: number;
  height?: number;
  handleDoubleClick(page?: number): void;
}

const PageThumbnailControllable = forwardRef<HTMLDivElement, IPageThumbnailControllable>((props, ref) => {
  const [width, setWidth] = useState(props.width || 0);
  const [height, setHeight] = useState(props.height || 0);
  const [rendering, setRendering] = useState(!!props.page);

  const onPageLoaded = useCallback(async (page: PDFPageProxy) => {
    const canvasView = [...page.view];
    let [width, height] = [canvasView[2], canvasView[3]];
    if ([90, 270].includes(page.rotate)) {
      [width, height] = [canvasView[3], canvasView[2]];
    }
    setWidth(width);
    setHeight(height);
  }, []);
  const onPageRender = () => {
    setRendering(false);
  };
  const classes = useMemo(() => {
    return classNames('page-thumbnail-controllable', {
      'page-thumbnail-controllable_landscape': props.page ? width > height : (props.width || 0) > (props.height || 0),
      'page-thumbnail-controllable_portrait': props.page ? width <= height : (props.width || 0) <= (props.height || 0),
      'page-thumbnail-controllable_active': props.active,
      'page-thumbnail-controllable_rendering': rendering,
    });
  }, [width, height, props.active, rendering, props.page, props.width, props.height]);
  const onDoubleClick = useCallback(() => {
    props.handleDoubleClick(props.index);
  }, [props.handleDoubleClick, props.index]);

  return (
    <div className={classes}>
      <div ref={ref} onDoubleClick={onDoubleClick}>
        {props.page ? (
          <Page
            loading={
              <Skeleton.Input className="page-placeholder" style={{ width: width + 'px', height: height + 'px' }} />
            }
            pageNumber={props.page}
            onLoadSuccess={onPageLoaded}
            onRenderSuccess={onPageRender}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        ) : (
          <div className="fake-page">
            <canvas width={props.width} height={props.height} />
          </div>
        )}
      </div>
      <Badge count={props.pageNumber} overflowCount={Number.MAX_SAFE_INTEGER} />
    </div>
  );
});

export default memo(PageThumbnailControllable);
