import React, { FC, memo } from 'react';
import { Col, Divider, Row } from 'antd';

import { IPdfInfoCardData } from './interfaces/IPdfInfoCardData';
import { PdfInfoLabel } from './enums/PdfInfo';
import { parseFileSize, parsePdfDate } from '../../helpers/fileHelper';

interface IPdfInfoCard {
  pdfInfo: IPdfInfoCardData;
}

const PdfInfoCard: FC<IPdfInfoCard> = (props) => {
  const { pdfInfo } = props;

  return (
    <>
      <Row gutter={16}>
        <Col span={8}>{PdfInfoLabel.Name}:</Col>
        <Col span={16}>{pdfInfo.name || '—'}</Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>{PdfInfoLabel.Size}:</Col>
        <Col span={16}>{pdfInfo.size ? parseFileSize(pdfInfo.size) : '—'}</Col>
      </Row>
      <Divider style={{ margin: '10px 0' }} />
      <Row gutter={16}>
        <Col span={8}>{PdfInfoLabel.Title}:</Col>
        <Col span={16}>{pdfInfo.title || '—'}</Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>{PdfInfoLabel.Author}:</Col>
        <Col span={16}>{pdfInfo.author || '—'}</Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>{PdfInfoLabel.CreationDate}:</Col>
        <Col span={16}>{pdfInfo.creationDate ? parsePdfDate(pdfInfo.creationDate) : '—'}</Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>{PdfInfoLabel.ModificationDate}:</Col>
        <Col span={16}>{pdfInfo.modificationDate ? parsePdfDate(pdfInfo.modificationDate) : '—'}</Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>{PdfInfoLabel.Creator}:</Col>
        <Col span={16}>{pdfInfo.creator || '—'}</Col>
      </Row>
      <Divider style={{ margin: '10px 0' }} />
      <Row gutter={16}>
        <Col span={8}>{PdfInfoLabel.PdfFormatVersion}:</Col>
        <Col span={16}>{pdfInfo.pdfFormatVersion || '—'}</Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>{PdfInfoLabel.NumPages}:</Col>
        <Col span={16}>{pdfInfo.numPages || '—'}</Col>
      </Row>
    </>
  );
};

export default memo(PdfInfoCard);
