import React, { FC, useMemo, memo } from 'react';
import { Button as AntdButton, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import classNames from 'classnames';

import { ButtonType } from './enums/buttonType';
import { HtmlType } from './enums/htmlType';

import './Button.less';

interface IButtonProps {
  type: ButtonType;
  icon?: React.ReactNode;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  tooltipPlacement?: TooltipPlacement;
  toggled?: boolean;
  htmlType?: HtmlType;
  ghost?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onDoubleClick?: React.MouseEventHandler<HTMLElement>;
  content?: React.ReactNode;
}

const Button: FC<IButtonProps> = (props) => {
  const { type = ButtonType.Default } = props;
  const AntdButtonComponent = useMemo(
    () => (
      <AntdButton
        type={type}
        icon={props.icon}
        disabled={props.disabled}
        ghost={props.ghost}
        onClick={props.onClick}
        className={classNames('app-button', { 'app-button_toggle': props.toggled })}
        htmlType={props.htmlType}
        onDoubleClick={props.onDoubleClick}
      >
        {props.content}
      </AntdButton>
    ),
    [type, props],
  );

  return !props.disabled ? (
    <Tooltip title={props.tooltip} arrowPointAtCenter placement={props.tooltipPlacement} mouseLeaveDelay={0}>
      {AntdButtonComponent}
    </Tooltip>
  ) : (
    AntdButtonComponent
  );
};

export default memo(Button);
