import { CustomAny } from '../shared/types/generics';

export const setItem = (item: string, value: CustomAny): void => {
  localStorage.setItem(item, JSON.stringify(value));
};

export const getItem = (item: string): CustomAny => {
  const itemValue = localStorage.getItem(item);
  if (typeof itemValue !== 'string') {
    return undefined;
  }

  return JSON.parse(itemValue);
};

export const removeItem = (item: string): void => {
  localStorage.removeItem(item);
};
