import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { Slider, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Trash } from 'react-bootstrap-icons';

import { AnyFunction, OrNull } from '../../types/generics';
import { basicColorMap, getColorAndOpacity, rgbAdjustOpacity } from '../../helpers/colorHelper';
import ColorGrid from './colorGrid/ColorGrid';
import { getFavoriteColors } from '../../../store/colorsStore/selectors';
import Dialog from '../dialog/Dialog';
import { addFavoriteColor, removeFavoriteColor } from '../../../store/colorsStore/actions';
import Button from '../button/Button';
import { ButtonType } from '../button/enums/buttonType';
import { TEXT_MAP } from '../../constants/application';

import './FavoriteColorsDialog.less';

interface IFavoritesColorsDialogProps {
  visible?: boolean;
  color?: string;
  onOk: AnyFunction;
  onChange?: AnyFunction;
  hideOpacity?: boolean;
  onCancel(): void;
}

const FavoritesColorsDialog: FC<IFavoritesColorsDialogProps> = (props) => {
  const maxFavColorNumber = 48;
  const dispatch = useDispatch();
  const favoriteColors = useSelector(getFavoriteColors);
  const [opacity, setOpacity] = useState<number>(
    !props.hideOpacity && props.color ? getColorAndOpacity(props.color)[1] : 1,
  );
  const [selectedColor, setSelectedColor] = useState<OrNull<string>>(
    props.color ? getColorAndOpacity(props.color)[0] : basicColorMap[0],
  );
  const isFavoriteSelected = useMemo(() => favoriteColors.includes(selectedColor), [selectedColor, favoriteColors]);

  useEffect(() => {
    if (props.color !== rgbAdjustOpacity(selectedColor, opacity)) {
      props.onChange?.(rgbAdjustOpacity(selectedColor, opacity));
    }
  }, [selectedColor, opacity]);

  const onDialogOk = useCallback(() => {
    if (!selectedColor) {
      props.onCancel?.();
    }
    addFavorite(selectedColor);
    props.onOk?.(rgbAdjustOpacity(selectedColor as string, opacity));
  }, [favoriteColors, selectedColor, opacity, props.onOk, props.onCancel]);
  const onDialogCancel = useCallback(() => {
    props.onCancel?.();
  }, [props.onCancel]);
  const onFavoriteAdd = useCallback(
    (color) => {
      addFavorite(color);
      setSelectedColor(color);
    },
    [favoriteColors],
  );
  const onFavoriteRemove = useCallback(() => {
    dispatch(removeFavoriteColor(selectedColor));
    setSelectedColor(basicColorMap[0]);
  }, [selectedColor]);
  const addFavorite = (color?: string) => {
    if (color && !favoriteColors.includes(color) && favoriteColors.length < maxFavColorNumber) {
      dispatch(addFavoriteColor(color));
    }
  };

  useEffect(() => {
    if (props.color) {
      setSelectedColor(getColorAndOpacity(props.color)[0]);
      setOpacity(props.hideOpacity ? 1 : getColorAndOpacity(props.color)[1]);
    }
  }, [props.color, props.hideOpacity]);

  return (
    <Dialog
      className="favorites-colors-dialog"
      wrapClassName="favorites-colors-dialog-wrap"
      visible={props.visible}
      destroyOnClose
      onOk={onDialogOk}
      onCancel={onDialogCancel}
      maskClosable
      closable={false}
      width="400px"
    >
      <Typography.Title level={5}>{TEXT_MAP.COLORS.BASIC}</Typography.Title>
      <ColorGrid colors={basicColorMap} selected={selectedColor} onChange={setSelectedColor} />
      <div className="title-with-action">
        <Typography.Title level={5}>{TEXT_MAP.COLORS.FAVORITES}</Typography.Title>
        {isFavoriteSelected && <Button type={ButtonType.Text} icon={<Trash />} onClick={onFavoriteRemove} />}
      </div>
      <ColorGrid
        colors={favoriteColors}
        selected={selectedColor}
        onChange={setSelectedColor}
        onAdd={favoriteColors.length < maxFavColorNumber ? onFavoriteAdd : undefined}
      />
      {!props.hideOpacity && <Typography.Title level={5}>{TEXT_MAP.COLORS.OPACITY}</Typography.Title>}
      {!props.hideOpacity && (
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Slider defaultValue={opacity} min={0} max={1} step={0.01} onAfterChange={setOpacity} />
        </div>
      )}
    </Dialog>
  );
};

export default FavoritesColorsDialog;
