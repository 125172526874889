import { fabric } from 'fabric';

import { IRectangle } from '../../services/interfaces/IAnnotation';
import { adjustRectangleWithViewport, rotateRectangle } from '../../services/annotationService';
import { getReverseRotationAngle, rotatePoints, swapWidthAndHeight } from './canvasHelper';

export const setSelectable = (selectable: boolean, canvas: fabric.Canvas): void => {
  canvas.forEachObject((object) => {
    object.selectable = selectable;
    object.lockMovementX = !selectable;
    object.lockMovementY = !selectable;
    object.lockSkewingX = !selectable;
    object.lockSkewingY = !selectable;
    object.lockRotation = !selectable;
    object.lockScalingX = !selectable;
    object.lockScalingY = !selectable;
    object.setCoords();
  });
};

export const convertY = (y: number, viewport: number[], rotationAngle = 0): number => {
  const height = swapWidthAndHeight(rotationAngle) ? viewport[2] - viewport[0] : viewport[3] - viewport[1];
  return (y - height) * -1;
};

/**
 * Converts fabric.Object coordinates on canvas to rectangle coordinates in PDF
 * @param object - object to convert coordinates for
 * @param viewport
 * @param useScale
 * @param rotationAngle
 * @param adjustWidthHeight
 * @private
 */
export const getObjectRectangle = (
  object: fabric.Object,
  viewport: number[],
  useScale?: boolean,
  rotationAngle = 0,
  adjustWidthHeight?: boolean,
): IRectangle => {
  const { left = 0, top = 0, width = 0, height = 0, scaleX = 1, scaleY = 1 } = object;
  let rectangle = {
    lowerLeftX: left,
    upperRightX: left + width * (useScale ? scaleX : 1),
    upperRightY: top,
    lowerLeftY: top + height * (useScale ? scaleY : 1),
  };
  if (adjustWidthHeight) {
    switch (rotationAngle) {
      case 270:
        rectangle.lowerLeftX -= width * (useScale ? scaleX : 1);
        rectangle.upperRightX -= width * (useScale ? scaleX : 1);
        break;
      case 180:
        rectangle.lowerLeftX -= width * (useScale ? scaleX : 1);
        rectangle.upperRightX -= width * (useScale ? scaleX : 1);
        rectangle.lowerLeftY -= height * (useScale ? scaleY : 1);
        rectangle.upperRightY -= height * (useScale ? scaleY : 1);
        break;
      case 90:
        rectangle.lowerLeftY -= height * (useScale ? scaleY : 1);
        rectangle.upperRightY -= height * (useScale ? scaleY : 1);
        break;
      default:
        break;
    }
  }
  rectangle.lowerLeftY = convertY(rectangle.lowerLeftY, viewport, rotationAngle);
  rectangle.upperRightY = convertY(rectangle.upperRightY, viewport, rotationAngle);
  rectangle = adjustRectangleWithViewport(rectangle, viewport, true, rotationAngle);
  rectangle = rotateRectangle(rectangle, viewport, rotationAngle, true);
  return rectangle;
};

export const getObjectRectangleWithoutRotation = (
  object: fabric.Object,
  viewport: number[],
  useScale?: boolean,
  rotationAngle = 0,
): IRectangle => {
  const { left = 0, top = 0, width = 0, height = 0, scaleX = 1, scaleY = 1 } = object;
  let objectTop = viewport[3] - top - height * (useScale ? scaleY : 1);
  let objectLeft = left + viewport[0];
  switch (rotationAngle) {
    case 270:
      objectTop = left - height * (useScale ? scaleY : 1) + viewport[1];
      objectLeft = top + viewport[0];
      break;
    case 180:
      objectTop = top + viewport[1] - height * (useScale ? scaleY : 1);
      objectLeft = viewport[2] - left;
      break;
    case 90:
      objectTop = viewport[3] - left - height * (useScale ? scaleY : 1);
      objectLeft = viewport[2] - top;
      break;
    default:
      break;
  }

  return {
    lowerLeftX: objectLeft,
    upperRightX: objectLeft + width * (useScale ? scaleX : 1),
    upperRightY: objectTop,
    lowerLeftY: objectTop + height * (useScale ? scaleY : 1),
  };
};

export const updateShapeRectangleWithLineWidth = (
  rectangle: IRectangle,
  lineWidth: number,
  rotationAngle: number,
): IRectangle => {
  const newRectangle = { ...rectangle };
  switch (rotationAngle) {
    case 0:
      newRectangle.upperRightX += lineWidth;
      newRectangle.lowerLeftY -= lineWidth;
      break;
    case 90:
      newRectangle.upperRightX += lineWidth;
      newRectangle.upperRightY += lineWidth;
      break;
    case 180:
      newRectangle.lowerLeftX -= lineWidth;
      newRectangle.upperRightY += lineWidth;
      break;
    case 270:
      newRectangle.lowerLeftX -= lineWidth;
      newRectangle.lowerLeftY -= lineWidth;
      break;
    default:
      break;
  }
  return newRectangle;
};

export const getTriangleVerticesWithRotationAngle = (
  minX: number,
  minY: number,
  maxX: number,
  maxY: number,
  rotationAngle: number,
  viewport: number[],
): number[] => {
  let vertices: number[] = [];
  switch (rotationAngle) {
    case 0:
      vertices = [minX, maxY, (minX + maxX) / 2, minY, maxX, maxY];
      break;
    case 90:
      vertices = [minX, minY, maxX, (minY + maxY) / 2, minX, maxY];
      break;
    case 180:
      vertices = [maxX, minY, (minX + maxX) / 2, maxY, minX, minY];
      break;
    case 270:
      vertices = [maxX, maxY, minX, (minY + maxY) / 2, maxX, minY];
      break;
    default:
      break;
  }
  return rotatePoints(getReverseRotationAngle(rotationAngle), vertices, viewport, true);
};

export const getRotatedObjectCoordinates = (
  object: fabric.Object,
  rotationAngle: number,
  withOffset: boolean,
): { left: number; top: number; width: number; height: number; leftOffset?: number; topOffset?: number } => {
  const { top = 0, left = 0, width = 0, height = 0, scaleX = 1, scaleY = 1, strokeWidth = 0 } = object;
  switch (rotationAngle) {
    case 90:
      return {
        left: left - (withOffset ? height * scaleY + strokeWidth : 0),
        top: top,
        width: height * scaleY + strokeWidth,
        height: width * scaleX + strokeWidth,
        leftOffset: withOffset ? height * scaleY : 0,
      };
    case 180:
      return {
        left: left - (withOffset ? width * scaleX + strokeWidth : 0),
        top: top - (withOffset ? height * scaleY + strokeWidth : 0),
        width: width * scaleX + strokeWidth,
        height: height * scaleY + strokeWidth,
        leftOffset: withOffset ? width * scaleX : 0,
        topOffset: withOffset ? height * scaleY : 0,
      };
    case 270:
      return {
        left: left,
        top: top - (withOffset ? width * scaleX + strokeWidth : 0),
        width: height * scaleY + strokeWidth,
        height: width * scaleX + strokeWidth,
        topOffset: withOffset ? width * scaleX : 0,
      };
    default:
      return {
        left: left,
        top: top,
        width: width * scaleX + strokeWidth,
        height: height * scaleY + strokeWidth,
      };
  }
};
