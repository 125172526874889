import React, { FC, useCallback, useMemo } from 'react';
import ColorPicker from '../colorPicker/ColorPicker';
import { CheckCircle, CircleFill } from 'react-bootstrap-icons';
import { getContrastColor, rgbAdjustOpacity, stringToRGB } from '../../helpers/colorHelper';
import { ReactComponent as ZoomIN } from '../../../assets/ZoomIN.svg';
import { MAX_COLOR_LIST_LENGTH } from '../../constants/application';

interface IStylePickerWithActiveItem {
  activeColors: string[];
  activeColorIndex: number;
  onColorChange(color: string): void;
  onColorSelect(index: number): () => void;
  onAddColor(color: string): void;
  hideOpacity?: boolean;
}

const ColorPickersContainer: FC<IStylePickerWithActiveItem> = (props) => {
  const contrastedColor = useMemo(() => {
    const [r, g, b] = stringToRGB(props.activeColors[props.activeColorIndex]);
    return getContrastColor(r, g, b);
  }, [props.activeColors, props.activeColorIndex]);
  const colorPickerIcon = useCallback(
    (color: string, index: number) => (
      <div className="settings-bar__circle">
        <CircleFill size={24} color={rgbAdjustOpacity(color, 1)} />
        {props.activeColorIndex === index && <CheckCircle size={24} color={contrastedColor} />}
      </div>
    ),
    [props.activeColorIndex, props.activeColors],
  );
  return (
    <>
      {props.activeColors.map((color: string, index: number) =>
        props.activeColorIndex === index ? (
          <ColorPicker
            className="settings-bar__color-picker"
            key={color}
            icon={colorPickerIcon(color, index)}
            onSave={props.onColorChange}
            color={color}
            hideOpacity={props.hideOpacity}
          />
        ) : (
          <div
            className="settings-bar__color-picker settings-bar__color-picker_readonly"
            onClick={props.onColorSelect(index)}
            key={color}
          >
            <CircleFill size={24} color={color} />
          </div>
        ),
      )}
      {props.activeColors.length < MAX_COLOR_LIST_LENGTH && (
        <ColorPicker
          className="settings-bar__color-picker"
          icon={<ZoomIN className="assets-icon assets-icon_center" />}
          onSave={props.onAddColor}
          color={props.activeColors[props.activeColorIndex]}
          hideOpacity={props.hideOpacity}
        />
      )}
    </>
  );
};

export default ColorPickersContainer;
