import _ from 'lodash';
import { TextItem } from 'react-pdf/dist/Page';

import { concatBboxes, isRectsCross } from '../shared/helpers/canvasHelper';
import { TBbox } from '../shared/types/bbox';
import { TBboxMap } from '../shared/types/bboxMap';
import { AnnotateActionType } from '../store/enums/annotateActionType';
import { ITextMetrics } from './interfaces/ITextMetrics';
import { ICanvasServiceProps } from './interfaces/IFabric';

interface ITextField extends TextItem {
  letters: TBboxMap;
  baseline?: number;
  hanging?: number;
  underline?: number;
}

export class CanvasService {
  viewport: number[];
  scale: number;
  _textFields!: ITextField[];
  _highlightBox?: TBbox;

  constructor(props: ICanvasServiceProps) {
    this.viewport = props.viewport;
    this.scale = props.scale;
  }

  adjustTextFields(_textFields: TextItem[], ctx?: CanvasRenderingContext2D): ITextField[] {
    return _textFields.map((textField: TextItem): ITextField => {
      const [baseline, hanging, underline] = this.getTextFieldActualSize(textField, ctx);
      return {
        ...textField,
        letters: this.generateLettersBboxes(textField, ctx),
        baseline,
        hanging,
        underline,
      };
    });
  }

  getFieldY(ctx: CanvasRenderingContext2D, textField: TextItem): number {
    const [ascent, descent] = CanvasService.getTextMeasures(ctx);
    const yScale = textField.height / (descent + ascent);
    return this.viewport[3] - textField.transform[5] - textField.height / yScale + descent;
  }

  getFieldX(textField: TextItem): number {
    return textField.transform[4] - this.viewport[0];
  }

  // Returns textField under cursor position
  findTextIndexByCoords(x: number, y: number, ctx?: CanvasRenderingContext2D): number {
    if (!ctx) {
      return -1;
    }

    return _.findIndex(this._textFields, (textField: TextItem) => {
      ctx.font = CanvasService.getFieldFont(textField);
      const fieldX = this.getFieldX(textField);
      const fieldY = this.getFieldY(ctx, textField);
      const [ascent] = CanvasService.getTextMeasures(ctx);
      return isRectsCross(
        [x, y, x, y],
        [
          fieldX * this.scale,
          fieldY * this.scale,
          (fieldX + textField.width) * this.scale,
          (fieldY + ascent) * this.scale,
        ],
      );
    });
  }

  // Get bbox from text field which should be highlighted
  getHighlightFieldLetters(data: {
    textField: ITextField;
    fromStart: boolean;
    toEnd: boolean;
    mode?: AnnotateActionType;
    ctx?: CanvasRenderingContext2D;
  }): { bboxes: TBbox[]; selectedString: string } {
    const { textField, fromStart = false, toEnd = false, mode, ctx } = data;
    if (!ctx) {
      return {
        bboxes: [],
        selectedString: '',
      };
    }
    const bboxes: TBbox[] = [];
    const hightlightBbox: TBbox[] = [];
    let firstIndex = 0;
    let lastIndex = 0;
    ctx.font = CanvasService.getFieldFont(textField);
    Object.values(textField.letters).forEach((letter, index) => {
      if (!letter) return;
      const scaledBbox: TBbox = [
        letter[0] * this.scale,
        letter[1] * this.scale,
        letter[2] * this.scale,
        letter[3] * this.scale,
      ];
      if (this._highlightBox && isRectsCross(scaledBbox, this._highlightBox)) {
        if (!bboxes.length) {
          firstIndex = index;
        }
        lastIndex = index;
        hightlightBbox.push([...letter]);
        bboxes.push([
          letter[0],
          (mode === AnnotateActionType.Strikeout && textField.hanging) || letter[1],
          letter[2],
          (mode === AnnotateActionType.Strikeout && textField.baseline) ||
            (mode === AnnotateActionType.Underline && textField.underline) ||
            letter[3],
        ]);
      }
    });
    if (fromStart) {
      for (let i = 0; i < firstIndex; i++) {
        const letterBbox = textField.letters[i];
        if (letterBbox) {
          hightlightBbox.push([...letterBbox]);
          bboxes.push([
            letterBbox[0],
            (mode === AnnotateActionType.Strikeout && textField.hanging) || letterBbox[1],
            letterBbox[2],
            (mode === AnnotateActionType.Strikeout && textField.baseline) ||
              (mode === AnnotateActionType.Underline && textField.underline) ||
              letterBbox[3],
          ]);
        }
      }
      firstIndex = 0;
    }

    if (toEnd) {
      for (let i = lastIndex; i < Object.values(textField.letters).length; i++) {
        const letterBbox = textField.letters[i];
        if (letterBbox) {
          hightlightBbox.push([...letterBbox]);
          bboxes.push([
            letterBbox[0],
            (mode === AnnotateActionType.Strikeout && textField.hanging) || letterBbox[1],
            letterBbox[2],
            (mode === AnnotateActionType.Strikeout && textField.baseline) ||
              (mode === AnnotateActionType.Underline && textField.underline) ||
              letterBbox[3],
          ]);
        }
      }
      lastIndex = Object.values(textField.letters).length - 1;
    }

    return {
      bboxes: bboxes.length ? [concatBboxes(bboxes), concatBboxes(hightlightBbox)] : [],
      selectedString: textField.str.slice(firstIndex, lastIndex + 1),
    };
  }

  // Returns array of indexes for highlighted text fields
  getHighlightedTextIndexes(ctx?: CanvasRenderingContext2D): number[] {
    const found_textFieldsIndexes: number[] = [];
    if (!ctx) {
      return found_textFieldsIndexes;
    }

    this._textFields.forEach((textField, index) => {
      ctx.font = CanvasService.getFieldFont(textField);
      const canvasTextMeasure = ctx.measureText(textField.str);
      const fieldX = this.getFieldX(textField);
      const fieldY = this.getFieldY(ctx, textField);
      const fieldBboxes: TBbox = [
        fieldX * this.scale,
        fieldY * this.scale,
        (fieldX + textField.width) * this.scale,
        (fieldY + canvasTextMeasure.actualBoundingBoxAscent) * this.scale,
      ];

      // Ignore fields out of selection rect and viewport
      if (
        this._highlightBox &&
        isRectsCross(fieldBboxes, this._highlightBox) &&
        isRectsCross(
          [fieldX, fieldY, fieldX + textField.width, fieldY + canvasTextMeasure.actualBoundingBoxAscent],
          [0, 0, this.viewport[2] - this.viewport[0], this.viewport[3] - this.viewport[1]],
        )
      ) {
        if (found_textFieldsIndexes.length && index > found_textFieldsIndexes[found_textFieldsIndexes.length - 1] + 1) {
          for (let i = found_textFieldsIndexes[found_textFieldsIndexes.length - 1]; i < index; i++) {
            found_textFieldsIndexes.push(i);
          }
        }
        found_textFieldsIndexes.push(index);
      }
    });

    return _.uniq(found_textFieldsIndexes);
  }

  // Generate map of bboxes per letter
  private generateLettersBboxes(textField: TextItem, ctx?: CanvasRenderingContext2D): TBboxMap {
    const lettersBboxes: TBboxMap = {};
    if (!ctx) {
      return lettersBboxes;
    }
    let str = '';
    const strLetters = textField.str.split('');
    ctx.font = CanvasService.getFieldFont(textField);
    const canvasFieldMeasure = ctx.measureText(textField.str);
    const textScaleX = textField.width / canvasFieldMeasure.width;
    strLetters.forEach((letter, index) => {
      str += letter;
      if (!letter.trim()) {
        lettersBboxes[index] = undefined;
        return;
      }
      const canvasStringMeasure = ctx.measureText(str);
      const canvasLetterMeasure = ctx.measureText(letter);
      const letterX = this.getFieldX(textField) + (canvasStringMeasure.width - canvasLetterMeasure.width) * textScaleX;
      const letterY = this.getFieldY(ctx, textField);
      const [ascent, descent] = CanvasService.getTextMeasures(ctx);
      lettersBboxes[index] = [
        letterX,
        letterY,
        letterX + (canvasLetterMeasure.actualBoundingBoxLeft + canvasLetterMeasure.actualBoundingBoxRight) * textScaleX,
        letterY + ascent + descent,
      ];
    });

    return lettersBboxes;
  }

  // Returns [baseline, hanging, underline]
  private getTextFieldActualSize(textField: TextItem, ctx?: CanvasRenderingContext2D): (number | undefined)[] {
    if (!ctx) {
      return [];
    }
    ctx.font = CanvasService.getFieldFont(textField);
    const defaultMeasures = ctx.measureText('ay');
    const [ascent, descent] = CanvasService.getTextMeasures(ctx);
    return [
      this.getFieldY(ctx, textField) + ascent,
      this.getFieldY(ctx, textField) + ascent - defaultMeasures.actualBoundingBoxAscent,
      this.getFieldY(ctx, textField) + ascent + descent / 2,
    ];
  }

  private static getFieldFont(textField: TextItem): string {
    return `${textField.height}px ${textField.fontName} sans-serif`;
  }

  private static getTextMeasures(ctx: CanvasRenderingContext2D): number[] {
    const defaultMeasures: ITextMetrics = ctx.measureText('Aay');
    return [
      defaultMeasures.fontBoundingBoxAscent || defaultMeasures.fontBoundingBoxAscent === 0
        ? defaultMeasures.fontBoundingBoxAscent
        : defaultMeasures.actualBoundingBoxAscent,
      defaultMeasures.fontBoundingBoxDescent || defaultMeasures.fontBoundingBoxDescent === 0
        ? defaultMeasures.fontBoundingBoxDescent
        : defaultMeasures.actualBoundingBoxDescent,
    ];
  }
}
