import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Tree as AntdTree } from 'antd';
import _ from 'lodash';
import { EventDataNode } from 'rc-tree/lib/interface';
import { MenuInfo } from 'rc-menu/lib/interface';

import { ITreeNode } from './interfaces/ITreeNode';
import { CustomAny } from '../../types/generics';
import { TEXT_MAP } from '../../constants/application';
import OutlineTreeItem from '../outlineTreeItem/OutlineTreeItem';

interface ITreeProps {
  className?: string;
  treeData: ITreeNode[];
  draggable?: boolean;
  selectedKey?: string | number | undefined;
  height?: number;
  onSelect?(keys: (string | number)[], info: CustomAny): void;
  onExpand?(): void;
  onRightClick?(info: { event: React.MouseEvent; node: EventDataNode }): void;
  onDragEnd?(info: CustomAny): void;
  onRemove?(outline: ITreeNode): void;
  onUpdate?(outline: ITreeNode): void;
}

const Tree: FC<ITreeProps> = (props) => {
  const [outlineToEdit, setOutlineToEdit] = useState<ITreeNode | null>(null);
  const treeRef = useRef<CustomAny>();
  const onMenuItemClick = (e: MenuInfo, outline: ITreeNode) => {
    e.domEvent.stopPropagation();
    switch (e.key) {
      case TEXT_MAP.COMMON.DELETE:
        props.onRemove?.(outline);
        break;
      case TEXT_MAP.COMMON.EDIT:
        setOutlineToEdit({ ...outline, page: _.isNull(outline.page) || outline.page === -1 ? null : outline.page + 1 });
        break;
      default:
        break;
    }
  };

  const onOutlineTitleChange = useCallback(
    (e) => {
      if (outlineToEdit) {
        setOutlineToEdit({ ...outlineToEdit, title: e.target.value });
      }
    },
    [outlineToEdit],
  );

  const onOutlinePageChange = useCallback(
    (value) => {
      if (outlineToEdit && !_.isNull(value)) {
        setOutlineToEdit({ ...outlineToEdit, page: value, destinationType: null, coordinates: null });
      }
    },
    [outlineToEdit],
  );

  const onOutlineBlur = useCallback(
    (outline: ITreeNode) => {
      if (outlineToEdit && outlineToEdit.key === outline.key) {
        props.onUpdate?.({ ...outlineToEdit, page: outlineToEdit.page ? outlineToEdit.page - 1 : outlineToEdit.page });
        setOutlineToEdit(null);
      }
    },
    [outlineToEdit],
  );

  useEffect(() => {
    setTimeout(() => treeRef.current?.scrollTo({ key: props.selectedKey }), 0);
  }, [props.selectedKey]);

  return (
    <AntdTree
      blockNode
      selectedKeys={props.selectedKey ? [props.selectedKey] : []}
      height={props.height}
      ref={treeRef}
      className={props.className}
      treeData={props.treeData}
      draggable={props.draggable}
      onSelect={props.onSelect}
      onExpand={props.onExpand}
      onRightClick={props.onRightClick}
      onDrop={props.onDragEnd}
      titleRender={(node) => {
        const outline = node as ITreeNode;
        return (
          <OutlineTreeItem
            outline={outline}
            outlineToEdit={outlineToEdit}
            onMenuItemClick={onMenuItemClick}
            onOutlinePageChange={onOutlinePageChange}
            onOutlineTitleChange={onOutlineTitleChange}
            onOutlineBlur={onOutlineBlur}
          />
        );
      }}
    />
  );
};

export default Tree;
