import { fabric } from 'fabric';
import _ from 'lodash';

import {
  convertY,
  getObjectRectangle,
  getTriangleVerticesWithRotationAngle,
  setSelectable,
  updateShapeRectangleWithLineWidth,
} from '../shared/helpers/fabricCanvasHelper';
import { IAnnotationEntity, IAnnotationShape } from './interfaces/IAnnotation';
import { ArrowLine } from './arrowLine';
import { colorToPDFColor, pdfColorToRgb } from '../shared/helpers/colorHelper';
import { getAnnotationTypeByShapeType, lineHasEndArrow, lineHasStartArrow, rotateRectangle } from './annotationService';
import { AnnotationType } from '../store/enums/annotationType';
import { SquareCircleType } from '../store/enums/squareCircleType';
import { IShapeStyle } from '../store/interfaces/IShapeStyle';
import { ShapeType } from '../store/enums/shapeType';
import { PointEndingStyle } from '../store/enums/pointEndingStyle';
import { getReverseRotationAngle, rotatePoint, rotatePoints, swapWidthAndHeight } from '../shared/helpers/canvasHelper';
import { IDrawServiceProps } from './interfaces/IFabric';

const DEFAULT_ANNOTATION_WIDTH = 100;
const DEFAULT_ANNOTATION_HEIGHT = 100;

export class ShapeDrawService {
  private _drawMode = false;
  private _tempShape: fabric.Object | null = null;
  private _tempShapeType: ShapeType | null = null;
  private _tempShapeStyle: IShapeStyle | null = null;
  private _tempX: number | null = null;
  private _tempY: number | null = null;
  private _rotationAngle = 0;

  constructor(props: IDrawServiceProps) {
    this._rotationAngle = props.rotationAngle;
  }

  setRotateAngle(angle: number): void {
    this._rotationAngle = angle;
  }

  enterDrawMode(canvas: fabric.Canvas, shapeStyle: IShapeStyle, shapeType: ShapeType, x: number, y: number): void {
    if (!this._drawMode) {
      setSelectable(false, canvas);
      this._drawMode = true;
      this._tempShapeStyle = { ...shapeStyle };
      this._tempShapeType = shapeType;
      this._tempX = x;
      this._tempY = y;
    }
  }

  applyCoordinatesChange(canvas: fabric.Canvas, x: number, y: number, dx: number, dy: number): void {
    if (!this._drawMode) {
      return;
    }
    if (!this._tempShape && this._tempShapeType && this._tempShapeStyle) {
      this._tempShape = this._createShapeObject(
        x,
        y,
        dx,
        dy,
        this._tempShapeType,
        this._tempShapeStyle,
        this._tempShapeType === ShapeType.TwoArrowsLine,
        this._tempShapeType === ShapeType.ArrowLine || this._tempShapeType === ShapeType.TwoArrowsLine,
      );
      if (this._tempShape) {
        canvas.add(this._tempShape);
      }
    } else {
      this._updateTempShapeCoordinates(x, y, dx, dy);
    }
  }

  exitDrawMode(canvas: fabric.Canvas, page: number, viewport: number[], scale: number): IAnnotationShape | null {
    if (this._drawMode) {
      setSelectable(true, canvas);
      this._drawMode = false;
      if (this._tempShape) {
        const annotation = this.createShapeAnnotationObject(this._tempShape, page, viewport);
        canvas.remove(this._tempShape);
        this._tempShape = null;
        if (
          annotation.rectangle.upperRightY === annotation.rectangle.lowerLeftY &&
          annotation.rectangle.upperRightX === annotation.rectangle.lowerLeftX
        ) {
          return null;
        }
        return annotation;
      } else {
        return this._createDefaultAnnotation(viewport, page, scale);
      }
    }
    return null;
  }

  createShapeObjectByAnnotation(annotation: IAnnotationEntity): fabric.Object | null {
    const isLocked = annotation.readOnly || annotation.locked || annotation.lockedContents;
    let object = null;
    switch (annotation.annotationType) {
      case AnnotationType.Line:
        object = this.createLineObject(annotation);
        break;
      case AnnotationType.SquareCircle:
        object =
          annotation.squareCircleType === SquareCircleType.Square
            ? this.createSquareObject(annotation)
            : this.createCircleObject(annotation);
        break;
      case AnnotationType.Triangle:
        object = this.createTriangleObject(annotation);
        break;
      default:
        break;
    }
    if (object) {
      object.set({
        borderColor: '#ffc546',
        borderDashArray: [6, 6],
        borderOpacityWhenMoving: 1,
        borderScaleFactor: 2,
        cornerColor: '#ffffff',
        cornerStrokeColor: '#ffc546',
        transparentCorners: false,
        lockUniScaling: true,
        padding: 2,
      });
      object.setControlsVisibility({
        bl: true,
        br: true,
        mb: true,
        ml: true,
        mr: true,
        mt: true,
        tl: true,
        tr: true,
        mtr: false,
      });
      object.set({
        lockMovementX: isLocked,
        lockMovementY: isLocked,
        lockSkewingX: isLocked,
        lockSkewingY: isLocked,
        lockScalingX: isLocked,
        lockScalingY: isLocked,
      });
    }
    return object;
  }

  createLineObject(annotation: IAnnotationEntity): fabric.Object {
    return this._createLineObject(
      annotation.linePoints || [],
      {
        backgroundColor: null,
        lineColor: pdfColorToRgb(...annotation.colorRGB, 1),
        lineWidth: annotation.width || 0,
        opacity: annotation.opacity,
      },
      lineHasStartArrow(annotation),
      lineHasEndArrow(annotation),
    );
  }

  createSquareObject(annotation: IAnnotationEntity): fabric.Object {
    return this._createSquareObject(
      [
        annotation.rectangle.lowerLeftX,
        annotation.rectangle.lowerLeftY,
        annotation.rectangle.upperRightX,
        annotation.rectangle.upperRightY,
      ],
      {
        backgroundColor: annotation.interiorColor ? pdfColorToRgb(...annotation.interiorColor, 1) : 'transparent',
        lineColor: pdfColorToRgb(...annotation.colorRGB, 1),
        lineWidth: annotation.width || 0,
        opacity: annotation.opacity,
      },
    );
  }

  createCircleObject(annotation: IAnnotationEntity): fabric.Object {
    return this._createCircleObject(
      [
        annotation.rectangle.lowerLeftX,
        annotation.rectangle.lowerLeftY,
        annotation.rectangle.upperRightX,
        annotation.rectangle.upperRightY,
      ],
      {
        backgroundColor: annotation.interiorColor ? pdfColorToRgb(...annotation.interiorColor, 1) : 'transparent',
        lineColor: pdfColorToRgb(...annotation.colorRGB, 1),
        lineWidth: annotation.width || 0,
        opacity: annotation.opacity,
      },
    );
  }

  createTriangleObject(annotation: IAnnotationEntity): fabric.Object {
    return this._createTriangleObject(annotation.vertices || [], {
      backgroundColor: annotation.interiorColor ? pdfColorToRgb(...annotation.interiorColor, 1) : 'transparent',
      lineColor: pdfColorToRgb(...annotation.colorRGB, 1),
      lineWidth: annotation.width || 0,
      opacity: annotation.opacity,
    });
  }

  createShapeAnnotationObject(object: fabric.Object, page: number, viewport: number[]): IAnnotationShape {
    const annotation: IAnnotationShape = {
      page: page - 1,
      annotationType: AnnotationType.Line,
      rectangle: getObjectRectangle(object, viewport, false, getReverseRotationAngle(this._rotationAngle)),
      colorRGB: colorToPDFColor(object.stroke as string)[0],
      opacity: _.isNumber(object.opacity) ? object.opacity : 1,
    };
    const left = swapWidthAndHeight(this._rotationAngle) ? viewport[1] : viewport[0];
    const top = swapWidthAndHeight(this._rotationAngle) ? viewport[0] : viewport[1];
    if (object instanceof ArrowLine) {
      if ((object as typeof ArrowLine).startArrow) {
        annotation.startPointEndingStyle = PointEndingStyle.OpenArrow;
      }
      if ((object as typeof ArrowLine).endArrow) {
        annotation.endPointEndingStyle = PointEndingStyle.OpenArrow;
      }
      const points = (object as typeof ArrowLine).calcPoints() || [];
      points[0] += left;
      points[1] = convertY(points[1], viewport, this._rotationAngle) + top;
      points[2] += left;
      points[3] = convertY(points[3], viewport, this._rotationAngle) + top;
      annotation.linePoints = [
        ...rotatePoint(getReverseRotationAngle(this._rotationAngle), [points[0], points[1]], viewport, true),
        ...rotatePoint(getReverseRotationAngle(this._rotationAngle), [points[2], points[3]], viewport, true),
      ];
      annotation.width = object.strokeWidth;
    } else if (object instanceof fabric.Rect) {
      annotation.annotationType = AnnotationType.SquareCircle;
      annotation.interiorColor =
        !object.fill || object.fill === 'transparent' ? null : colorToPDFColor(object.fill as string)[0];
      annotation.squareCircleType = SquareCircleType.Square;
      annotation.rectangle = getObjectRectangle(object, viewport, true, getReverseRotationAngle(this._rotationAngle));
      annotation.rectangle = updateShapeRectangleWithLineWidth(
        annotation.rectangle,
        object.strokeWidth || 0,
        this._rotationAngle,
      );
      annotation.width = (object.strokeWidth || 0) / 2;
    } else if (object instanceof fabric.Ellipse) {
      annotation.annotationType = AnnotationType.SquareCircle;
      annotation.interiorColor =
        !object.fill || object.fill === 'transparent' ? null : colorToPDFColor(object.fill as string)[0];
      annotation.squareCircleType = SquareCircleType.Circle;
      annotation.rectangle = getObjectRectangle(object, viewport, true, getReverseRotationAngle(this._rotationAngle));
      annotation.rectangle = updateShapeRectangleWithLineWidth(
        annotation.rectangle,
        object.strokeWidth || 0,
        this._rotationAngle,
      );
      annotation.width = (object.strokeWidth || 0) / 2;
    } else if (object instanceof fabric.Polygon) {
      annotation.annotationType = AnnotationType.Triangle;
      const points = object.points || [];
      const minX = Math.min(...points.map((point) => point.x));
      const minY = Math.min(...points.map((point) => point.y));
      annotation.vertices = _.flatten(
        object.points
          ?.map((point) => [
            (point.x - minX) * (object.scaleX || 1) + (object.left || 0) + left,
            convertY((point.y - minY) * (object.scaleY || 1) + (object.top || 0), viewport, this._rotationAngle) + top,
          ])
          .map((point) => rotatePoint(getReverseRotationAngle(this._rotationAngle), point, viewport, true)),
      );
      annotation.interiorColor =
        !object.fill || object.fill === 'transparent' ? null : colorToPDFColor(object.fill as string)[0];
      annotation.width = (object.strokeWidth || 0) / 2;
    }
    return annotation;
  }

  private _createDefaultAnnotation(viewport: number[], page: number, scale: number): IAnnotationShape | null {
    if (!this._tempShapeType || !this._tempShapeStyle) {
      return null;
    }
    const width = swapWidthAndHeight(this._rotationAngle) ? viewport[3] - viewport[1] : viewport[2] - viewport[0];
    const height = swapWidthAndHeight(this._rotationAngle) ? viewport[2] - viewport[0] : viewport[3] - viewport[1];
    const left = swapWidthAndHeight(this._rotationAngle) ? viewport[1] : viewport[0];
    const top = swapWidthAndHeight(this._rotationAngle) ? viewport[0] : viewport[1];
    let minX = (this._tempX || 0) / scale;
    let maxX = minX + DEFAULT_ANNOTATION_WIDTH;
    if (minX + DEFAULT_ANNOTATION_WIDTH > width) {
      minX = width - DEFAULT_ANNOTATION_WIDTH;
      maxX = width;
    }
    let minY = (this._tempY || 0) / scale;
    let maxY = minY + DEFAULT_ANNOTATION_HEIGHT;
    if (minY + DEFAULT_ANNOTATION_HEIGHT > height) {
      minY = height - DEFAULT_ANNOTATION_HEIGHT;
      maxY = height;
    }
    minY = convertY(minY, viewport, this._rotationAngle);
    maxY = convertY(maxY, viewport, this._rotationAngle);
    minX += left;
    minY += top;
    maxX += left;
    maxY += top;

    const annotation: IAnnotationShape = {
      page: page - 1,
      annotationType: getAnnotationTypeByShapeType(this._tempShapeType),
      rectangle: rotateRectangle(
        {
          lowerLeftX: minX,
          upperRightX: maxX,
          lowerLeftY: maxY,
          upperRightY: minY,
        },
        viewport,
        getReverseRotationAngle(this._rotationAngle),
        true,
      ),
      colorRGB: colorToPDFColor(this._tempShapeStyle.lineColor as string)[0],
      opacity: this._tempShapeStyle.opacity,
      width: this._tempShapeStyle.lineWidth,
    };
    switch (this._tempShapeType) {
      case ShapeType.Line:
        annotation.linePoints = rotatePoints(
          getReverseRotationAngle(this._rotationAngle),
          [minX, maxY, maxX, minY],
          viewport,
          true,
        );
        break;
      case ShapeType.ArrowLine:
        annotation.linePoints = rotatePoints(
          getReverseRotationAngle(this._rotationAngle),
          [minX, maxY, maxX, minY],
          viewport,
          true,
        );
        annotation.endPointEndingStyle = PointEndingStyle.OpenArrow;
        break;
      case ShapeType.TwoArrowsLine:
        annotation.linePoints = rotatePoints(
          getReverseRotationAngle(this._rotationAngle),
          [minX, maxY, maxX, minY],
          viewport,
          true,
        );
        annotation.startPointEndingStyle = PointEndingStyle.OpenArrow;
        annotation.endPointEndingStyle = PointEndingStyle.OpenArrow;
        break;
      case ShapeType.Square:
        annotation.interiorColor = this._tempShapeStyle.backgroundColor
          ? colorToPDFColor(this._tempShapeStyle.backgroundColor as string)[0]
          : null;
        annotation.squareCircleType = SquareCircleType.Square;
        break;
      case ShapeType.Circle:
        annotation.interiorColor = this._tempShapeStyle.backgroundColor
          ? colorToPDFColor(this._tempShapeStyle.backgroundColor as string)[0]
          : null;
        annotation.squareCircleType = SquareCircleType.Circle;
        break;
      case ShapeType.Triangle:
        annotation.vertices = getTriangleVerticesWithRotationAngle(
          minX,
          minY,
          maxX,
          maxY,
          this._rotationAngle,
          viewport,
        );
        annotation.interiorColor = this._tempShapeStyle.backgroundColor
          ? colorToPDFColor(this._tempShapeStyle.backgroundColor as string)[0]
          : null;
        break;
      default:
        break;
    }
    return annotation;
  }

  private _updateTempShapeCoordinates(x: number, y: number, dx: number, dy: number) {
    if (!this._tempShape || !this._tempShapeType || !this._tempShapeStyle) {
      return;
    }
    const minX = Math.min(x, x + dx);
    const minY = Math.min(y, y + dy);
    const maxX = Math.max(x, x + dx);
    const maxY = Math.max(y, y + dy);
    switch (this._tempShapeType) {
      case ShapeType.Line:
      case ShapeType.ArrowLine:
      case ShapeType.TwoArrowsLine:
        (this._tempShape as fabric.Line).set({
          x1: x - minX,
          y1: y - minY,
          x2: x + dx - minX,
          y2: y + dy - minY,
        });
        this._tempShape.left = minX;
        this._tempShape.top = minY;
        this._tempShape.setCoords();
        break;
      case ShapeType.Square:
        this._tempShape.set({
          left: minX,
          top: minY,
          width: maxX - minX - (this._tempShape.strokeWidth || 0),
          height: maxY - minY - (this._tempShape.strokeWidth || 0),
        });
        break;
      case ShapeType.Circle:
        (this._tempShape as fabric.Ellipse).set({
          left: minX,
          top: minY,
          width: maxX - minX,
          height: maxY - minY,
          rx: (maxX - minX) / 2,
          ry: (maxY - minY) / 2,
        });
        break;
      case ShapeType.Triangle:
        this._updatePolygonCoordinates(minX, minY, maxX, maxY);
        break;
      default:
        break;
    }
  }

  private _updatePolygonCoordinates(minX: number, minY: number, maxX: number, maxY: number) {
    if (!this._tempShape) {
      return;
    }
    let points = [
      new fabric.Point(0, maxY - minY),
      new fabric.Point((maxX - minX) / 2, 0),
      new fabric.Point(maxX - minX, maxY - minY),
    ];
    if (this._rotationAngle === 90) {
      points = [
        new fabric.Point(0, 0),
        new fabric.Point(maxX - minX, (maxY - minY) / 2),
        new fabric.Point(0, maxY - minY),
      ];
    } else if (this._rotationAngle === 180) {
      points = [
        new fabric.Point(maxX - minX, 0),
        new fabric.Point((maxX - minX) / 2, maxY - minY),
        new fabric.Point(0, 0),
      ];
    } else if (this._rotationAngle === 270) {
      points = [
        new fabric.Point(maxX - minX, maxY - minY),
        new fabric.Point(0, (maxY - minY) / 2),
        new fabric.Point(maxX - minX, 0),
      ];
    }
    (this._tempShape as fabric.Polygon).set({
      points,
    });
    const dim = (this._tempShape as fabric.Polygon)._calcDimensions();
    this._tempShape.width = dim.width;
    this._tempShape.height = dim.height;
    (this._tempShape as fabric.Polygon).set({
      left: dim.left + minX,
      top: dim.top + minY,
      pathOffset: new fabric.Point(dim.left + dim.width / 2, dim.top + dim.height / 2),
    });
    this._tempShape.setCoords();
  }

  private _createShapeObject(
    x: number,
    y: number,
    dx: number,
    dy: number,
    type: ShapeType,
    style: IShapeStyle,
    startArrow = false,
    endArrow = false,
  ): fabric.Object | null {
    const minX = Math.min(x, x + dx);
    const minY = Math.min(y, y + dy);
    const maxX = Math.max(x, x + dx);
    const maxY = Math.max(y, y + dy);
    switch (type) {
      case ShapeType.Line:
        return this._createLineObject([x, y, x + dx, y + dy], style, false, false);
      case ShapeType.ArrowLine:
        return this._createLineObject([x, y, x + dx, y + dy], style, startArrow, endArrow);
      case ShapeType.TwoArrowsLine:
        return this._createLineObject([x, y, x + dx, y + dy], style, true, true);
      case ShapeType.Square:
        return this._createSquareObject([minX, minY, maxX, maxY], style);
      case ShapeType.Circle:
        return this._createCircleObject([minX, minY, maxX, maxY], style);
      case ShapeType.Triangle:
        if (this._rotationAngle === 0) {
          return this._createTriangleObject([minX, maxY, (minX + maxX) / 2, minY, maxX, maxY], style);
        } else if (this._rotationAngle === 90) {
          return this._createTriangleObject([minX, minY, maxX, (minY + maxY) / 2, minX, maxY], style);
        } else if (this._rotationAngle === 180) {
          return this._createTriangleObject([maxX, minY, (minX + maxX) / 2, maxY, minX, minY], style);
        } else {
          return this._createTriangleObject([maxX, maxY, minX, (minY + maxY) / 2, maxX, minY], style);
        }
      default:
        return null;
    }
  }

  _createLineObject(points: number[], style: IShapeStyle, startArrow: boolean, endArrow: boolean): fabric.Object {
    const left = Math.min(points[0], points[2]);
    const top = Math.min(points[1], points[3]);
    points[0] -= left;
    points[1] -= top;
    points[2] -= left;
    points[3] -= top;
    return new ArrowLine(points, {
      left,
      top,
      stroke: style.lineColor,
      strokeWidth: style.lineWidth,
      startArrow,
      endArrow,
      strokeUniform: true,
      padding: 5,
      lockSkewingX: true,
      lockSkewingY: true,
      opacity: style.opacity,
    });
  }

  _createSquareObject(points: number[], style: IShapeStyle): fabric.Object {
    const strokeWidth = style.lineWidth * 2;
    return new fabric.Rect({
      left: Math.min(points[0], points[2]),
      top: Math.min(points[1], points[3]),
      width: Math.abs(points[0] - points[2]) - strokeWidth,
      height: Math.abs(points[1] - points[3]) - strokeWidth,
      fill: style.backgroundColor || 'transparent',
      stroke: style.lineColor,
      strokeWidth,
      strokeUniform: true,
      paintFirst: 'stroke',
      opacity: style.opacity,
      lockSkewingX: true,
      lockSkewingY: true,
    });
  }

  _createCircleObject(points: number[], style: IShapeStyle): fabric.Object {
    const strokeWidth = style.lineWidth * 2;
    let width = Math.abs(points[0] - points[2]);
    let height = Math.abs(points[1] - points[3]);
    width = width - strokeWidth <= 0 ? width : width - strokeWidth;
    height = height - strokeWidth <= 0 ? height : height - strokeWidth;
    return new fabric.Ellipse({
      left: Math.min(points[0], points[2]),
      top: Math.min(points[1], points[3]),
      rx: width / 2,
      ry: height / 2,
      width: width,
      height: height,
      fill: style.backgroundColor || 'transparent',
      stroke: style.lineColor,
      strokeWidth,
      strokeUniform: true,
      paintFirst: 'stroke',
      opacity: style.opacity,
      lockSkewingX: true,
      lockSkewingY: true,
    });
  }

  _createTriangleObject(points: number[], style: IShapeStyle): fabric.Object {
    const strokeWidth = style.lineWidth * 2;
    const convertedPoints = _.chunk(points, 2).map((pair) => ({
      x: pair[0],
      y: pair[1],
    }));
    const left = Math.min(...convertedPoints.map((point) => point.x));
    const top = Math.min(...convertedPoints.map((point) => point.y));
    return new fabric.Polygon(convertedPoints, {
      left,
      top,
      width: Math.max(...convertedPoints.map((point) => point.x - left)),
      height: Math.max(...convertedPoints.map((point) => point.y - top)),
      fill: style.backgroundColor || 'transparent',
      stroke: style.lineColor,
      strokeWidth,
      strokeUniform: true,
      opacity: style.opacity,
      lockSkewingX: true,
      lockSkewingY: true,
      paintFirst: 'stroke',
    });
  }
}
