export enum DestinationType {
  XYZ = 'XYZ',
  Fit = 'FIT',
  FitH = 'FIT_H',
  FitR = 'FIT_R',
  FitV = 'FIT_V',
  FitB = 'FIT_B',
  FitBH = 'FIT_BH',
  FitBV = 'FIT_BV',
}
