import { getItem } from '../../services/localStorageService';
import { LS_COLOR_MAP, LS_DRAWING_STYLE, LS_LINE_STYLE, LS_SHAPE_STYLE } from '../constants/localStorageKeys';
import { AnnotateActionType, AnnotateActionTypeName } from '../../store/enums/annotateActionType';
import { DefaultColors } from '../enums/defaultColors';
import { ILineStyle } from '../components/lineStyleSelect/interfaces/ILineStyle';
import { CustomAny } from '../types/generics';
import { ShapeType } from '../../store/enums/shapeType';
import { IShapeStyle } from '../../store/interfaces/IShapeStyle';

export type TColorSetting = {
  activeIndex: number;
  list: string[];
};

export type TLineStyle = {
  activeIndex: number;
  list: ILineStyle[];
};

export type TShapeStyle = {
  activeIndex: number;
  list: IShapeStyle[];
};

export const DefaultLineStyle = {
  [AnnotateActionType.FreeText]: {
    borderStyle: null,
    borderColor: null,
    borderWidth: 0,
  },
};

export const DefaultShapeLineStyle = {
  backgroundColor: null,
  opacity: 1,
  lineColor: 'rgba(255,50,50,1)',
  lineWidth: 2,
};

export const DefaultShapePolygonStyle = {
  backgroundColor: 'rgba(255,255,255,1)',
  opacity: 1,
  lineColor: 'rgba(255,50,50,1)',
  lineWidth: 2,
};

export const LINE_SHAPES = [ShapeType.Line, ShapeType.ArrowLine, ShapeType.TwoArrowsLine];

export const getContrastColor = (r: number, g: number, b: number): string => {
  if (r * 0.299 + g * 0.587 + b * 0.114 > 200) {
    return '#2b2929';
  }

  return '#fff';
};

export const restoreColors = (annotationType: AnnotateActionTypeName): TColorSetting => {
  const storedColors = getItem(LS_COLOR_MAP);
  if (!storedColors?.[AnnotateActionType[annotationType]]) {
    return {
      activeIndex: 0,
      list: [DefaultColors[annotationType]],
    };
  }

  if (typeof storedColors[AnnotateActionType[annotationType]] === 'string') {
    return {
      activeIndex: 0,
      list: [storedColors[AnnotateActionType[annotationType]]],
    };
  }

  return storedColors[AnnotateActionType[annotationType]];
};

export const restoreLineStyles = (annotationType: AnnotateActionTypeName): TLineStyle => {
  const storedLineStyles = getItem(LS_LINE_STYLE);
  if (!storedLineStyles?.[AnnotateActionType[annotationType]]) {
    return {
      activeIndex: 0,
      list: [(DefaultLineStyle as CustomAny)?.[AnnotateActionType[annotationType]]],
    };
  }

  if (typeof storedLineStyles[AnnotateActionType[annotationType]] === 'string') {
    return {
      activeIndex: 0,
      list: [storedLineStyles[AnnotateActionType[annotationType]]],
    };
  }

  return storedLineStyles[AnnotateActionType[annotationType]];
};

export const restoreShapeStyles = (shapeType: ShapeType): TShapeStyle => {
  const storedShapeStyles = getItem(LS_SHAPE_STYLE);
  if (!storedShapeStyles?.[shapeType]) {
    return {
      activeIndex: 0,
      list: LINE_SHAPES.includes(shapeType) ? [DefaultShapeLineStyle] : [DefaultShapePolygonStyle],
    };
  }

  if (typeof storedShapeStyles[shapeType] === 'string') {
    return {
      activeIndex: 0,
      list: [storedShapeStyles[shapeType]],
    };
  }

  return storedShapeStyles[shapeType];
};

export const restoreDrawingStyles = (): TShapeStyle => {
  const storedShapeStyles = getItem(LS_DRAWING_STYLE);
  if (!storedShapeStyles) {
    return {
      activeIndex: 0,
      list: [DefaultShapeLineStyle],
    };
  }

  return storedShapeStyles;
};

export const rgbToString = (r: number, g: number, b: number, opacity = 1): string => {
  return `rgba(${r},${g},${b},${opacity})`;
};

export const pdfColorToRgb = (r: number, g: number, b: number, opacity = 1): string => {
  return rgbToString(Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255), opacity);
};

export const rgbAdjustOpacity = (rgb: string, opacity?: number): string => {
  const colorRGB: number[] = rgb
    .split('(')[1]
    .split(')')[0]
    .split(',')
    .map((color: string) => parseFloat(color.trim()));
  return rgbToString(colorRGB[0], colorRGB[1], colorRGB[2], opacity || colorRGB[3] || 1);
};

export const getColorAndOpacity = (rgba: string): [string, number] => {
  const colorRGBA: number[] = rgba
    .split('(')[1]
    .split(')')[0]
    .split(',')
    .map((color: string) => parseFloat(color.trim()));
  return [rgbToString(colorRGBA[0], colorRGBA[1], colorRGBA[2]), colorRGBA[3] || 1];
};

export const colorToPDFColor = (rgba?: string): [[number, number, number], number] => {
  if (!rgba) {
    return [[0, 0, 0], 1];
  }
  const colorRGB: number[] = stringToRGB(rgba);
  return [rgbToPDFColor(colorRGB), colorRGB[3] || 1];
};

export const rgbToPDFColor = (rgb?: number[]): [number, number, number] => {
  if (!rgb) {
    return [0, 0, 0];
  }
  return [rgb[0] / 255, rgb[1] / 255, rgb[2] / 255];
};

export const stringToRGB = (rgbString: string): [number, number, number] => {
  return rgbString
    .split('(')[1]
    .split(')')[0]
    .split(',')
    .map((color: string) => parseFloat(color.trim())) as [number, number, number];
};

export const basicColorMap = [
  'rgba(255,255,255,1)',
  'rgba(230,230,230,1)',
  'rgba(216,216,216,1)',
  'rgba(205,205,205,1)',
  'rgba(185,185,185,1)',
  'rgba(164,164,164,1)',
  'rgba(132,132,132,1)',
  'rgba(107,107,107,1)',
  'rgba(77,77,77,1)',
  'rgba(51,51,51,1)',
  'rgba(23,23,23,1)',
  'rgba(0,0,0,1)',
  'rgba(203,241,255,1)',
  'rgba(207,230,255,1)',
  'rgba(217,201,255,1)',
  'rgba(255,191,253,1)',
  'rgba(255,204,228,1)',
  'rgba(255,193,193,1)',
  'rgba(255,232,210,1)',
  'rgba(254,255,161,1)',
  'rgba(209,255,160,1)',
  'rgba(98,255,162,1)',
  'rgba(255,211,190,1)',
  'rgba(186,194,255,1)',
  'rgba(146,227,254,1)',
  'rgba(141,196,255,1)',
  'rgba(172,138,245,1)',
  'rgba(255,144,251,1)',
  'rgba(255,166,218,1)',
  'rgba(255,151,151,1)',
  'rgba(255,206,159,1)',
  'rgba(255,237,42,1)',
  'rgba(174,255,85,1)',
  'rgba(26,255,119,1)',
  'rgba(246,176,142,1)',
  'rgba(139,152,255,1)',
  'rgba(82,214,253,1)',
  'rgba(77,162,255,1)',
  'rgba(151,104,255,1)',
  'rgba(255,92,250,1)',
  'rgba(255,117,190,1)',
  'rgba(255,121,121,1)',
  'rgba(255,176,100,1)',
  'rgba(255,220,0,1)',
  'rgba(146,238,46,1)',
  'rgba(0,218,88,1)',
  'rgba(229,146,105,1)',
  'rgba(96,114,255,1)',
  'rgba(2,199,253,1)',
  'rgba(45,145,255,1)',
  'rgba(115,86,233,1)',
  'rgba(223,61,218,1)',
  'rgba(255,83,173,1)',
  'rgba(255,81,81,1)',
  'rgba(255,154,57,1)',
  'rgba(239,206,0,1)',
  'rgba(124,213,27,1)',
  'rgba(0,180,73,1)',
  'rgba(190,114,76,1)',
  'rgba(60,81,247,1)',
  'rgba(0,161,216,1)',
  'rgba(0,122,255,1)',
  'rgba(100,54,231,1)',
  'rgba(181,41,177,1)',
  'rgba(239,52,136,1)',
  'rgba(255,50,50,1)',
  'rgba(255,125,0,1)',
  'rgba(204,176,3,1)',
  'rgba(101,180,13,1)',
  'rgba(0,151,61,1)',
  'rgba(149,89,60,1)',
  'rgba(36,48,233,1)',
  'rgba(2,139,180,1)',
  'rgba(0,105,219,1)',
  'rgba(67,41,178,1)',
  'rgba(128,27,125,1)',
  'rgba(192,40,124,1)',
  'rgba(208,41,41,1)',
  'rgba(203,100,0,1)',
  'rgba(183,159,1,1)',
  'rgba(84,148,15,1)',
  'rgba(0,103,42,1)',
  'rgba(106,63,42,1)',
  'rgba(14,35,178,1)',
  'rgba(3,110,142,1)',
  'rgba(0,89,186,1)',
  'rgba(52,30,144,1)',
  'rgba(103,18,101,1)',
  'rgba(149,31,99,1)',
  'rgba(167,32,32,1)',
  'rgba(164,81,0,1)',
  'rgba(160,128,1,1)',
  'rgba(65,114,12,1)',
  'rgba(0,80,33,1)',
  'rgba(75,45,30,1)',
  'rgba(5,20,125,1)',
  'rgba(1,77,101,1)',
  'rgba(0,63,131,1)',
  'rgba(35,19,114,1)',
  'rgba(74,13,72,1)',
  'rgba(90,22,67,1)',
  'rgba(109,20,20,1)',
  'rgba(107,53,0,1)',
  'rgba(117,93,0,1)',
  'rgba(54,91,14,1)',
  'rgba(0,62,25,1)',
  'rgba(52,32,22,1)',
  'rgba(4,14,89,1)',
];
