import React, { FC, useContext } from 'react';
import { Layout, Tabs, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { getAnnotationsPanelActiveTab, isShowAnnotationsPanel } from '../../../store/viewerSettings/selectors';
import AnnotationsPanel from '../annotationsPanel/AnnotationsPanel';
import ThumbnailsPanel from '../thumbnailsPanel/ThumbnailsPanel';
import OutlinesPanel from '../outlinesPanel/OutlinesPanel';
import { FileContext } from '../fileContext/FileContext';
import { TOOLTIPS_MAP } from '../../constants/application';
import { setAnnotationPanelActiveTab } from '../../../store/viewerSettings/actions';

import './ViewerSideBar.less';
import { ReactComponent as ThumbnailsIcon } from '../../../assets/Menubar_navigation.svg';
import { ReactComponent as AnnotationsIcon } from '../../../assets/Menubar_annotations.svg';
import { ReactComponent as OutlinesIcon } from '../../../assets/Menubar_List.svg';
import { AnnotationPanelTab } from '../../../store/enums/annotationPanelTab';

const ViewerSideBar: FC = () => {
  const dispatch = useDispatch();
  const { file } = useContext(FileContext);
  const showAnnotationsPanel: boolean = useSelector(isShowAnnotationsPanel);
  const activeTab: string = useSelector(getAnnotationsPanelActiveTab);

  const onChange = (activeTab: string) => {
    dispatch(setAnnotationPanelActiveTab(activeTab));
  };

  return (
    <Layout.Sider className="viewer-sider" width="350" collapsed={!showAnnotationsPanel} collapsedWidth={0}>
      <Tabs activeKey={activeTab} onChange={onChange} size="small" centered tabBarGutter={0}>
        <Tabs.TabPane
          key={AnnotationPanelTab.Thumbnails}
          tab={
            <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.THUMBNAILS}>
              <ThumbnailsIcon className="viewer-sider__tab-icon" />
            </Tooltip>
          }
        >
          <ThumbnailsPanel />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={AnnotationPanelTab.Outlines}
          disabled={!file}
          tab={
            <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.OUTLINES}>
              <OutlinesIcon className="viewer-sider__tab-icon" />
            </Tooltip>
          }
        >
          <OutlinesPanel />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={AnnotationPanelTab.Annotations}
          disabled={!file}
          tab={
            <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.ANNOTATIONS}>
              <AnnotationsIcon className="viewer-sider__tab-icon" />
            </Tooltip>
          }
        >
          <AnnotationsPanel />
        </Tabs.TabPane>
      </Tabs>
    </Layout.Sider>
  );
};

export default ViewerSideBar;
