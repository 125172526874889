import { createAction } from '@reduxjs/toolkit';

import {
  LS_IS_SINGLE_PAGE,
  LS_PAGE_SCALE,
  LS_HORIZONTAL_MODE,
  LS_PAGE_LAYOUT,
} from '../../shared/constants/localStorageKeys';
import { setItem } from '../../services/localStorageService';
import { FileProgressStatus } from '../enums/fileProgressStatus';
import { IPdfInfo } from '../interfaces/IPdfInfo';

export const setPageTransitionMode = createAction('settings/transitionMode', (singlePage: boolean) => {
  setItem(LS_IS_SINGLE_PAGE, singlePage);
  return {
    payload: singlePage,
  };
});
export const setScale = createAction('settings/scale', (scale: number) => {
  setItem(LS_PAGE_SCALE, scale);
  return {
    payload: scale,
  };
});
export const setHorizontalMode = createAction('settings/horizontalMode', (horizontalMode: boolean) => {
  setItem(LS_HORIZONTAL_MODE, horizontalMode);
  return {
    payload: horizontalMode,
  };
});
export const setPageLayout = createAction('settings/pageLayout', (pageLayout: string) => {
  setItem(LS_PAGE_LAYOUT, pageLayout);
  return {
    payload: pageLayout,
  };
});
export const setFileId = createAction<number | undefined>('settings/setFile');
export const setNumPages = createAction<number>('settings/numPages');
export const setPage = createAction<number>('settings/page');
export const setScrollIntoPage = createAction<number>('settings/scrollInto');
export const toggleShowAnnotationsPanel = createAction<boolean | undefined>('settings/showAnnotationsPanel');
export const setAppLoading = createAction<boolean>('settings/setAppLoading');
export const setFileStatus = createAction<FileProgressStatus>('settings/setFileStatus');
export const setFileInfo = createAction<IPdfInfo>('settings/setFileInfo');
export const setPageNavigationMode = createAction<boolean>('settings/pageNavigation');
export const setAnnotationPanelActiveTab = createAction<string>('settings/annotationsPanelActiveTab');
export const setRotationAngle = createAction<number>('settings/setRotationAngle');
export const setContextMenuActivePage = createAction<{ value: number; triggerPage: number }>(
  'settings/setContextMenuActivePage',
);
