import React, { FC, useCallback, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton, Typography } from 'antd';
import { CardList, Plus } from 'react-bootstrap-icons';
import _ from 'lodash';
import { useMeasure } from 'react-use';

import CustomScroll from '../../components/customScroll/CustomScroll';
import Tree from '../../components/tree/Tree';
import { ITreeNode } from '../../components/tree/interfaces/ITreeNode';
import { setPage, setScrollIntoPage } from '../../../store/viewerSettings/actions';
import { getNumPages, getScale, isSinglePageView } from '../../../store/viewerSettings/selectors';
import { useForceUpdate } from '../../hooks/forceUpdateHook';
import NoData from '../../components/noData/NoData';
import { getOutlinesTree, getSelectedOutline } from '../../../store/outlines/selectors';
import { CustomAny } from '../../types/generics';
import {
  createNewOutline,
  filterTreeNodes,
  INITIAL_SELECTED_ITEM,
  insertNodeToTree,
  removeNodeFromTree,
  updateTreeNode,
} from '../../helpers/outlineHelper';
import { setOutlines, setSelectedOutline } from '../../../store/outlines/actions';
import { TEXT_MAP } from '../../constants/application';
import { getPagesByViewport } from '../../../store/pages/selectors';

import './OutlinesPanel.less';

const TREE_BOTTOM_PADDING = 10;

const OutlinesPanel: FC = () => {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const scale = useSelector(getScale);
  const singlePageView: boolean = useSelector(isSinglePageView);
  const outlines: ITreeNode[] = useSelector(getOutlinesTree);
  const numPages: number = useSelector(getNumPages);
  const pagesByViewport: number[] = useSelector(getPagesByViewport);
  const selectedOutline = useSelector(getSelectedOutline);
  const treeRef = useRef<HTMLDivElement>(null);
  const [ref, size] = useMeasure<HTMLDivElement>();
  const treeHeight = useMemo(() => {
    return size.height - TREE_BOTTOM_PADDING;
  }, [size]);
  useEffect(() => {
    const scrollbars = treeRef.current?.getElementsByClassName('ant-tree-list-scrollbar');
    const scrollbar = scrollbars && scrollbars.length > 0 ? scrollbars[0] : null;
    if (scrollbar) {
      (scrollbar as CustomAny).style.height = `${treeHeight}px`;
    }
  }, [treeRef, treeHeight]);
  const onItemSelect = useCallback(
    async (selectedKeys, info): Promise<void> => {
      if (selectedKeys.length > 0) {
        dispatch(
          setSelectedOutline({
            key: info.node ? info.node.key : undefined,
            pos: info.node ? info.node.pos : null,
          }),
        );
      } else {
        dispatch(setSelectedOutline(INITIAL_SELECTED_ITEM));
      }
      const outline = info.node;
      if (!outline) {
        return;
      }
      if (_.isNull(outline.page) || outline.page === -1) {
        return;
      }
      if (!singlePageView) {
        dispatch(setScrollIntoPage(outline.page + 1));
      } else {
        dispatch(setPage(outline.page + 1));
      }
    },
    [singlePageView, scale],
  );
  const onDragEnd = useCallback(
    (info: CustomAny) => {
      const outline = info.dragNode.props.data;
      let tree = removeNodeFromTree(outlines, outline);
      const dropPosition = info.dropPosition;
      const dropToGap = info.dropToGap;
      let to = info.node.pos
        .split('-')
        .slice(1)
        .map((pos: string) => _.parseInt(pos, 10));
      if (dropToGap) {
        to = to.slice(0, -1);
      }
      tree = insertNodeToTree(tree, outline, to, dropToGap, dropPosition);
      tree = filterTreeNodes(tree, (node) => !node.isRemoved);
      dispatch(setOutlines(tree));
    },
    [outlines],
  );

  const onRemoveOutline = useCallback(
    (outline: ITreeNode) => {
      const tree = filterTreeNodes(outlines, (node) => !_.isEqual(node, outline));
      dispatch(setOutlines(tree));
    },
    [outlines],
  );

  const onUpdateOutline = useCallback(
    (outline: ITreeNode) => {
      const updatedOutline = {
        ...outline,
        page: (outline.page || 0) >= numPages ? numPages - 1 : outline.page,
      };
      const tree = updateTreeNode(outlines, updatedOutline);
      dispatch(setOutlines(tree));
    },
    [outlines, numPages],
  );

  const onAddOutline = useCallback(() => {
    const outline = createNewOutline(pagesByViewport);
    let tree = [...outlines];
    if (selectedOutline.pos) {
      let to = selectedOutline.pos
        .split('-')
        .slice(1)
        .map((pos: string) => _.parseInt(pos, 10));
      const dropPosition = to[to.length - 1] + 1;
      to = to.slice(0, -1);
      tree = insertNodeToTree(tree, outline, to, true, dropPosition);
    } else {
      tree = [...tree, outline];
    }
    dispatch(
      setSelectedOutline({
        key: outline.key,
        pos: null,
      }),
    );
    dispatch(setOutlines(tree));
  }, [outlines, pagesByViewport, selectedOutline]);

  return (
    <section className="outlines-panel">
      <div className="outlines-panel__tree-container" ref={ref}>
        <CustomScroll>
          <div className="outlines-panel__tree" ref={treeRef}>
            {!outlines ? <Skeleton active={true} title={false} paragraph={{ rows: 2, width: '100%' }} /> : null}
            {outlines &&
              (outlines.length ? (
                <Tree
                  draggable
                  height={treeHeight}
                  selectedKey={selectedOutline.key}
                  treeData={outlines}
                  onSelect={onItemSelect}
                  onExpand={forceUpdate}
                  onDragEnd={onDragEnd}
                  onRemove={onRemoveOutline}
                  onUpdate={onUpdateOutline}
                />
              ) : (
                <NoData icon={<CardList size={54} />} description="This document has no Outline" />
              ))}
          </div>
        </CustomScroll>
      </div>
      <div className="add-outline-button" onClick={onAddOutline}>
        <Plus size={20} />
        <Typography.Text>{TEXT_MAP.OUTLINES.ADD_ITEM}</Typography.Text>
      </div>
    </section>
  );
};

export default OutlinesPanel;
