import { createSelector } from 'reselect';

import { IRootState } from '../interfaces/IRootState';
import { IHistory } from '../interfaces/IHistory';

const getHistoryInfo = (state: IRootState): IHistory => state.historyInfo;

export const getHistory = createSelector(getHistoryInfo, (historyInfo: IHistory) => historyInfo.history);
export const getHistoryIndex = createSelector(getHistoryInfo, (historyInfo: IHistory) => historyInfo.historyIndex);
export const getHistoryLength = createSelector(getHistoryInfo, (historyInfo: IHistory) => historyInfo.history.length);
