import React, { FC, memo } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setFontSize } from '../../../store/annotateSettings/actions';
import { getFontSize } from '../../../store/annotateSettings/selectors';
import { FONT_SIZES } from '../../constants/application';

import './FontSizeSelect.less';

const { Option } = Select;

const FontSizeSelect: FC = () => {
  const dispatch = useDispatch();
  const fontSize: number = useSelector(getFontSize);
  const onSelect = (value: number) => {
    dispatch(setFontSize(value));
  };

  return (
    <Select
      className="font-size-select"
      dropdownClassName="ignore-on-deselect"
      onSelect={onSelect}
      value={Math.round(fontSize)}
    >
      {FONT_SIZES.map((size) => (
        <Option value={size} key={size}>
          {size}
        </Option>
      ))}
    </Select>
  );
};

export default memo(FontSizeSelect);
