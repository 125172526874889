import React, { FC, useMemo } from 'react';
import { Typography, TreeSelect } from 'antd';
import { BorderStyle } from '../../lineStyleSelect/enum/BorderStyle';

import LineStyleItem from '../../lineStyleSelect/lineStyleItem/LineStyleItem';

interface IStyleSelect {
  value: BorderStyle;
  onSelect(value: BorderStyle): void;
  width?: number;
  color?: string;
}

const { Text } = Typography;

const StyleSelect: FC<IStyleSelect> = (props) => {
  const options = [BorderStyle.NoBorder, BorderStyle.Solid, BorderStyle.Dashed];
  const selectedIndex = useMemo(() => {
    return options.indexOf(props.value);
  }, [props.value]);
  const onSelect = (value: number) => {
    props.onSelect(options[value]);
  };

  const items = useMemo(() => {
    return options.map((lineStyle: BorderStyle, index: number) => ({
      title:
        lineStyle === BorderStyle.NoBorder ? (
          <Text>No Border</Text>
        ) : (
          <LineStyleItem
            key={index}
            index={index}
            lineStyle={{ borderStyle: lineStyle, borderColor: props.color || 'white', borderWidth: props.width || 2 }}
            hideButton
          />
        ),
      value: index,
      isLeaf: true,
      showArrow: false,
    }));
  }, [props.color, props.width]);

  return (
    <TreeSelect
      className="style-select"
      value={selectedIndex}
      treeData={items}
      onSelect={onSelect}
      onMouseDown={(e) => e.stopPropagation()}
    />
  );
};

export default StyleSelect;
