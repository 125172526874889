import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Slider, Tabs, Typography } from 'antd';
import { Trash } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';

import { ShapeType } from '../../../store/enums/shapeType';
import Dialog from '../dialog/Dialog';
import { TEXT_MAP } from '../../constants/application';
import { basicColorMap, LINE_SHAPES } from '../../helpers/colorHelper';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';
import ColorGrid from '../colorDropDown/colorGrid/ColorGrid';
import Button from '../button/Button';
import { ButtonType } from '../button/enums/buttonType';
import { getFavoriteColors } from '../../../store/colorsStore/selectors';
import { addFavoriteColor, removeFavoriteColor } from '../../../store/colorsStore/actions';

const { TabPane } = Tabs;

const KEY_MAP = {
  LINE_STYLE: 'line_style',
  BACKGROUND_STYLE: 'background_style',
};

interface IShapeStyleDialog {
  visible: boolean;
  type?: ShapeType | null;
  styles?: IShapeStyle | null;
  hideBackground?: boolean;
  maxLineWidth?: number;
  onCancel(style?: IShapeStyle | null): void;
  onSave(): void;
  onStyleChange?(style?: IShapeStyle): void;
}

const ShapeStyleDialog: FC<IShapeStyleDialog> = (props) => {
  const maxFavColorNumber = 48;
  const maxLineWidth = props.maxLineWidth || 12;
  const minLineWidth = 1;
  const dispatch = useDispatch();
  const favoriteColors = useSelector(getFavoriteColors);
  const prevVisible = usePrevious(props.visible);
  const [oldStyles, setOldStyles] = useState<IShapeStyle | null>(props.styles || null);

  useEffect(
    useCallback(() => {
      if (props.styles && !prevVisible && props.visible) {
        setOldStyles(props.styles);
      }
    }, [props.styles, props.visible, prevVisible]),
    [props.visible],
  );
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(
    props.styles?.backgroundColor || undefined,
  );
  const [lineColor, setLineColor] = useState<string | undefined>(props.styles?.lineColor);
  const [opacity, setOpacity] = useState<number | undefined>(props.styles?.opacity);
  const [lineWidth, setLineWidth] = useState<number | undefined>(props.styles?.lineWidth);
  useEffect(
    useCallback(() => {
      if (!props.styles) {
        return;
      }
      if (
        props.styles.lineColor &&
        !favoriteColors.includes(props.styles.lineColor) &&
        !basicColorMap.includes(props.styles.lineColor)
      ) {
        addFavorite(props.styles.lineColor);
      }
      if (
        props.styles.backgroundColor &&
        !favoriteColors.includes(props.styles.backgroundColor) &&
        !basicColorMap.includes(props.styles.backgroundColor)
      ) {
        addFavorite(props.styles.backgroundColor);
      }
      if (
        props.styles.lineColor === lineColor &&
        props.styles.backgroundColor === backgroundColor &&
        props.styles.opacity === opacity &&
        props.styles.lineWidth === lineWidth
      ) {
        return;
      }
      setLineColor(props.styles?.lineColor);
      setOpacity(props.styles?.opacity);
      setLineWidth(props.styles?.lineWidth);
      setBackgroundColor(props.styles?.backgroundColor || undefined);
    }, [props.styles, lineColor, backgroundColor, opacity, lineWidth]),
    [props.styles],
  );
  const onDialogOk = useCallback(() => {
    props.onSave();
  }, [props.onSave, lineColor, opacity, lineWidth, backgroundColor]);
  const onDialogCancel = useCallback(() => {
    props.onCancel(oldStyles);
  }, [props.onCancel, oldStyles]);
  const onFavoriteAdd = useCallback(
    (key: string) => (color: string) => {
      addFavorite(color);
      switch (key) {
        case KEY_MAP.LINE_STYLE:
          setLineColor(color);
          break;
        case KEY_MAP.BACKGROUND_STYLE:
          setBackgroundColor(color);
          break;
        default:
          break;
      }
    },
    [favoriteColors],
  );
  const onFavoriteRemove = useCallback(
    (key: string) => () => {
      switch (key) {
        case KEY_MAP.LINE_STYLE:
          if (lineColor) {
            dispatch(removeFavoriteColor(lineColor));
          }
          setLineColor(basicColorMap[0]);
          break;
        case KEY_MAP.BACKGROUND_STYLE:
          if (backgroundColor) {
            dispatch(removeFavoriteColor(backgroundColor));
          }
          setBackgroundColor(basicColorMap[0]);
          break;
        default:
          break;
      }
    },
    [lineColor, backgroundColor],
  );
  const addFavorite = (color?: string) => {
    if (color && !favoriteColors.includes(color) && favoriteColors.length < maxFavColorNumber) {
      dispatch(addFavoriteColor(color));
    }
  };

  const isFavoriteLineSelected = useMemo(() => favoriteColors.includes(lineColor || ''), [lineColor, favoriteColors]);
  const isFavoriteBackgroundSelected = useMemo(
    () => favoriteColors.includes(backgroundColor || ''),
    [backgroundColor, favoriteColors],
  );
  const backgroundDisabled = useMemo(() => {
    return !!props.type && LINE_SHAPES.includes(props.type);
  }, [props.type]);

  useEffect(() => {
    props.onStyleChange?.({
      lineColor: lineColor || '',
      opacity: opacity || 0,
      lineWidth: lineWidth || 1,
      backgroundColor: backgroundColor || null,
    });
  }, [lineColor, backgroundColor, opacity, lineWidth]);

  const renderTabBar = useCallback(
    (tabBarProps, DefaultTabBar) => {
      return props.hideBackground ? <div /> : <DefaultTabBar {...tabBarProps} />;
    },
    [props.hideBackground],
  );

  return (
    <Dialog
      className="line-style-dialog ignore-on-deselect"
      visible={props.visible}
      destroyOnClose
      onOk={onDialogOk}
      onCancel={onDialogCancel}
      maskClosable
      closable={false}
      width="400px"
    >
      <Tabs
        defaultActiveKey={KEY_MAP.LINE_STYLE}
        renderTabBar={(props, DefaultTabBar) => renderTabBar(props, DefaultTabBar)}
      >
        <TabPane tab={TEXT_MAP.SHAPE.LINE} key={KEY_MAP.LINE_STYLE} style={{ padding: '5px' }}>
          <Typography.Title level={5}>{TEXT_MAP.COLORS.BASIC}</Typography.Title>
          <ColorGrid colors={basicColorMap} selected={lineColor} onChange={setLineColor} />
          <div className="title-with-action">
            <Typography.Title level={5}>{TEXT_MAP.COLORS.FAVORITES}</Typography.Title>
            {isFavoriteLineSelected && (
              <Button type={ButtonType.Text} icon={<Trash />} onClick={onFavoriteRemove(KEY_MAP.LINE_STYLE)} />
            )}
          </div>
          <ColorGrid
            colors={favoriteColors}
            selected={lineColor}
            onChange={setLineColor}
            onAdd={favoriteColors.length < maxFavColorNumber ? onFavoriteAdd(KEY_MAP.LINE_STYLE) : undefined}
          />
          <Typography.Title level={5}>{TEXT_MAP.COLORS.OPACITY}</Typography.Title>
          <div onMouseDown={(e) => e.stopPropagation()}>
            <Slider defaultValue={opacity} min={0} max={1} step={0.01} onAfterChange={setOpacity} />
          </div>
          <Typography.Title level={5}>{TEXT_MAP.LINE.WIDTH}</Typography.Title>
          <div onMouseDown={(e) => e.stopPropagation()}>
            <Slider
              defaultValue={lineWidth}
              min={minLineWidth}
              max={maxLineWidth}
              onAfterChange={setLineWidth}
              marks={{ [minLineWidth]: minLineWidth, [maxLineWidth]: maxLineWidth }}
            />
          </div>
        </TabPane>
        {!props.hideBackground ? (
          <TabPane tab={TEXT_MAP.SHAPE.BACKGROUND} key={KEY_MAP.BACKGROUND_STYLE} disabled={backgroundDisabled}>
            <Typography.Title level={5}>{TEXT_MAP.COLORS.BASIC}</Typography.Title>
            <ColorGrid colors={basicColorMap} selected={backgroundColor} onChange={setBackgroundColor} />
            <div className="title-with-action">
              <Typography.Title level={5}>{TEXT_MAP.COLORS.FAVORITES}</Typography.Title>
              {isFavoriteBackgroundSelected && (
                <Button type={ButtonType.Text} icon={<Trash />} onClick={onFavoriteRemove(KEY_MAP.BACKGROUND_STYLE)} />
              )}
            </div>
            <ColorGrid
              colors={favoriteColors}
              selected={backgroundColor}
              onChange={setBackgroundColor}
              onAdd={favoriteColors.length < maxFavColorNumber ? onFavoriteAdd(KEY_MAP.BACKGROUND_STYLE) : undefined}
            />
            <Typography.Title level={5}>{TEXT_MAP.COLORS.OPACITY}</Typography.Title>
            <div onMouseDown={(e) => e.stopPropagation()}>
              <Slider defaultValue={opacity} min={0} max={1} step={0.01} onAfterChange={setOpacity} />
            </div>
          </TabPane>
        ) : null}
      </Tabs>
    </Dialog>
  );
};

export default ShapeStyleDialog;
