import { ISelectOption } from '../../components/select/interfaces/ISelectOption';

export const SCALE_OPTIONS: ISelectOption[] = [
  { title: '50%', value: 0.5 },
  { title: '75%', value: 0.75 },
  { title: '100%', value: 1 },
  { title: '125%', value: 1.25 },
  { title: '150%', value: 1.5 },
  { title: '200%', value: 2 },
  { title: '300%', value: 3 },
  { title: '400%', value: 4 },
];

export const PAGE_INPUT_WIDTH = '50px';
export const SCALE_SELECT_WIDTH = '100px';
