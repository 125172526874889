import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';

import {
  addColor,
  addDrawingStyle,
  selectDrawingStyle,
  setColor,
  setDrawingStyle,
  updateColor,
} from '../../../store/annotateSettings/actions';
import { AnnotateActionType } from '../../../store/enums/annotateActionType';
import { getActiveAction, getColor, getDrawingStyle } from '../../../store/annotateSettings/selectors';
import { AnyObject } from '../../types/generics';
import FontSelect from '../../components/fontSelect/FontSelect';
import FontStylePanel from '../../components/fontStylePanel/FontStylePanel';
import FontSizeSelect from '../../components/fontSizeSelect/FontSizeSelect';
import LineStyleSelect from '../../components/lineStyleSelect/LineStyleSelect';
import ShapeStylePanel from '../../components/shapeStylePanel/ShapeStylePanel';
import ColorPickersContainer from '../../components/colorPickersContainer/ColorPickersContainer';
import { TShapeStyle } from '../../helpers/colorHelper';
import DrawStylePickersContainer from '../../components/drawStylePickersContainer/DrawStylePickersContainer';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';
import EraserToolPanel from '../../components/eraserToolPanel/EraserToolPanel';
import { setActiveAnnotation } from '../../../store/annotations/actions';

import './SettingsBar.less';

const SettingsBar: FC = () => {
  const dispatch = useDispatch();
  const activeAction: AnnotateActionType | undefined = useSelector(getActiveAction);
  const color: AnyObject = useSelector(getColor);
  const drawingStyle: TShapeStyle = useSelector(getDrawingStyle);
  const activeColors = useMemo(() => (activeAction ? color[activeAction].list : []), [activeAction, color]);
  const activeColorIndex: number = useMemo(
    () => (activeAction ? color[activeAction].activeIndex : 0),
    [activeAction, color],
  );
  const onColorChange = useCallback((color: string) => {
    if (activeAction !== AnnotateActionType.FreeText) {
      dispatch(setActiveAnnotation(null));
    }
    dispatch(updateColor(color));
  }, []);
  const onAddColor = useCallback(
    (color: string) => {
      if (activeAction !== AnnotateActionType.FreeText) {
        dispatch(setActiveAnnotation(null));
      }
      dispatch(addColor(color));
    },
    [activeAction],
  );
  const onColorSelect = useCallback(
    (index: number) => () => {
      if (activeAction !== AnnotateActionType.FreeText) {
        dispatch(setActiveAnnotation(null));
      }
      dispatch(setColor(index));
    },
    [activeAction],
  );
  const onDrawStyleChange = useCallback(
    (style: IShapeStyle) => {
      dispatch(setDrawingStyle({ style, index: drawingStyle.activeIndex }));
    },
    [drawingStyle],
  );
  const onDrawStyleAdd = (style: IShapeStyle) => {
    dispatch(addDrawingStyle(style));
  };
  const onDrawStyleSelect = (index: number) => {
    dispatch(selectDrawingStyle(index));
  };
  const showEraserSettings = useMemo(() => activeAction === AnnotateActionType.Eraser, [activeAction]);
  const showFontSettings = useMemo(() => activeAction === AnnotateActionType.FreeText, [activeAction]);
  const showShapePanel = useMemo(() => activeAction === AnnotateActionType.Shape, [activeAction]);
  const showDrawStylePanel = useMemo(() => activeAction === AnnotateActionType.Drawing, [activeAction]);
  const showSettingsBar = useMemo(() => activeAction !== AnnotateActionType.Image, [activeAction]);

  return showSettingsBar ? (
    <section className="settings-bar">
      <div className="settings-bar__color-panel ignore-on-deselect">
        {showEraserSettings ? <EraserToolPanel /> : null}
        {showShapePanel ? <ShapeStylePanel /> : null}
        {showFontSettings ? (
          <>
            <FontSelect />
            <Divider type="vertical" />
            <FontStylePanel />
            <Divider type="vertical" />
            <FontSizeSelect />
            <Divider type="vertical" />
          </>
        ) : null}
        {!showShapePanel && !showDrawStylePanel && !showEraserSettings && (
          <ColorPickersContainer
            activeColors={activeColors}
            activeColorIndex={activeColorIndex}
            onColorChange={onColorChange}
            onColorSelect={onColorSelect}
            onAddColor={onAddColor}
            hideOpacity={
              activeAction !== AnnotateActionType.Highlight && activeAction !== AnnotateActionType.StickyNote
            }
          />
        )}
        {showDrawStylePanel && (
          <DrawStylePickersContainer
            activeStyles={drawingStyle.list}
            activeStyleIndex={drawingStyle.activeIndex}
            onStyleChange={onDrawStyleChange}
            onStyleSelect={onDrawStyleSelect}
            onAddStyle={onDrawStyleAdd}
          />
        )}
        {showFontSettings ? <Divider type="vertical" /> : null}
        {showFontSettings ? <LineStyleSelect /> : null}
      </div>
    </section>
  ) : null;
};

export default SettingsBar;
