import React, { FC, memo, useMemo } from 'react';
import { Card, Tag, Typography, Tooltip } from 'antd';
import { Pencil, Trash, Lock, ChatLeftText } from 'react-bootstrap-icons';
import classNames from 'classnames';

import { IAnnotationCard } from '../interfaces/IAnnotationCard';
import AnnotationCardIcon from '../../annotationIcon/AnnotationIcon';
import Button from '../../button/Button';
import { ButtonType } from '../../button/enums/buttonType';
import { AnnotationCardFormLabels } from '../annotationCardForm/enums/AnnotationCardFormFields';
import { SHAPE_ANNOTATIONS, TOOLTIPS_MAP } from '../../../constants/application';
import ShapeStylePicker from '../../shapeStylePicker/ShapeStylePicker';

const { Text, Title } = Typography;

interface IAnnotationCardViewProps extends IAnnotationCard {
  creationDate: string;
  onEditClick?(): void;
  onDeleteClick?(): void;
  onCommentClick?(): void;
}

const AnnotationCardView: FC<IAnnotationCardViewProps> = (props) => {
  const tagList = useMemo(() => {
    const tags = [];
    if (props.annotation.flatten) {
      tags.push(AnnotationCardFormLabels.Flatten);
    }
    return tags;
  }, [props.annotation]);
  const isLocked = useMemo(() => !props.onEditClick || !props.onDeleteClick, [props.onEditClick, props.onDeleteClick]);
  const cardClassNames = useMemo(
    () =>
      classNames('annotation-card', {
        'annotation-card_active': props.active,
        'annotation-card_empty': !props.annotation.content && !tagList.length && !isLocked,
      }),
    [props.active, props.annotation.content, tagList],
  );

  const icon = useMemo(() => {
    if (SHAPE_ANNOTATIONS.includes(props.annotation.annotationType)) {
      return (
        <ShapeStylePicker
          id={props.annotation.id}
          className="shape-annotation-style-picker"
          icon={<AnnotationCardIcon annotation={props.annotation} size={26} />}
        />
      );
    }
    return <AnnotationCardIcon annotation={props.annotation} size={26} />;
  }, [props.annotation]);

  return (
    <Card
      size="small"
      className={cardClassNames}
      onClick={props.onClick}
      extra={
        <>
          {props.onEditClick && (
            <Button
              icon={
                <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.EDIT}>
                  <Pencil size={16} />
                </Tooltip>
              }
              onClick={props.onEditClick}
              type={ButtonType.Text}
            />
          )}
          <Button
            icon={
              <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.COMMENT}>
                <ChatLeftText size={16} />
              </Tooltip>
            }
            onClick={props.onCommentClick}
            type={ButtonType.Text}
          />
          {props.onDeleteClick && (
            <Button
              icon={
                <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.DELETE}>
                  <Trash size={16} />
                </Tooltip>
              }
              onClick={props.onDeleteClick}
              type={ButtonType.Text}
            />
          )}
        </>
      }
      title={
        <>
          <div className="annotation-card__title">
            {icon}
            <Title level={5}>{props.annotation.title}</Title>
          </div>
          <Text className="annotation-card__description" type="secondary">
            {props.creationDate}
          </Text>
        </>
      }
    >
      {tagList.length || isLocked ? (
        <section className="annotation-card__tags">
          {tagList.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
          {isLocked && (
            <Tag key="isLocked">
              <Lock />
            </Tag>
          )}
        </section>
      ) : null}
      {props.annotation.content ? <div className="annotation-card__content">{props.annotation.content}</div> : null}
    </Card>
  );
};

export default memo(AnnotationCardView);
