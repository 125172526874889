import React, { FC, memo } from 'react';
import { Menu, Divider, Tooltip } from 'antd';
import classNames from 'classnames';

import { ContextMenuItemType } from '../enum/ContextMenuItemType';
import { CanvasContextMenuProps } from '../interfaces/CanvasContextMenu';

const CanvasContextMenu: FC<CanvasContextMenuProps> = (props) => {
  return (
    <Menu
      mode="horizontal"
      className={`canvas-context-menu__menu page_${props.page}`}
      style={{ padding: '0 10px' }}
      forceSubMenuRender
    >
      {props.menuItems.map((item, index) => {
        switch (item.type) {
          case ContextMenuItemType.ITEM:
            return (
              <Menu.Item
                className="canvas-context-menu__item"
                key={index}
                icon={
                  <Tooltip title={item.title}>
                    <div className="canvas-context-menu__item-icon">{item.icon}</div>
                  </Tooltip>
                }
                style={{ padding: 0 }}
                onClick={item.onClick}
              />
            );
          case ContextMenuItemType.SUBMENU:
            return (
              <Menu.SubMenu
                className="canvas-context-menu__item"
                key={index}
                icon={
                  <Tooltip title={item.title}>
                    <div className="canvas-context-menu__item-icon">{item.icon}</div>
                  </Tooltip>
                }
                style={{ padding: 0 }}
                onTitleClick={item.onClick}
              >
                {item.menu &&
                  item.menu.map((subMenuItem, subIndex) => (
                    <Menu.Item
                      key={`${index}-${subIndex}`}
                      className={classNames('canvas-context-menu__subItem', { 'with-content': item.itemsHaveContent })}
                      icon={subMenuItem.icon}
                      onClick={subMenuItem.onClick}
                      disabled={subMenuItem.disabled}
                    >
                      {item.itemsHaveContent ? subMenuItem.title : null}
                    </Menu.Item>
                  ))}
              </Menu.SubMenu>
            );
          case ContextMenuItemType.DIVIDER:
            return (
              <Menu.Item key={index} className="canvas-context-menu__divider" style={{ padding: 0 }}>
                <Divider type="vertical" style={{ margin: '5px' }} />
              </Menu.Item>
            );
          default:
            return null;
        }
      })}
    </Menu>
  );
};

export default memo(CanvasContextMenu);
