import React, { FC } from 'react';
import { Form as AntdForm } from 'antd';
import classNames from 'classnames';

import { AnyObject, CustomAny } from '../../types/generics';
import { FormSize } from './enums/FormSize';

interface IFormProps {
  name: string;
  initialValues?: AnyObject;
  size?: FormSize;
  className?: string;
  onValuesChange?(formData: CustomAny): void;
  onSubmit(formData: CustomAny): void;
}

const Form: FC<IFormProps> = (props) => {
  const [form] = AntdForm.useForm();

  return (
    <AntdForm
      form={form}
      className={classNames('form', props.className)}
      name={props.name}
      size={props.size}
      initialValues={props.initialValues}
      onValuesChange={props.onValuesChange}
      onFinish={props.onSubmit}
    >
      {props.children}
    </AntdForm>
  );
};

export default Form;
