import React, {
  createRef,
  FC,
  memo,
  RefObject,
  TouchEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Document, pdfjs } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useKeyPress, useScratch } from 'react-use';

import CustomScroll from '../../components/customScroll/CustomScroll';
import FileLoading from '../../components/fileLoading/FileLoading';
import NoData from '../../components/noData/NoData';
import { FileContext } from '../fileContext/FileContext';
import { getNumPages } from '../../../store/viewerSettings/selectors';
import PageThumbnailControllable from '../../components/pageThumbnailControllable/PageThumbnailControllable';
import SelectArea from '../../components/selectArea/SelectArea';
import { checkIntersection, getPageCoordinates } from '../../helpers/pdfViewerHelper';
import { getPagesHistory, getPagesHistoryIndex, getSelectedPages, getShownPages } from '../../../store/pages/selectors';
import { addActionToHistory, clearPages, setSelectedPages } from '../../../store/pages/actions';
import { CustomAny } from '../../types/generics';
import { PageAction } from '../../../services/enums/pageAction';
import PageDragPreview from '../../components/pageDragPreview/PageDragPreview';
import PageGap from '../../components/pageGap/PageGap';
import { movePagesTo } from '../../../services/pageService';

import './ThumbnailsViewer.less';
import { isGenerateAStream } from '../../../store/annotateSettings/selectors';
import { IPageAction } from '../../../services/interfaces/IPageActions';
import { IAnnotationEntity } from '../../../services/interfaces/IAnnotation';
import { getAnnotationsList } from '../../../store/annotations/selectors';
import { ITreeNode } from '../../components/tree/interfaces/ITreeNode';
import { getOutlinesTree } from '../../../store/outlines/selectors';
import { setPageNavigationMode } from '../../../store/viewerSettings/actions';

const ThumbnailsViewer: FC = () => {
  const dispatch = useDispatch();
  const { file, updatePages } = useContext(FileContext);
  const shownPages: CustomAny[] = useSelector(getShownPages);
  const numPages: number = useSelector(getNumPages);
  const selectedPages: number[] = useSelector(getSelectedPages);
  const generateAStream: boolean = useSelector(isGenerateAStream);
  const pagesHistory: IPageAction[] = useSelector(getPagesHistory);
  const pagesHistoryIndex: number = useSelector(getPagesHistoryIndex);
  const annotations: IAnnotationEntity[] = useSelector(getAnnotationsList);
  const outlines: ITreeNode[] = useSelector(getOutlinesTree);
  const isShiftPressed = useKeyPress('Shift')[0];
  const isCtrlPressed = useKeyPress('Control')[0];
  const isMetaPressed = useKeyPress('Meta')[0];
  const [ref, scratchState] = useScratch();
  const [coordinates, setCoordinates] = useState<number[]>([]);
  const [refs, setRefs] = useState<Array<RefObject<HTMLDivElement> | null>>([]);
  const [hoveredPageRef, setHoveredPageRef] = useState<RefObject<HTMLDivElement> | null>(null);
  const [hoveredPageIndex, setHoveredPageIndex] = useState<number>(-1);
  const [insideOnePage, setInsideOnePage] = useState<boolean>(false);
  const [gapToDrop, setGapToDrop] = useState<number>(-1);
  const sortedCoordinates = useMemo(
    () =>
      coordinates.length > 0
        ? [
            Math.min(coordinates[0], coordinates[2]),
            Math.min(coordinates[1], coordinates[3]),
            Math.max(coordinates[0], coordinates[2]),
            Math.max(coordinates[1], coordinates[3]),
          ]
        : [],
    [coordinates],
  );
  const [touchPosition, setTouchPosition] = useState<{ x: number; y: number } | undefined>(undefined);

  useEffect(() => {
    setRefs(
      Array(shownPages.length)
        .fill(null)
        .map((pageRef, i) => refs[i] || createRef()),
    );
  }, [shownPages]);

  useEffect(
    useCallback(() => {
      if (scratchState.isScratching && !scratchState.start) {
        return;
      }
      if (scratchState.isScratching) {
        const { x = 0, y = 0, dx = 0, dy = 0 } = scratchState;
        let hoveredPageRef = null;
        let hoveredPageIndex = -1;
        refs.forEach((pageRef, index) => {
          if (!pageRef || !pageRef.current) {
            return;
          }
          const [x1, y1, x2, y2] = getPageCoordinates(pageRef.current as unknown as HTMLDivElement);
          if (x1 <= x && x2 >= x && y1 <= y && y2 >= y && selectedPages.includes(index)) {
            hoveredPageRef = pageRef;
            hoveredPageIndex = index;
            if (!(x1 <= x + dx && x2 >= x + dx && y1 <= y + dy && y2 >= y + dy)) {
              setInsideOnePage(false);
            } else {
              setInsideOnePage(true);
            }
          }
        });
        const coords = [x, y, x + dx, y + dy];
        setCoordinates(coords);
        setHoveredPageRef(hoveredPageRef);
        setHoveredPageIndex(hoveredPageIndex);
      } else {
        if (hoveredPageRef && !insideOnePage) {
          const action = movePagesTo(shownPages.length, selectedPages, gapToDrop);
          dispatch(addActionToHistory(action));
          dispatch(setSelectedPages([]));
          setHoveredPageRef(null);
          setHoveredPageIndex(-1);
          setCoordinates([]);
          setGapToDrop(-1);
        } else {
          setHoveredPageRef(null);
          setHoveredPageIndex(-1);
          setGapToDrop(-1);
          const selected: number[] = [];
          refs.forEach((pageRef, index) => {
            if (!pageRef || !pageRef.current) {
              return;
            }
            const canvas = pageRef.current as unknown as HTMLDivElement;
            if (checkIntersection(getPageCoordinates(canvas), sortedCoordinates)) {
              selected.push(index);
            }
          });
          if (isShiftPressed) {
            if (selected.length === 1 && selectedPages.length > 0) {
              dispatch(
                setSelectedPages(
                  _.range(Math.min(selected[0], selectedPages[0]), Math.max(selected[0], selectedPages[0]) + 1),
                ),
              );
            } else {
              dispatch(setSelectedPages(_.uniq([...selectedPages, ...selected])));
            }
          } else if (isCtrlPressed || isMetaPressed) {
            dispatch(setSelectedPages(_.xor([...selectedPages], [...selected])));
          } else {
            dispatch(setSelectedPages(selected));
          }
          setCoordinates([]);
        }
      }
    }, [
      scratchState,
      sortedCoordinates,
      coordinates,
      refs,
      isShiftPressed,
      isCtrlPressed,
      isMetaPressed,
      selectedPages,
      shownPages,
      hoveredPageRef,
      hoveredPageIndex,
      insideOnePage,
      gapToDrop,
    ]),
    [scratchState],
  );

  const selectGap = useCallback(
    (index: number) => {
      if (hoveredPageRef) {
        setGapToDrop(index);
      }
    },
    [hoveredPageRef],
  );

  const handleDoubleClick = useCallback(
    (page: number) => {
      if (pagesHistoryIndex !== -1) {
        updatePages(generateAStream);
      } else {
        dispatch(clearPages());
        dispatch(setPageNavigationMode(false));
      }
      dispatch(setSelectedPages([page]));
    },
    [generateAStream, annotations, pagesHistory, pagesHistoryIndex, outlines],
  );

  const pages = useMemo(() => {
    const pages: JSX.Element[] = [];
    let pageIndex = 0;
    pages.push(
      <PageGap
        key={`page-gap-${pageIndex}`}
        index={pageIndex}
        activeIndex={gapToDrop}
        touchPosition={touchPosition}
        onSelect={selectGap}
      />,
    );
    shownPages.forEach((page, index) => {
      if (page.deleted) {
        return;
      } else if (page.action === PageAction.Create) {
        let pageToCopyIndex = index - 1;
        let pageToCopy = refs[pageToCopyIndex]?.current as unknown as HTMLDivElement;
        while (!pageToCopy && pageToCopyIndex >= 0) {
          pageToCopyIndex--;
          pageToCopy = refs[pageToCopyIndex]?.current as unknown as HTMLDivElement;
        }
        pages.push(
          <PageThumbnailControllable
            key={index}
            index={index}
            ref={refs[index]}
            pageNumber={pageIndex + 1}
            active={selectedPages.includes(index)}
            width={page.width || pageToCopy.offsetWidth}
            height={page.height || pageToCopy.offsetHeight}
            handleDoubleClick={handleDoubleClick}
          />,
        );
      } else {
        pages.push(
          <PageThumbnailControllable
            key={index}
            index={index}
            ref={refs[index]}
            page={page.page}
            pageNumber={pageIndex + 1}
            active={selectedPages.includes(index)}
            handleDoubleClick={handleDoubleClick}
          />,
        );
      }
      pageIndex++;
      pages.push(
        <PageGap
          key={`page-gap-${pageIndex}-left`}
          index={pageIndex}
          activeIndex={gapToDrop}
          touchPosition={touchPosition}
          onSelect={selectGap}
        />,
      );
      if (index !== shownPages.length - 1) {
        pages.push(
          <PageGap
            key={`page-gap-${pageIndex}-right`}
            index={pageIndex}
            activeIndex={gapToDrop}
            touchPosition={touchPosition}
            onSelect={selectGap}
          />,
        );
      }
    });
    return pages;
  }, [numPages, shownPages, selectedPages, refs, gapToDrop, selectGap, hoveredPageRef, touchPosition]);

  const hoveredPageWidth = useMemo(() => {
    return hoveredPageRef ? (hoveredPageRef.current as unknown as HTMLDivElement).offsetWidth : 0;
  }, [hoveredPageRef]);

  const hoveredPageHeight = useMemo(() => {
    return hoveredPageRef ? (hoveredPageRef.current as unknown as HTMLDivElement).offsetHeight : 0;
  }, [hoveredPageRef]);

  const onTouchMove = (e: TouchEvent) => {
    if (e.touches.length !== 1) {
      return;
    }
    setTouchPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
  };

  return (
    <CustomScroll style={{ position: 'absolute' }}>
      <section className="thumbnails-viewer" ref={ref} onTouchMove={onTouchMove}>
        <Document
          loading={<FileLoading className="thumbnails-viewer__loading" />}
          noData={<NoData />}
          file={file}
          options={{
            cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
        >
          <div className="thumbnails-viewer__page-container">{pages}</div>
          {!insideOnePage ? (
            <PageDragPreview
              coverPageNumber={hoveredPageIndex !== -1 ? shownPages[hoveredPageIndex].page : -1}
              numberOfSelectedPages={selectedPages.length}
              width={hoveredPageWidth}
              height={hoveredPageHeight}
              top={coordinates.length > 0 ? coordinates[3] : 0}
              left={coordinates.length > 0 ? coordinates[2] : 0}
            />
          ) : null}
        </Document>
        {!hoveredPageRef ? <SelectArea coordinates={sortedCoordinates} /> : null}
      </section>
    </CustomScroll>
  );
};

export default memo(ThumbnailsViewer);
