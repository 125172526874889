import React, { FC, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { Divider, message, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FileEarmarkPlus as AddPage, Trash } from 'react-bootstrap-icons';
import { useKeyPress, useKey } from 'react-use';

import Toolbar from '../toolbar/Toolbar';
import { FileContext } from '../fileContext/FileContext';
import { ButtonType } from '../../components/button/enums/buttonType';
import { HOTKEYS_MAP, TEXT_MAP, TOOLTIPS_MAP } from '../../constants/application';
import Button from '../../components/button/Button';
import {
  addActionToHistory,
  clearPages,
  copyPages,
  pagesRedo,
  pagesUndo,
  setSelectedPages,
} from '../../../store/pages/actions';
import {
  getPagesHistory,
  getPagesHistoryIndex,
  getPagesToCopy,
  getSelectedPages,
  getShownPages,
} from '../../../store/pages/selectors';
import { CustomAny } from '../../types/generics';
import { PageAction } from '../../../services/enums/pageAction';
import { IAnnotationEntity } from '../../../services/interfaces/IAnnotation';
import { getAnnotationsList } from '../../../store/annotations/selectors';
import { IPageAction } from '../../../services/interfaces/IPageActions';
import { copyPagesTo } from '../../../services/pageService';
import { ITreeNode } from '../../components/tree/interfaces/ITreeNode';
import { getOutlinesTree } from '../../../store/outlines/selectors';
import { isGenerateAStream } from '../../../store/annotateSettings/selectors';
import { setPageNavigationMode } from '../../../store/viewerSettings/actions';

import { ReactComponent as RedoIcon } from '../../../assets/redo.svg';
import { ReactComponent as PasteIcon } from '../../../assets/Top_paste.svg';
import { ReactComponent as CopyIcon } from '../../../assets/Top_copy.svg';

import './PageNavigationToolbar.less';

const { Text } = Typography;

const PageNavigationToolbar: FC = () => {
  const dispatch = useDispatch();
  const { file, updatePages } = useContext(FileContext);
  const selectedPages: number[] = useSelector(getSelectedPages);
  const pagesToCopy: number[] = useSelector(getPagesToCopy);
  const shownPages: CustomAny[] = useSelector(getShownPages);
  const pagesHistory: IPageAction[] = useSelector(getPagesHistory);
  const pagesHistoryIndex: number = useSelector(getPagesHistoryIndex);
  const annotations: IAnnotationEntity[] = useSelector(getAnnotationsList);
  const outlines: ITreeNode[] = useSelector(getOutlinesTree);
  const generateAStream: boolean = useSelector(isGenerateAStream);
  const isDeletePressed = useKeyPress('Delete')[0];
  const isBackspacePressed = useKeyPress('Backspace')[0];
  const pagesListIndexes = useMemo(
    () => shownPages.filter((item) => !item.deleted).map((item, index) => index),
    [shownPages],
  );
  const isCtrlPressed = useKeyPress('Control')[0];
  const isMetaPressed = useKeyPress('Meta')[0];
  const isZPressed = useKeyPress('z')[0];
  const isXPressed = useKeyPress('x')[0];
  const isCPressed = useKeyPress('c')[0];
  const isVPressed = useKeyPress('v')[0];
  const isAPressed = useKeyPress('a')[0];

  useKey(
    (e) => e.key === 'Control' || e.key === 'Meta' || e.key === 'a',
    (e) => e.preventDefault(),
  );

  const onCopy = useCallback(() => {
    dispatch(copyPages(selectedPages));
    dispatch(setSelectedPages([]));
  }, [selectedPages]);
  const onPaste = useCallback(() => {
    const sortedPagesToCopy = [...pagesToCopy];
    sortedPagesToCopy.sort((a, b) => a - b);
    const sortedSelectedPages = [...selectedPages];
    sortedSelectedPages.sort((a, b) => a - b);
    let index = shownPages.length - 1;
    if (sortedSelectedPages.length > 0) {
      index = sortedSelectedPages[sortedSelectedPages.length - 1];
    }
    const action = copyPagesTo(shownPages.length, sortedPagesToCopy, index);
    dispatch(addActionToHistory(action));
    dispatch(copyPages([]));
    dispatch(setSelectedPages([]));
  }, [selectedPages, pagesToCopy, shownPages]);

  useEffect(
    useCallback(() => {
      if ((isCtrlPressed && isCPressed) || (isMetaPressed && isCPressed)) {
        onCopy();
      }
    }, [isCtrlPressed, isCPressed, isMetaPressed, onCopy]),
    [isCtrlPressed, isCPressed, isMetaPressed],
  );
  useEffect(
    useCallback(() => {
      if ((isCtrlPressed && isVPressed) || (isMetaPressed && isVPressed)) {
        onPaste();
      }
    }, [isCtrlPressed, isVPressed, isMetaPressed, onPaste]),
    [isCtrlPressed, isVPressed, isMetaPressed],
  );
  useEffect(() => {
    if ((isCtrlPressed && isZPressed) || (isMetaPressed && isZPressed)) {
      dispatch(pagesUndo());
    }
  }, [isCtrlPressed, isZPressed, isMetaPressed]);
  useEffect(() => {
    if ((isCtrlPressed && isXPressed) || (isMetaPressed && isXPressed)) {
      dispatch(pagesRedo());
    }
  }, [isCtrlPressed, isXPressed, isMetaPressed]);
  useEffect(
    useCallback(() => {
      if ((isCtrlPressed && isAPressed) || (isMetaPressed && isAPressed)) {
        dispatch(setSelectedPages(pagesListIndexes));
      }
    }, [isCtrlPressed, isAPressed, isMetaPressed, pagesListIndexes]),
    [isCtrlPressed, isAPressed, isMetaPressed],
  );

  const onDelete = useCallback(async () => {
    if (selectedPages.length === shownPages.filter((page) => !page.deleted).length) {
      await message.error(TEXT_MAP.ERROR.REMOVE_ALL_PAGES_ERROR, 1);
      return;
    }
    const action: IPageAction = {
      pageActionType: PageAction.Delete,
      pages: selectedPages,
    };
    dispatch(addActionToHistory(action));
    dispatch(setSelectedPages([]));
  }, [selectedPages, shownPages]);

  const onAdd = useCallback(() => {
    const sortedSelectedPages = [...selectedPages];
    sortedSelectedPages.sort((a, b) => a - b);
    let index = shownPages.filter((page) => !page.deleted).length - 1;
    if (sortedSelectedPages.length > 0) {
      index = sortedSelectedPages[sortedSelectedPages.length - 1];
    }
    const action: IPageAction = {
      pageActionType: PageAction.Create,
      modelPageNumber: index,
      page: index + 1,
    };
    dispatch(addActionToHistory(action));
    dispatch(setSelectedPages([]));
  }, [selectedPages, shownPages]);

  const onDone = useCallback(() => {
    updatePages(generateAStream);
  }, [generateAStream, annotations, pagesHistory, pagesHistoryIndex, outlines]);

  const onCancel = () => {
    dispatch(clearPages());
    dispatch(setPageNavigationMode(false));
  };

  useEffect(
    useCallback(() => {
      if (isDeletePressed || isBackspacePressed) {
        onDelete();
      }
    }, [isDeletePressed, isBackspacePressed, onDelete]),
    [isDeletePressed, isBackspacePressed],
  );

  const left = useMemo(
    () => (
      <Text className="page-navigation-toolbar__file-name" title={file.name}>
        {file.name}
      </Text>
    ),
    [file],
  );
  const center = useMemo(
    () => (
      <>
        <Button
          type={ButtonType.Text}
          icon={<AddPage size={18} />}
          content={<span>{TOOLTIPS_MAP.TOOLBAR.ADD_PAGE}</span>}
          onClick={onAdd}
        />
        <Button
          disabled={selectedPages.length === 0}
          type={ButtonType.Text}
          icon={<CopyIcon />}
          content={<span>{TOOLTIPS_MAP.TOOLBAR.COPY}</span>}
          tooltip={
            <Typography.Text className="tooltip_secondary" type="secondary">
              {HOTKEYS_MAP.COPY}
            </Typography.Text>
          }
          onClick={onCopy}
        />
        <Button
          disabled={pagesToCopy.length === 0}
          type={ButtonType.Text}
          icon={<PasteIcon />}
          content={<span>{TOOLTIPS_MAP.TOOLBAR.PASTE}</span>}
          tooltip={
            <Typography.Text className="tooltip_secondary" type="secondary">
              {HOTKEYS_MAP.PASTE}
            </Typography.Text>
          }
          onClick={onPaste}
        />
        <Button
          disabled={selectedPages.length === 0}
          type={ButtonType.Text}
          icon={<Trash size={19} />}
          content={<span>{TOOLTIPS_MAP.TOOLBAR.DELETE_PAGE}</span>}
          onClick={onDelete}
        />
      </>
    ),
    [onAdd, onDelete, onPaste, onCopy, pagesToCopy, selectedPages],
  );
  const right = useMemo(
    () => (
      <>
        <Button
          type={ButtonType.Text}
          icon={<RedoIcon className="assets-icon assets-icon_center _flipped" />}
          disabled={pagesHistoryIndex === -1}
          onClick={() => dispatch(pagesUndo())}
          tooltip={
            <>
              {TOOLTIPS_MAP.TOOLBAR.UNDO}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.UNDO}
              </Typography.Text>
            </>
          }
        />
        <Button
          type={ButtonType.Text}
          icon={<RedoIcon className="assets-icon assets-icon_center" />}
          disabled={pagesHistoryIndex === pagesHistory.length - 1}
          onClick={() => dispatch(pagesRedo())}
          tooltip={
            <>
              {TOOLTIPS_MAP.TOOLBAR.REDO}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.REDO}
              </Typography.Text>
            </>
          }
        />
        <Divider type="vertical" />
        {pagesHistoryIndex !== -1 ? (
          <Button type={ButtonType.Primary} onClick={onDone} content={TEXT_MAP.COMMON.DONE} />
        ) : (
          <Button type={ButtonType.Default} onClick={onCancel} content={TEXT_MAP.COMMON.CLOSE} />
        )}
      </>
    ),
    [onDone, pagesHistory, pagesHistoryIndex],
  );
  return <Toolbar className="page-navigation-toolbar" left={left} center={center} right={right} />;
};

export default memo(PageNavigationToolbar);
