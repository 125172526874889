import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ShapeStyleDialog from '../shapeStyleDialog/ShapeStyleDialog';
import { getAnnotationsList } from '../../../store/annotations/selectors';
import { DefaultShapeLineStyle, pdfColorToRgb } from '../../helpers/colorHelper';
import { editShapeStyle, getShapeTypeByAnnotation } from '../../../services/annotationService';
import { initAnnotations } from '../../../store/annotations/actions';
import { rememberState } from '../../../store/historyStore/actions';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';
import { AnnotationType } from '../../../store/enums/annotationType';
import { MAX_LINE_WIDTH } from '../../constants/application';

interface IShapeStyleEditor {
  id: string | null;
  style?: IShapeStyle;
  page?: number;
  visible?: boolean;
  hideBackground?: boolean;
  maxLineWidth?: number;
  onClose(): void;
  onChange?(style: IShapeStyle | null): void;
}

const ShapeStyleEditor: FC<IShapeStyleEditor> = (props) => {
  const dispatch = useDispatch();
  const annotations = useSelector(getAnnotationsList);
  const annotation = useMemo(
    () => annotations.find((annotation) => annotation.id === props.id) || null,
    [annotations, props.id],
  );
  const annotationMaxLineWidth = useMemo(
    () => (annotation ? MAX_LINE_WIDTH[annotation.annotationType] : 0),
    [annotation],
  );
  const shapeStyle = useMemo(
    () =>
      annotation
        ? {
            backgroundColor: annotation.interiorColor ? pdfColorToRgb(...annotation.interiorColor, 1) : '',
            lineColor: pdfColorToRgb(...annotation.colorRGB, 1),
            lineWidth: annotation.width || 0,
            opacity: annotation.opacity,
          }
        : props.style || null,
    [annotation, props.style],
  );
  const shapeType = useMemo(() => (annotation ? getShapeTypeByAnnotation(annotation) : null), [annotation]);
  const visible = useMemo(
    () => props.visible || (!!annotation && annotation.page + 1 === props.page),
    [annotation, props.page, props.visible],
  );
  const [styleToChange, setStyleToChange] = useState<IShapeStyle>(DefaultShapeLineStyle);

  const onStyleChange = useCallback(
    (style: IShapeStyle) => {
      if (!annotation) {
        return;
      }
      let newAnnotationList = [...annotations];
      newAnnotationList = newAnnotationList.map((item) => {
        if (item.id === annotation.id) {
          return editShapeStyle(item, style);
        }
        return item;
      });
      dispatch(initAnnotations(newAnnotationList));
      setStyleToChange(style);
    },
    [annotations, annotation],
  );

  const onCancel = useCallback(
    (style: IShapeStyle) => {
      onStyleChange(style);
      props.onClose();
    },
    [annotations, annotation],
  );

  const onSave = useCallback(() => {
    dispatch(rememberState(annotations));
    if (shapeType && props.onChange) props.onChange(styleToChange);
    props.onClose();
  }, [annotations, annotation]);

  return (
    <ShapeStyleDialog
      visible={visible}
      type={shapeType}
      styles={shapeStyle}
      maxLineWidth={props.maxLineWidth || annotationMaxLineWidth}
      onCancel={onCancel}
      onStyleChange={onStyleChange}
      onSave={onSave}
      hideBackground={props.hideBackground || annotation?.annotationType === AnnotationType.Ink}
    />
  );
};

export default ShapeStyleEditor;
