import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { isShowAnnotationsPanel } from '../../../store/viewerSettings/selectors';
import AnnotationsPanelList from './annotationsPanelList/AnnotationsPanelList';
import CustomScroll from '../../components/customScroll/CustomScroll';
import { useForceUpdate } from '../../hooks/forceUpdateHook';
import { getNotDeletedAnnotationsList } from '../../../store/annotations/selectors';
import { IAnnotationEntity } from '../../../services/interfaces/IAnnotation';

import './AnnotationsPanel.less';

const AnnotationsPanel: FC = () => {
  const showAnnotationsPanel: boolean = useSelector(isShowAnnotationsPanel);
  const annotations: IAnnotationEntity[] = useSelector(getNotDeletedAnnotationsList);
  const forceUpdate = useForceUpdate();
  useEffect(() => forceUpdate(), [showAnnotationsPanel]);

  return (
    <div className="annotations-panel">
      <h5 className="ant-typography annotations-amount">
        {(annotations.length === 0 && 'No annotations') ||
          (annotations.length === 1 && '1 annotation') ||
          (annotations.length > 1 && annotations.length + ' annotations')}
      </h5>
      <CustomScroll>
        <AnnotationsPanelList onUpdate={forceUpdate} />
      </CustomScroll>
    </div>
  );
};

export default AnnotationsPanel;
