import React, { FC, useState, useCallback, memo } from 'react';
import { ListRowProps } from 'react-virtualized/dist/es/List';
import { Page, PDFPageProxy } from 'react-pdf';
import { Spin } from 'antd';
import classNames from 'classnames';

import './PageThumbnail.less';

interface IPageThumbnail extends ListRowProps {
  activeThumbnail: number;
  changePage(pageNumber: number): void;
}

const PageThumbnail: FC<IPageThumbnail> = (props) => {
  const { index, style } = props;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const onPageLoaded = useCallback(async (page: PDFPageProxy) => {
    const canvasView = [...page.view];
    let [width, height] = [canvasView[2], canvasView[3]];
    if ([90, 270].includes(page.rotate)) {
      [width, height] = [canvasView[3], canvasView[2]];
    }
    setWidth(width);
    setHeight(height);
  }, []);

  const handlePageClick = useCallback(() => {
    props.changePage(index + 1);
  }, [props.changePage, index]);

  return (
    <div
      style={style}
      className={classNames('page-thumbnail', {
        'page-thumbnail_landscape': width > height,
        'page-thumbnail_portrait': width <= height,
        'page-thumbnail_active': props.activeThumbnail === index + 1,
      })}
    >
      <div onClick={handlePageClick}>
        <Page
          loading={
            <div className="page-thumbnail__loading">
              <Spin />
            </div>
          }
          renderAnnotationLayer={false}
          renderTextLayer={false}
          pageNumber={index + 1}
          onLoadSuccess={onPageLoaded}
        />
      </div>
      <div className="page-thumbnail__number">{index + 1}</div>
    </div>
  );
};

export default memo(PageThumbnail);
