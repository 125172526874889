import { createReducer } from '@reduxjs/toolkit';

import { IHistory } from '../interfaces/IHistory';

import { setHistory, setHistoryIndex, redo, undo, rememberState, restore } from './actions';

const initialState: IHistory = {
  history: [],
  historyIndex: -1,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setHistory, (state, action) => {
      state.history = action.payload || [];
    })
    .addCase(setHistoryIndex, (state, action) => {
      state.historyIndex = action.payload;
    })
    .addCase(redo, (state) => {
      if (state.historyIndex === -1 || state.historyIndex === state.history.length - 1) {
        return;
      }
      state.historyIndex = state.historyIndex + 1;
    })
    .addCase(undo, (state) => {
      if (state.historyIndex <= 0) {
        return;
      }
      state.historyIndex = state.historyIndex - 1;
    })
    .addCase(restore, (state) => {
      state.historyIndex = 0;
      state.history = state.history.slice(0, 1);
    })
    .addCase(rememberState, (state, action) => {
      const historyClone = [...state.history];
      if (state.historyIndex < state.history.length - 1) {
        historyClone.splice(state.historyIndex + 1);
      }
      const newHistory = [...historyClone, action.payload];
      const newIndex = state.historyIndex + 1 >= newHistory.length ? newHistory.length - 1 : state.historyIndex + 1;
      state.historyIndex = newIndex;
      state.history = newHistory;
    });
});
