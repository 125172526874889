import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';

import Button from '../../components/button/Button';
import { ButtonType } from '../button/enums/buttonType';
import { AnnotateActionType, AnnotateActionTypeLabel } from '../../../store/enums/annotateActionType';
import { getNumPages, getStoredFileId } from '../../../store/viewerSettings/selectors';
import { getActiveAction, getIsEditing } from '../../../store/annotateSettings/selectors';
import { setActiveAction, setImageBase64 } from '../../../store/annotateSettings/actions';
import { getColor } from '../../../store/annotateSettings/selectors';
import { AnyObject } from '../../types/generics';
import { useKeyPress } from 'react-use';
import { HOTKEYS_MAP, TAG_NAMES } from '../../constants/application';
import { rgbAdjustOpacity } from '../../helpers/colorHelper';

import './TextMarkupPanel.less';

import { ReactComponent as Underline1 } from '../../../assets/Underline1.svg';
import { ReactComponent as Underline2 } from '../../../assets/Underline2.svg';
import { ReactComponent as Highlight } from '../../../assets/Highlight.svg';
import { ReactComponent as CancelLine } from '../../../assets/CancelLine.svg';

const TextMarkupPanel: FC = () => {
  const dispatch = useDispatch();

  const isUnderlinePressed = useKeyPress('u')[0];
  const isStrikeoutPressed = useKeyPress('k')[0];
  const isHighlightPressed = useKeyPress('h')[0];
  const isSquigglyPressed = useKeyPress('g')[0];
  const activeAction: AnnotateActionType | undefined = useSelector(getActiveAction);
  const storedFileId = useSelector(getStoredFileId);
  const isEditing = useSelector(getIsEditing);
  const color: AnyObject = useSelector(getColor);
  const numPages: number = useSelector(getNumPages);
  const disabled = useMemo(() => !storedFileId || numPages === 0, [storedFileId, numPages]);

  const onHighlightClick = useCallback(
    () => disabled || toggleAction(AnnotateActionType.Highlight),
    [activeAction === AnnotateActionType.Highlight, disabled],
  );
  const onUnderlineClick = useCallback(
    () => disabled || toggleAction(AnnotateActionType.Underline),
    [activeAction === AnnotateActionType.Underline, disabled],
  );
  const onStrikeoutClick = useCallback(
    () => disabled || toggleAction(AnnotateActionType.Strikeout),
    [activeAction === AnnotateActionType.Strikeout, disabled],
  );
  const onSquigglyClick = useCallback(
    () => disabled || toggleAction(AnnotateActionType.Squiggly),
    [activeAction === AnnotateActionType.Squiggly, disabled],
  );

  const toggleAction = useCallback(
    (type: AnnotateActionType) => {
      dispatch(setImageBase64(null));
      if (activeAction === type) {
        return dispatch(setActiveAction(undefined));
      }
      return dispatch(setActiveAction(type));
    },
    [activeAction],
  );
  // Key press events
  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isUnderlinePressed && !isEditing && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      dispatch(setActiveAction(AnnotateActionType.Underline));
    }
  }, [isUnderlinePressed, isEditing]);
  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isHighlightPressed && !isEditing && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      dispatch(setActiveAction(AnnotateActionType.Highlight));
    }
  }, [isHighlightPressed, isEditing]);
  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isStrikeoutPressed && !isEditing && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      dispatch(setActiveAction(AnnotateActionType.Strikeout));
    }
  }, [isStrikeoutPressed, isEditing]);
  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isSquigglyPressed && !isEditing && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      dispatch(setActiveAction(AnnotateActionType.Squiggly));
    }
  }, [isSquigglyPressed, isEditing]);

  const getButtonType = (type: AnnotateActionType) => (type === activeAction ? ButtonType.Primary : ButtonType.Text);
  const getActionColor = useCallback(
    (type: AnnotateActionType) => {
      return rgbAdjustOpacity(color[type].list[color[type].activeIndex], 1);
    },
    [color],
  );

  return (
    <div className="text-markup-panel">
      <Button
        ghost={getButtonType(AnnotateActionType.Highlight) === ButtonType.Primary}
        type={getButtonType(AnnotateActionType.Highlight)}
        icon={useMemo(
          () => (
            <Highlight
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': getActionColor(AnnotateActionType.Highlight),
                  '--stroke-color': 'none',
                } as React.CSSProperties
              }
            />
          ),
          [color],
        )}
        onClick={onHighlightClick}
        disabled={disabled}
        tooltip={useMemo(
          () => (
            <>
              {AnnotateActionTypeLabel.Highlight}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.HIGHLIGHT}
              </Typography.Text>
            </>
          ),
          [],
        )}
      />
      <Button
        ghost={getButtonType(AnnotateActionType.Underline) === ButtonType.Primary}
        type={getButtonType(AnnotateActionType.Underline)}
        icon={useMemo(
          () => (
            <Underline1
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': getActionColor(AnnotateActionType.Underline),
                  '--stroke-color': getActionColor(AnnotateActionType.Underline),
                } as React.CSSProperties
              }
            />
          ),
          [color],
        )}
        onClick={onUnderlineClick}
        disabled={disabled}
        tooltip={useMemo(
          () => (
            <>
              {AnnotateActionTypeLabel.Underline}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.UNDERLINE}
              </Typography.Text>
            </>
          ),
          [],
        )}
      />
      <Button
        ghost={getButtonType(AnnotateActionType.Strikeout) === ButtonType.Primary}
        type={getButtonType(AnnotateActionType.Strikeout)}
        icon={useMemo(
          () => (
            <CancelLine
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': getActionColor(AnnotateActionType.Strikeout),
                  '--stroke-color': 'none',
                } as React.CSSProperties
              }
            />
          ),
          [color],
        )}
        onClick={onStrikeoutClick}
        disabled={disabled}
        tooltip={useMemo(
          () => (
            <>
              {AnnotateActionTypeLabel.Strikeout}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.STRIKEOUT}
              </Typography.Text>
            </>
          ),
          [],
        )}
      />
      <Button
        ghost={getButtonType(AnnotateActionType.Squiggly) === ButtonType.Primary}
        type={getButtonType(AnnotateActionType.Squiggly)}
        icon={useMemo(
          () => (
            <Underline2
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': getActionColor(AnnotateActionType.Squiggly),
                  '--stroke-color': getActionColor(AnnotateActionType.Squiggly),
                } as React.CSSProperties
              }
            />
          ),
          [color],
        )}
        onClick={onSquigglyClick}
        disabled={disabled}
        tooltip={useMemo(
          () => (
            <>
              {AnnotateActionTypeLabel.Squiggly}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.SQUIGGLY}
              </Typography.Text>
            </>
          ),
          [],
        )}
      />
    </div>
  );
};

export default TextMarkupPanel;
