import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputNumber from '../input/InputNumber';
import { InputSize } from '../input/enums/InputSize';
import { getNumPages, getPage, getStoredFileId, isSinglePageView } from '../../../store/viewerSettings/selectors';
import { setPage, setScrollIntoPage } from '../../../store/viewerSettings/actions';
import { PAGE_INPUT_WIDTH } from '../../layouts/toolbar/constants';

import './Paginator.less';

const Paginator: FC = () => {
  const dispatch = useDispatch();
  const singlePageView: boolean = useSelector(isSinglePageView);
  const numPages: number = useSelector(getNumPages);
  const page: number = useSelector(getPage);
  const storedFileId = useSelector(getStoredFileId);
  const [pageInputValue, setPageInputValue] = useState(page);
  const disabled = useMemo(() => !storedFileId, [storedFileId]);

  const pageOnEnter = useCallback(
    (e) => {
      const newPage = parseInt(e.target.value);
      if (!isNaN(newPage) && newPage > 0 && newPage <= numPages && newPage !== page) {
        changePage(newPage, singlePageView);
      } else {
        setPageInputValue(page);
      }
    },
    [page, numPages, singlePageView],
  );

  useEffect(
    useCallback(() => {
      if (page !== pageInputValue) {
        setPageInputValue(page);
      }
    }, [pageInputValue, page]),
    [page],
  );

  const changePage = (newPage: number, singlePageView: boolean) => {
    if (!singlePageView) {
      dispatch(setScrollIntoPage(newPage));
    } else {
      dispatch(setPage(newPage));
    }
  };

  const handleChange = useCallback((value) => {
    setPageInputValue(value);
  }, []);

  useEffect(
    useCallback(() => {
      if (page !== pageInputValue) {
        setPageInputValue(page);
      }
    }, [pageInputValue, page]),
    [page],
  );

  return (
    <div className="paginator">
      <InputNumber
        value={pageInputValue}
        onChange={handleChange}
        onPressEnter={pageOnEnter}
        onBlur={pageOnEnter}
        width={PAGE_INPUT_WIDTH}
        size={InputSize.Small}
        min={1}
        bordered={false}
        max={numPages}
        disabled={disabled}
      />
      {` / ${numPages}`}
    </div>
  );
};

export default memo(Paginator);
