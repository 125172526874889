import React, { FC, useCallback } from 'react';
import { Color, CustomPicker } from 'react-color';
import Sketch from 'react-color/lib/components/sketch/Sketch';

import Dialog from '../../dialog/Dialog';
import { AnyFunction } from '../../../types/generics';

import './ColorSelector.less';

interface IColorSelectorProps {
  visible?: boolean;
  color?: Color;
  onChange: AnyFunction;
  onOk(): void;
  onCancel(): void;
}

const ColorSelector: FC<IColorSelectorProps> = (props) => {
  const handleColorChange = useCallback((color, event) => {
    event?.preventDefault?.();
    props.onChange(color);
  }, []);

  return (
    <Dialog
      visible={props.visible}
      destroyOnClose
      onOk={props.onOk}
      onCancel={props.onCancel}
      maskClosable
      closable={false}
      width="330px"
      className="color-selector-dialog"
    >
      <div className="color-selector-dialog__picker" onMouseDown={(e) => e.stopPropagation()}>
        <Sketch width="100%" disableAlpha presetColors={[]} {...props} onChange={handleColorChange} />
      </div>
    </Dialog>
  );
};

export default CustomPicker(ColorSelector);
