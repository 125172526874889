import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/button/Button';
import { ButtonType } from '../button/enums/buttonType';
import { getFontStyle } from '../../../store/annotateSettings/selectors';
import { toggleStyle } from '../../../store/annotateSettings/actions';

import { ReactComponent as Bold } from '../../../assets/Bold.svg';
import { ReactComponent as Italic } from '../../../assets/Italic.svg';
import { ReactComponent as Underline } from '../../../assets/Underline.svg';
import { AnnotateStyle, AnnotateStyleLabel } from '../../../store/enums/annotateStyle';

const FontStylePanel: FC = () => {
  const dispatch = useDispatch();
  const { bold, italic, underline } = useSelector(getFontStyle);
  const onBoldClick = () => dispatch(toggleStyle(AnnotateStyle.Bold));
  const onItalicClick = () => dispatch(toggleStyle(AnnotateStyle.Italic));
  const onUnderlineClick = () => dispatch(toggleStyle(AnnotateStyle.Underline));
  const getButtonType = (isStyleApplied: boolean) => (isStyleApplied ? ButtonType.Primary : ButtonType.Text);

  return (
    <div className="text-markup-panel">
      <Button
        ghost={bold}
        type={getButtonType(bold)}
        icon={<Bold className="assets-icon assets-icon_center" />}
        onClick={onBoldClick}
        tooltip={AnnotateStyleLabel.Bold}
      />
      <Button
        ghost={italic}
        type={getButtonType(italic)}
        icon={<Italic className="assets-icon assets-icon_center" />}
        onClick={onItalicClick}
        tooltip={AnnotateStyleLabel.Italic}
      />
      <Button
        ghost={underline}
        type={getButtonType(underline)}
        icon={<Underline className="assets-icon assets-icon_center" />}
        onClick={onUnderlineClick}
        tooltip={AnnotateStyleLabel.Underline}
      />
    </div>
  );
};

export default FontStylePanel;
