import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Dropzone from '../../../components/dropzone/Dropzone';
import Dialog, { IDialogProps } from '../../../components/dialog/Dialog';
import { retrieveAllFiles } from '../../../../services/gDriveService';
import { AnyObject, CustomAny, OrNull } from '../../../types/generics';
import { isSignedIn } from '../../../../store/googleUser/selectors';
import Select from '../../../components/select/Select';
import { ISelectOption } from '../../../components/select/interfaces/ISelectOption';
import { TOOLTIPS_MAP } from '../../../constants/application';
import { parseFileSize } from '../../../helpers/fileHelper';

import './DialogAttachFile.less';
import { Typography } from 'antd';

interface IDialogAttachFileProps extends IDialogProps {
  onOk?(fileData?: AnyObject): void;
  onFileChange(file: File | string): void;
}

const DialogAttachFile: FC<IDialogAttachFileProps> = (props) => {
  const isSigned = useSelector(isSignedIn);
  const [filesList, setFilesList] = useState<AnyObject[]>([]);
  const [filesOptions, setFilesOptions] = useState<ISelectOption[]>([]);
  const [selectedGoogleFile, setSelectedGoogleFile] = useState<OrNull<string>>(null);
  const [filesLoading, setFilesLoading] = useState<boolean>(true);
  const handleFileChange = useCallback((value: string) => {
    setSelectedGoogleFile(value);
  }, []);
  const onDialogOk = useCallback(() => {
    props.onOk?.(_.find(filesList, { id: selectedGoogleFile }) || undefined);
  }, [filesList, selectedGoogleFile, props.onOk]);
  useEffect(() => {
    setSelectedGoogleFile(null);
    setFilesLoading(true);
    if (props.visible && isSigned) {
      retrieveAllFiles().then((files) => {
        setFilesList(files);
        setFilesOptions(
          files
            .filter((file: CustomAny) => (file ? !file.shared || file.ownedByMe : false))
            .map((file: CustomAny) => {
              return {
                title: (
                  <div>
                    {file.name}
                    <Typography.Text type="secondary"> - {parseFileSize(file.size)}</Typography.Text>
                  </div>
                ),
                value: file.id,
              };
            }),
        );
        setFilesLoading(false);
      });
    } else {
      setFilesLoading(false);
    }
  }, [props.visible, isSigned]);
  return (
    <Dialog
      visible={props.visible}
      onOk={onDialogOk}
      onCancel={props.onCancel}
      title="Open PDF"
      destroyOnClose
      className="dialog-attach-file"
    >
      <Dropzone onChange={props.onFileChange} />
      <Select
        onChange={handleFileChange}
        value={selectedGoogleFile}
        options={filesOptions}
        disabled={filesLoading || !isSigned}
        loading={filesLoading}
        placeholder={TOOLTIPS_MAP.ADD_FILE_DIALOG.OPEN_DRIVE_FILE}
        width="100%"
      />
    </Dialog>
  );
};

export default DialogAttachFile;
