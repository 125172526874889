import React, { FC, memo, useMemo } from 'react';
import { Input as AntdInput } from 'antd';

import { IInput } from './interfaces/IInput';

interface IInputProps extends IInput {
  suffix?: React.ReactNode;
  onChange?: React.ChangeEventHandler;
}

const Input: FC<IInputProps> = (props) => {
  const { type = 'string', width = 'auto' } = props;
  const styles = useMemo(
    () => ({
      width,
    }),
    [width],
  );

  return (
    <AntdInput
      defaultValue={props.defaultValue}
      style={styles}
      type={type}
      value={props.value}
      size={props.size}
      placeholder={props.placeholder}
      suffix={props.suffix}
      disabled={props.disabled}
      onChange={props.onChange}
      onPressEnter={props.onPressEnter}
      onBlur={props.onBlur}
      {...props}
    />
  );
};

export default memo(Input);
