import React, { FC, memo } from 'react';

interface IToolbarRight {
  children?: JSX.Element;
}

const ToolbarRight: FC<IToolbarRight> = (props) => {
  return <div className="toolbar-right">{props.children}</div>;
};

export default memo(ToolbarRight);
