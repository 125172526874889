import React, { forwardRef, memo } from 'react';
import { Input as AntdInput } from 'antd';
interface ITextArea {
  placeholder?: string;
  rows?: number;
  onChange?: React.ChangeEventHandler;
}

const TextArea = forwardRef<AntdInput, ITextArea>((props, ref) => {
  return <AntdInput.TextArea rows={props.rows} placeholder={props.placeholder} ref={ref} {...props} />;
});

export default memo(TextArea);
