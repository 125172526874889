import { createAction } from '@reduxjs/toolkit';

import { IAnnotationEntity } from '../../services/interfaces/IAnnotation';

export const setHistory = createAction<IAnnotationEntity[][]>('history/history');
export const setHistoryIndex = createAction<number>('history/historyIndex');
export const rememberState = createAction<IAnnotationEntity[]>('history/rememberState');
export const redo = createAction('history/redo');
export const undo = createAction('history/undo');
export const restore = createAction('history/restore');
