export enum AnnotationCardFormFields {
  Title = 'title',
  Content = 'content',
  Color = 'color',
  Flatten = 'flatten',
}

export enum AnnotationCardFormLabels {
  Title = 'Title',
  Content = 'Content',
  Color = 'Color',
  Flatten = 'Flatten',
}
