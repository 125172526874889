import { configureStore, EnhancedStore } from '@reduxjs/toolkit';

import rootReducer from './reducers';
import { setPageTransitionMode, setScale, toggleShowAnnotationsPanel } from './viewerSettings/actions';
import { LS_IS_SINGLE_PAGE, LS_PAGE_SCALE, LS_SHOW_ANNOTATIONS } from '../shared/constants/localStorageKeys';
import { getItem } from '../services/localStorageService';
import { FontList, FontManager, OPTIONS_DEFAULTS, FONT_FAMILY_DEFAULT } from '@samuelmeuli/font-manager';
import { REACT_APP_GOOGLE_FONT_API_KEY } from '../shared/constants/application';
import { saveFonts, setLoadingFonts } from './fonts/actions';

export default function initStore(): EnhancedStore {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
  });

  // Init store values
  const isSinglePage = getItem(LS_IS_SINGLE_PAGE);
  const pageScale = getItem(LS_PAGE_SCALE);
  const showAnnotationPanel = getItem(LS_SHOW_ANNOTATIONS);

  store.dispatch(setPageTransitionMode(!!isSinglePage));
  store.dispatch(toggleShowAnnotationsPanel(!!showAnnotationPanel));
  if (pageScale) {
    store.dispatch(setScale(pageScale));
  }

  // Get fonts list
  const fontManager = new FontManager(REACT_APP_GOOGLE_FONT_API_KEY, FONT_FAMILY_DEFAULT, OPTIONS_DEFAULTS);
  fontManager.removeFont(FONT_FAMILY_DEFAULT);
  fontManager
    .init()
    .then((fontMap: FontList): void => {
      const fonts = Array.from(fontMap.values());
      store.dispatch(saveFonts(fonts));
      store.dispatch(setLoadingFonts(false));
    })
    .catch((e) => {
      console.error(e);
      store.dispatch(setLoadingFonts(false));
    });

  return store;
}
