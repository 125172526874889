import React, { FC, memo, useState } from 'react';
import { Upload } from 'antd';
import { Upload as UploadIcon } from 'react-bootstrap-icons';
import { UploadChangeParam } from 'antd/lib/upload/interface';

import { CustomAny, OrNull } from '../../types/generics';

const { Dragger } = Upload;

interface IDropzoneProps {
  onChange(file: File | string): void;
}

const Dropzone: FC<IDropzoneProps> = (props) => {
  const [attachedFile, setAttachedFile] = useState<OrNull<File>>(null);
  const dropProps = {
    name: 'file',
    accept: '.pdf',
    multiple: false,
    beforeUpload: () => false,
    showUploadList: false,
    customRequest(file: CustomAny) {
      setAttachedFile(file);
    },
    onChange(info: UploadChangeParam) {
      const file = (info.file.originFileObj || info.file) as File;
      setAttachedFile(file);
      props.onChange?.(file);
    },
  };
  return (
    <Dragger {...dropProps}>
      <p className="ant-upload-drag-icon">
        <UploadIcon size={36} />
      </p>
      {attachedFile ? (
        <p className="ant-upload-text">{attachedFile.name}</p>
      ) : (
        <>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for a single upload</p>
        </>
      )}
    </Dragger>
  );
};

export default memo(Dropzone);
