import React, { FC, memo, useMemo } from 'react';
import { Card, Tag, Typography, Tooltip } from 'antd';
import { Pencil, Trash, Lock } from 'react-bootstrap-icons';
import { IAnnotationComment } from '../interfaces/IAnnotationComment';
import Button from '../../button/Button';
import { ButtonType } from '../../button/enums/buttonType';
import { TOOLTIPS_MAP } from '../../../constants/application';

const { Text } = Typography;

interface IAnnotationCommentViewProps extends IAnnotationComment {
  creationDate: string;
  onEditClick?(): void;
  onDeleteClick?(): void;
}

const AnnotationCommentView: FC<IAnnotationCommentViewProps> = (props) => {
  const isLocked = useMemo(() => !props.onEditClick || !props.onDeleteClick, [props.onEditClick, props.onDeleteClick]);
  return (
    <Card
      size="small"
      className="annotation-comment-card"
      extra={
        <>
          {props.onEditClick && (
            <Button
              icon={
                <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.EDIT}>
                  <Pencil size={16} />
                </Tooltip>
              }
              onClick={props.onEditClick}
              type={ButtonType.Text}
            />
          )}
          {props.onDeleteClick && (
            <Button
              icon={
                <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.DELETE}>
                  <Trash size={16} />
                </Tooltip>
              }
              type={ButtonType.Text}
              onClick={props.onDeleteClick}
            />
          )}
        </>
      }
      title={
        <>
          <Text strong>{props.annotation.title}</Text>
          <Text className="annotation-comment__description" type="secondary">
            {props.creationDate}
          </Text>
        </>
      }
    >
      <section className="annotation-comment__tags">
        {isLocked && (
          <Tag>
            <Lock />
          </Tag>
        )}
      </section>
      <div>{props.annotation.content}</div>
    </Card>
  );
};

export default memo(AnnotationCommentView);
