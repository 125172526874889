import React, { FC } from 'react';

import { TEXT_MAP } from '../../constants/application';

import './ModalTitle.less';

interface ModalTitleProps {
  title: string;
  irreversible?: boolean;
}

const ModalTitle: FC<ModalTitleProps> = (props) => {
  return (
    <div className="modal-title">
      <div>{props.title}</div>
      {props.irreversible && <div className="modal-title_note">{TEXT_MAP.ACTION_CANNOT_BE_UNDONE}</div>}
    </div>
  );
};

export default ModalTitle;
