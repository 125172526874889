import React, { FC, useCallback, useEffect, useMemo, useState, memo } from 'react';
import { Slider, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Trash } from 'react-bootstrap-icons';

import { AnyFunction, OrNull } from '../../types/generics';
import { basicColorMap } from '../../helpers/colorHelper';
import ColorGrid from '../colorDropDown/colorGrid/ColorGrid';
import { getFavoriteColors } from '../../../store/colorsStore/selectors';
import Dialog from '../dialog/Dialog';
import { addFavoriteColor, removeFavoriteColor } from '../../../store/colorsStore/actions';
import Button from '../button/Button';
import { ButtonType } from '../button/enums/buttonType';
import { TEXT_MAP } from '../../constants/application';
import { ILineStyle } from '../lineStyleSelect/interfaces/ILineStyle';
import { BorderStyle } from '../lineStyleSelect/enum/BorderStyle';
import StyleSelect from './styleSelect/StyleSelect';

import './LineStyleDialog.less';

interface ILineStyleDialogProps {
  visible?: boolean;
  style?: ILineStyle | null;
  onOk?: AnyFunction;
  hideOpacity?: boolean;
  onCancel?(): void;
}

const LineStyleDialog: FC<ILineStyleDialogProps> = (props) => {
  const maxFavColorNumber = 48;
  const maxLineWidth = 12;
  const minLineWidth = 1;
  const defaultLineWidth = 2;
  const dispatch = useDispatch();
  const favoriteColors = useSelector(getFavoriteColors);
  const [lineStyle, setLineStyle] = useState<OrNull<BorderStyle>>(
    props.style ? props.style?.borderStyle || BorderStyle.NoBorder : BorderStyle.Solid,
  );
  const [selectedColor, setSelectedColor] = useState<OrNull<string>>(
    props.style ? props.style.borderColor : basicColorMap[0],
  );
  const [lineWidth, setLineWidth] = useState<OrNull<number>>(props.style ? props.style?.borderWidth : defaultLineWidth);
  const isFavoriteSelected = useMemo(() => favoriteColors.includes(selectedColor), [selectedColor, favoriteColors]);

  const onDialogOk = useCallback(() => {
    if (
      lineStyle === (props.style?.borderStyle ? props.style?.borderStyle : BorderStyle.Solid) &&
      selectedColor === (props.style?.borderColor ? props.style.borderColor : basicColorMap[0]) &&
      lineWidth === (props.style?.borderWidth ? props.style?.borderWidth : defaultLineWidth)
    ) {
      props.onCancel?.();
    }
    addFavorite(selectedColor);
    props.onOk?.({
      borderStyle: lineStyle === BorderStyle.NoBorder ? null : lineStyle,
      borderColor: lineStyle === BorderStyle.NoBorder ? null : selectedColor,
      borderWidth: lineStyle === BorderStyle.NoBorder ? null : lineWidth,
    });
  }, [favoriteColors, lineStyle, selectedColor, lineWidth, props.onOk, props.onCancel]);
  const onDialogCancel = useCallback(() => {
    props.onCancel?.();
  }, [props.onCancel]);
  const onFavoriteAdd = useCallback(
    (color) => {
      addFavorite(color);
      setSelectedColor(color);
    },
    [favoriteColors],
  );
  const onFavoriteRemove = useCallback(() => {
    dispatch(removeFavoriteColor(selectedColor));
    setSelectedColor(basicColorMap[0]);
  }, [selectedColor]);
  const addFavorite = (color?: string) => {
    if (color && !favoriteColors.includes(color) && favoriteColors.length < maxFavColorNumber) {
      dispatch(addFavoriteColor(color));
    }
  };
  const onLineWidthChange = (value: number) => {
    setLineWidth(value);
  };

  useEffect(() => {
    if (props.style?.borderColor) {
      setSelectedColor(props.style.borderColor);
    }
    setLineStyle(props.style ? props.style?.borderStyle || BorderStyle.NoBorder : BorderStyle.Solid);
    if (props.style?.borderWidth) {
      setLineWidth(props.style.borderWidth || defaultLineWidth);
    }
  }, [props.style]);

  return (
    <Dialog
      className="line-style-dialog ignore-on-deselect"
      visible={props.visible}
      destroyOnClose
      onOk={onDialogOk}
      onCancel={onDialogCancel}
      maskClosable
      closable={false}
      width="400px"
    >
      <Typography.Title level={5}>{TEXT_MAP.LINE.STYLE}</Typography.Title>
      <StyleSelect value={lineStyle} width={lineWidth} color={selectedColor} onSelect={setLineStyle} />
      <Typography.Title level={5}>{TEXT_MAP.LINE.WIDTH}</Typography.Title>
      <div onMouseDown={(e) => e.stopPropagation()}>
        <Slider
          disabled={lineStyle === BorderStyle.NoBorder}
          min={minLineWidth}
          max={maxLineWidth}
          defaultValue={lineWidth}
          onAfterChange={onLineWidthChange}
          marks={{ [minLineWidth]: minLineWidth, [maxLineWidth]: maxLineWidth }}
        />
      </div>
      <Typography.Title level={5}>{TEXT_MAP.COLORS.BASIC}</Typography.Title>
      <ColorGrid colors={basicColorMap} selected={selectedColor} onChange={setSelectedColor} />
      <div className="title-with-action">
        <Typography.Title level={5}>{TEXT_MAP.COLORS.FAVORITES}</Typography.Title>
        {isFavoriteSelected && <Button type={ButtonType.Text} icon={<Trash />} onClick={onFavoriteRemove} />}
      </div>
      <ColorGrid
        colors={favoriteColors}
        selected={selectedColor}
        onChange={setSelectedColor}
        onAdd={favoriteColors.length < maxFavColorNumber ? onFavoriteAdd : undefined}
      />
    </Dialog>
  );
};

export default memo(LineStyleDialog);
