import React, { memo, useMemo, FC } from 'react';

import './SelectArea.less';

interface ISelectArea {
  coordinates?: number[];
}

const SelectArea: FC<ISelectArea> = (props) => {
  const style = useMemo(() => {
    if (
      !props.coordinates ||
      props.coordinates.length < 4 ||
      (props.coordinates[0] === props.coordinates[2] && props.coordinates[1] === props.coordinates[3])
    ) {
      return { border: 'none' };
    }
    return {
      top: props.coordinates[1] + 'px',
      left: props.coordinates[0] + 'px',
      width: props.coordinates[2] - props.coordinates[0] + 'px',
      height: props.coordinates[3] - props.coordinates[1] + 'px',
    };
  }, [props.coordinates]);

  return <div className="select-area" style={style} />;
};

export default memo(SelectArea);
