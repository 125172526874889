import React, { FC, useCallback } from 'react';
import { Divider, Switch, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { getTypesToRemove } from '../../../store/annotateSettings/selectors';
import { setTypesToRemove } from '../../../store/annotateSettings/actions';
import { AnnotationType } from '../../../store/enums/annotationType';

import './EraserToolPanel.less';

const ERASER_FILTER_OPTIONS = [
  {
    label: 'Text annotation',
    types: [AnnotationType.TextMarkup],
    tooltip: 'Highlight, Underline, Strikeout, Squiggly',
  },
  {
    label: 'Drawing',
    types: [AnnotationType.Ink],
  },
  {
    label: 'Shape',
    types: [AnnotationType.Line, AnnotationType.SquareCircle, AnnotationType.Triangle],
  },
];

const EraserToolPanel: FC = () => {
  const dispatch = useDispatch();
  const typesToRemove: string[] = useSelector(getTypesToRemove);
  const onChange = useCallback(
    (types: string[]) => (checked: boolean) => {
      if (checked) {
        dispatch(setTypesToRemove(_.uniq([...typesToRemove, ...types])));
      } else {
        const newTypes = typesToRemove.filter((type) => !types.includes(type));
        if (newTypes.length > 0) {
          dispatch(setTypesToRemove(typesToRemove.filter((type) => !types.includes(type))));
        }
      }
    },
    [typesToRemove],
  );

  return (
    <>
      {ERASER_FILTER_OPTIONS.map((option, index) => (
        <div key={index} className="eraser-switch">
          {index > 0 && <Divider type="vertical" />}
          <div
            className="eraser-switch__element"
            onClick={() => onChange(option.types)(_.intersection(option.types, typesToRemove).length === 0)}
          >
            <Tooltip title={option.tooltip}>
              <Typography.Text>{option.label}</Typography.Text>
              <Switch
                checked={_.intersection(option.types, typesToRemove).length > 0}
                onChange={onChange(option.types)}
              />
            </Tooltip>
          </div>
        </div>
      ))}
    </>
  );
};

export default EraserToolPanel;
