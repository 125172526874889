import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';

import { IPages } from '../interfaces/IPages';
import {
  addActionToHistory,
  clearPages,
  deleteSelectedPages,
  copyPages,
  pagesRedo,
  pagesUndo,
  setPages,
  setSelectedPages,
  setPagesByViewport,
  setShownPages,
  setPageByViewport,
} from './actions';
import { applyCurrentHistory } from '../../services/pageService';

const initialState: IPages = {
  pages: [],
  selectedPages: [],
  pagesToDelete: [],
  pagesToCopy: [],
  history: [],
  historyIndex: -1,
  pagesByViewport: [],
  shownPages: [],
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setSelectedPages, (state, action) => {
      state.selectedPages = action.payload || [];
    })
    .addCase(deleteSelectedPages, (state) => {
      state.pagesToDelete = _.uniq([...state.pagesToDelete, ...state.selectedPages]);
      state.selectedPages = [];
    })
    .addCase(clearPages, (state) => {
      state.pagesToDelete = [];
      state.pagesToCopy = [];
      state.history = [];
      state.historyIndex = -1;
      state.shownPages = state.pages;
    })
    .addCase(setPages, (state, action) => {
      state.pages = action.payload;
      state.shownPages = [...state.pages];
    })
    .addCase(addActionToHistory, (state, action) => {
      const newHistory = [...state.history];
      if (state.historyIndex < state.history.length - 1) {
        newHistory.splice(state.historyIndex + 1);
      }
      state.history = [...newHistory, action.payload];
      state.historyIndex =
        state.historyIndex + 1 >= state.history.length ? state.history.length - 1 : state.historyIndex + 1;
      state.shownPages = applyCurrentHistory(state.pages, state.history, state.historyIndex);
    })
    .addCase(pagesUndo, (state) => {
      if (state.historyIndex === -1) {
        return;
      }
      state.historyIndex--;
      state.shownPages = applyCurrentHistory(state.pages, state.history, state.historyIndex);
    })
    .addCase(pagesRedo, (state) => {
      if (state.historyIndex === state.history.length - 1) {
        return;
      }
      state.historyIndex++;
      state.shownPages = applyCurrentHistory(state.pages, state.history, state.historyIndex);
    })
    .addCase(copyPages, (state, action) => {
      state.pagesToCopy = action.payload;
    })
    .addCase(setPagesByViewport, (state, action) => {
      state.pagesByViewport = action.payload;
    })
    .addCase(setPageByViewport, (state, action) => {
      state.pagesByViewport[action.payload.index] = action.payload.ratio;
    })
    .addCase(setShownPages, (state, action) => {
      state.shownPages = action.payload;
    });
});
