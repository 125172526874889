import React, { FC, useCallback } from 'react';
import { Scrollbars } from 'rc-scrollbars';
import classNames from 'classnames';
import { useKeyPress } from 'react-use';

import { AnyObject } from '../../types/generics';

import './CustomScroll.less';

interface ICustomScroll {
  style?: AnyObject;
  autoHeight?: boolean;
  autoHeightMax?: number;
  onScroll?(e: AnyObject): void;
  onScrollStop?(): void;
  onUpdate?(e: AnyObject): void;
}

const CustomScroll: FC<ICustomScroll> = (props) => {
  const isCtrlPressed = useKeyPress('Control')[0];
  const isMetaPressed = useKeyPress('Meta')[0];
  const customThumb = (type: string) => (props: AnyObject) => {
    const { className, ...restProps } = props;
    return <div {...restProps} className={classNames(className, `custom-scroll__bar custom-scroll__bar_${type}`)} />;
  };
  const customTrack = (type: string) => (props: AnyObject) => {
    const { className, ...restProps } = props;
    return (
      <div {...restProps} className={classNames(className, `custom-scroll__track custom-scroll__track_${type}`)} />
    );
  };
  const onUpdate = useCallback(
    (e: AnyObject) => {
      if (isCtrlPressed || isMetaPressed) {
        return;
      }
      props.onUpdate?.(e);
    },
    [isCtrlPressed, isMetaPressed],
  );
  return (
    <Scrollbars
      className="custom-scroll"
      renderThumbHorizontal={customThumb('horizontal')}
      renderThumbVertical={customThumb('vertical')}
      renderTrackHorizontal={customTrack('horizontal')}
      renderTrackVertical={customTrack('vertical')}
      hideTracksWhenNotNeeded
      style={props.style}
      autoHeight={props.autoHeight}
      autoHeightMax={props.autoHeightMax}
      onScroll={props.onScroll}
      onScrollStop={props.onScrollStop}
      onUpdate={onUpdate}
    >
      {props.children}
    </Scrollbars>
  );
};

export default CustomScroll;
