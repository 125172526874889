import { fabric } from 'fabric';
import _ from 'lodash';

import { AnyFunction, AnyObject, CustomAny } from '../shared/types/generics';
import {
  IAnnotationEntity,
  IAnnotationFreeText,
  IAnnotationFreeTextEntity,
  IAnnotationText,
  IFreeTextPartStyle,
  IFreeTextStyle,
  IRectangle,
} from './interfaces/IAnnotation';
import { AnnotationType } from '../store/enums/annotationType';
import { TBbox } from '../shared/types/bbox';
import { TQuadPoints } from '../shared/types/quadPoints';
import { AnnotateActionType } from '../store/enums/annotateActionType';
import {
  adjustAnnotationPointsWithViewport,
  adjustQPointsWithViewport,
  adjustRectangleWithViewport,
  convertAnnotationYPoints,
  parseBboxesToQPoints,
  parseQPointsToBboxList,
  rotateAnnotationPoints,
  rotateRectangle,
} from './annotationService';
import { colorToPDFColor, pdfColorToRgb } from '../shared/helpers/colorHelper';
import { WavyLine } from './wavyLine';
import {
  concatFloatBboxes,
  getAnnotationPosition,
  getObjectAnnotationType,
  getObjectId,
  getReverseRotationAngle,
  getRotatedStrikeoutPointsByBbox,
  getRotatedUnderlinePointsByBbox,
  isObjectLocked,
  rotatePointOnCanvas,
  rotatePoints,
  rotatePointsOnCanvas,
  rotateViewport,
  setObjectAnnotationType,
  setObjectId,
  setObjectLocked,
  setTextBoxRectangle,
  swapWidthAndHeight,
} from '../shared/helpers/canvasHelper';

import {
  ICanvas,
  IFabricCanvasServiceProps,
  IGlobalStyles,
  IInitFabricCanvasData,
  IObjectProps,
  IObjectStyles,
  ISelectEvent,
  ITextboxProps,
} from './interfaces/IFabric';
import { StyleProperty } from './enums/styleProperty';
import { ObjectType } from './enums/objectType';
import { TextStyle } from '../store/enums/annotateStyle';
import { CanvasService } from './canvasService';
import { TextItem } from 'react-pdf/dist/Page';
import { BorderStyle } from '../shared/components/lineStyleSelect/enum/BorderStyle';
import { DOUBLE_CLICK_DELAY, SHAPE_ANNOTATIONS } from '../shared/constants/application';
import { ShapeDrawService } from './shapeDrawService';
import { IShapeStyle } from '../store/interfaces/IShapeStyle';
import { ShapeType } from '../store/enums/shapeType';
import {
  convertY,
  getObjectRectangle,
  getObjectRectangleWithoutRotation,
  getRotatedObjectCoordinates,
  setSelectable,
} from '../shared/helpers/fabricCanvasHelper';
import { ImageDrawService } from './imageDrawService';
import { FreeDrawingService } from './freeDrawingService';

const STICKY_NOTE_PATH =
  'M8 7.05c-.552 0-1 .449-1 1v17.2c0 .553.448 1 1 1h5v4.2l5.46-4.2H30c.552 0 1-.447 1-1V8.05c0-.552-.448-1-1-1H8z';

fabric.Object.prototype.set({
  objectCaching: false,
});
fabric.Textbox.prototype.setControlsVisibility({
  mtr: false,
  tr: false,
  tl: false,
  br: false,
  bl: false,
  mt: false,
  mb: false,
});

fabric.Group.prototype.set({
  borderColor: 'rgb(125,201,255)',
  borderScaleFactor: 3,
});
fabric.Textbox.prototype.set({
  borderColor: '#ffc546',
  borderDashArray: [4, 4],
  borderOpacityWhenMoving: 1,
  cornerStyle: 'circle',
  cornerColor: '#ffc546',
  transparentCorners: false,
  lockRotation: true,
  lockScalingY: true,
  lockSkewingX: true,
  lockSkewingY: true,
  splitByGrapheme: false, // disabled until the bug is fixed (https://github.com/fabricjs/fabric.js/issues/7207)
  editingBorderColor: '#ffc546',
});
fabric.Image.prototype.set({
  borderColor: '#ffc546',
  borderDashArray: [6, 6],
  borderOpacityWhenMoving: 1,
  borderScaleFactor: 3,
  cornerColor: '#ffffff',
  cornerStrokeColor: '#ffc546',
  transparentCorners: false,
  lockUniScaling: true,
});
fabric.Image.prototype.setControlsVisibility({
  mtr: false,
  mr: false,
  ml: false,
  mt: false,
  mb: false,
});
fabric.util.object.extend(fabric.StaticCanvas.prototype, {
  __initRetinaScaling: function (scaleRatio: number, canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    if (canvas) {
      canvas.setAttribute('width', this.width * scaleRatio + '');
      canvas.setAttribute('height', this.height * scaleRatio + '');
    }
    if (context) {
      context.scale(scaleRatio, scaleRatio);
    }
  },
});

const defaultTouchStartHandler = (fabric.Canvas.prototype as CustomAny)._onTouchStart;

fabric.util.object.extend(fabric.Canvas.prototype, {
  _onTouchStart: function (e: CustomAny) {
    if (this.allowTouchScrolling) {
      return;
    }
    defaultTouchStartHandler.call(this, e);
  },
});

const originalRender = fabric.Textbox.prototype._render;
fabric.Textbox.prototype._render = function (ctx) {
  originalRender.call(this, ctx);
  if (
    (this as CustomAny).textboxBorderStyle &&
    (this as CustomAny).textboxBorderColor &&
    (this as CustomAny).textboxBorderWidth
  ) {
    const w = (this.width || 0) + (this as CustomAny).textboxBorderWidth;
    const h = (this.height || 0) + (this as CustomAny).textboxBorderWidth;
    ctx.beginPath();
    ctx.closePath();
    const stroke = ctx.strokeStyle;
    const lineWidth = ctx.lineWidth;
    const lineDash = ctx.getLineDash();
    ctx.strokeStyle = (this as CustomAny).textboxBorderColor;
    ctx.lineWidth = (this as CustomAny).textboxBorderWidth;
    ctx.setLineDash((this as CustomAny).textboxBorderStyle === BorderStyle.Dashed ? [4] : []);
    ctx.strokeRect(-w / 2, -h / 2, w, h);
    ctx.strokeStyle = stroke;
    ctx.lineWidth = lineWidth;
    ctx.setLineDash(lineDash);
  }
};
fabric.Textbox.prototype.cacheProperties = fabric.Textbox.prototype.cacheProperties?.concat('active');

export class FabricCanvasService extends CanvasService {
  scale: number;
  page: number;
  onSave: (data: CustomAny, id: string) => void;
  handleTextBoxSelection: (textbox: fabric.Textbox) => void;
  handleAnnotationSelection: (object: fabric.Object) => void;
  clearSelection: () => void;
  toggleEditing: (isEditing: boolean) => void;
  handleTextSelection: (styles: IGlobalStyles) => void;
  style: AnyObject = {};
  shapeDrawService: ShapeDrawService;
  imageDrawService: ImageDrawService;
  handleImageLoading: (loading: boolean, error?: boolean) => void;
  freeDrawingService: FreeDrawingService;
  handleAnnotationDoubleClick: (id: string) => void;
  handleShapeDoubleClick: (id: string) => void;
  removeAnnotation: (id: string) => void;
  private _canvas: ICanvas | undefined;
  private _objectLostSelection = false;
  private _selectionStart: number | undefined = 0;
  private _selectionEnd: number | undefined = 0;
  private _selectionGroup: fabric.Group | null = null;
  private _defaultBackgroundColor = 'rgb(255,255,255)';
  private _defaultWidth = 100;
  private _rotationAngle = 0;
  private _prevMouseUpEvent: fabric.IEvent | null = null;

  constructor(props: IFabricCanvasServiceProps) {
    super(props);
    this.scale = props.scale;
    this.page = props.page;
    this.onSave = props.onSave;
    this.handleTextBoxSelection = props.handleTextBoxSelection;
    this.handleAnnotationSelection = props.handleAnnotationSelection;
    this.handleShapeDoubleClick = props.handleShapeDoubleClick;
    this.clearSelection = props.clearSelection;
    this.toggleEditing = props.toggleEditing;
    this.handleTextSelection = props.handleTextSelection;
    this.textFields = props.textFields || [];
    this._rotationAngle = props.rotationAngle;
    this.shapeDrawService = new ShapeDrawService({
      rotationAngle: props.rotationAngle,
    });
    this.handleImageLoading = props.handleImageLoading;
    this.imageDrawService = new ImageDrawService({
      handleImageLoading: props.handleImageLoading,
      rotationAngle: props.rotationAngle,
    });
    this.freeDrawingService = new FreeDrawingService({
      handleRemoveAnnotations: props.handleRemoveAnnotations,
      rotationAngle: props.rotationAngle,
      getObjectsByIntersection: this.getObjectsByIntersection.bind(this),
      typesToRemove: props.typesToRemove,
    });
    this.handleAnnotationDoubleClick = props.handleAnnotationDoubleClick;
    this.removeAnnotation = props.removeAnnotation;
  }

  set textFields(textFields: TextItem[]) {
    if (textFields) {
      this._textFields = this.adjustTextFields(textFields, this._canvas?.getContext());
    }
  }

  init(id: string, data: IInitFabricCanvasData): void {
    if (!document.getElementById(id)) {
      return;
    }
    this._canvas = new fabric.Canvas(id, {
      imageSmoothingEnabled: false,
      uniformScaling: false,
      targetFindTolerance: 5,
      allowTouchScrolling: true,
    });
    this._canvas.renderOnAddRemove = false;
    this._canvas.selection = false;
    this._canvas.setZoom(this.scale);
    this.updateViewport(data.viewport);
    this.initCanvasListeners();
    this.textFields = this._textFields;
    if (data.isEraser) {
      this.startEraser();
    }
    if (data.isDrawing) {
      this.startDrawing(data.drawingStyle);
    }
  }

  destroy(): void {
    if (this._canvas) {
      this._canvas.off();
      this._canvas.dispose();
      this._canvas = undefined;
    }
  }

  isInitialized(): boolean {
    return !!this._canvas;
  }

  updateViewport(viewport: number[]): void {
    if (!this._canvas) {
      return;
    }
    const height = (this._rotationAngle / 90) % 2 === 0 ? viewport[3] - viewport[1] : viewport[2] - viewport[0];
    const width = (this._rotationAngle / 90) % 2 === 0 ? viewport[2] - viewport[0] : viewport[3] - viewport[1];
    this._canvas.setHeight(height * this.scale);
    this._canvas.setWidth(width * this.scale);
    this.viewport = viewport;
    this.textFields = this._textFields;
  }

  setAllowTouchScrolling(allow: boolean): void {
    if (this._canvas) {
      this._canvas.allowTouchScrolling = allow;
      if (this._canvas.upperCanvasEl && this._canvas.upperCanvasEl.style) {
        (this._canvas.upperCanvasEl.style as CustomAny)['touch-action'] = allow ? 'manipulation' : 'none';
      }
    }
  }

  clearCanvas(): void {
    if (!this._canvas) {
      return;
    }
    this._canvas.clear();
  }

  initCanvasListeners(): void {
    if (!this._canvas) {
      return;
    }
    this._canvas.on('object:moving', this._handleObjectMovement.bind(this));
    this._canvas.on('selection:cleared', this._handleSelectionCleared.bind(this));
    this._canvas.on('text:changed', this._handleTextChange.bind(this));
    this._canvas.on('selection:updated', this._handleSelection.bind(this));
    this._canvas.on('selection:created', this._handleSelection.bind(this));
    this._canvas.on('object:modified', this._handleObjectModified.bind(this));
    this._canvas.on('object:scaling', this._handleObjectScaling.bind(this));
  }

  clearSelectionGroup(): void {
    if (this._canvas && this._selectionGroup) {
      this._canvas.remove(this._selectionGroup);
      this._canvas.requestRenderAll();
    }
    this._selectionGroup = null;
  }

  updateStyleForSelection(property: StyleProperty, value: number | string | boolean): IAnnotationFreeText | null {
    switch (property) {
      case StyleProperty.FontSize:
        value = value as number;
        break;
      case StyleProperty.FontWeight:
        value = value ? TextStyle.Bold : TextStyle.Normal;
        break;
      case StyleProperty.FontStyle:
        value = value ? TextStyle.Italic : TextStyle.Normal;
        break;
      default:
        break;
    }
    this.style[property] = value;
    return this._setStyleForSelection(property, value);
  }

  setCanvasSelectable(selectable: boolean): void {
    if (this._canvas) {
      setSelectable(selectable, this._canvas);
    }
  }

  // Check is cursor on text
  isTextHovered(cursorX: number, cursorY: number): boolean {
    const coordinates = rotatePointOnCanvas(
      getReverseRotationAngle(this._rotationAngle),
      [cursorX, cursorY],
      this.viewport,
      true,
    );
    return this.findTextIndexByCoords(coordinates[0], coordinates[1], this._canvas?.getContext()) > -1;
  }

  isTextSelectionHovered(cursorX: number, cursorY: number): boolean {
    if (this._canvas && this._selectionGroup) {
      const objects = this.getObjectsByIntersection([this._selectionGroup], [[cursorX, cursorY]], this._canvas);
      return objects.length > 0;
    }
    return false;
  }

  isObjectHovered(cursorX: number, cursorY: number, id?: string): boolean {
    if (this._canvas) {
      const objectsWithHoveredCorners = this._canvas
        .getObjects()
        .filter((object) => this._isCoordinatesInsideVisibleCorners(object, cursorX, cursorY));
      const hoveredObjects = this.getObjectsByIntersection(
        this._canvas.getObjects(),
        [[cursorX, cursorY]],
        this._canvas,
      );
      let objects = _.uniqBy([...objectsWithHoveredCorners, ...hoveredObjects], 'annot_id');
      if (id) {
        objects = objects.filter((object) => getObjectId(object) === id);
      }
      return objects.length > 0;
    }
    return false;
  }

  _isCoordinatesInsideObject(object: fabric.Object | undefined, cursorX: number, cursorY: number): boolean {
    if (!object) {
      return false;
    }
    const { left = 0, top = 0, width = 0, height = 0, scaleX = 1, scaleY = 1, angle = 0 } = object;
    let leftOffset = 0;
    let topOffset = 0;
    if (angle == 90) {
      leftOffset = width * scaleX;
    }
    if (angle == 180) {
      leftOffset = width * scaleX;
      topOffset = height * scaleY;
    }
    if (angle == 270) {
      topOffset = height * scaleY;
    }
    return (
      (cursorX > left - leftOffset &&
        cursorX < left + width * scaleX - leftOffset &&
        cursorY > top - topOffset &&
        cursorY < top + height * scaleY - topOffset) ||
      this._isCoordinatesInsideVisibleCorners(object, cursorX, cursorY)
    );
  }

  _isCoordinatesInsideVisibleCorners(object: fabric.Object, cursorX: number, cursorY: number): boolean {
    const { oCoords, _controlsVisibility = {}, controls } = object;
    if (!oCoords || !controls) {
      return false;
    }
    return _.some(controls, (value, key) => {
      if (!(_controlsVisibility as CustomAny)[key] && !value.visible) {
        return false;
      }
      const { tr = { x: 0, y: 0 }, bl = { x: 0, y: 0 } } = (oCoords as CustomAny)[key]?.corner;
      return (
        tr.x / this.scale > cursorX &&
        bl.x / this.scale < cursorX &&
        bl.y / this.scale > cursorY &&
        tr.y / this.scale < cursorY
      );
    });
  }

  setCursor(cursor: string): void {
    if (!this._canvas) {
      return;
    }
    this._canvas.defaultCursor = cursor;
    this._canvas.hoverCursor = cursor === 'default' ? 'move' : cursor;
  }

  updateStyleForGroup(annotation: IAnnotationEntity, color: string): void {
    if (!this._canvas) {
      return;
    }
    const group = this._findObject(annotation);
    if (!group) {
      return;
    }
    (group as fabric.Group).getObjects().forEach((object) => {
      if (object instanceof fabric.Rect && (object as CustomAny).hasFill()) {
        object.fill = color;
      } else if (object instanceof WavyLine || object instanceof fabric.Line) {
        object.stroke = color;
      }
      (group as fabric.Group).addWithUpdate();
    });
    this._canvas.requestRenderAll();
  }

  updateTextBox(prevAnnotation: IAnnotationFreeTextEntity, annotation: IAnnotationFreeTextEntity): void {
    if (!this._canvas || !prevAnnotation) {
      return;
    }
    const textBox = this._findTextBox(prevAnnotation);
    if (!textBox) {
      return;
    }
    const newTextBox = this._createTextBoxObject(annotation);
    textBox.left = newTextBox.left;
    textBox.top = newTextBox.top;
    textBox.width = newTextBox.width;
    textBox.height = newTextBox.height;
    textBox.fontFamily = newTextBox.fontFamily;
    textBox.fill = newTextBox.fill;
    textBox.fontSize = newTextBox.fontSize;
    textBox.fontWeight = newTextBox.fontWeight;
    textBox.fontStyle = newTextBox.fontStyle;
    textBox.underline = newTextBox.underline;
    textBox.styles = newTextBox.styles;
    setTextBoxRectangle(textBox, annotation.rectangle);
    textBox.setCoords();
    this._canvas.requestRenderAll();
  }

  updateScale(scale: number): void {
    if (!this._canvas) {
      return;
    }
    this._canvas.setZoom(scale);
    const height = swapWidthAndHeight(this._rotationAngle)
      ? this.viewport[2] - this.viewport[0]
      : this.viewport[3] - this.viewport[1];
    const width = swapWidthAndHeight(this._rotationAngle)
      ? this.viewport[3] - this.viewport[1]
      : this.viewport[2] - this.viewport[0];
    this._canvas.setHeight(height * scale);
    this._canvas.setWidth(width * scale);
    this._canvas.requestRenderAll();
    this.scale = scale;
  }

  rotateCanvas(angle: number): void {
    this._rotationAngle = angle;
    const height = swapWidthAndHeight(this._rotationAngle)
      ? this.viewport[2] - this.viewport[0]
      : this.viewport[3] - this.viewport[1];
    const width = swapWidthAndHeight(this._rotationAngle)
      ? this.viewport[3] - this.viewport[1]
      : this.viewport[2] - this.viewport[0];
    this._canvas?.setHeight(height * this.scale);
    this._canvas?.setWidth(width * this.scale);
    this.freeDrawingService.setRotateAngle(angle);
    this.shapeDrawService.setRotateAngle(angle);
    this.imageDrawService.setRotationAngle(angle);
    this._canvas?.renderAll();
  }

  removeObject(annotation: IAnnotationEntity): void {
    if (!this._canvas) {
      return;
    }
    let object: fabric.Object | null;
    switch (annotation.annotationType) {
      case AnnotationType.FreeText:
        object = this._findTextBox(annotation);
        break;
      case AnnotationType.Text:
        object = this._findStickyNote(annotation);
        break;
      default:
        object = this._findObject(annotation);
        break;
    }
    if (object) {
      this._canvas.remove(object);
      this._canvas.requestRenderAll();
    }
  }

  removeTextBox(annotation: IAnnotationFreeTextEntity): void {
    if (!this._canvas) {
      return;
    }
    const textBox = this._findTextBox(annotation);
    if (textBox) {
      this._canvas.remove(textBox);
      this._canvas.requestRenderAll();
    }
  }

  removeGroup(annotation: IAnnotationEntity): void {
    if (!this._canvas) {
      return;
    }
    const group = this._findObject(annotation);
    if (group) {
      this._canvas.remove(group);
      this._canvas.requestRenderAll();
    }
  }

  updateTextBoxObjects(annotations: IAnnotationFreeTextEntity[]): void {
    if (!this._canvas || this._canvas.getObjects('textbox').length !== 0) {
      return;
    }
    const activeObject = this._canvas.getActiveObject();
    if (activeObject && activeObject instanceof fabric.Textbox && activeObject.isEditing) {
      activeObject.exitEditing();
    }
    this._canvas._discardActiveObject?.();
    const objects = this._canvas.getObjects('textbox');
    objects.forEach((object) => this._canvas?.remove(object));
    annotations.forEach((annotation) => {
      this._canvas?.add(this._createTextBoxObject(annotation));
    });
    this._canvas.requestRenderAll();
  }

  addGroupObject(annotation: IAnnotationEntity): void {
    if (!this._canvas) {
      return;
    }
    if (annotation.page === this.page - 1 && annotation.quadPoints && annotation.textMarkupType) {
      const [r, g, b] = annotation.colorRGB;
      const color = pdfColorToRgb(r, g, b, annotation.opacity);
      const group = this._createGroupObject(
        adjustQPointsWithViewport(annotation.quadPoints, this.viewport, false),
        annotation.textMarkupType,
        color,
      );
      group.set('qpoints' as keyof fabric.Object, annotation.quadPoints);
      this._canvas.add(group);
      this._canvas._setActiveObject(group);
      this._canvas.requestRenderAll();
    }
  }

  async updateObjects(annotations: IAnnotationEntity[], activeAnnotation?: IAnnotationEntity): Promise<void> {
    if (!this._canvas) {
      return;
    }
    const activeObject = this._canvas.getActiveObject();
    let editing = false;
    let selectionStart: number | undefined = undefined;
    let selectionEnd: number | undefined = undefined;
    if (activeObject && activeObject instanceof fabric.Textbox && activeObject.isEditing) {
      editing = true;
      selectionStart = activeObject.selectionStart;
      selectionEnd = activeObject.selectionEnd;
      activeObject.exitEditing();
    }
    this._canvas._discardActiveObject?.();
    this._canvas.remove(...this._canvas.getObjects());
    for (const annotation of annotations) {
      const isActive = _.isEqual(annotation, activeAnnotation);
      const isLocked = !!(annotation.readOnly || annotation.locked || annotation.lockedContents);
      let object;
      if (annotation.quadPoints && annotation.textMarkupType) {
        const [r, g, b] = annotation.colorRGB;
        const color = pdfColorToRgb(r, g, b, annotation.opacity);
        const rotatedQuadPoints = rotatePoints(this._rotationAngle, annotation.quadPoints, this.viewport, false);
        const adjustedQuadPoints = adjustQPointsWithViewport(
          rotatedQuadPoints,
          rotateViewport(this._rotationAngle, this.viewport),
          false,
        );
        object = this._createGroupObject(adjustedQuadPoints, annotation.textMarkupType, color);
        object.set('qpoints' as keyof fabric.Object, annotation.quadPoints);
        object.on('mouseup', this._handleAnnotationMouseUp.bind(this, this.handleAnnotationDoubleClick));
      } else if (annotation.annotationType === AnnotationType.FreeText) {
        object = this._createTextBoxObject(annotation);
      } else if (annotation.annotationType === AnnotationType.Text) {
        object = this._createStickyNote(annotation);
        object.on('mouseup', this._handleAnnotationMouseUp.bind(this, this.handleAnnotationDoubleClick));
      } else if (SHAPE_ANNOTATIONS.includes(annotation.annotationType)) {
        let annotationToDisplay = rotateAnnotationPoints(annotation, this.viewport, this._rotationAngle);
        annotationToDisplay = adjustAnnotationPointsWithViewport(
          annotationToDisplay,
          this.viewport,
          false,
          this._rotationAngle,
        );
        annotationToDisplay = convertAnnotationYPoints(annotationToDisplay, this.viewport, this._rotationAngle);
        object = this.shapeDrawService.createShapeObjectByAnnotation(annotationToDisplay);
        if (object) {
          object.on('mouseup', this._handleAnnotationMouseUp.bind(this, this.handleShapeDoubleClick));
        }
      } else if (annotation.annotationType === AnnotationType.Stamp) {
        object = await this.imageDrawService.createImageObject(annotation, this.viewport);
      } else if (annotation.annotationType === AnnotationType.Ink) {
        object = this.freeDrawingService.createPathObject(annotation, this.viewport);
        if (object) {
          object.on('mouseup', this._handleAnnotationMouseUp.bind(this, this.handleShapeDoubleClick));
        }
      }
      if (object) {
        object.perPixelTargetFind = true;
        setObjectId(object, annotation.id || '');
        setObjectLocked(object, isLocked);
        setObjectAnnotationType(object, annotation.annotationType || '');
        this._canvas?.add(object);
        if (isActive) {
          this._canvas?._setActiveObject(object);
          if (editing && object instanceof fabric.Textbox) {
            object.selectionStart = selectionStart;
            object.selectionEnd = selectionEnd;
            object.enterEditing();
          }
        }
      }
    }
    this._canvas.requestRenderAll();
  }

  isObjectLostSelection(): boolean {
    return this._objectLostSelection;
  }

  setObjectLostSelection(lostSelection: boolean): void {
    this._objectLostSelection = lostSelection;
  }

  addObject(type: ObjectType, props: IObjectProps): void {
    if (!this._canvas || this._canvas.getActiveObject()) {
      return;
    }
    if (this._objectLostSelection) {
      this._objectLostSelection = false;
      return;
    }
    switch (type) {
      case ObjectType.Textbox:
        this.addTextBox(props as ITextboxProps);
        return;
      default:
        return;
    }
  }

  addTextBox(props: ITextboxProps): void {
    if (!this._canvas) {
      return;
    }
    this.style = {
      fill: props.fill,
      fontSize: props.fontSize,
      fontWeight: props.fontWeight,
      fontStyle: props.fontStyle,
      underline: props.underline,
      fontFamily: props.font,
    };
    const textBox = new fabric.Textbox('', {
      left: props.x,
      top: props.y,
      width: this._defaultWidth,
      backgroundColor: this._defaultBackgroundColor,
      padding: props.lineStyle.borderStyle ? (props.lineStyle.borderWidth || 0) * this.scale - 1 : 0,
      angle: this._rotationAngle,
      ...this.style,
    });
    textBox.setCoords();
    (textBox as CustomAny).textboxBorderColor = props.lineStyle.borderColor;
    (textBox as CustomAny).textboxBorderStyle = props.lineStyle.borderStyle;
    (textBox as CustomAny).textboxBorderWidth = props.lineStyle.borderWidth;
    setTextBoxRectangle(textBox, this._getObjectRectangle(textBox));
    this._initTextBoxListeners(textBox);
    this._canvas.add(textBox);
    this._canvas._setActiveObject(textBox);
    textBox.enterEditing();
    textBox.hiddenTextarea?.focus();
  }

  async addImageObject(imageBase64: string, cursorX: number, cursorY: number): Promise<void> {
    if (!this._canvas) {
      return;
    }
    const annotation = await this.imageDrawService.createImageObjectByBase64(
      imageBase64,
      cursorX,
      cursorY,
      this.page,
      this.viewport,
    );
    this.onSave(annotation, '');
  }

  startEraser(): void {
    if (this._canvas) {
      this.freeDrawingService.startEraserDrawing(this._canvas);
    }
  }

  stopEraser(): void {
    if (this._canvas) {
      this.freeDrawingService.stopEraserDrawing(this._canvas);
    }
  }

  startDrawing(drawingStyle: IShapeStyle): void {
    if (this._canvas) {
      this.freeDrawingService.startFreeDrawing(this._canvas, drawingStyle);
    }
  }

  updateDrawingStyle(style: IShapeStyle): void {
    if (this._canvas) {
      this.freeDrawingService.updateStyle(this._canvas, style);
    }
  }

  saveDrawings(): void {
    const annotation = this.freeDrawingService.saveDrawings(this.viewport, this.page);
    if (annotation) {
      this.onSave(annotation, '');
    }
  }

  isDrawingMode(): boolean {
    return !!this._canvas && !!this._canvas.isDrawingMode;
  }

  stopDrawing(): void {
    let annotation = null;
    if (this._canvas) {
      annotation = this.freeDrawingService.stopFreeDrawing(this._canvas, this.viewport, this.page);
    }
    if (annotation) {
      this.onSave(annotation, '');
    }
  }

  getSelectedText(quadPoints: TQuadPoints): string {
    let adjustedQuadPoints = rotatePoints(this._rotationAngle, quadPoints, this.viewport, false);
    adjustedQuadPoints = adjustQPointsWithViewport(
      adjustedQuadPoints,
      rotateViewport(this._rotationAngle, this.viewport),
      false,
    );
    const bboxList = parseQPointsToBboxList(adjustedQuadPoints);
    let selectedText = '';
    bboxList.forEach((bbox) => {
      bbox[0] *= this.scale;
      bbox[1] = convertY(bbox[1], this.viewport, this._rotationAngle) * this.scale;
      bbox[2] *= this.scale;
      bbox[3] = convertY(bbox[3], this.viewport, this._rotationAngle) * this.scale;
      this._highlightBox = bbox;
      const textFieldIndexes = this.getHighlightedTextIndexes(this._canvas?.getContext());
      if (!textFieldIndexes.length || !this._canvas) {
        return;
      }
      const highlightFieldsData = textFieldIndexes.map((fieldIndex) => {
        const { bboxes, selectedString } = this.getHighlightFieldLetters({
          textField: this._textFields[fieldIndex],
          fromStart: textFieldIndexes.indexOf(fieldIndex) > 0,
          toEnd: textFieldIndexes.indexOf(fieldIndex) < textFieldIndexes.length - 1,
          mode: AnnotateActionType.Highlight,
          ctx: this._canvas?.getContext(),
        });
        return {
          bboxes: bboxes[0] || undefined,
          selectedString,
        };
      });
      let text = '';
      let baseline: number | null = null;
      highlightFieldsData.forEach((bbox) => {
        if (bbox.bboxes && baseline !== _.round(bbox.bboxes[1], 2)) {
          if (!_.isNull(baseline)) {
            text += '\r\n';
          }
          baseline = _.round(bbox.bboxes[1], 2);
        }
        text += bbox.selectedString;
      });
      selectedText += text;
      selectedText += '\r\n';
    });
    return selectedText;
  }

  highlightText(bbox: TBbox, mode?: AnnotateActionType): { quadPoints: TQuadPoints; selectedText: string } {
    const points = rotatePointsOnCanvas(getReverseRotationAngle(this._rotationAngle), [...bbox], this.viewport, true);
    this._highlightBox = [
      Math.min(points[0], points[2]),
      Math.min(points[1], points[3]),
      Math.max(points[0], points[2]),
      Math.max(points[1], points[3]),
    ];
    const textFieldIndexes = this.getHighlightedTextIndexes(this._canvas?.getContext());
    if (!textFieldIndexes.length || !this._canvas) {
      return {
        quadPoints: [],
        selectedText: '',
      };
    }
    const highlightFieldsData = textFieldIndexes.map((fieldIndex) => {
      const { bboxes, selectedString } = this.getHighlightFieldLetters({
        textField: this._textFields[fieldIndex],
        fromStart: textFieldIndexes.indexOf(fieldIndex) > 0,
        toEnd: textFieldIndexes.indexOf(fieldIndex) < textFieldIndexes.length - 1,
        mode,
        ctx: this._canvas?.getContext(),
      });
      return {
        bboxes: bboxes[0] || undefined,
        selectedString,
      };
    });
    const bboxesToFill = highlightFieldsData.map((bbox) => bbox.bboxes);
    let selectedText = '';
    let baseline: number | null = null;
    highlightFieldsData.forEach((bbox) => {
      if (bbox.bboxes && baseline !== _.round(bbox.bboxes[1], 2)) {
        if (!_.isNull(baseline)) {
          selectedText += '\r\n';
        }
        baseline = _.round(bbox.bboxes[1], 2);
      }
      selectedText += bbox.selectedString;
    });

    const annotationBboxes = concatFloatBboxes(bboxesToFill, 10);
    const annotationQuadPoints = parseBboxesToQPoints(annotationBboxes, this.viewport[3] - this.viewport[1]);
    let rotatedQuadPoints = adjustQPointsWithViewport(annotationQuadPoints, this.viewport);
    rotatedQuadPoints = rotatePoints(this._rotationAngle, rotatedQuadPoints, this.viewport, false);
    rotatedQuadPoints = adjustQPointsWithViewport(
      rotatedQuadPoints,
      rotateViewport(this._rotationAngle, this.viewport),
      false,
    );
    const selectionGroup = this._createGroupObject(rotatedQuadPoints);
    if (this._selectionGroup) {
      this._canvas.remove(this._selectionGroup);
    }
    this._canvas.add(selectionGroup);
    this._canvas.requestRenderAll();
    this._selectionGroup = selectionGroup;
    return {
      quadPoints: annotationQuadPoints,
      selectedText,
    };
  }

  selectObject(annotation: IAnnotationEntity): void {
    if (!this._canvas) {
      return;
    }
    let object: fabric.Object | null;
    switch (annotation.annotationType) {
      case AnnotationType.FreeText:
        object = this._findTextBox(annotation);
        break;
      case AnnotationType.Text:
        object = this._findStickyNote(annotation);
        break;
      default:
        object = this._findObject(annotation);
        break;
    }
    if (object) {
      this._canvas.setActiveObject(object);
      this._canvas.requestRenderAll();
    }
  }

  selectTextBox(annotation: IAnnotationFreeTextEntity): void {
    if (!this._canvas) {
      return;
    }
    const textBox = this._findTextBox(annotation);
    if (textBox) {
      this._canvas._setActiveObject(textBox);
      this._canvas.requestRenderAll();
    }
  }

  exitEditing(): void {
    if (!this._canvas) {
      return;
    }
    const activeObject = this._canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    if (activeObject instanceof fabric.Textbox) {
      if (activeObject.isEditing) {
        activeObject.exitEditing();
      }
    }
  }

  getTextboxSelectedText(): string {
    if (!this._canvas) {
      return '';
    }
    const activeObject = this._canvas.getActiveObject();
    if (!activeObject || !(activeObject instanceof fabric.Textbox) || !activeObject.isEditing) {
      return '';
    }
    return activeObject.text?.slice(activeObject.selectionStart, activeObject.selectionEnd) || '';
  }

  removeSelectedTextFromActiveTextbox(): void {
    if (!this._canvas) {
      return;
    }
    const activeObject = this._canvas.getActiveObject();
    if (!activeObject || !(activeObject instanceof fabric.Textbox) || !activeObject.isEditing) {
      return;
    }
    if (activeObject.selectionStart !== activeObject.selectionEnd) {
      const firstPart = activeObject.text?.slice(0, activeObject.selectionStart);
      const secondPart = activeObject.text?.slice(activeObject.selectionEnd);
      activeObject.text = _.isString(firstPart) && _.isString(secondPart) ? firstPart + secondPart : activeObject.text;
      if (activeObject.text) {
        this._canvas.requestRenderAll();
        activeObject.selectionEnd = activeObject.selectionStart;
        this.onSave(this._createFreeTextObject(activeObject), getObjectId(activeObject));
      } else {
        this.removeAnnotation(getObjectId(activeObject));
      }
    }
  }

  discardSelection(types: string[], fireEvent?: boolean): void {
    if (!this._canvas) {
      return;
    }
    const activeObject = this._canvas.getActiveObject();
    if (!activeObject || !types.includes(activeObject.type || '')) {
      return;
    }
    if (activeObject instanceof fabric.Textbox) {
      if (activeObject.isEditing) {
        activeObject.exitEditing();
      }
    }
    if (activeObject instanceof fabric.Textbox && activeObject.isEditing) {
      activeObject.exitEditing();
    }
    if (fireEvent) {
      this._canvas.discardActiveObject();
      this._canvas.requestRenderAll();
    } else {
      this._canvas._discardActiveObject?.();
      this._canvas.requestRenderAll();
    }
  }

  startDrawingShape(shapeStyle: IShapeStyle, shapeType: ShapeType, x: number, y: number): void {
    if (this._canvas) {
      this.shapeDrawService.enterDrawMode(this._canvas, shapeStyle, shapeType, x, y);
      this._canvas.requestRenderAll();
    }
  }

  updateDrawingShape(x: number, y: number, dx: number, dy: number): void {
    if (this._canvas) {
      let newDx = dx;
      let newDy = dy;
      const width = swapWidthAndHeight(this._rotationAngle)
        ? this.viewport[3] - this.viewport[1]
        : this.viewport[2] - this.viewport[0];
      const height = swapWidthAndHeight(this._rotationAngle)
        ? this.viewport[2] - this.viewport[0]
        : this.viewport[3] - this.viewport[1];
      if (x + dx < 0) {
        newDx = -x;
      }
      if (x + dx > width) {
        newDx = width - x;
      }
      if (y + dy < 0) {
        newDy = -y;
      }
      if (y + dy > height) {
        newDy = height - y;
      }
      this.shapeDrawService.applyCoordinatesChange(this._canvas, x, y, newDx, newDy);
      this._canvas.requestRenderAll();
    }
  }

  finishDrawingShape(): void {
    if (this._canvas) {
      const annotation = this.shapeDrawService.exitDrawMode(this._canvas, this.page, this.viewport, this.scale);
      this._canvas.requestRenderAll();
      if (annotation) {
        this.onSave(annotation, '');
      }
    }
  }

  _resetObjectsStyles(objects: fabric.Object[], styles: IObjectStyles[]): void {
    objects.forEach((object, objectIndex) => {
      object.fill = styles[objectIndex].fill;
      object.stroke = styles[objectIndex].stroke;
      object.opacity = styles[objectIndex].opacity;
      if (object instanceof fabric.Group) {
        object.forEachObject((subObject, subObjectIndex) => {
          subObject.fill = styles[objectIndex].fills[subObjectIndex];
          subObject.stroke = styles[objectIndex].strokes[subObjectIndex];
          subObject.opacity = styles[objectIndex].opacities[subObjectIndex];
        });
      }
    });
  }

  _setDefaultStyles(objects: fabric.Object[]): IObjectStyles[] {
    const styles: IObjectStyles[] = [];
    objects.forEach((object, objectIndex) => {
      const fill: string | fabric.Pattern | fabric.Gradient | undefined = object.fill;
      if (object.fill !== 'transparent') {
        object.fill = '#000';
      }
      const stroke: string | undefined = object.stroke;
      object.stroke = '#000';
      const opacity: number | undefined = object.opacity;
      object.opacity = 1;
      const fills: (string | fabric.Pattern | fabric.Gradient | undefined)[] = [];
      const strokes: (string | undefined)[] = [];
      const opacities: (number | undefined)[] = [];
      if (object instanceof fabric.Group) {
        object.forEachObject((subObject, subObjectIndex) => {
          fills[subObjectIndex] = subObject.fill;
          if (subObject.fill !== 'transparent') {
            subObject.fill = '#000';
          }
          strokes[subObjectIndex] = subObject.stroke;
          subObject.stroke = '#000';
          opacities[subObjectIndex] = subObject.opacity;
          subObject.opacity = 1;
        });
      }
      styles[objectIndex] = {
        fill,
        stroke,
        opacity,
        fills,
        strokes,
        opacities,
      };
    });
    return styles;
  }

  getObjectsByIntersection(objects: fabric.Object[], points: number[][], canvas?: fabric.Canvas): fabric.Object[] {
    const zoom = canvas?.getZoom() || 1;
    const objectsStyles = this._setDefaultStyles(objects);
    const foundObjects: fabric.Object[] = [];
    const foundIds: string[] = [];
    points.forEach((point) => {
      const fabricPoint = new fabric.Point(point[0] * zoom, point[1] * zoom);
      objects.forEach((object) => {
        if (
          object.containsPoint(fabricPoint) &&
          !canvas?.isTargetTransparent(object, point[0] * zoom, point[1] * zoom) &&
          !foundIds.includes(getObjectId(object))
        ) {
          foundObjects.push(object);
          foundIds.push(getObjectId(object));
        }
      });
    });

    this._resetObjectsStyles(objects, objectsStyles);
    return foundObjects;
  }

  private _handleSelection(e: ISelectEvent): void {
    if (e.deselected && e.deselected.length > 0) {
      e.deselected.forEach((object) => {
        if (object instanceof fabric.Textbox && !object.text) {
          this._canvas?.remove(object);
        }
      });
    }
    if (e.selected && e.selected.length > 0) {
      if (e.selected[0] instanceof fabric.Textbox) {
        this.handleTextBoxSelection(e.selected[0]);
        this.handleTextSelection({
          style: {
            bold: e.selected[0].fontWeight === TextStyle.Bold,
            italic: e.selected[0].fontStyle === TextStyle.Italic,
            underline: !!e.selected[0].underline,
          },
          fontSize: e.selected[0].fontSize || 0,
          font: e.selected[0].fontFamily || '',
          color: e.selected[0].fill as string,
          lineStyle: {
            borderStyle: (e.selected[0] as CustomAny).textboxBorderStyle,
            borderWidth: (e.selected[0] as CustomAny).textboxBorderWidth,
            borderColor: (e.selected[0] as CustomAny).textboxBorderColor,
          },
        });
      } else {
        this.handleAnnotationSelection(e.selected[0]);
      }
    }
  }

  private _createStickyNote(annotation: IAnnotationText): fabric.Object {
    const isLocked = annotation.readOnly || annotation.locked || annotation.lockedContents;
    let rectangle = rotateRectangle(annotation.rectangle, this.viewport, this._rotationAngle, false);
    rectangle = adjustRectangleWithViewport(rectangle, this.viewport, false, this._rotationAngle);
    const width = Math.abs(rectangle.upperRightX - rectangle.lowerLeftX);
    const height = Math.abs(rectangle.upperRightY - rectangle.lowerLeftY);
    let leftOffset = 0;
    let topOffset = 0;
    if (this._rotationAngle == 90) {
      leftOffset = width;
    }
    if (this._rotationAngle == 180) {
      leftOffset = width;
      topOffset = height;
    }
    if (this._rotationAngle == 270) {
      topOffset = height;
    }
    return new fabric.Path(STICKY_NOTE_PATH, {
      fill: pdfColorToRgb(...annotation.colorRGB, annotation.opacity),
      left: rectangle.lowerLeftX + leftOffset,
      top: convertY(rectangle.upperRightY, this.viewport, this._rotationAngle) + topOffset,
      lockMovementX: isLocked,
      lockMovementY: isLocked,
      stroke: 'white',
      strokeWidth: 3,
      lockSkewingX: true,
      lockSkewingY: true,
      lockScalingX: true,
      lockScalingY: true,
      hasControls: false,
      shadow: new fabric.Shadow({
        color: 'rgba(0,0,0,0.3)',
        offsetY: 2,
        blur: 11,
      }),
      borderColor: 'rgb(125,201,255)',
      borderScaleFactor: 2,
      angle: this._rotationAngle,
    });
  }

  private _createGroupObject(qPoints: TQuadPoints, mode?: AnnotateActionType, color?: string): fabric.Group {
    const bboxList = parseQPointsToBboxList(qPoints);
    const group: fabric.Object[] = [];
    bboxList.forEach((bbox) => {
      bbox[1] = convertY(bbox[1], this.viewport, this._rotationAngle);
      bbox[3] = convertY(bbox[3], this.viewport, this._rotationAngle);
      if (bbox) {
        switch (mode) {
          case AnnotateActionType.Highlight:
            group.push(FabricCanvasService._createHighlight(bbox, color));
            break;
          case AnnotateActionType.Underline:
            group.push(...FabricCanvasService._createUnderline(bbox, color, this._rotationAngle));
            break;
          case AnnotateActionType.Strikeout:
            group.push(...FabricCanvasService._createStrikeout(bbox, color, this._rotationAngle));
            break;
          case AnnotateActionType.Squiggly:
            group.push(...FabricCanvasService._createSquiggly(bbox, color, this._rotationAngle));
            break;
          default:
            group.push(FabricCanvasService._createSelection(bbox));
            break;
        }
      }
    });
    return new fabric.Group(group, {
      lockMovementY: true,
      lockMovementX: true,
      hasControls: false,
      subTargetCheck: false,
      selectable: !!mode,
    });
  }

  private convertY(y: number): number {
    const height = this.viewport[3] - this.viewport[1];
    return (y - height) * -1;
  }

  private static _createSelection(bbox: TBbox): fabric.Rect {
    return new fabric.Rect({
      left: bbox[0],
      top: bbox[1],
      width: Math.abs(bbox[2] - bbox[0]),
      height: Math.abs(bbox[3] - bbox[1]),
      fill: 'rgba(125, 201, 255, .4)',
      selectable: false,
    });
  }

  private static _createHighlight(bbox: TBbox, color?: string): fabric.Rect {
    return new fabric.Rect({
      left: bbox[0],
      top: bbox[1],
      width: Math.abs(bbox[2] - bbox[0]),
      height: Math.abs(bbox[3] - bbox[1]),
      fill: color,
    });
  }

  private static _createUnderline(bbox: TBbox, color?: string, rotationAngle = 0): fabric.Object[] {
    return [
      new fabric.Line(getRotatedUnderlinePointsByBbox(bbox, rotationAngle), {
        stroke: color,
        strokeWidth: 1.3,
      }),
      new fabric.Rect({
        left: bbox[0],
        top: bbox[1],
        width: Math.abs(bbox[2] - bbox[0]),
        height: Math.abs(bbox[3] - bbox[1]),
        fill: 'transparent',
      }),
    ];
  }

  private static _createStrikeout(bbox: TBbox, color?: string, rotationAngle = 0): fabric.Object[] {
    return [
      new fabric.Line(getRotatedStrikeoutPointsByBbox(bbox, rotationAngle), {
        stroke: color,
        strokeWidth: 1.3,
      }),
      new fabric.Rect({
        left: bbox[0],
        top: bbox[1],
        width: Math.abs(bbox[2] - bbox[0]),
        height: Math.abs(bbox[3] - bbox[1]),
        fill: 'transparent',
      }),
    ];
  }

  private static _createSquiggly(bbox: TBbox, color?: string, rotationAngle = 0): fabric.Object[] {
    return [
      new WavyLine(getRotatedUnderlinePointsByBbox(bbox, rotationAngle), {
        stroke: color,
        strokeWidth: 1.3,
      }),
      new fabric.Rect({
        left: bbox[0],
        top: bbox[1],
        width: Math.abs(bbox[2] - bbox[0]),
        height: Math.abs(bbox[3] - bbox[1]),
        fill: 'transparent',
      }),
    ];
  }

  private _findObject(annotation: IAnnotationEntity): fabric.Object | null {
    if (!this._canvas) {
      return null;
    }
    const objects = this._canvas.getObjects().filter((object) => getObjectId(object) === annotation.id);
    if (objects.length > 0) {
      return objects[0];
    }
    return null;
  }

  private _findTextBox(annotation: IAnnotationFreeTextEntity): fabric.Textbox | null {
    if (!this._canvas) {
      return null;
    }
    const textBoxes = this._canvas.getObjects('textbox').filter((object) => {
      const textBox = object as fabric.Textbox;
      return getObjectId(textBox) === annotation.id;
    });
    if (textBoxes.length > 0) {
      return textBoxes[0] as fabric.Textbox;
    }
    return null;
  }

  private _findStickyNote(annotation: IAnnotationFreeTextEntity): fabric.Object | null {
    if (!this._canvas) {
      return null;
    }
    const notes = this._canvas.getObjects().filter((object) => getObjectId(object) === annotation.id);
    if (notes.length > 0) {
      return notes[0];
    }
    return null;
  }

  private _createTextBoxObject(annotation: IAnnotationFreeTextEntity) {
    const isLocked = annotation.readOnly || annotation.locked || annotation.lockedContents;
    const styles = this._constructStylesFromStyleBlocks(annotation.content || '', annotation.textStyles);
    let style = _.values(styles)[0];
    style = style ? _.values(style)[0] : {};
    const { left, top, width, height } = getAnnotationPosition(
      annotation.rectangle,
      this.viewport,
      this._rotationAngle,
    );
    const textBox = new fabric.Textbox(annotation.content || '', {
      left,
      top,
      width,
      height,
      backgroundColor: pdfColorToRgb(...annotation.colorRGB, annotation.opacity),
      styles: styles,
      ...style,
      editable: !isLocked,
      lockMovementX: isLocked,
      lockMovementY: isLocked,
      lockSkewingX: isLocked,
      lockSkewingY: isLocked,
      lockScalingX: isLocked,
      lockScalingY: isLocked,
      hasControls: !isLocked,
      textboxBorderColor: annotation.borderColorRGB ? pdfColorToRgb(...annotation.borderColorRGB) : null,
      textboxBorderStyle: annotation.borderStyle,
      textboxBorderWidth: annotation.borderWidth,
      padding: annotation.borderStyle ? (annotation.borderWidth || 0) * this.scale - 1 : 0,
      angle: this._rotationAngle,
    });
    textBox.setCoords();
    // save rectangle as is to have an ability to select textbox object
    textBox.set('rectangle' as keyof fabric.Object, annotation.rectangle);
    this._initTextBoxListeners(textBox);
    return textBox;
  }

  /**
   * Converts style blocks (IFreeTextPartStyle) to fabric.Textbox styles.
   * fabric.Textbox styles should contain pairs {[lineIndex]: lineStyle}.
   * lineStyle is an object containing {[charIndex]: style},
   * where style is an object with style properties (IFreeTextStyle)
   * @param text
   * @param styleBlocks
   * @private
   */
  private _constructStylesFromStyleBlocks(text: string, styleBlocks?: IFreeTextPartStyle[]) {
    if (!styleBlocks) {
      return {};
    }
    let lineIndex = 0;
    let charIndex = 0;
    const styles: AnyObject = {};
    [...text].forEach((char, index) => {
      if (char === '\n') {
        lineIndex++;
        charIndex = 0;
      } else {
        let charStyles: CustomAny = styleBlocks.find((block) => block.start <= index && block.end > index);
        charStyles = _.omit(charStyles, ['start', 'end']);
        if (charStyles.colorRGB) {
          const [r, g, b] = charStyles.colorRGB;
          charStyles['fill'] = pdfColorToRgb(r, g, b);
          delete charStyles.colorRGB;
        }
        if (charStyles.fontName) {
          charStyles['fontFamily'] = charStyles.fontName;
          delete charStyles.fontName;
        } else {
          charStyles['fontFamily'] = 'Roboto';
        }
        if (charStyles) {
          if (!styles[lineIndex]) {
            styles[lineIndex] = {};
          }
          styles[lineIndex][charIndex] = {
            ...(charStyles as IFreeTextStyle),
          };
        }
        charIndex++;
      }
    });
    return styles;
  }

  /**
   * Initializes listeners for events that fabric.Textbox fires
   * @param textbox - object to init listeners for
   * @private
   */
  private _initTextBoxListeners(textbox: fabric.Textbox): void {
    textbox.on('selection:changed', () => this._handleTextSelection.bind(this)(textbox, this.handleTextSelection));
    textbox.on('resizing', () => this._handleObjectResize.bind(this)(textbox));
    textbox.on('editing:entered', () => this.toggleEditing(true));
    textbox.on('editing:exited', () => this.toggleEditing(false));
  }

  /**
   * Creates IAnnotationFreeText object to save in annotations list
   * @param object - fabric.Textbox object to convert
   * @private
   */
  private _createFreeTextObject(object: fabric.Textbox): IAnnotationFreeText {
    const rectangle = getObjectRectangleWithoutRotation(
      object,
      this.viewport,
      false,
      getReverseRotationAngle(this._rotationAngle),
    );
    const annotation: IAnnotationFreeText = {
      page: this.page - 1,
      annotationType: AnnotationType.FreeText,
      content: object.text,
      rectangle,
      colorRGB: colorToPDFColor(object.backgroundColor as string)[0],
      opacity: 1,
      textStyles: FabricCanvasService._constructTextBoxStyleBlocks(object as fabric.Textbox),
      borderColorRGB: (object as CustomAny).textboxBorderColor
        ? colorToPDFColor((object as CustomAny).textboxBorderColor as string)[0]
        : null,
      borderWidth: (object as CustomAny).textboxBorderWidth,
      borderStyle: (object as CustomAny).textboxBorderStyle,
    };
    const id = getObjectId(object);
    if (id) {
      annotation.id = id;
    }
    return annotation;
  }

  /**
   * Creates IAnnotationFreeText object to save in annotations list
   * @param object - fabric.Textbox object to convert
   * @private
   */
  private _createTextObject(object: fabric.Object): IAnnotationText {
    const [color, opacity] = colorToPDFColor(object.fill as string);
    const annotation: IAnnotationText = {
      page: this.page - 1,
      annotationType: AnnotationType.Text,
      rectangle: getObjectRectangle(object, this.viewport, false, getReverseRotationAngle(this._rotationAngle), true),
      colorRGB: color,
      opacity: opacity,
    };
    const id = getObjectId(object);
    if (id) {
      annotation.id = id;
    }
    return annotation;
  }

  /**
   * Converts fabric.Object coordinates on canvas to rectangle coordinates in PDF
   * @param object - object to convert coordinates for
   * @param useScale
   * @private
   */
  private _getObjectRectangle(object: fabric.Object, useScale?: boolean): IRectangle {
    const { left = 0, top = 0, width = 0, height = 0, scaleX = 1, scaleY = 1 } = object;
    const rectangle = {
      lowerLeftX: left,
      upperRightX: left + width * (useScale ? scaleX : 1),
      upperRightY: top,
      lowerLeftY: top + height * (useScale ? scaleY : 1),
    };
    rectangle.lowerLeftY = this.convertY(rectangle.lowerLeftY);
    rectangle.upperRightY = this.convertY(rectangle.upperRightY);
    return adjustRectangleWithViewport(rectangle, this.viewport, true, this._rotationAngle);
  }

  /**
   * Converts styles of fabric.Textbox object to styleBlocks
   * @param object - object to convert styles for
   * @private
   */
  private static _constructTextBoxStyleBlocks(object: fabric.Textbox): IFreeTextPartStyle[] {
    const text = object.text || '';
    const styleBlocks = [];
    let block: CustomAny = null;
    let prevStyles = {};
    for (let i = 0; i <= text.length; i++) {
      // get char styles ignoring linebreak symbol
      const styles = '\n' === text[i] ? prevStyles : object.getSelectionStyles(i, i + 1, true)[0];
      if (styles.fill) {
        styles['colorRGB'] = colorToPDFColor(styles.fill)[0];
        delete styles.fill;
      }
      if (styles.fontFamily) {
        styles['fontName'] = styles.fontFamily;
        delete styles.fontFamily;
      }
      if (!_.isEqual(prevStyles, styles) || i === text.length) {
        // construct new style block if meet char with new style
        if (block) {
          styleBlocks.push(block);
        }
        block = {
          start: i,
          end: i + 1,
          ...styles,
        };
        prevStyles = styles;
      } else if (_.isEqual(prevStyles, styles)) {
        // extend style block for char with the same style
        if (block) {
          block.end++;
        }
      }
    }
    return styleBlocks;
  }

  public setStyleForBorder(property: StyleProperty, value: string | number | null): IAnnotationFreeText | null {
    if (!this._canvas) {
      return null;
    }
    const activeObject = this._canvas.getActiveObject();
    if (!activeObject || !(activeObject instanceof fabric.Textbox)) {
      return null;
    }
    const oldAnnotation = this._createFreeTextObject(activeObject as fabric.Textbox);
    if ((activeObject as CustomAny)[property] !== value) {
      (activeObject as CustomAny)[property] = value;
    }
    this._canvas.requestRenderAll();
    const newAnnotation = this._createFreeTextObject(activeObject as fabric.Textbox);
    return !_.isEqual(oldAnnotation, newAnnotation) && newAnnotation.id ? newAnnotation : null;
  }

  /**
   * Sets style to selection of different types (object selection, full text selection, text part selection)
   * and updates canvas. Supports only fabric.Textbox objects.
   * @param property - property name to set
   * @param value - value of property to set
   * @return converted object to save if object style was updated
   * @private
   */
  private _setStyleForSelection(property: StyleProperty, value: number | string | boolean): IAnnotationFreeText | null {
    if (!this._canvas) {
      return null;
    }
    const activeObject = this._canvas.getActiveObject();
    if (!activeObject || !(activeObject instanceof fabric.Textbox)) {
      return null;
    }
    const oldAnnotation = this._createFreeTextObject(activeObject as fabric.Textbox);
    if (
      (activeObject.isEditing &&
        activeObject.text &&
        activeObject.selectionStart === 0 &&
        activeObject.selectionEnd === activeObject.text.length &&
        (activeObject as CustomAny)[property] !== value) ||
      !activeObject.isEditing
    ) {
      const styles = activeObject.styles;
      _.mapValues(styles, (stylePerLine: CustomAny) =>
        _.mapValues(stylePerLine, (style: CustomAny) => (style[property] = value)),
      );
      activeObject.set({ styles });
    } else if (activeObject.isEditing) {
      if (activeObject.selectionEnd !== activeObject.selectionStart) {
        activeObject.setSelectionStyles({ [property]: value });
      } else {
        activeObject.hiddenTextarea?.focus();
      }
    }
    this._canvas.requestRenderAll();
    const newAnnotation = this._createFreeTextObject(activeObject as fabric.Textbox);
    return !_.isEqual(oldAnnotation, newAnnotation) && newAnnotation.id ? newAnnotation : null;
  }

  private _handleObjectScaling(e: fabric.IEvent) {
    if (e.target instanceof fabric.Image && this._canvas) {
      this._canvas.uniformScaling = true;
    }
  }

  /**
   * Handles object modified event.
   * Updates fabric.Textbox rectangle and saves it
   * @param e
   * @private
   */
  private _handleObjectModified(e: fabric.IEvent) {
    if (this._canvas) {
      this._canvas.uniformScaling = false;
    }
    const target = e.target;
    if (!target) {
      return;
    }
    if (target instanceof fabric.Textbox) {
      if (target.text) {
        setTextBoxRectangle(target, this._getObjectRectangle(target));
        this.onSave(this._createFreeTextObject(target), getObjectId(target));
      } else {
        this.removeAnnotation(getObjectId(target));
      }
    } else if (target instanceof fabric.Path) {
      this.onSave(this._createTextObject(target), getObjectId(target));
    } else if (target instanceof fabric.Image) {
      this.onSave(this.imageDrawService.createImageAnnotation(target, this.page, this.viewport), getObjectId(target));
    } else if (target instanceof fabric.Group) {
      this.onSave(
        this.freeDrawingService.createInkAnnotationObject(target, this.page, this.viewport),
        getObjectId(target),
      );
    } else {
      const annotation = this.shapeDrawService.createShapeAnnotationObject(target, this.page, this.viewport);
      const id = getObjectId(target);
      if (id) {
        annotation.id = id;
      }
      this.onSave(annotation, id);
    }
  }

  /**
   * Limits object resize out of viewport.
   * @param object - object to handle resize event for
   * @private
   */
  private _handleObjectResize(object: fabric.Object): void {
    const { left = 0, top = 0, width = 0 } = object;
    switch (this._rotationAngle) {
      case 0:
        if (left < 0) {
          object.left = 0;
          object.width = width + left;
        }
        if (left + width > this.viewport[2] - this.viewport[0]) {
          object.width = this.viewport[2] - this.viewport[0] - left;
        }
        break;
      case 90:
        if (top < 0) {
          object.top = 0;
          object.width = width + top;
        }
        if (top + width > this.viewport[2] - this.viewport[0]) {
          object.width = this.viewport[2] - this.viewport[0] - top;
        }
        break;
      case 180:
        if (left - width < 0) {
          object.width = left;
        }
        if (left > this.viewport[2] - this.viewport[0]) {
          object.left = this.viewport[2] - this.viewport[0];
          object.width = width + (this.viewport[2] - this.viewport[0] - left);
        }
        break;
      case 270:
        if (top > this.viewport[2] - this.viewport[0]) {
          object.top = this.viewport[2] - this.viewport[0];
          object.width = width + (this.viewport[2] - this.viewport[0] - top);
        }
        if (top - width < 0) {
          object.width = top;
        }
        break;
    }
  }

  /**
   * Gets style for current caret position in text of fabric.Textbox object.
   * Passes derived styles to callback function
   * @param textBox - object to get styles from
   * @param callback - function to pass styles to
   * @private
   */
  private _handleTextSelection(textBox: fabric.Textbox, callback?: AnyFunction): void {
    if (!textBox.text) {
      return;
    }
    this._selectionStart = textBox.selectionStart;
    this._selectionEnd = textBox.selectionEnd;
    let selectionStart, selectionEnd;
    if (!textBox.selectionStart) {
      return;
    }
    if (textBox.selectionStart !== textBox.selectionEnd) {
      selectionStart = textBox.selectionStart;
      selectionEnd = textBox.selectionStart + 1;
    } else if (textBox.selectionStart === 0) {
      selectionStart = textBox.selectionStart;
      selectionEnd = textBox.selectionStart + 1;
    } else {
      selectionStart = textBox.selectionStart - 1;
      selectionEnd = textBox.selectionStart;
    }
    const styles = textBox.getSelectionStyles(selectionStart, selectionEnd)[0];
    const newStyle = {
      bold: styles.fontWeight === TextStyle.Bold || (!styles.fontWeight && textBox.fontWeight === TextStyle.Bold),
      italic: styles.fontStyle === TextStyle.Italic || (!styles.fontStyle && textBox.fontStyle === TextStyle.Italic),
      underline: _.keys(styles).includes('underline') ? styles.underline : textBox.underline,
    };
    const fontSize = styles.fontSize || textBox.fontSize;
    const font = styles.fontFamily || textBox.fontFamily;
    const color = styles.fill || textBox.fill;
    const lineStyle = {
      borderStyle: (textBox as CustomAny).textboxBorderStyle,
      borderColor: (textBox as CustomAny).textboxBorderColor,
      borderWidth: (textBox as CustomAny).textboxBorderWidth,
    };
    this.style = { style: newStyle, fontSize, font, color, lineStyle };
    if (callback) {
      callback(this.style);
    }
  }

  /**
   * Limits object movement out of viewport
   * @param e
   * @private
   */
  private _handleObjectMovement(e: fabric.IEvent): void {
    const target = e.target;
    if (!target || !this._canvas) {
      return;
    }
    const {
      top = 0,
      left = 0,
      width = 0,
      height = 0,
      leftOffset = 0,
      topOffset = 0,
    } = getRotatedObjectCoordinates(
      target,
      this._rotationAngle,
      [AnnotationType.FreeText, AnnotationType.Stamp].includes(getObjectAnnotationType(target) as AnnotationType),
    );
    const viewport = rotateViewport(this._rotationAngle, this.viewport);
    let newTop = top + topOffset;
    let newLeft = left + leftOffset;
    if (top + height > viewport[3] - viewport[1]) {
      newTop = viewport[3] - viewport[1] - height + topOffset;
    }
    if (left + width > viewport[2] - viewport[0]) {
      newLeft = viewport[2] - viewport[0] - width + leftOffset;
    }
    if (top < 0 || newTop < 0) {
      newTop = topOffset;
    }
    if (left < 0 || newLeft < 0) {
      newLeft = leftOffset;
    }
    target.top = newTop;
    target.left = newLeft;
    target.setCoords();
  }

  private _handleSelectionCleared({ deselected }: ISelectEvent): void {
    deselected?.forEach((obj) => {
      if (obj instanceof fabric.Textbox && obj.text?.length === 0) {
        this._canvas?.remove(obj);
      }
    });
    this._objectLostSelection = true;
    this._selectionStart = 0;
    this._selectionEnd = 0;
    if (!this._canvas?.getActiveObject()) {
      this.clearSelection();
    }
  }

  /**
   * Sets selected styles for entered text
   * @param e
   * @private
   */
  private _handleTextChange(e: fabric.IEvent): void {
    if (!this._canvas) {
      return;
    }
    const textbox = e.target as fabric.Textbox;
    if (
      this._selectionStart !== undefined &&
      textbox.selectionStart !== undefined &&
      this._selectionStart < textbox.selectionStart
    ) {
      textbox.setSelectionStyles(this.style, this._selectionStart, textbox.selectionStart);
    }
    this._selectionStart = textbox.selectionStart;
    this._selectionEnd = textbox.selectionEnd;
    setTextBoxRectangle(textbox, this._getObjectRectangle(textbox));
    this._canvas.requestRenderAll();
  }

  private _handleAnnotationMouseUp(callback: (id: string) => void, event: fabric.IEvent): void {
    if (this._prevMouseUpEvent && event.e.timeStamp - this._prevMouseUpEvent.e.timeStamp <= DOUBLE_CLICK_DELAY) {
      const prevId = this._prevMouseUpEvent.target ? getObjectId(this._prevMouseUpEvent.target) : null;
      const id = event.target ? getObjectId(event.target) : null;
      const isLocked = event.target && isObjectLocked(event.target);
      if (prevId && id && prevId === id && !isLocked) {
        callback(id);
      }
    }
    this._prevMouseUpEvent = event;
  }
}
