import _ from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';

import { AnyObject, CustomAny } from '../../shared/types/generics';

class APIException {
  message: string;
  hasMessage: boolean;

  constructor(message: string, hasMessage: boolean) {
    this.message = message;
    this.hasMessage = hasMessage;
  }
}

export const handleResponse = (response: AxiosResponse): CustomAny => response.data;

export const handleDownload =
  (fileName = 'file') =>
  (response: AxiosResponse): CustomAny => {
    let extension;
    // extend extensions when need
    switch (response.headers['content-type']) {
      case 'application/pdf':
        extension = 'pdf';
        break;
      default:
        extension = 'txt';
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.${extension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return response.data;
  };

export const buildFileFormData = (files: File[] | File, data: AnyObject): FormData => {
  const fileData = new FormData();
  if (files instanceof Blob) {
    fileData.append('file', files);
  } else {
    files.forEach((file: File, index) => fileData.append(`file[${index}]`, files[index]));
  }
  _.mapKeys(data, (value, key) =>
    fileData.append(key, new Blob([JSON.stringify(value)], { type: 'application/json' })),
  );

  return fileData;
};

export const buildFormData = (data: AnyObject): FormData => {
  const formData = new FormData();
  _.mapKeys(data, (value, key) => {
    if (_.isString(value)) {
      formData.append(key, value);
    } else {
      formData.append(key, new Blob([JSON.stringify(value)], { type: 'application/json' }));
    }
  });
  return formData;
};

export const handleError = (error: AxiosError): void => {
  const message = error.response?.data?.message || error.message;
  const hasMessage = !!error.response?.data?.message;
  throw new APIException(message, hasMessage);
};
