import { createSelector } from 'reselect';

import { FileProgressStatus } from '../enums/fileProgressStatus';
import { IRootState } from '../interfaces/IRootState';
import { IViewerSettings } from '../interfaces/IViewerSettings';

const getSettings = (state: IRootState): IViewerSettings => state.viewerSettings;

export const getScale = createSelector(getSettings, (settings: IViewerSettings) => settings.scale);
export const getStoredFileId = createSelector(getSettings, (settings: IViewerSettings) => settings.fileId);
export const isSinglePageView = createSelector(getSettings, (settings: IViewerSettings) => settings.singlePageView);
export const isHorizontalMode = createSelector(getSettings, (settings: IViewerSettings) => settings.horizontalMode);
export const getPageLayout = createSelector(getSettings, (settings: IViewerSettings) => settings.pageLayout);
export const getNumPages = createSelector(getSettings, (settings: IViewerSettings) => settings.numPages);
export const getScrollIntoPage = createSelector(getSettings, (settings: IViewerSettings) => settings.scrollIntoPage);
export const getPage = createSelector(getSettings, (settings: IViewerSettings) => settings.page);
export const isShowAnnotationsPanel = createSelector(
  getSettings,
  (settings: IViewerSettings) => settings.showAnnotationsPanel,
);
export const isAppLoading = createSelector(getSettings, (settings: IViewerSettings) => settings.appLoading);
export const getFileProgress = createSelector(getSettings, (settings: IViewerSettings) => {
  return (100 * settings.completedSteps.length) / Object.keys(FileProgressStatus).length;
});
export const getFileInfo = createSelector(getSettings, (settings: IViewerSettings) => settings.fileInfo);
export const isPageNavigationMode = createSelector(
  getSettings,
  (settings: IViewerSettings) => settings.pageNavigationMode,
);
export const getAnnotationsPanelActiveTab = createSelector(
  getSettings,
  (settings: IViewerSettings) => settings.annotationsPanelActiveTab,
);
export const getRotationAngle = createSelector(getSettings, (settings: IViewerSettings) => settings.rotationAngle);
export const getContextMenuActivePage = createSelector(
  getSettings,
  (settings: IViewerSettings) => settings.contextMenuActivePage,
);
