export enum PdfInfoLabel {
  Name = 'Name',
  Size = 'Size',
  Title = 'Title',
  CreationDate = 'Creation Date',
  ModificationDate = 'Modification Date',
  Creator = 'Creator',
  PdfFormatVersion = 'PDFFormatVersion',
  Author = 'Author',
  NumPages = 'Page Count',
}
