import React, { FC, memo } from 'react';
import { Menu } from 'antd';

import { IDropdownItem } from './interfaces/IDropdownItem';
import { buildMenuItems } from './helpers';

import './DropdownMenu.less';

interface IDropdownMenuProps {
  items?: IDropdownItem[];
}

const DropdownMenu: FC<IDropdownMenuProps> = (props) => {
  const { items = [] } = props;

  return (
    <Menu className="drop-menu" selectable={false} inlineIndent={12}>
      {buildMenuItems(items)}
    </Menu>
  );
};

export default memo(DropdownMenu);
