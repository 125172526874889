import moment from 'moment';

import { AnyObject, CustomAny } from '../types/generics';
import { TAttachedFile } from '../layouts/fileContext/FileContext';

export const getFileData = (file: TAttachedFile): AnyObject => {
  return {
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate || file.lastModified,
    name: file.name,
    type: file.type,
  };
};

export const buildFile = (file: File, fileData: AnyObject): File => {
  return new File([new Blob([file])], fileData.name, { ...fileData });
};

export const fileToBase64 = (file: File): Promise<CustomAny> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const resultArray = [];
      if (typeof reader.result === 'string' && reader.result.length > 50000000) {
        for (let i = 0; i < reader.result.length; i = i + 49999999) {
          resultArray.push(reader.result.substring(i, i + 49999999));
        }
      } else {
        resultArray.push(reader.result);
      }
      resolve(resultArray);
    };
    reader.onerror = (error) => reject(error);
  });

export const base64ToFile = (fileStringArray: string[], fileData: AnyObject): File | null => {
  if (!fileData) {
    return null;
  }

  const blob = base64ToBlob(fileStringArray.join(''), fileData.type);
  return new File([blob], fileData.name, { ...fileData });
};

export const base64ToBlob = (base64: string, contentType: string): Blob => {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: contentType });
};

export const parseFileSize = (size: number): string => {
  return size < 1024 * 1024 ? `${(size / 1024).toFixed(2)} KB` : `${(size / 1024 / 1024).toFixed(2)} MB`;
};

export const parsePdfDate = (string: string): string => {
  let date = moment(string);
  if (date.isValid()) {
    return date.format('YYYY-DD-MM HH:mm:ss');
  } else {
    string = string.slice(2);
    string = /'/.test(string[string.length - 1]) ? string.slice(0, -1).replace(/'/, ':') : string;
    date = moment(string, 'YYYYMMDDHHmmssZ');
    return date.local().format('YYYY-DD-MM HH:mm:ss');
  }
};

export const uint8ArrayToHexString = (byteArray: Uint8Array): string => {
  return Array.from(byteArray, (byte) => {
    return ('0' + (byte & 0xff).toString(16)).slice(-2);
  }).join('%');
};

export const base64ToHexUrl = (base64: string): string => {
  const base64Array = base64.split(',');
  const byteString = atob(base64Array[base64Array.length - 1]);
  return 'data:image,%' + uint8ArrayToHexString(Uint8Array.from(byteString, (c) => c.charCodeAt(0)));
};
