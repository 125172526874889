import React, { FC, memo, useMemo } from 'react';
import { InputNumber as AntdInputNumber } from 'antd';

import { InputSize } from './enums/InputSize';
import { IInput } from './interfaces/IInput';

interface IInputNumberProps extends IInput {
  value?: number;
  min?: number;
  max?: number;
  bordered?: boolean;
  onChange?(value: number | string | undefined | null): void;
}

const InputNumber: FC<IInputNumberProps> = (props) => {
  const { size = InputSize.Middle, width = 'auto' } = props;
  const styles = useMemo(
    () => ({
      width,
    }),
    [width],
  );

  return (
    <AntdInputNumber
      bordered={props.bordered}
      style={styles}
      value={props.value}
      size={size}
      placeholder={props.placeholder}
      disabled={props.disabled}
      min={props.min}
      max={props.max}
      onChange={props.onChange}
      onPressEnter={props.onPressEnter}
      onBlur={props.onBlur}
    />
  );
};

export default memo(InputNumber);
