import { createReducer } from '@reduxjs/toolkit';

import { addOutline, setOutlines, setSelectedOutline } from './actions';
import { IOutlines } from '../interfaces/IOutlines';
import { INITIAL_SELECTED_ITEM } from '../../shared/helpers/outlineHelper';

const initialState: IOutlines = {
  list: [],
  selectedItem: INITIAL_SELECTED_ITEM,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setOutlines, (state, action) => {
      state.list = action.payload;
    })
    .addCase(addOutline, (state, action) => {
      state.list = [...state.list, action.payload];
    })
    .addCase(setSelectedOutline, (state, action) => {
      state.selectedItem = { ...action.payload };
    });
});
