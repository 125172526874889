import React, { FC } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { isAppLoading } from '../../../store/viewerSettings/selectors';

import './LoadingOverlay.less';

const LoadingOverlay: FC = () => {
  const appLoading = useSelector(isAppLoading);
  return <section className={classNames('app-loading', { 'app-loading_visible': appLoading })} />;
};

export default LoadingOverlay;
