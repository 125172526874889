import { createAction } from '@reduxjs/toolkit';
import { CustomAny } from '../../shared/types/generics';
import { IPageAction } from '../../services/interfaces/IPageActions';

export const setSelectedPages = createAction<number[]>('pages/select');
export const deleteSelectedPages = createAction('pages/delete');
export const clearPages = createAction('pages/clear');

export const setPages = createAction<CustomAny[]>('pages/set');

export const addActionToHistory = createAction<IPageAction>('pages/history');
export const pagesUndo = createAction('pages/undo');
export const pagesRedo = createAction('pages/redo');
export const copyPages = createAction<number[]>('pages/copy');
export const setPagesByViewport = createAction<number[]>('pages/setPagesByViewport');
export const setPageByViewport = createAction<{ index: number; ratio: number }>('pages/setPageByViewport');
export const setShownPages = createAction<number[]>('pages/setShownPages');
