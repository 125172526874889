import React, { FC } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';

interface IFileLoadingProps {
  className?: string;
}

const FileLoading: FC<IFileLoadingProps> = (props) => {
  return (
    <div className={classNames('file-loading', props.className)}>
      <Spin />
    </div>
  );
};

export default FileLoading;
