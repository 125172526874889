import React, { FC, memo } from 'react';

import ToolbarLeft from './toolbarLeft/ToolbarLeft';
import ToolbarCenter from './toolbarCenter/ToolbarCenter';
import ToolbarRight from './toolbarRight/ToolbarRight';

import './Toolbar.less';

interface IToolbarProps {
  left?: JSX.Element;
  center?: JSX.Element;
  right?: JSX.Element;
  className?: string;
}

const Toolbar: FC<IToolbarProps> = (props) => {
  return (
    <section className={`toolbar ${props.className || ''}`}>
      <ToolbarLeft>{props.left}</ToolbarLeft>
      <ToolbarCenter>{props.center}</ToolbarCenter>
      <ToolbarRight>{props.right}</ToolbarRight>
    </section>
  );
};

export default memo(Toolbar);
