import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Form as AntdForm, Input as AntdInput, Tag, Tooltip, Typography } from 'antd';
import { Check2, CircleFill, X } from 'react-bootstrap-icons';
import classNames from 'classnames';

import { IAnnotationCard } from '../interfaces/IAnnotationCard';
import Button from '../../button/Button';
import { ButtonType } from '../../button/enums/buttonType';
import Form from '../../form/Form';
import { HtmlType } from '../../button/enums/htmlType';
import Input from '../../input/Input';
import { FormSize } from '../../form/enums/FormSize';
import { AnnotationCardFormFields, AnnotationCardFormLabels } from './enums/AnnotationCardFormFields';
import { IAnnotationCardFormData } from './interfaces/IAnnotationCardFormData';
import TextArea from '../../textArea/TextArea';
import ColorPicker from '../../colorPicker/ColorPicker';
import { AnnotateActionType } from '../../../../store/enums/annotateActionType';
import { pdfColorToRgb, rgbAdjustOpacity } from '../../../helpers/colorHelper';
import { AnnotationType } from '../../../../store/enums/annotationType';
import { MAX_LINE_WIDTH, SHAPE_ANNOTATIONS, TOOLTIPS_MAP } from '../../../constants/application';
import ShapeStylePicker from '../../shapeStylePicker/ShapeStylePicker';
import { getAnnotationShapeStyle, getShapeTypeByAnnotation } from '../../../../services/annotationService';
import ShapeIcon from '../../shapeIcon/ShapeIcon';

const { Text } = Typography;

interface IAnnotationCardFormProps extends IAnnotationCard {
  creationDate: string;
  onSubmit(formData: IAnnotationCardFormData): void;
  onCancel(): void;
}

const AnnotationCardForm: FC<IAnnotationCardFormProps> = (props) => {
  const [color, setColor] = useState(pdfColorToRgb(...props.annotation.colorRGB, props.annotation.opacity));
  const [flatten, setFlatten] = useState(props.annotation.flatten || false);
  const textareaRef = useRef(null);
  const cardClassNames = useMemo(
    () =>
      classNames('annotation-card', {
        'annotation-card_active': props.active,
      }),
    [props.active, props.annotation.content],
  );
  const onFormLayoutChange = useCallback((data: IAnnotationCardFormData) => {
    if (data.color) {
      setColor(data.color);
    }
    if (Object.prototype.hasOwnProperty.call(data, 'flatten')) {
      setFlatten(data.flatten);
    }
  }, []);
  const formInitialValues = useMemo(() => {
    return {
      [AnnotationCardFormFields.Title]: props.annotation.title,
      [AnnotationCardFormFields.Content]: props.annotation.content,
      [AnnotationCardFormFields.Color]: color,
      [AnnotationCardFormFields.Flatten]: props.annotation.flatten,
    };
  }, [props.annotation]);
  const isContentEditable = useMemo(
    () => props.annotation.annotationType !== AnnotationType.FreeText,
    [props.annotation],
  );
  useEffect(() => {
    if (props.annotation.enterEditing && textareaRef.current) {
      setTimeout(() => {
        (textareaRef.current as unknown as AntdInput).focus();
      }, 0);
    }
  }, [props.annotation, textareaRef]);

  const stylePicker = useMemo(() => {
    if (
      props.annotation.annotationType === AnnotationType.FreeText ||
      props.annotation.annotationType === AnnotationType.Stamp
    ) {
      return null;
    }
    if (
      SHAPE_ANNOTATIONS.includes(props.annotation.annotationType) ||
      props.annotation.annotationType === AnnotationType.Ink
    ) {
      return (
        <div className="color-control">
          <ShapeStylePicker
            id={props.annotation.id}
            hideBackground={props.annotation.annotationType === AnnotationType.Ink}
            maxLineWidth={MAX_LINE_WIDTH[props.annotation.annotationType]}
            icon={
              <ShapeIcon
                className="annotation-icon assets-icon assets-icon_colorized"
                type={getShapeTypeByAnnotation(props.annotation)}
                style={getAnnotationShapeStyle(props.annotation)}
              />
            }
          />
        </div>
      );
    }
    return (
      <AntdForm.Item name={AnnotationCardFormFields.Color} valuePropName="color" className="color-control">
        <ColorPicker
          icon={<CircleFill className="annotation-bar__color-picker" size={24} color={rgbAdjustOpacity(color, 1)} />}
          hideOpacity={
            props.annotation.textMarkupType !== AnnotateActionType.Highlight &&
            props.annotation.annotationType !== AnnotationType.Text
          }
        />
      </AntdForm.Item>
    );
  }, [props.annotation, color]);

  return (
    <Form
      name={'annotation-form-' + props.annotation.id}
      onSubmit={props.onSubmit}
      className="annotation-card-form"
      size={FormSize.Small}
      onValuesChange={onFormLayoutChange}
      initialValues={formInitialValues}
    >
      <Card
        className={cardClassNames}
        onClick={props.onClick}
        extra={
          <AntdForm.Item noStyle>
            <Button
              htmlType={HtmlType.Submit}
              icon={
                <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.SAVE}>
                  <Check2 size={22} />
                </Tooltip>
              }
              type={ButtonType.Text}
            />
            <Button
              icon={
                <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.CANCEL}>
                  <X size={22} />
                </Tooltip>
              }
              type={ButtonType.Text}
              onClick={props.onCancel}
            />
          </AntdForm.Item>
        }
        title={
          <>
            <div className="annotation-card__title">
              {stylePicker}
              <AntdForm.Item name={AnnotationCardFormFields.Title}>
                <Input placeholder={AnnotationCardFormLabels.Title} />
              </AntdForm.Item>
            </div>
            <Text className="annotation-card__description" type="secondary">
              {props.creationDate}
            </Text>
          </>
        }
      >
        <section className="annotation-card__tags">
          <AntdForm.Item noStyle name={AnnotationCardFormFields.Flatten} valuePropName="checked">
            <Tag.CheckableTag checked={flatten}>{AnnotationCardFormLabels.Flatten}</Tag.CheckableTag>
          </AntdForm.Item>
        </section>
        {isContentEditable ? (
          <AntdForm.Item noStyle name={AnnotationCardFormFields.Content}>
            <TextArea ref={textareaRef} placeholder={AnnotationCardFormLabels.Content} />
          </AntdForm.Item>
        ) : (
          <div className="annotation-card__content">{props.annotation.content}</div>
        )}
      </Card>
    </Form>
  );
};

export default memo(AnnotationCardForm);
