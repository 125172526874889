import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';

import { IColors } from '../interfaces/IColors';
import { addFavoriteColor, removeFavoriteColor } from './actions';
import { getItem, setItem } from '../../services/localStorageService';
import { LS_FAVORITE_COLORS } from '../../shared/constants/localStorageKeys';

const initialState: IColors = {
  favorites: getItem(LS_FAVORITE_COLORS) || [],
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(addFavoriteColor, (state, action) => {
      state.favorites = _.uniq([...state.favorites, action.payload]) as string[];
      setItem(LS_FAVORITE_COLORS, state.favorites);
    })
    .addCase(removeFavoriteColor, (state, action) => {
      state.favorites = state.favorites.filter((color: string) => color !== action.payload);
      setItem(LS_FAVORITE_COLORS, state.favorites);
    });
});
