import React, { FC, useMemo } from 'react';
import { ChatLeft } from 'react-bootstrap-icons';

import { IAnnotationEntity } from '../../../services/interfaces/IAnnotation';
import { AnnotationType } from '../../../store/enums/annotationType';
import { AnnotateActionType } from '../../../store/enums/annotateActionType';
import { pdfColorToRgb, rgbAdjustOpacity } from '../../helpers/colorHelper';
import { getShapeTypeByAnnotation } from '../../../services/annotationService';
import ShapeIcon from '../shapeIcon/ShapeIcon';

import { ReactComponent as Underline1 } from '../../../assets/Underline1.svg';
import { ReactComponent as Underline2 } from '../../../assets/Underline2.svg';
import { ReactComponent as Highlight } from '../../../assets/Highlight.svg';
import { ReactComponent as CancelLine } from '../../../assets/CancelLine.svg';
import { ReactComponent as FreeText } from '../../../assets/FreeText.svg';
import { ReactComponent as StickyNote } from '../../../assets/StickyNoteStroke.svg';
import { ReactComponent as Image } from '../../../assets/Top_Image.svg';
import { ReactComponent as Pencil } from '../../../assets/Pencil.svg';

interface IAnnotationIconProps {
  annotation: IAnnotationEntity;
  size?: number;
}

const AnnotationCardIcon: FC<IAnnotationIconProps> = (props) => {
  const { size = 24 } = props;
  const { annotationType } = props.annotation;
  const shapeType = useMemo(() => getShapeTypeByAnnotation(props.annotation), [props.annotation]);
  const textMarkupType = props.annotation.textMarkupType || annotationType;
  const iconColor = useMemo(() => {
    const [r, g, b] = annotationType === AnnotationType.FreeText ? [255, 255, 255] : props.annotation.colorRGB;
    return rgbAdjustOpacity(pdfColorToRgb(r, g, b), 1);
  }, [props.annotation]);

  const fillColor = useMemo(() => {
    const [r, g, b] = !props.annotation.interiorColor ? [255, 255, 255] : props.annotation.interiorColor;
    return rgbAdjustOpacity(pdfColorToRgb(r, g, b), !props.annotation.interiorColor ? 0 : 1);
  }, [props.annotation]);

  if (annotationType === AnnotationType.Markup) {
    return <ChatLeft className="annotation-icon" size={size} />;
  }

  switch (textMarkupType) {
    case AnnotateActionType.Underline:
      return (
        <Underline1
          className="annotation-icon assets-icon assets-icon_colorized"
          style={{ '--fill-color': iconColor, '--stroke-color': iconColor } as React.CSSProperties}
        />
      );
    case AnnotateActionType.Squiggly:
      return (
        <Underline2
          className="annotation-icon assets-icon assets-icon_colorized"
          style={{ '--fill-color': iconColor, '--stroke-color': iconColor } as React.CSSProperties}
        />
      );
    case AnnotateActionType.Highlight:
      return (
        <Highlight
          className="annotation-icon assets-icon assets-icon_colorized"
          style={{ '--fill-color': iconColor, '--stroke-color': 'none' } as React.CSSProperties}
        />
      );
    case AnnotateActionType.Strikeout:
      return (
        <CancelLine
          className="annotation-icon assets-icon assets-icon_colorized"
          style={{ '--fill-color': iconColor, '--stroke-color': 'none' } as React.CSSProperties}
        />
      );
    case AnnotateActionType.FreeText:
      return (
        <FreeText
          className="annotation-icon assets-icon assets-icon_colorized"
          style={{ '--fill-color': iconColor, '--stroke-color': 'none' } as React.CSSProperties}
        />
      );
    case AnnotateActionType.StickyNote:
      return (
        <StickyNote
          className="annotation-icon assets-icon assets-icon_colorized"
          style={{ '--fill-color': iconColor, '--stroke-color': 'white' } as React.CSSProperties}
        />
      );
    case AnnotationType.Line:
    case AnnotationType.SquareCircle:
    case AnnotationType.Triangle:
      return (
        <ShapeIcon
          className="shape-annotation-icon assets-icon assets-icon_colorized"
          type={shapeType}
          strokeColor={iconColor}
          fillColor={fillColor}
        />
      );
    case AnnotationType.Stamp:
      return (
        <Image
          className="annotation-icon assets-icon assets-icon_colorized"
          style={{ '--fill-color': 'white', '--stroke-color': 'white' } as React.CSSProperties}
        />
      );
    case AnnotationType.Ink:
      return (
        <Pencil
          className="annotation-icon assets-icon assets-icon_colorized"
          style={{ '--fill-color': 'white', '--stroke-color': 'white' } as React.CSSProperties}
        />
      );
    default:
      return <ChatLeft className="annotation-icon" size={size} color={iconColor} />;
  }
};

export default AnnotationCardIcon;
