import _ from 'lodash';

import { IAnnotationEntity } from '../../services/interfaces/IAnnotation';
import { rotateRectangle } from '../../services/annotationService';
import { SCALE_OPTIONS } from '../layouts/toolbar/constants';

export const getNewPageNumber = (pagesByViewport: number[], isDoubleView = false): number => {
  let newPageIndex = -1;
  if (isDoubleView) {
    pagesByViewport.forEach((_ratioValue, index) => {
      if (newPageIndex > -1) {
        return;
      }

      if (pagesByViewport[index] > 0) {
        newPageIndex = index;
      }
    });
  } else {
    pagesByViewport.forEach((_ratioValue, index) => {
      if (newPageIndex === -1) {
        newPageIndex = index;
      }

      if (pagesByViewport[newPageIndex] < pagesByViewport[index]) {
        newPageIndex = index;
      }
    });
  }

  return newPageIndex + 1;
};

export const getPagesToLoad = (loadedPages: number[], page: number, numPages: number): number[] => {
  const newPages = [];
  if (!loadedPages.includes(page)) {
    newPages.push(page);
  }
  if (!loadedPages.includes(page + 1) && page < numPages) {
    newPages.push(page + 1);
  }
  if (!loadedPages.includes(page - 1) && page > 1) {
    newPages.push(page - 1);
  }

  return newPages;
};

export const scrollIntoAnnotation = (
  pdfPage: HTMLElement | null,
  pdfContainer: HTMLElement | null | undefined,
  viewport: number[],
  activeAnnotation: IAnnotationEntity,
  scale: number,
  rotationAngle: number,
): void => {
  if (!pdfPage || !pdfContainer) {
    return;
  }
  let rectangle = { ...activeAnnotation.rectangle };
  if (viewport) {
    rectangle.lowerLeftY = rectangle.lowerLeftY - viewport[1];
    rectangle.upperRightY = rectangle.upperRightY - viewport[1];
    rectangle.lowerLeftX = rectangle.lowerLeftX - viewport[0];
    rectangle.upperRightX = rectangle.upperRightX - viewport[0];
  }
  rectangle = rotateRectangle(rectangle, viewport, rotationAngle, false);
  const upperY = pdfPage.offsetHeight - rectangle.upperRightY * scale;
  const lowerY = pdfPage.offsetHeight - rectangle.lowerLeftY * scale;
  const lowerX = rectangle.lowerLeftX * scale;
  const upperX = rectangle.upperRightX * scale;
  const offset = 100;
  if (
    upperY - offset < pdfContainer.scrollTop ||
    upperY + offset > pdfContainer.scrollTop + pdfContainer.offsetHeight ||
    lowerY - offset < pdfContainer.scrollTop ||
    lowerY + offset > pdfContainer.scrollTop + pdfContainer.offsetHeight
  ) {
    pdfContainer.scrollTop += upperY - offset;
  }
  if (
    lowerX - offset / 2 < pdfContainer.scrollLeft ||
    lowerX + offset / 2 > pdfContainer.scrollLeft + pdfContainer.offsetWidth ||
    upperX - offset / 2 < pdfContainer.scrollLeft ||
    upperX + offset / 2 > pdfContainer.scrollLeft + pdfContainer.offsetWidth
  ) {
    pdfContainer.scrollLeft = lowerX - offset / 2;
  }
};

export const scrollToOutline = (pageNumber: number, yDiff: number): void => {
  const offset = 10;
  const pdfContainer = document.querySelector('.ant-layout-content .rc-scrollbars-view') as HTMLDivElement;
  const pdfPage = document.querySelector(`#page-${pageNumber}`) as HTMLDivElement;
  if (!pdfPage) return;
  pdfContainer.scrollTop = pdfPage.offsetTop + pdfPage.offsetHeight - (yDiff || pdfPage.offsetHeight) - offset;
};

export const scrollToNumber = (number: number): void => {
  const pdfContainer = document.querySelector('.ant-layout-content .rc-scrollbars-view') as HTMLDivElement;
  pdfContainer.scrollTop = number;
};

export const scrollThumbnailsTo = (scrollTop = 0): void => {
  const thumbnailsContainer = document.querySelector('.thumbnails-panel .rc-scrollbars-view') as HTMLDivElement;
  if (thumbnailsContainer) {
    thumbnailsContainer.scrollTop = scrollTop;
  }
};

export const scrollToCard = (card: HTMLDivElement): void => {
  const offset = 100;
  const cardsContainer = document.querySelector('.annotations-panel .rc-scrollbars-view') as HTMLDivElement;
  if (card.offsetTop > cardsContainer.scrollTop + cardsContainer.offsetHeight - offset) {
    cardsContainer.scrollTop = card.offsetTop - cardsContainer.offsetHeight / 2;
  }
  if (card.offsetTop < cardsContainer.scrollTop + offset) {
    cardsContainer.scrollTop = card.offsetTop - cardsContainer.offsetHeight / 2;
  }
};

export const checkIntersection = (coords1: number[], coords2: number[]): boolean => {
  if (coords1[2] < coords2[0] || coords1[0] > coords2[2]) {
    return false;
  }
  return coords1[3] >= coords2[1] && coords1[1] <= coords2[3];
};

export const getPageCoordinates = (canvas: HTMLDivElement): number[] => {
  const page = canvas.parentElement;
  const x1 = canvas.offsetLeft + (page?.offsetLeft || 0);
  const y1 = canvas.offsetTop + (page?.offsetTop || 0);
  const x2 = canvas.offsetLeft + canvas.offsetWidth + (page?.offsetLeft || 0);
  const y2 = canvas.offsetTop + canvas.offsetHeight + (page?.offsetTop || 0);
  return [x1, y1, x2, y2];
};

export const zoomIn = (scale: number): number => {
  const index = _.findLastIndex(SCALE_OPTIONS, (option) => option.value <= scale);
  if (index < 0) {
    return scale;
  }
  return SCALE_OPTIONS[index === SCALE_OPTIONS.length - 1 ? index : index + 1].value as number;
};

export const zoomOut = (scale: number): number => {
  const index = _.findIndex(SCALE_OPTIONS, (option) => option.value >= scale);
  if (index < 0) {
    return scale;
  }
  return SCALE_OPTIONS[index === 0 ? index : index - 1].value as number;
};
