import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { getFontsList } from '../../../store/fonts/selectors';
import { SYMBOLS_TO_LOAD_FONTS } from '../../constants/application';

import './FontLoader.less';

/**
 * Component to force request the uploaded fonts.
 * Uses all the fonts to force the fonts load to immediately
 * apply fonts to Free Text annotations on canvas.
 *
 */
const FontLoader: FC = () => {
  const fonts = useSelector(getFontsList);

  return (
    <>
      {fonts.map((font, index) => (
        <div key={`font-loader-${index}`}>
          <span className="font-loader-item" style={{ fontFamily: font.family }} key={font.id}>
            {SYMBOLS_TO_LOAD_FONTS}
          </span>
          <span
            className="font-loader-item"
            style={{ fontFamily: font.family, fontWeight: 'bold' }}
            key={font.id + 'italic'}
          >
            {SYMBOLS_TO_LOAD_FONTS}
          </span>
          <span
            className="font-loader-item"
            style={{ fontFamily: font.family, fontStyle: 'italic' }}
            key={font.id + 'bold'}
          >
            {SYMBOLS_TO_LOAD_FONTS}
          </span>
        </div>
      ))}
    </>
  );
};

export default memo(FontLoader);
