import { PageLayout } from '../toolbarLeft/enums/pageLayout';

export const A4Height = 842;
export const A4Width = 595;
const pagesGaps = 12 * 3; // *3 because of paddings

export const fitToPage = (
  scaleValue: number,
  pageNumber: number,
  pageLayout: string,
  horizontalMode: boolean,
): string => {
  let firstPage = A4Height;
  let secondPage = A4Height;
  let pageHeight = A4Height;
  switch (pageLayout) {
    case PageLayout.TwoPageView:
      if (Math.abs(pageNumber % 2) == 1) {
        firstPage = document.querySelector(`#page-${pageNumber}`)?.clientHeight || A4Height;
        secondPage = document.querySelector(`#page-${pageNumber + 1}`)?.clientHeight || A4Height;
      } else {
        firstPage = document.querySelector(`#page-${pageNumber}`)?.clientHeight || A4Height;
        secondPage = document.querySelector(`#page-${pageNumber - 1}`)?.clientHeight || A4Height;
      }
      break;
    case PageLayout.TwoPageViewGap:
      if (pageNumber % 2 == 0) {
        firstPage = document.querySelector(`#page-${pageNumber}`)?.clientHeight || A4Height;
        secondPage = document.querySelector(`#page-${pageNumber + 1}`)?.clientHeight || A4Height;
      } else {
        firstPage = document.querySelector(`#page-${pageNumber}`)?.clientHeight || A4Height;
        secondPage = document.querySelector(`#page-${pageNumber - 1}`)?.clientHeight || A4Height;
      }
      break;
    default:
      firstPage = document.querySelector(`#page-${pageNumber}`)?.clientHeight || A4Height;
      secondPage = document.querySelector(`#page-${pageNumber + 1}`)?.clientHeight || A4Height;
  }
  if ((pageLayout === PageLayout.TwoPageViewGap && pageNumber === 1) || horizontalMode) {
    pageHeight = firstPage;
  } else {
    pageHeight = firstPage < secondPage ? secondPage : firstPage;
  }

  const windowHeight =
    document.querySelector('.ant-layout-content')?.clientHeight || document.documentElement.clientHeight;
  const defaultPageHeight = Math.ceil(pageHeight / scaleValue);
  const newScale = windowHeight / defaultPageHeight;
  return newScale.toFixed(2);
};

export const fitToWidth = (
  scaleValue: number,
  pageNumber: number,
  pageLayout: string,
  horizontalMode: boolean,
): string => {
  let firstPage = A4Width;
  let secondPage = A4Width;
  switch (pageLayout) {
    case PageLayout.TwoPageView:
      if (Math.abs(pageNumber % 2) == 1) {
        firstPage = document.querySelector(`#page-${pageNumber}`)?.clientWidth || A4Width;
        secondPage = document.querySelector(`#page-${pageNumber + 1}`)?.clientWidth || A4Width;
      } else {
        firstPage = document.querySelector(`#page-${pageNumber}`)?.clientWidth || A4Width;
        secondPage = document.querySelector(`#page-${pageNumber - 1}`)?.clientWidth || A4Width;
      }
      break;
    case PageLayout.TwoPageViewGap:
      if (pageNumber % 2 == 0) {
        firstPage = document.querySelector(`#page-${pageNumber}`)?.clientWidth || A4Width;
        secondPage = document.querySelector(`#page-${pageNumber + 1}`)?.clientWidth || A4Width;
      } else {
        firstPage = document.querySelector(`#page-${pageNumber}`)?.clientWidth || A4Width;
        secondPage = document.querySelector(`#page-${pageNumber - 1}`)?.clientWidth || A4Width;
      }
      break;
    default:
      firstPage = document.querySelector(`#page-${pageNumber}`)?.clientWidth || A4Width;
      secondPage = document.querySelector(`#page-${pageNumber + 1}`)?.clientWidth || A4Width;
  }
  let pageWidth = firstPage + secondPage + pagesGaps;

  if (horizontalMode || pageLayout === PageLayout.OnePageView) {
    pageWidth = document.querySelector(`#page-${pageNumber}`)?.clientWidth || A4Width;
  }

  if (pageLayout === PageLayout.TwoPageViewGap && pageNumber === 1) {
    pageWidth = 2 * (document.querySelector(`#page-${pageNumber}`)?.clientWidth || A4Width);
  }
  const windowWidth =
    document.querySelector('.ant-layout-content')?.clientWidth || document.documentElement.clientWidth;
  const defaultPageWidth = Math.ceil(pageWidth / scaleValue);
  const newScale = windowWidth / defaultPageWidth;
  return newScale.toFixed(2);
};
