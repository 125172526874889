export enum StyleProperty {
  Fill = 'fill',
  FontFamily = 'fontFamily',
  FontSize = 'fontSize',
  FontWeight = 'fontWeight',
  FontStyle = 'fontStyle',
  Underline = 'underline',
  BorderColor = 'textboxBorderColor',
  StrokeWidth = 'textboxBorderWidth',
  BorderStyle = 'textboxBorderStyle',
}
