import React, { FC, memo } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setFont } from '../../../store/annotateSettings/actions';
import { getFont } from '../../../store/annotateSettings/selectors';
import { getFontsList, isLoadingFonts } from '../../../store/fonts/selectors';

import './FontSelect.less';

const { Option } = Select;

const FontSelect: FC = () => {
  const dispatch = useDispatch();
  const fonts = useSelector(getFontsList);
  const font: string = useSelector(getFont);
  const fontsLoading: boolean = useSelector(isLoadingFonts);
  const onSelect = (value: string) => {
    dispatch(setFont(value));
  };

  return (
    <Select
      className="font-select"
      dropdownClassName="ignore-on-deselect"
      onSelect={onSelect}
      value={font}
      loading={fontsLoading}
    >
      {fonts.map((font) => (
        <Option value={font.family} key={font.id}>
          <span style={{ fontFamily: font.family }}>{font.family}</span>
        </Option>
      ))}
    </Select>
  );
};

export default memo(FontSelect);
