import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initDB } from 'react-indexed-db';
import reportWebVitals from './reportWebVitals';

import App from './App';
import initStore from './store/store';
import { pdfDBConfig } from './services/indexedDBService';
import ErrorBoundary from './shared/layouts/errorBoundary/ErrorBoundary';

import './index.less';

initDB(pdfDBConfig);

ReactDOM.render(
  <Provider store={initStore()}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
