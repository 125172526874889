import React, { FC, memo, useMemo } from 'react';
import { Select as AntdSelect } from 'antd';

import { ISelectOption } from './interfaces/ISelectOption';
import { SelectSize } from './enums/SelectSize';

interface ISelectProps {
  value: string | number;
  disabled?: boolean;
  size?: SelectSize;
  options?: ISelectOption[];
  width?: string;
  placeholder?: string;
  loading?: boolean;
  dropdownStyle?: any;
  dropdownMatchSelectWidth?: boolean;
  listHeight?: number;
  children?: any;
  onChange(value: number | string): void;
}

const Select: FC<ISelectProps> = (props) => {
  const { size = SelectSize.Middle, width = 'auto' } = props;
  const styles = useMemo(
    () => ({
      width,
    }),
    [width],
  );
  const options = useMemo(
    () => props.options?.map((option) => ({ label: option.title, value: option.value })) || undefined,
    [props.options],
  );

  return (
    <AntdSelect
      style={styles}
      value={props.value}
      disabled={props.disabled}
      size={size}
      loading={props.loading}
      placeholder={props.placeholder}
      dropdownStyle={props.dropdownStyle}
      dropdownMatchSelectWidth={props.dropdownMatchSelectWidth}
      listHeight={props.listHeight}
      onChange={props.onChange}
      options={options}
    >
      {props.children}
    </AntdSelect>
  );
};

export default memo(Select);
