import React, { ChangeEvent, FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { Col, Dropdown, InputNumber, Menu, Row, Typography } from 'antd';
import { Pencil, Trash } from 'react-bootstrap-icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useClickAway } from 'react-use';
import classNames from 'classnames';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import Input from '../input/Input';
import { ITreeNode } from '../tree/interfaces/ITreeNode';
import { TEXT_MAP } from '../../constants/application';
import { getNumPages } from '../../../store/viewerSettings/selectors';

import './OutlineTreeItem.less';

interface IOutlineTreeItem {
  outline: ITreeNode;
  outlineToEdit: ITreeNode | null;
  onMenuItemClick(e: MenuInfo, outline: ITreeNode): void;
  onOutlineTitleChange(e: ChangeEvent): void;
  onOutlinePageChange(value: string | number): void;
  onOutlineBlur(outline: ITreeNode): void;
}

const OutlineTreeItem: FC<IOutlineTreeItem> = (props) => {
  const rowRef = useRef(null);
  const numPages = useSelector(getNumPages);
  const [dropDownOpen, setDropdownOpen] = useState<boolean>(false);

  useClickAway(rowRef, () => {
    setDropdownOpen(false);
    props.onOutlineBlur(props.outline);
  });

  const renderMenu = (outline: ITreeNode) => (
    <Menu className="outline-menu">
      <Menu.Item
        key={TEXT_MAP.COMMON.EDIT}
        icon={<Pencil size={16} />}
        onClick={(e) => props.onMenuItemClick(e, outline)}
      >
        <Typography.Text>{TEXT_MAP.COMMON.EDIT}</Typography.Text>
      </Menu.Item>
      <Menu.Item
        key={TEXT_MAP.COMMON.DELETE}
        icon={<Trash size={16} />}
        onClick={(e) => props.onMenuItemClick(e, outline)}
      >
        <Typography.Text>{TEXT_MAP.COMMON.DELETE}</Typography.Text>
      </Menu.Item>
    </Menu>
  );

  const stopClickPropagation = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (props.outline.key === props.outlineToEdit?.key) {
        e.stopPropagation();
      }
    },
    [props.outline, props.outlineToEdit],
  );

  const classes = useMemo(() => {
    return classNames('outline-tree-item', { 'outline-tree-item__menu-open': dropDownOpen });
  }, [dropDownOpen]);

  return (
    <Dropdown
      className={classes}
      trigger={['contextMenu']}
      overlay={renderMenu(props.outline)}
      onVisibleChange={(visible) => setDropdownOpen(visible)}
    >
      <Row ref={rowRef} className="tree-node" gutter={2} justify="space-between" onClick={stopClickPropagation}>
        <Col span={20}>
          {props.outlineToEdit?.key === props.outline.key ? (
            <Input width="100%" value={props.outlineToEdit.title} onChange={props.onOutlineTitleChange} />
          ) : (
            <span>{props.outline.title}</span>
          )}
        </Col>
        <Col span={4} className="tree-node__page-col">
          {props.outlineToEdit?.key === props.outline.key ? (
            <InputNumber
              style={{ width: '100%' }}
              min={1}
              max={numPages}
              defaultValue={props.outlineToEdit.page || ''}
              onChange={props.onOutlinePageChange}
            />
          ) : (
            <Typography.Text type="secondary">
              {!_.isNull(props.outline.page) && props.outline.page !== -1 ? props.outline.page + 1 : ''}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </Dropdown>
  );
};

export default memo(OutlineTreeItem);
