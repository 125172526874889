import React, { FC } from 'react';
import classNames from 'classnames';

interface IAnnotationsGroupTitleProps {
  pageIndex: number;
  active?: boolean;
}

const AnnotationsGroupTitle: FC<IAnnotationsGroupTitleProps> = (props) => {
  return (
    <span
      className={classNames('annotations-group-title', {
        'annotations-group-title_active': props.active,
      })}
    >
      Page {props.pageIndex + 1}
    </span>
  );
};

export default AnnotationsGroupTitle;
