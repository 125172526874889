import { createSelector } from 'reselect';

import { IRootState } from '../interfaces/IRootState';
import { IPages } from '../interfaces/IPages';

const getPages = (state: IRootState): IPages => state.pages;

export const getSelectedPages = createSelector(getPages, (pages: IPages) => pages.selectedPages);
export const getPagesToDelete = createSelector(getPages, (pages: IPages) => pages.pagesToDelete);
export const getPagesList = createSelector(getPages, (pages: IPages) => pages.pages);
export const getPagesHistory = createSelector(getPages, (pages: IPages) => pages.history);
export const getPagesHistoryIndex = createSelector(getPages, (pages: IPages) => pages.historyIndex);
export const getPagesToCopy = createSelector(getPages, (pages: IPages) => pages.pagesToCopy);
export const getPagesByViewport = createSelector(getPages, (pages: IPages) => pages.pagesByViewport);
export const getShownPages = createSelector(getPages, (pages: IPages) => pages.shownPages);
