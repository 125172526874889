export enum AnnotateActionType {
  Highlight = 'HIGHLIGHT',
  Underline = 'UNDERLINE',
  Squiggly = 'SQUIGGLY',
  Strikeout = 'STRIKEOUT',
  FreeText = 'FREE_TEXT',
  StickyNote = 'TEXT',
  Shape = 'SHAPE',
  Image = 'IMAGE',
  Drawing = 'DRAWING',
  Eraser = 'ERASER',
}

export enum AnnotateActionTypeName {
  Highlight = 'Highlight',
  Underline = 'Underline',
  Squiggly = 'Squiggly',
  Strikeout = 'Strikeout',
  FreeText = 'FreeText',
  StickyNote = 'StickyNote',
  Shape = 'Shape',
  Image = 'Image',
  Drawing = 'Drawing',
  Eraser = 'Eraser',
}

export enum AnnotateActionTypeLabel {
  Highlight = 'Highlight',
  Underline = 'Underline',
  Squiggly = 'Squiggly',
  Strikeout = 'Strikeout',
  FreeText = 'Free Text',
  StickyNote = 'Sticky Note',
  Shape = 'Shape',
  Image = 'Image',
  Drawing = 'Drawing',
  Eraser = 'Eraser',
}
