import React from 'react';

import { CustomAny } from '../types/generics';

export function useForceUpdate(): CustomAny {
  const [, forceUpdate] = React.useState<boolean>();

  return React.useCallback(() => {
    // Timeout gives time ui animation
    setTimeout(() => {
      forceUpdate((s) => !s);
    }, 200);
  }, []);
}
