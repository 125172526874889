import React, { FC } from 'react';
import { Modal } from 'antd';
import Draggable from 'react-draggable';

export interface IDialogProps {
  title?: string;
  visible?: boolean;
  destroyOnClose?: boolean;
  className?: string;
  wrapClassName?: string;
  maskClosable?: boolean;
  closable?: boolean;
  width?: string | number;
  onOk?(e: React.MouseEvent<HTMLElement>): void;
  onCancel?(e: React.MouseEvent<HTMLElement>): void;
}

const IGNORE_DRAG_EVENTS_ELEMENTS =
  '.color-grid__color-rect, .ant-btn, .ant-slider, .sketch-picker, .ant-upload, .ant-select, .ant-tabs-tab';

const Dialog: FC<IDialogProps> = (props) => {
  return (
    <Modal
      closable={props.closable ?? true}
      className={props.className}
      wrapClassName={props.wrapClassName}
      title={props.title}
      visible={props.visible}
      destroyOnClose={props.destroyOnClose}
      maskClosable={props.maskClosable}
      width={props.width}
      onOk={props.onOk}
      onCancel={props.onCancel}
      cancelButtonProps={{ size: 'middle' }}
      okButtonProps={{ size: 'middle' }}
      modalRender={(node) => (
        <Draggable cancel={IGNORE_DRAG_EVENTS_ELEMENTS} onStart={(e) => e.stopPropagation()}>
          {node}
        </Draggable>
      )}
    >
      {props.children}
    </Modal>
  );
};

export default Dialog;
