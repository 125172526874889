import React, { FC, useMemo } from 'react';
import { Tooltip, Typography } from 'antd';
import { Pencil } from 'react-bootstrap-icons';
import { Property } from 'csstype';

import { ILineStyle } from '../interfaces/ILineStyle';
import Button from '../../button/Button';
import { ButtonType } from '../../button/enums/buttonType';
import { TOOLTIPS_MAP } from '../../../constants/application';

import './LineStyleItem.less';

const { Text } = Typography;

interface ILineStyleItem {
  index: number;
  lineStyle: ILineStyle;
  hideButton?: boolean;
  onEdit?(): void;
}

const LineStyleItem: FC<ILineStyleItem> = (props) => {
  const onEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onEdit?.();
  };

  const line = useMemo(() => {
    if (!props.lineStyle.borderStyle || !props.lineStyle.borderColor || !props.lineStyle.borderWidth) {
      return <Text>No border</Text>;
    }
    return (
      <div
        style={{
          borderBottomColor: props.lineStyle.borderColor || '',
          borderBottomWidth: (props.lineStyle.borderWidth || 0) + 'px',
          borderBottomStyle: props.lineStyle.borderStyle as Property.BorderBottomStyle,
          width: '100%',
          margin: '0 4px',
        }}
      />
    );
  }, [props.lineStyle]);

  return (
    <div className="line-style-item">
      {line}
      {!props.hideButton ? (
        <Button
          type={ButtonType.Text}
          icon={
            <Tooltip title={TOOLTIPS_MAP.SIDE_MENU.EDIT}>
              <Pencil />
            </Tooltip>
          }
          onClick={onEditClick}
        />
      ) : null}
    </div>
  );
};

export default LineStyleItem;
