import React, { FC, memo } from 'react';

interface IToolbarLeft {
  children?: JSX.Element;
}

const ToolbarLeft: FC<IToolbarLeft> = (props) => {
  return <div className="toolbar-left">{props.children}</div>;
};

export default memo(ToolbarLeft);
