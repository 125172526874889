import { COPIED_ANNOTATION_DB_NAME, FILE_DB_NAME } from '../shared/constants/application';

export const pdfDBConfig = {
  name: 'pdfStore',
  version: 5,
  objectStoresMeta: [
    {
      store: FILE_DB_NAME,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [{ name: 'filename', keypath: 'filename', options: { unique: false } }],
    },
    {
      store: COPIED_ANNOTATION_DB_NAME,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
    },
  ],
};
