import React, { FC, useMemo, memo } from 'react';
import { CircleFill } from 'react-bootstrap-icons';

import { ShapeType } from '../../../store/enums/shapeType';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';

import { ReactComponent as ShapeLine } from '../../../assets/ShapeLine.svg';
import { ReactComponent as ArrowLine } from '../../../assets/ArrowLine.svg';
import { ReactComponent as TwoArrowsLine } from '../../../assets/TwoArrowsLine.svg';
import { ReactComponent as Square } from '../../../assets/Square.svg';
import { ReactComponent as Circle } from '../../../assets/ShapeCircle.svg';
import { ReactComponent as Triangle } from '../../../assets/Triangle.svg';

interface IShapeIcon {
  type: ShapeType | null;
  className?: string;
  width?: number;
  style?: IShapeStyle;
  strokeColor?: string;
  fillColor?: string;
}

const ShapeIcon: FC<IShapeIcon> = (props) => {
  const strokeColor = useMemo(() => props.style?.lineColor || props.strokeColor, [props.style, props.strokeColor]);
  const fillColor = useMemo(() => props.style?.backgroundColor || props.fillColor, [props.style, props.fillColor]);

  switch (props.type) {
    case ShapeType.Line:
      return (
        <ShapeLine
          className={props.className || ''}
          style={
            {
              '--fill-color': 'none',
              '--stroke-color': strokeColor,
            } as React.CSSProperties
          }
        />
      );
    case ShapeType.ArrowLine:
      return (
        <ArrowLine
          className={props.className || ''}
          style={
            {
              '--fill-color': 'none',
              '--stroke-color': strokeColor,
            } as React.CSSProperties
          }
        />
      );
    case ShapeType.TwoArrowsLine:
      return (
        <TwoArrowsLine
          className={props.className || ''}
          style={
            {
              '--fill-color': 'null',
              '--stroke-color': strokeColor,
            } as React.CSSProperties
          }
        />
      );
    case ShapeType.Square:
      return (
        <Square
          className={props.className || ''}
          style={
            {
              '--fill-color': fillColor,
              '--stroke-color': strokeColor,
            } as React.CSSProperties
          }
        />
      );
    case ShapeType.Circle:
      return (
        <Circle
          className={props.className || ''}
          style={
            {
              '--fill-color': fillColor,
              '--stroke-color': strokeColor,
            } as React.CSSProperties
          }
        />
      );
    case ShapeType.Triangle:
      return (
        <Triangle
          className={props.className || ''}
          style={
            {
              '--fill-color': fillColor,
              '--stroke-color': strokeColor,
            } as React.CSSProperties
          }
        />
      );
    default:
      return <CircleFill className="annotation-bar__color-picker" size={24} color={strokeColor} />;
  }
};

export default memo(ShapeIcon);
