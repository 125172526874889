import { createReducer } from '@reduxjs/toolkit';

import { IGoogleUser } from '../interfaces/IGoogleUser';
import { setUser } from './actions';

const initialState: IGoogleUser = {
  isSigned: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(setUser, (state, action) => {
    return action.payload;
  });
});
