import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useClickAway, useCopyToClipboard, useMouseHovered, usePrevious } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { fabric } from 'fabric';
import _ from 'lodash';
import { TextItem } from 'react-pdf/dist/Page';
import { message } from 'antd';
import { v4 as uuid } from 'uuid';

import { getRotationAngle, getScale } from '../../../store/viewerSettings/selectors';
import { AnyObject, CustomAny } from '../../types/generics';
import {
  getActiveAction,
  getColor,
  getDefaultTitle,
  getDrawingStyle,
  getFont,
  getFontSize,
  getFontStyle,
  getImageBase64,
  getIsEditing,
  getLineStyle,
  getShapeStyle,
  getShapeType,
  getTypesToRemove,
} from '../../../store/annotateSettings/selectors';
import { AnnotateActionType } from '../../../store/enums/annotateActionType';
import {
  addColorByAction,
  addDrawingStyle,
  addShapeStyleByType,
  selectDrawingStyle,
  selectLineStyleByStyle,
  selectShapeStyleByType,
  setActiveAction,
  setColor,
  setColorByString,
  setFont,
  setFontSize,
  setFontStyle,
  setImageBase64,
  setIsEditing,
  setRemovingAnnotation,
} from '../../../store/annotateSettings/actions';
import { FabricCanvasService } from '../../../services/fabricCanvasService';
import {
  addAnnotation,
  initAnnotations,
  setActiveAnnotation,
  setCopiedAnnotation,
  updateAnnotation,
} from '../../../store/annotations/actions';
import {
  IAnnotationEntity,
  IAnnotationFreeTextEntity,
  IFreeTextPartStyle,
} from '../../../services/interfaces/IAnnotation';
import {
  getActiveAnnotation,
  getAnnotations,
  getAnnotationsList,
  getCopiedAnnotation,
} from '../../../store/annotations/selectors';
import {
  adjustQPointsWithViewport,
  adjustRectangleWithViewport,
  createAnnotation,
  editAnnotation,
  editShapeStyle,
  findAnnotationIndex,
  findAnnotationIndexByObject,
  getShapeTypeByAnnotation,
  removeAnnotationsByIds,
  rotateRectangle,
} from '../../../services/annotationService';
import { TBbox } from '../../types/bbox';
import { TQuadPoints } from '../../types/quadPoints';
import { rememberState } from '../../../store/historyStore/actions';
import { ActionType } from '../../../store/enums/actionType';
import { AnnotationType } from '../../../store/enums/annotationType';
import { IGlobalStyles } from '../../../services/interfaces/IFabric';
import { StyleProperty } from '../../../services/enums/styleProperty';
import { ObjectType } from '../../../services/enums/objectType';
import { IFontStyle } from '../../../store/interfaces/IFontStyle';
import { TextAnnotationName } from '../../components/annotationCard/enums/TextAnnotationName';
import { AnnotateStyle, TextStyle } from '../../../store/enums/annotateStyle';
import {
  setAnnotationPanelActiveTab,
  setAppLoading,
  setContextMenuActivePage,
  setFileStatus,
  toggleShowAnnotationsPanel,
} from '../../../store/viewerSettings/actions';
import { AnnotationPanelTab } from '../../../store/enums/annotationPanelTab';
import { ShapeType } from '../../../store/enums/shapeType';
import {
  ACTIONS_TO_PREVENT_TEXT_SELECTION,
  SHAPE_ANNOTATIONS,
  TEXT_MAP,
  TEXT_SELECT_ACTION_TYPES,
} from '../../constants/application';
import ShapeStyleEditor from '../../components/shapeStyleEditor/ShapeStyleEditor';
import { FileProgressStatus } from '../../../store/enums/fileProgressStatus';
import {
  DefaultShapeLineStyle,
  getContrastColor,
  pdfColorToRgb,
  stringToRGB,
  TShapeStyle,
} from '../../helpers/colorHelper';
import useScratch from '../../hooks/useScratch';
import {
  checkIfNodeIsInsideAnnotationCard,
  checkIfNodeIsInsideContextMenu,
  getReverseRotationAngle,
  isObjectLocked,
  moveAnnotationToPoint,
  rotatePointOnCanvas,
  rotatePoints,
  rotateViewport,
  swapWidthAndHeight,
} from '../../helpers/canvasHelper';
import CanvasContextMenuZone from '../../components/canvasContextMenuZone/CanvasContextMenuZone';
import ColorEditor from '../../components/colorEditor/ColorEditor';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';
import { createNewOutlineByTitleAndPage } from '../../helpers/outlineHelper';
import { addOutline, setSelectedOutline } from '../../../store/outlines/actions';
import { convertY } from '../../helpers/fabricCanvasHelper';

import './FabricCanvas.less';

const ACTIONS_IGNORING_DOUBLE_CLICK: string[] = [AnnotateActionType.Drawing, AnnotateActionType.Eraser];

interface IFreeTextCanvasProps {
  viewport: number[];
  textFields?: TextItem[];
  page: number;
  activePage: number;
  setActivePage(page: number): void;
  onCopyAnnotation(annotation: IAnnotationEntity): void;
}

const FabricCanvas: FC<IFreeTextCanvasProps> = (props) => {
  const dispatch = useDispatch();
  const { viewport = [0, 0, 0, 0], textFields = [] } = props;
  const scale: number = useSelector(getScale);
  const font: string = useSelector(getFont);
  const fontSize: number = useSelector(getFontSize);
  const color: AnyObject = useSelector(getColor);
  const lineStyle: AnyObject = useSelector(getLineStyle);
  const fontStyle: IFontStyle = useSelector(getFontStyle);
  const defaultTitle: string = useSelector(getDefaultTitle);
  const annotations: IAnnotationEntity[] = useSelector(getAnnotationsList);
  const prevAnnotations: IAnnotationEntity[] | undefined = usePrevious(annotations);
  const activeAction: AnnotateActionType | undefined = useSelector(getActiveAction);
  const prevActiveAction: AnnotateActionType | undefined = usePrevious(activeAction);
  const shapeType: ShapeType = useSelector(getShapeType);
  const shapeStyle = useSelector(getShapeStyle);
  const { activeAnnotation } = useSelector(getAnnotations);
  const activeAnnotationData = useSelector(getActiveAnnotation);
  const copiedAnnotation = useSelector(getCopiedAnnotation);
  const imageBase64: string | null = useSelector(getImageBase64);
  const drawingStyle: TShapeStyle = useSelector(getDrawingStyle);
  const typesToRemove: string[] = useSelector(getTypesToRemove);
  const rotationAngle: number = useSelector(getRotationAngle);
  const isEditing: boolean = useSelector(getIsEditing);
  const [activeQuadPoints, setActiveQuadPoints] = useState<TQuadPoints>([]);
  const [selectedText, setSelectedText] = useState<string>('');
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const wrapperRef = useRef(null);
  const pageWidth = useMemo(() => viewport[2] - viewport[0], [viewport]);
  const pageHeight = useMemo(() => viewport[3] - viewport[1], [viewport]);
  const [ref, scratchState] = useScratch({ ignoreRightClick: true, ignorePinchGesture: true });
  const selectedColor = useMemo(
    () => color[AnnotateActionType.FreeText].list[color[AnnotateActionType.FreeText].activeIndex],
    [color],
  );
  const selectedLineStyle = useMemo(
    () => lineStyle[AnnotateActionType.FreeText].list[lineStyle[AnnotateActionType.FreeText].activeIndex],
    [lineStyle],
  );
  const [localStyle, setLocalStyle] = useState<IGlobalStyles>({
    style: fontStyle,
    fontSize,
    font,
    color: selectedColor,
    lineStyle: selectedLineStyle,
  });
  const [shapeToEdit, setShapeToEdit] = useState<string | null>(null);
  const [annotationToEdit, setAnnotationToEdit] = useState<string | null>(null);
  const [isAnnotationClicked, setIsAnnotationClicked] = useState<boolean>(false);
  const [isTextSelectionClicked, setIsTextSelectionClicked] = useState<boolean>(false);
  const [drawStyleToAdd, setDrawStyleToAdd] = useState<IShapeStyle>(DefaultShapeLineStyle);
  const [prevTextStyle, setPrevTextStyle] = useState<IFreeTextPartStyle[] | undefined>();

  const { elX, elY } = useMouseHovered(canvasRef, { whenHovered: false });
  const [, copyToClipboard] = useCopyToClipboard();

  const annotateActionType = useMemo(() => {
    if (activeAnnotationData?.annotationType === AnnotationType.FreeText) {
      return AnnotateActionType.FreeText;
    } else if (activeAnnotationData?.annotationType === AnnotationType.TextMarkup) {
      return activeAnnotationData.textMarkupType;
    } else if (activeAnnotationData?.annotationType === AnnotationType.Ink) {
      return AnnotateActionType.Drawing;
    } else if (SHAPE_ANNOTATIONS.includes(activeAnnotationData?.annotationType)) {
      return AnnotateActionType.Shape;
    } else if (activeAnnotationData?.annotationType === AnnotationType.Text) {
      return AnnotateActionType.StickyNote;
    }
  }, [activeAnnotationData, activeAnnotation]);
  const activeQuadPointsLeftBottom = useMemo(() => {
    if (!activeQuadPoints) {
      return null;
    }

    let rotatedQuadPoints = adjustQPointsWithViewport(activeQuadPoints, viewport);
    rotatedQuadPoints = rotatePoints(rotationAngle, rotatedQuadPoints, viewport, false);
    rotatedQuadPoints = adjustQPointsWithViewport(rotatedQuadPoints, rotateViewport(rotationAngle, viewport), false);
    const minX = Math.min(...rotatedQuadPoints.filter((point, index) => !(index % 2)));
    const minY = Math.min(...rotatedQuadPoints.filter((point, index) => index % 2));
    return [minX * scale, convertY(minY, viewport, rotationAngle) * scale + 2];
  }, [activeQuadPoints, viewport, rotationAngle, scale]);
  const shapeAnnotationType = useMemo(() => {
    if (activeAnnotationData) return getShapeTypeByAnnotation(activeAnnotationData);
    return null;
  }, [activeAnnotationData]);
  const colorList = useMemo(() => {
    if (annotateActionType) {
      return color[annotateActionType].list;
    }
    return;
  }, [annotateActionType, color]);
  const shapesList = useMemo(() => {
    if (shapeAnnotationType) {
      return shapeStyle[shapeAnnotationType].list;
    }
  }, [shapeAnnotationType, shapeStyle]);

  const currentStyle = useMemo(() => {
    if (activeAnnotationData?.annotationType === AnnotationType.FreeText) {
      return selectedColor;
    } else if (
      activeAnnotationData?.annotationType === AnnotationType.TextMarkup ||
      activeAnnotationData?.annotationType === AnnotationType.Ink ||
      activeAnnotationData?.annotationType === AnnotationType.Text
    ) {
      const [r, g, b] = activeAnnotationData.colorRGB;
      return pdfColorToRgb(r, g, b, activeAnnotationData.opacity);
    }
    return undefined;
  }, [activeAnnotationData, selectedColor]);

  const onPaste = useCallback(
    (
      copiedAnnotation: IAnnotationEntity | undefined,
      { x: clickX, y: clickY }: { x: number; y: number },
      activePage: number,
    ) => {
      const [x, y] = rotatePointOnCanvas(
        getReverseRotationAngle(rotationAngle),
        [clickX / scale, clickY / scale],
        viewport,
        true,
      );
      if (copiedAnnotation) {
        let newAnnotation = createAnnotation({
          ...copiedAnnotation,
          id: uuid(),
          page: activePage - 1,
          title: defaultTitle,
          type: copiedAnnotation.annotationType,
          rectangle: copiedAnnotation.rectangle,
        });
        if (!newAnnotation) {
          return;
        }
        newAnnotation = moveAnnotationToPoint(newAnnotation, [x, y], viewport, rotationAngle);
        dispatch(addAnnotation(newAnnotation));
        setActiveQuadPoints([]);
        setSelectedText('');
        dispatch(rememberState([...annotations, newAnnotation]));
        fabricCanvasService?.clearSelectionGroup();
      }
    },
    [annotations, scale, rotationAngle, viewport],
  );

  const onCloseEditor = () => {
    setShapeToEdit(null);
    setAnnotationToEdit(null);
  };

  const handleShapeDoubleClick = (id: string) => {
    setShapeToEdit(id);
  };

  const handleAnnotationDoubleClick = (id: string) => {
    setAnnotationToEdit(id);
  };

  const getContrastedColor = useCallback((color) => {
    const [r, g, b] = stringToRGB(color);
    return getContrastColor(r, g, b);
  }, []);

  const onSave = useCallback(
    (annotation: IAnnotationEntity | null, id: string | undefined) => {
      if (!annotation || annotation.page !== props.page - 1) {
        return;
      }
      if (!_.isNull(activeAnnotation) && id) {
        const newAnnotationsList = [...annotations];
        newAnnotationsList[activeAnnotation] = {
          ...newAnnotationsList[activeAnnotation],
          ...annotation,
          modified: true,
        };
        dispatch(updateAnnotation({ annotation: newAnnotationsList[activeAnnotation], index: activeAnnotation }));
        dispatch(rememberState(newAnnotationsList));
        dispatch(setImageBase64(null));
        dispatch(setContextMenuActivePage({ triggerPage: props.page, value: -1 }));
      } else {
        const newAnnotation = createAnnotation({
          ...annotation,
          title: defaultTitle,
          type: annotation.annotationType,
        });
        if (!newAnnotation) {
          return;
        }
        dispatch(addAnnotation(newAnnotation));
        dispatch(rememberState([...annotations, newAnnotation]));
        dispatch(setImageBase64(null));
      }
    },
    [activeAnnotation, props.activePage, annotations, defaultTitle, viewport, props.page],
  );

  const handleTextBoxSelection = useCallback(
    (textbox: CustomAny) => {
      const index = findAnnotationIndexByObject(annotations, textbox);
      if (index !== -1 && !isObjectLocked(textbox)) {
        setIsAnnotationClicked(true);
        dispatch(setActiveAction(AnnotateActionType.FreeText));
        dispatch(setActiveAnnotation(index));
      }
    },
    [annotations],
  );

  const handleAnnotationSelection = useCallback(
    (object: fabric.Object) => {
      const index = findAnnotationIndexByObject(annotations, object);
      if (index !== -1 && !isObjectLocked(object)) {
        setIsAnnotationClicked(true);
        dispatch(setActiveAnnotation(index));
      }
    },
    [annotations],
  );

  const clearSelection = () => {
    dispatch(setActiveAnnotation(null));
    setIsAnnotationClicked(false);
    setIsTextSelectionClicked(false);
  };

  const handleTextSelection = ({ style, fontSize, font, color, lineStyle }: IGlobalStyles) => {
    setLocalStyle({ style, fontSize, font, color, lineStyle });
    dispatch(setFontSize(fontSize));
    dispatch(setFont(font));
    dispatch(setFontStyle(style));
    dispatch(setColorByString(color));
    dispatch(selectLineStyleByStyle(lineStyle));
  };

  const handleImageLoading = (loading: boolean, error?: boolean) => {
    if (loading) {
      dispatch(setAppLoading(true));
      dispatch(setFileStatus(FileProgressStatus.UPLOADING));
    } else {
      dispatch(setAppLoading(false));
      if (error) {
        message.error(TEXT_MAP.IMAGE.LOAD_ERROR);
        dispatch(setImageBase64(null));
      }
    }
  };

  const handleRemoveAnnotations = useCallback(
    (ids: string[]) => {
      const { newAnnotations, removed } = removeAnnotationsByIds(annotations, ids);
      if (removed === 0) {
        return;
      }
      dispatch(initAnnotations(newAnnotations));
      dispatch(rememberState(newAnnotations));
      fabricCanvasService?.discardSelection(['activeSelection'], false);
    },
    [annotations],
  );

  const removeAnnotation = useCallback(
    (id: string) => {
      dispatch(setActiveAnnotation(null));
      const newAnnotationList: IAnnotationEntity[] = [];
      const indexToRemove = annotations.findIndex((annotation) => annotation.id === id);
      annotations.forEach((annotation, index) => {
        if ((annotation.idToReply === id || index === indexToRemove) && annotation.actionType !== ActionType.Create) {
          const newAnnotation = { ...annotation, actionType: ActionType.Delete };
          newAnnotationList.push(newAnnotation);
        } else if (annotation.idToReply !== id && index !== indexToRemove) {
          newAnnotationList.push(annotation);
        }
      });
      dispatch(initAnnotations(newAnnotationList));
      dispatch(rememberState(newAnnotationList));
    },
    [annotations],
  );

  const [fabricCanvasService, setFabricCanvasService] = useState<FabricCanvasService | null>(null);

  useEffect(() => {
    if (activeAnnotationData?.annotationType === AnnotationType.Ink && activeAnnotationData?.width) {
      setDrawStyleToAdd({
        backgroundColor: null,
        opacity: activeAnnotationData.opacity,
        lineColor: currentStyle,
        lineWidth: activeAnnotationData.width,
      });
    }
  }, [activeAnnotationData, currentStyle]);

  useEffect(
    useCallback(() => {
      fabricCanvasService?.freeDrawingService.setTypesToRemove(typesToRemove);
    }, [typesToRemove, fabricCanvasService]),
    [typesToRemove],
  );

  useClickAway(wrapperRef, (e) => {
    const isMenuClicked = e.target && checkIfNodeIsInsideContextMenu(e.target as Node, props.page);
    const isAnnotationCardClick = e.target && checkIfNodeIsInsideAnnotationCard(e.target as Node);
    if (!isMenuClicked) {
      fabricCanvasService?.saveDrawings();
      setActiveQuadPoints([]);
      setSelectedText('');
      fabricCanvasService?.clearSelectionGroup();
    }

    // Fire discard active annotation event on click out of context menu and annotation card
    if (!isMenuClicked && !isAnnotationCardClick) {
      fabricCanvasService?.discardSelection(
        ['group', 'textbox', 'path', 'arrowLine', 'ellipse', 'polygon', 'rect', 'image'],
        true,
      );
    }
  });

  const isTextHovered = useMemo(() => {
    return (
      _.isNull(activeAnnotation) &&
      (!activeAction || !ACTIONS_TO_PREVENT_TEXT_SELECTION.includes(activeAction)) &&
      fabricCanvasService?.isTextHovered(elX, elY)
    );
  }, [elX, elY, fabricCanvasService, activeAction, activeAnnotation]);

  useEffect(() => {
    if (imageBase64) {
      fabricCanvasService?.setCanvasSelectable(false);
    } else {
      fabricCanvasService?.setCanvasSelectable(true);
    }
  }, [imageBase64, fabricCanvasService]);

  useEffect(() => {
    let cursor = 'default';
    if (imageBase64) {
      cursor = 'crosshair';
    } else if (isTextHovered) {
      if (swapWidthAndHeight(rotationAngle)) {
        cursor = 'vertical-text';
      } else {
        cursor = 'text';
      }
    }
    fabricCanvasService?.setCursor(cursor);
  }, [isTextHovered, imageBase64, fabricCanvasService, rotationAngle]);

  useEffect(
    useCallback(() => {
      fabricCanvasService && (fabricCanvasService.textFields = textFields);
    }, [textFields, fabricCanvasService]),
    [textFields],
  );

  useEffect(
    useCallback(() => {
      fabricCanvasService && (fabricCanvasService.removeAnnotation = removeAnnotation);
    }, [removeAnnotation, fabricCanvasService]),
    [removeAnnotation],
  );

  useEffect(
    useCallback(() => {
      fabricCanvasService && (fabricCanvasService.freeDrawingService.handleRemoveAnnotations = handleRemoveAnnotations);
    }, [handleRemoveAnnotations, fabricCanvasService]),
    [handleRemoveAnnotations],
  );

  useEffect(
    useCallback(() => {
      fabricCanvasService && (fabricCanvasService.onSave = onSave);
    }, [onSave, fabricCanvasService]),
    [onSave],
  );

  useEffect(
    useCallback(() => {
      fabricCanvasService && (fabricCanvasService.handleTextBoxSelection = handleTextBoxSelection);
    }, [handleTextBoxSelection, fabricCanvasService]),
    [handleTextBoxSelection],
  );

  useEffect(
    useCallback(() => {
      fabricCanvasService && (fabricCanvasService.handleAnnotationSelection = handleAnnotationSelection);
    }, [handleAnnotationSelection, fabricCanvasService]),
    [handleAnnotationSelection],
  );

  useEffect(
    useCallback(() => {
      fabricCanvasService?.updateDrawingStyle(drawingStyle.list[drawingStyle.activeIndex]);
    }, [drawingStyle, fabricCanvasService]),
    [drawingStyle],
  );

  useEffect(
    useCallback(() => {
      const annotation = annotations[activeAnnotation];
      if (annotation) {
        if (annotation.page === props.page - 1) {
          fabricCanvasService?.selectObject(annotation);
        } else {
          fabricCanvasService?.discardSelection([
            'group',
            'textbox',
            'path',
            'arrowLine',
            'ellipse',
            'polygon',
            'rect',
            'image',
          ]);
        }
      } else {
        fabricCanvasService?.discardSelection(['group', 'path', 'arrowLine', 'ellipse', 'polygon', 'rect', 'image']);
      }
      fabricCanvasService?.setAllowTouchScrolling(_.isNull(activeAnnotation));
    }, [activeAnnotation, annotations, fabricCanvasService, props.page]),
    [activeAnnotation],
  );

  const updateObjects = useCallback(async () => {
    const annotationsForPage = annotations.filter(
      (annotation) =>
        annotation.page === props.page - 1 && annotation.actionType !== ActionType.Delete && !annotation.idToReply,
    );
    if (!_.isUndefined(prevAnnotations) && !_.isEqual(prevAnnotations, annotations)) {
      await fabricCanvasService?.updateObjects(annotationsForPage, activeAnnotationData);
    }
  }, [annotations, prevAnnotations, fabricCanvasService, viewport, activeAnnotationData, props.page]);

  useEffect(() => {
    updateObjects();
  }, [annotations]);

  useEffect(
    useCallback(() => {
      const annotation =
        localStyle.color !== selectedColor
          ? fabricCanvasService?.updateStyleForSelection(StyleProperty.Fill, selectedColor)
          : null;
      if (annotation) {
        onSave(annotation as IAnnotationFreeTextEntity, annotation.id);
        setLocalStyle({ ...localStyle, color: selectedColor });
      }
    }, [selectedColor, localStyle, fabricCanvasService]),
    [selectedColor],
  );

  useEffect(
    useCallback(() => {
      let annotation = fabricCanvasService?.setStyleForBorder(StyleProperty.BorderStyle, selectedLineStyle.borderStyle);
      annotation =
        fabricCanvasService?.setStyleForBorder(StyleProperty.BorderColor, selectedLineStyle.borderColor) || annotation;
      annotation =
        fabricCanvasService?.setStyleForBorder(StyleProperty.StrokeWidth, selectedLineStyle.borderWidth) || annotation;
      if (annotation) {
        onSave(annotation as IAnnotationFreeTextEntity, annotation.id);
        setLocalStyle({ ...localStyle, lineStyle: selectedLineStyle });
      }
    }, [selectedLineStyle, localStyle, fabricCanvasService]),
    [selectedLineStyle],
  );

  useEffect(
    useCallback(() => {
      const annotation =
        localStyle.fontSize !== fontSize
          ? fabricCanvasService?.updateStyleForSelection(StyleProperty.FontSize, fontSize)
          : null;
      if (annotation) {
        onSave(annotation as IAnnotationFreeTextEntity, annotation.id);
        setLocalStyle({ ...localStyle, fontSize });
      }
    }, [fontSize, localStyle, fabricCanvasService]),
    [fontSize],
  );

  useEffect(
    useCallback(() => {
      const annotation =
        localStyle.font !== font ? fabricCanvasService?.updateStyleForSelection(StyleProperty.FontFamily, font) : null;
      if (annotation) {
        onSave(annotation as IAnnotationFreeTextEntity, annotation.id);
        setLocalStyle({ ...localStyle, font });
      }
    }, [font, localStyle, fabricCanvasService]),
    [font],
  );

  useEffect(
    useCallback(() => {
      let annotation =
        localStyle.style.bold !== fontStyle.bold
          ? fabricCanvasService?.updateStyleForSelection(StyleProperty.FontWeight, fontStyle.bold)
          : null;
      annotation =
        (localStyle.style.italic !== fontStyle.italic
          ? fabricCanvasService?.updateStyleForSelection(StyleProperty.FontStyle, fontStyle.italic)
          : null) || annotation;
      annotation =
        (localStyle.style.underline !== fontStyle.underline
          ? fabricCanvasService?.updateStyleForSelection(StyleProperty.Underline, fontStyle.underline)
          : null) || annotation;
      if (annotation) {
        onSave(annotation as IAnnotationFreeTextEntity, annotation.id);
        setLocalStyle({ ...localStyle, style: fontStyle });
      }
    }, [fontStyle, localStyle, fabricCanvasService]),
    [fontStyle],
  );

  useEffect(
    useCallback(() => {
      fabricCanvasService?.updateScale(scale);
    }, [scale, fabricCanvasService]),
    [scale],
  );

  useEffect(
    useCallback(() => {
      fabricCanvasService?.rotateCanvas(rotationAngle);
      fabricCanvasService?.updateObjects(
        annotations.filter(
          (annotation) =>
            annotation.page === props.page - 1 && annotation.actionType !== ActionType.Delete && !annotation.idToReply,
        ),
        activeAnnotationData,
      );
    }, [rotationAngle, fabricCanvasService, annotations, activeAnnotationData, props.page]),
    [rotationAngle],
  );

  useEffect(
    useCallback(() => {
      if (!fabricCanvasService?.isInitialized() && canvasRef.current) {
        const data = {
          viewport,
          isEraser: activeAction === AnnotateActionType.Eraser,
          isDrawing: activeAction === AnnotateActionType.Drawing,
          drawingStyle: drawingStyle.list[drawingStyle.activeIndex],
        };
        fabricCanvasService?.init(`free-text-canvas-${props.page}`, data);
      } else {
        fabricCanvasService?.updateViewport(viewport);
      }
      fabricCanvasService?.updateObjects(
        annotations.filter(
          (annotation) =>
            annotation.page === props.page - 1 && annotation.actionType !== ActionType.Delete && !annotation.idToReply,
        ),
        activeAnnotationData,
      );
    }, [
      fabricCanvasService,
      viewport,
      annotations,
      activeAnnotationData,
      props.page,
      canvasRef,
      activeAction,
      drawingStyle,
    ]),
    [viewport, canvasRef],
  );

  useEffect(() => {
    setFabricCanvasService(
      new FabricCanvasService({
        scale,
        page: props.page,
        onSave,
        viewport,
        textFields,
        handleTextBoxSelection,
        handleAnnotationSelection,
        clearSelection,
        toggleEditing: (isEditing: boolean) => dispatch(setIsEditing(isEditing)),
        handleTextSelection,
        handleShapeDoubleClick,
        handleAnnotationDoubleClick,
        handleImageLoading,
        handleRemoveAnnotations,
        rotationAngle,
        removeAnnotation,
        typesToRemove,
      }),
    );
    return () => {
      fabricCanvasService?.destroy();
    };
  }, []);

  useEffect(
    useCallback(() => {
      if (props.activePage !== props.page) {
        fabricCanvasService?.discardSelection(['textbox', 'group', 'path']);
      }
    }, [fabricCanvasService, props.activePage, activeAnnotation, props.page]),
    [props.activePage],
  );

  useEffect(
    useCallback(() => {
      fabricCanvasService && (fabricCanvasService.page = props.page);
    }, [props.page, fabricCanvasService]),
    [props.page],
  );

  useEffect(() => {
    if (!scratchState.isScratching && activeAction && activeQuadPoints?.length) {
      const annotation = createAnnotation({
        quadPoints: adjustQPointsWithViewport(activeQuadPoints, viewport),
        page: props.page - 1,
        textMarkupType: activeAction,
        color: color[activeAction].list[color[activeAction].activeIndex],
        title: defaultTitle,
        type: AnnotationType.TextMarkup,
      });
      if (!annotation) {
        return;
      }
      dispatch(addAnnotation(annotation));
      setActiveQuadPoints([]);
      setSelectedText('');
      dispatch(rememberState([...annotations, annotation]));
      fabricCanvasService?.clearSelectionGroup();
    }
  }, [
    scratchState.isScratching,
    activeQuadPoints,
    activeAction,
    props.page,
    annotations,
    color,
    viewport,
    defaultTitle,
    fabricCanvasService,
  ]);

  useEffect(
    useCallback(() => {
      if (!activeAction && activeQuadPoints.length) {
        setIsTextSelectionClicked(true);
      }
    }, [activeQuadPoints, activeAction]),
    [activeQuadPoints],
  );

  const createTextMarkupAnnotation = useCallback(
    (type: AnnotateActionType, color: string) => {
      if (activeQuadPoints?.length) {
        const annotation = createAnnotation({
          quadPoints: adjustQPointsWithViewport(activeQuadPoints, viewport),
          page: props.page - 1,
          textMarkupType: type,
          color: color,
          title: defaultTitle,
          type: AnnotationType.TextMarkup,
        });
        if (!annotation) {
          return;
        }
        dispatch(addAnnotation(annotation));
        setActiveQuadPoints([]);
        setSelectedText('');
        dispatch(rememberState([...annotations, annotation]));
        fabricCanvasService?.clearSelectionGroup();
      }
    },
    [activeQuadPoints, props.page, annotations, viewport, defaultTitle, fabricCanvasService],
  );

  useEffect(
    useCallback(() => {
      if (!activeAction) {
        fabricCanvasService?.discardSelection(['textbox'], true);
      }
      // stop actions if necessary
      if (activeAction !== AnnotateActionType.Drawing && prevActiveAction === AnnotateActionType.Drawing) {
        fabricCanvasService?.stopDrawing();
      }
      if (activeAction !== AnnotateActionType.Eraser && prevActiveAction === AnnotateActionType.Eraser) {
        fabricCanvasService?.stopEraser();
      }

      // start actions if necessary
      if (activeAction === AnnotateActionType.Drawing && prevActiveAction !== AnnotateActionType.Drawing) {
        fabricCanvasService?.startDrawing(drawingStyle.list[drawingStyle.activeIndex]);
      }
      if (activeAction === AnnotateActionType.Eraser && prevActiveAction !== AnnotateActionType.Eraser) {
        fabricCanvasService?.startEraser();
      }

      fabricCanvasService?.setAllowTouchScrolling(!activeAction);
    }, [activeAction, prevActiveAction, fabricCanvasService, drawingStyle]),
    [activeAction],
  );

  const discardActiveAnnotation = useCallback(() => {
    if (
      !_.isNull(activeAnnotation) &&
      annotations[activeAnnotation] &&
      annotations[activeAnnotation].page !== props.page - 1
    ) {
      dispatch(setActiveAnnotation(null));
    }
  }, [annotations, activeAnnotation]);

  const onChangeColor = useCallback(
    (color: string) => {
      const annIndex = findAnnotationIndex(annotations, activeAnnotationData);
      let annotation: CustomAny;
      if (activeAnnotationData?.annotationType === AnnotationType.FreeText) {
        annotation = fabricCanvasService?.updateStyleForSelection(StyleProperty.Fill, color);
      } else {
        annotation = editAnnotation(activeAnnotationData, {
          color,
          flatten: !!activeAnnotationData?.flatten,
          content: activeAnnotationData?.content,
          title: activeAnnotationData?.title,
        });
      }
      if (!annotation) {
        return;
      }
      let newAnnotationList = [...annotations];
      newAnnotationList = newAnnotationList.map((reply) => {
        if (reply.idToReply === annotation.id) {
          return { ...reply };
        }
        return reply;
      });
      newAnnotationList[annIndex] = {
        ...newAnnotationList[annIndex],
        ...annotation,
        modified: true,
      };
      dispatch(initAnnotations(newAnnotationList));
      return newAnnotationList;
    },
    [annotations, activeAnnotationData, annotateActionType, activeAnnotation],
  );

  const onSelectColor = useCallback(
    (color: string, index?: number) => {
      const annotations = onChangeColor(color);
      if (!annotations) {
        return;
      }
      if (activeAnnotationData?.annotationType === AnnotationType.FreeText && !_.isUndefined(index)) {
        dispatch(setColor(index));
        setPrevTextStyle(activeAnnotationData?.textStyles);
      }
      dispatch(rememberState(annotations));
    },
    [annotateActionType, onChangeColor, annotations],
  );

  const onCancelFreeTextChanges = useCallback(() => {
    const annIndex = findAnnotationIndex(annotations, activeAnnotationData);
    const annotation = {
      ...activeAnnotationData,
      textStyles: prevTextStyle,
    };
    if (annIndex === -1) {
      return;
    }
    const newAnnotationList = [...annotations];
    newAnnotationList[annIndex] = annotation;
    dispatch(initAnnotations(newAnnotationList));
    dispatch(rememberState(newAnnotationList));
  }, [annotations, activeAnnotationData, prevTextStyle]);

  const onSelectShapeStyle = useCallback(
    (shapeStyle) => {
      if (activeAnnotationData) {
        const annIndex = findAnnotationIndex(annotations, activeAnnotationData);
        const annotation = editShapeStyle(activeAnnotationData, shapeStyle);
        if (!annotation) {
          return;
        }
        let newAnnotationList = [...annotations];
        newAnnotationList = newAnnotationList.map((reply) => {
          if (reply.idToReply === annotation.id) {
            return { ...reply };
          }
          return reply;
        });
        newAnnotationList[annIndex] = annotation;
        dispatch(initAnnotations(newAnnotationList));
        dispatch(rememberState(newAnnotationList));
        if (shapeAnnotationType) {
          dispatch(selectShapeStyleByType({ shapeType: shapeAnnotationType, style: shapeStyle }));
        }
      }
    },
    [annotations, activeAnnotationData, annotateActionType, activeAnnotation, shapeAnnotationType],
  );

  const onChangeDrawStyle = useCallback(
    (shapeStyle) => {
      if (activeAnnotationData) {
        setDrawStyleToAdd(shapeStyle);
        const annIndex = findAnnotationIndex(annotations, activeAnnotationData);
        const annotation = editShapeStyle(activeAnnotationData, shapeStyle);
        if (!annotation) {
          return;
        }
        let newAnnotationList = [...annotations];
        newAnnotationList = newAnnotationList.map((reply) => {
          if (reply.idToReply === annotation.id) {
            return { ...reply };
          }
          return reply;
        });
        newAnnotationList[annIndex] = annotation;
        dispatch(initAnnotations(newAnnotationList));
        return newAnnotationList;
      }
    },
    [annotations, activeAnnotationData],
  );

  const onDrawStyleSelect = useCallback(
    (shapeStyle, index?) => {
      const annotations = onChangeDrawStyle(shapeStyle);
      if (!annotations) {
        return;
      }
      dispatch(rememberState(annotations));
      if (index !== null && index !== undefined) {
        dispatch(selectDrawingStyle(index));
      }
    },
    [onChangeDrawStyle],
  );

  const onAddColor = useCallback(
    (color: string) => {
      if (annotateActionType) {
        dispatch(addColorByAction({ actionType: annotateActionType, color }));
      }
      onSelectColor(color);
    },
    [annotateActionType, onSelectColor],
  );

  const onAddShapeStyle = useCallback(
    (shapeStyle) => {
      if (shapeAnnotationType) {
        dispatch(addShapeStyleByType({ shapeType: shapeAnnotationType, style: shapeStyle }));
      }
      onSelectShapeStyle(shapeStyle);
    },
    [shapeAnnotationType, onSelectShapeStyle],
  );

  const onAddDrawStyle = useCallback(
    (shapeStyle) => {
      dispatch(addDrawingStyle(shapeStyle));
      onDrawStyleSelect(shapeStyle);
    },
    [onDrawStyleSelect],
  );

  const onCopy = useCallback(
    (annotationData) => {
      if (!annotationData) {
        if (activeQuadPoints) {
          if (selectedText?.trim()) {
            copyToClipboard(selectedText);
          }
          setActiveQuadPoints([]);
          setSelectedText('');
          fabricCanvasService?.clearSelectionGroup();
        }
      } else if (annotationData?.annotationType === AnnotationType.TextMarkup) {
        const selectedText = fabricCanvasService?.getSelectedText(annotationData.quadPoints);
        if (selectedText?.trim()) {
          copyToClipboard(selectedText);
        }
      } else if (annotationData?.annotationType === AnnotationType.FreeText && isEditing) {
        const selectedText = fabricCanvasService?.getTextboxSelectedText();
        if (selectedText?.trim()) {
          copyToClipboard(selectedText);
        }
      } else {
        dispatch(setCopiedAnnotation(annotationData));
        props.onCopyAnnotation(annotationData);
      }
    },
    [fabricCanvasService, activeQuadPoints, viewport, isEditing, selectedText],
  );

  const onDelete = useCallback(() => {
    if (activeAnnotationData?.annotationType === AnnotationType.FreeText && isEditing) {
      fabricCanvasService?.removeSelectedTextFromActiveTextbox();
    } else if (activeAnnotationData) {
      const index = findAnnotationIndex(annotations, activeAnnotationData);
      fabricCanvasService?.exitEditing();
      dispatch(setActiveAnnotation(index));
      dispatch(setRemovingAnnotation(true));
    }
  }, [activeAnnotationData, annotations, fabricCanvasService, isEditing]);

  useEffect(
    useCallback(() => {
      const { x = 0, y = 0, dx = 0, dy = 0, isScratching, isTouchEvent } = scratchState;
      if (isScratching) {
        if (x && y && !dx && !dy) {
          if (activeAction === AnnotateActionType.FreeText) {
            addTextBox({ x, y }, true);
          } else if (
            activeAction === AnnotateActionType.StickyNote &&
            !fabricCanvasService?.isObjectHovered(x / scale, y / scale)
          ) {
            addStickyNote({ x, y }, color[activeAction].list[color[activeAction].activeIndex], true);
          } else if (
            activeAction === AnnotateActionType.Shape &&
            !fabricCanvasService?.isObjectHovered(x / scale, y / scale) &&
            !fabricCanvasService?.isObjectLostSelection()
          ) {
            dispatch(setActiveAnnotation(null));
            fabricCanvasService?.startDrawingShape(
              shapeStyle[shapeType].list[shapeStyle[shapeType].activeIndex],
              shapeType,
              x,
              y,
            );
          } else if (activeAction === AnnotateActionType.Image && imageBase64) {
            handleImageLoading(true);
            setTimeout(() => fabricCanvasService?.addImageObject(imageBase64, x / scale, y / scale), 0);
            dispatch(setImageBase64(null));
          } else if (activeQuadPoints?.length && dx == 0 && dy == 0) {
            setActiveQuadPoints([]);
            setSelectedText('');
            setIsTextSelectionClicked(false);
            fabricCanvasService?.clearSelectionGroup();
          }
          props.setActivePage(props.page);
        } else if ((dx !== 0 || dy !== 0) && activeAction === AnnotateActionType.Shape && _.isNull(activeAnnotation)) {
          fabricCanvasService?.updateDrawingShape(x / scale, y / scale, dx / scale, dy / scale);
        } else if (
          _.isNull(activeAnnotation) &&
          ((!activeAction && !isTouchEvent) || TEXT_SELECT_ACTION_TYPES.includes(activeAction as AnnotateActionType))
        ) {
          const highlightBbox: TBbox = [
            dx > 0 ? x : x + dx,
            dy > 0 ? y : y + dy,
            dx > 0 ? x + dx : x,
            dy > 0 ? y + dy : y,
          ];
          const { quadPoints = [], selectedText = '' } =
            fabricCanvasService?.highlightText(highlightBbox, activeAction) || {};
          setActiveQuadPoints(quadPoints);
          setSelectedText(selectedText);
        }
        if (fabricCanvasService?.isObjectLostSelection()) {
          fabricCanvasService?.setObjectLostSelection(false);
        }
      } else {
        discardActiveAnnotation();
        if (activeAction === AnnotateActionType.Shape) {
          fabricCanvasService?.finishDrawingShape();
        }
      }
    }, [
      scratchState,
      fabricCanvasService,
      props.page,
      annotations,
      activeAnnotation,
      scale,
      font,
      fontSize,
      fontStyle,
      shapeType,
      shapeStyle,
      viewport,
      discardActiveAnnotation,
      imageBase64,
      rotationAngle,
    ]),
    [scratchState],
  );

  const onContextMenuOpen = useCallback(
    ({ x, y }: { x: number; y: number }) => {
      if (
        !_.isNull(activeAnnotation) &&
        fabricCanvasService?.isObjectHovered(x / scale, y / scale, activeAnnotationData?.id) &&
        !activeAnnotationData?.readOnly &&
        !activeAnnotationData?.locked &&
        !activeAnnotationData?.lockedContents
      ) {
        setIsAnnotationClicked(true);
        setIsTextSelectionClicked(false);
        if (activeAnnotationData?.annotationType === AnnotationType.FreeText) {
          setPrevTextStyle(activeAnnotationData.textStyles);
        }
      } else if (_.isNull(activeAnnotation) && fabricCanvasService?.isTextSelectionHovered(x / scale, y / scale)) {
        setIsAnnotationClicked(false);
        setIsTextSelectionClicked(true);
      } else {
        dispatch(setActiveAnnotation(null));
        setIsAnnotationClicked(false);
        setIsTextSelectionClicked(false);
        fabricCanvasService?.discardSelection([
          'group',
          'textbox',
          'path',
          'arrowLine',
          'ellipse',
          'polygon',
          'rect',
          'image',
        ]);
      }
    },
    [fabricCanvasService, activeAnnotation, scale, activeAnnotationData],
  );

  const clearClickedObjectState = () => {
    setIsAnnotationClicked(false);
    setIsTextSelectionClicked(false);
  };

  const addTextBox = useCallback(
    ({ x, y }: { x: number; y: number }, ignoreActiveAction?: boolean) => {
      if (!ignoreActiveAction) {
        dispatch(setActiveAction(AnnotateActionType.FreeText));
        fabricCanvasService?.setObjectLostSelection(false);
      }
      const objectProps = {
        x: x / scale,
        y: y / scale,
        font,
        fontSize,
        fontWeight: fontStyle[AnnotateStyle.Bold] ? TextStyle.Bold : TextStyle.Normal,
        fontStyle: fontStyle[AnnotateStyle.Italic] ? TextStyle.Italic : TextStyle.Normal,
        underline: fontStyle[AnnotateStyle.Underline],
        fill: color[AnnotateActionType.FreeText].list[color[AnnotateActionType.FreeText].activeIndex],
        lineStyle: lineStyle[AnnotateActionType.FreeText].list[lineStyle[AnnotateActionType.FreeText].activeIndex],
        selectionCallback: handleTextSelection,
        toggleEditing: (isEditing: boolean) => dispatch(setIsEditing(isEditing)),
      };
      fabricCanvasService?.addObject(ObjectType.Textbox, objectProps);
    },
    [fabricCanvasService, scale, font, fontSize, fontStyle, color, lineStyle],
  );

  const addStickyNote = useCallback(
    ({ x, y }: { x: number; y: number }, color: string, ignoreActiveAction?: boolean) => {
      if (!ignoreActiveAction) {
        dispatch(setActiveAction(AnnotateActionType.StickyNote));
      }
      const height = swapWidthAndHeight(rotationAngle) ? viewport[2] - viewport[0] : viewport[3] - viewport[1];
      let rectangle = {
        upperRightX: x / scale + 30,
        lowerLeftX: x / scale,
        upperRightY: height - y / scale,
        lowerLeftY: height - y / scale - 30,
      };
      rectangle = adjustRectangleWithViewport(rectangle, viewport, true, rotationAngle);
      rectangle = rotateRectangle(rectangle, viewport, getReverseRotationAngle(rotationAngle), true);
      const annotation = createAnnotation({
        rectangle: rectangle,
        page: props.page - 1,
        color: color,
        title: defaultTitle,
        type: AnnotationType.Text,
        textAnnotationName: TextAnnotationName.Comment,
        enterEditing: true,
      });
      if (!annotation) {
        return;
      }
      dispatch(addAnnotation(annotation));
      setActiveQuadPoints([]);
      setSelectedText('');
      dispatch(setActiveAnnotation(annotations.length));
      dispatch(rememberState([...annotations, annotation]));
      dispatch(toggleShowAnnotationsPanel(true));
      dispatch(setAnnotationPanelActiveTab(AnnotationPanelTab.Annotations));
    },
    [fabricCanvasService, rotationAngle, viewport, scale, annotations],
  );

  const onCreateOutline = useCallback(() => {
    if (activeQuadPoints) {
      if (selectedText?.trim()) {
        const newOutline = createNewOutlineByTitleAndPage(selectedText, props.page - 1);
        dispatch(addOutline(newOutline));
        dispatch(toggleShowAnnotationsPanel(true));
        dispatch(setAnnotationPanelActiveTab(AnnotationPanelTab.Outlines));
        dispatch(setSelectedOutline({ key: newOutline.key, pos: null }));
        setActiveQuadPoints([]);
        setSelectedText('');
        fabricCanvasService?.clearSelectionGroup();
      } else {
        message.error(TEXT_MAP.OUTLINES.EMPTY_OUTLINE_ERROR);
      }
    }
  }, [fabricCanvasService, activeQuadPoints, props.page, selectedText]);

  const height = useMemo(() => {
    return (swapWidthAndHeight(rotationAngle) ? pageWidth : pageHeight) * scale;
  }, [rotationAngle, pageWidth, pageHeight, scale]);
  const width = useMemo(() => {
    return (swapWidthAndHeight(rotationAngle) ? pageHeight : pageWidth) * scale;
  }, [rotationAngle, pageWidth, pageHeight, scale]);

  return (
    <>
      <CanvasContextMenuZone
        page={props.page}
        copiedAnnotation={copiedAnnotation}
        colorList={colorList}
        currentStyle={currentStyle}
        shapesList={shapesList}
        drawingList={drawingStyle?.list}
        drawStyleToAdd={drawStyleToAdd}
        getContrastedColor={getContrastedColor}
        onChangeDrawStyle={onChangeDrawStyle}
        addFreeTextAnnotation={addTextBox}
        addStickyNoteAnnotation={addStickyNote}
        isAnnotationClicked={isAnnotationClicked}
        isSelectedTextClicked={isTextSelectionClicked}
        annotationData={activeAnnotationData}
        shapeType={shapeAnnotationType}
        annotateActionType={annotateActionType}
        activeQuadPoints={activeQuadPointsLeftBottom}
        onOpen={onContextMenuOpen}
        onSelectColor={onSelectColor}
        onChangeColor={onChangeColor}
        onSelectShapeStyle={onSelectShapeStyle}
        onDrawStyleSelect={onDrawStyleSelect}
        onAddDrawStyle={onAddDrawStyle}
        onAddShapeStyle={onAddShapeStyle}
        onAddColor={onAddColor}
        onCopy={onCopy}
        onPaste={onPaste}
        onDelete={onDelete}
        onCancelFreeTextChanges={onCancelFreeTextChanges}
        onCreateOutline={onCreateOutline}
        createTextMarkupAnnotation={createTextMarkupAnnotation}
        clearClickedObjectState={clearClickedObjectState}
      >
        <div id={'free-text-canvas-container-' + props.page} className="free-text-canvas-container" ref={ref}>
          <div ref={wrapperRef}>
            <canvas
              id={'free-text-canvas-' + props.page}
              className="free-text-canvas"
              height={height}
              width={width}
              ref={canvasRef}
            />
          </div>
        </div>
      </CanvasContextMenuZone>
      <ShapeStyleEditor
        id={ACTIONS_IGNORING_DOUBLE_CLICK.includes(activeAction || '') ? null : shapeToEdit}
        page={props.page}
        onClose={onCloseEditor}
      />
      <ColorEditor
        id={ACTIONS_IGNORING_DOUBLE_CLICK.includes(activeAction || '') ? null : annotationToEdit}
        page={props.page}
        onClose={onCloseEditor}
      />
    </>
  );
};

export default memo(FabricCanvas);
