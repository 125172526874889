import React from 'react';
import { Avatar, Menu } from 'antd';
import classNames from 'classnames';

import { IDropdownItem, IStringDropdownItem } from './interfaces/IDropdownItem';
import { DropdownItemType } from './enum/DropdownItemType';
import { AnnotationCardFormLabels } from '../annotationCard/annotationCardForm/enums/AnnotationCardFormFields';
import Input from '../input/Input';
import { InputSize } from '../input/enums/InputSize';
import Button from '../button/Button';
import { ButtonType } from '../button/enums/buttonType';
import { signIn, signOut } from '../../../services/gDriveService';
import { Download, Folder2Open, Google } from 'react-bootstrap-icons';
import { TOOLTIPS_MAP } from '../../constants/application';
import { IDefaultAppMenu } from './interfaces/IDefaultAppMenu';

import { ReactComponent as Info } from '../../../assets/information.svg';
import { ReactComponent as DeleteAll } from '../../../assets/delete.svg';
import { ReactComponent as FullScreen } from '../../../assets/fullScreen.svg';
import { ReactComponent as Print } from '../../../assets/Print.svg';
import { ReactComponent as Restore } from '../../../assets/RestoreAnnotations.svg';
import { ReactComponent as RotateClockwise } from '../../../assets/Rotate_clockwise.svg';
import { ReactComponent as RotateCounterclockwise } from '../../../assets/Rotate_counterclockwise.svg';

type TItem = IDropdownItem | IStringDropdownItem;

export const buildMenuItems = (items: TItem[]): JSX.Element[] => {
  return items.map((item, index) => {
    switch (item.type) {
      case DropdownItemType.Group:
        return (
          <Menu.ItemGroup className={classNames('drop-menu__group')} key={`g${index}`} title={item.title}>
            {buildMenuItems((item as IDropdownItem).children || [])}
          </Menu.ItemGroup>
        );
      case DropdownItemType.String:
        return (
          <Menu.Item className={classNames('drop-menu__item')} key={`s${index}`} disabled={item.disabled}>
            <Input
              placeholder={AnnotationCardFormLabels.Title}
              size={InputSize.Small}
              value={(item as IStringDropdownItem).value}
              onChange={(e) => (item as IStringDropdownItem).onChange((e.target as HTMLInputElement).value)}
            />
          </Menu.Item>
        );
      case DropdownItemType.Button:
        return (
          <Menu.Item
            className={classNames('drop-menu__item drop-menu__item_button')}
            key={`b${index}`}
            disabled={item.disabled}
          >
            {(item as IDropdownItem).button}
          </Menu.Item>
        );
      default:
        return (
          <Menu.Item
            className={classNames('drop-menu__item', { 'drop-menu__item_active': (item as IDropdownItem).active })}
            icon={(item as IDropdownItem).icon}
            key={`index${item.title}`}
            disabled={item.disabled}
            onClick={(item as IDropdownItem).onClick}
          >
            {item.title}
          </Menu.Item>
        );
    }
  });
};

export const buildMainAppMenuItems = ({
  isGoogleSigned,
  isSignDisabled,
  disabled,
  removeAllDisabled,
  userImage,
  onUploadClick,
  onSave,
  onShowProperties,
  onDeleteAll,
  onFullScreen,
  onPrint,
  onRestoreAnnotations,
  isRestoreDisabled,
  onRotate,
}: IDefaultAppMenu): TItem[] => [
  {
    type: DropdownItemType.Button,
    button: (
      <>
        {isGoogleSigned ? (
          <Button
            type={ButtonType.Default}
            disabled={isSignDisabled}
            onClick={signOut}
            content={
              <div className="app-button__content-wrapper">
                <Avatar size={16} src={userImage} />
                <span>Sign out</span>
              </div>
            }
          />
        ) : (
          <Button
            type={ButtonType.Default}
            disabled={isSignDisabled}
            onClick={signIn}
            content={
              <div className="app-button__content-wrapper">
                <Google />
                <span>Sign in</span>
              </div>
            }
          />
        )}
      </>
    ),
  },
  {
    type: DropdownItemType.Default,
    title: TOOLTIPS_MAP.TOOLBAR.OPEN,
    icon: <Folder2Open />,
    onClick: onUploadClick,
  },
  {
    type: DropdownItemType.Default,
    title: TOOLTIPS_MAP.TOOLBAR.SAVE,
    icon: <Download />,
    onClick: onSave,
    disabled,
  },
  {
    type: DropdownItemType.Default,
    title: TOOLTIPS_MAP.TOOLBAR.PRINT,
    icon: <Print />,
    onClick: onPrint,
  },
  {
    type: DropdownItemType.Default,
    title: TOOLTIPS_MAP.TOOLBAR.FULLSCREEN,
    icon: <FullScreen />,
    onClick: onFullScreen,
  },
  {
    type: DropdownItemType.Default,
    title: TOOLTIPS_MAP.TOOLBAR.ROTATE_CLOCKWISE,
    icon: <RotateClockwise />,
    onClick: onRotate(1),
    disabled,
  },
  {
    type: DropdownItemType.Default,
    title: TOOLTIPS_MAP.TOOLBAR.ROTATE_COUNTERCLOCKWISE,
    icon: <RotateCounterclockwise />,
    onClick: onRotate(-1),
    disabled,
  },
  {
    type: DropdownItemType.Default,
    title: TOOLTIPS_MAP.TOOLBAR.RESTORE_ANNOTATIONS,
    icon: <Restore />,
    onClick: onRestoreAnnotations,
    disabled: isRestoreDisabled,
  },
  {
    type: DropdownItemType.Default,
    title: TOOLTIPS_MAP.TOOLBAR.DELETE_ALL,
    icon: <DeleteAll />,
    onClick: onDeleteAll,
    disabled: disabled || removeAllDisabled,
  },
  {
    type: DropdownItemType.Default,
    title: TOOLTIPS_MAP.TOOLBAR.PROPERTIES,
    icon: <Info />,
    onClick: onShowProperties,
    disabled,
  },
];
