export const LS_IS_SINGLE_PAGE = 'is-single-page';
export const LS_PAGE_SCALE = 'page-scale';
export const LS_HORIZONTAL_MODE = 'horizontal-mode';
export const LS_SHOW_ANNOTATIONS = 'show-annotations-panel';
export const LS_COLOR_MAP = 'color-map';
export const LS_DEFAULT_ANNOTATION_TITLE = 'default-annotation-title';
export const LS_GENERATE_STREAM = 'generate-appearance-stream';
export const LS_FAVORITE_COLORS = 'favorite-colors';
export const LS_PAGE_LAYOUT = 'page-layout';
export const LS_FONT = 'font-name';
export const LS_FONT_SIZE = 'font-size';
export const LS_LINE_STYLE = 'line-style';
export const LS_SHAPE_TYPE = 'shape-type';
export const LS_SHAPE_STYLE = 'shape-style';
export const LS_DRAWING_STYLE = 'drawing-style';
export const LS_ROTATION_ANGLE = 'rotation-angle';
export const LS_COPIED_ANNOTATION = 'copied-annotation';
