import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Document, pdfjs } from 'react-pdf';
import { useHoverDirty, useKeyPress, useMouseWheel, usePrevious } from 'react-use';
import { message } from 'antd';
import classNames from 'classnames';
import { PDFDocumentProxy } from 'pdfjs-dist';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import _ from 'lodash';
import { useIndexedDB } from 'react-indexed-db';
import { useGesture } from '@use-gesture/react';
import { VariableSizeList as List } from 'react-window';

import { getPagesToLoad, scrollIntoAnnotation, scrollToNumber, zoomIn, zoomOut } from '../../helpers/pdfViewerHelper';
import {
  setContextMenuActivePage,
  setFileInfo,
  setFileStatus,
  setNumPages,
  setPage,
  setScale,
  setScrollIntoPage,
} from '../../../store/viewerSettings/actions';
import {
  getContextMenuActivePage,
  getNumPages,
  getPage,
  getPageLayout,
  getRotationAngle,
  getScale,
  getScrollIntoPage,
  isHorizontalMode,
  isShowAnnotationsPanel,
  isSinglePageView,
} from '../../../store/viewerSettings/selectors';
import { FileContext } from '../fileContext/FileContext';
import { IAnnotationEntity } from '../../../services/interfaces/IAnnotation';
import { getActiveAnnotation, getAnnotationsList } from '../../../store/annotations/selectors';
import { FileProgressStatus } from '../../../store/enums/fileProgressStatus';
import Paginator from '../../components/pageInput/Paginator';
import PdfViewerFooter from '../../layouts/pdfViewerFooter/pdfViewerFooter';
import CustomScroll from '../../components/customScroll/CustomScroll';
import { useForceUpdate } from '../../hooks/forceUpdateHook';
import { PageLayout } from '../toolbar/toolbarLeft/enums/pageLayout';
import FileLoading from '../../components/fileLoading/FileLoading';
import NoData from '../../components/noData/NoData';
import { A4Height, A4Width } from '../toolbar/scaleSelect/scaleHelper';
import {
  COPIED_ANNOTATION_DB_NAME,
  SCALE_ROUND_MULTIPLIER,
  SCALE_STEP,
  TAG_NAMES,
  TEXT_MAP,
} from '../../constants/application';
import { initAnnotations, setActiveAnnotation, setCopiedAnnotation } from '../../../store/annotations/actions';
import { findAnnotationIndex } from '../../../services/annotationService';
import { rememberState } from '../../../store/historyStore/actions';
import { getActiveAction, getIsEditing, getRemovingAnnotation } from '../../../store/annotateSettings/selectors';
import { setRemovingAnnotation } from '../../../store/annotateSettings/actions';
import { ActionType } from '../../../store/enums/actionType';
import { setPageByViewport, setPages, setSelectedPages } from '../../../store/pages/actions';
import { setOutlines } from '../../../store/outlines/actions';
import { buildTree, IPdfData } from '../../helpers/outlineHelper';
import { getPagesByViewport, getPagesList, getSelectedPages } from '../../../store/pages/selectors';
import { CASHED_IMAGES_CONTAINER_ID } from '../../../services/imageDrawService';
import { AnnotateActionType } from '../../../store/enums/annotateActionType';
import { AnyObject, CustomAny } from '../../types/generics';
import { IPdfInfo } from '../../../store/interfaces/IPdfInfo';
import { swapWidthAndHeight } from '../../helpers/canvasHelper';
import PageRenderer from './pageRenderer/PageRenderer';

import './PdfViewer.less';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer: FC = () => {
  const db = useIndexedDB(COPIED_ANNOTATION_DB_NAME);
  const dispatch = useDispatch();
  const numPages: number = useSelector(getNumPages);
  const singlePageView: boolean = useSelector(isSinglePageView);
  const horizontalMode: boolean = useSelector(isHorizontalMode);
  const pageLayout: string = useSelector(getPageLayout);
  const page: number = useSelector(getPage);
  const scrollIntoPage: number = useSelector(getScrollIntoPage);
  const scale: number = useSelector(getScale);
  const showAnnotationsPanel: boolean = useSelector(isShowAnnotationsPanel);
  const activeAnnotation: IAnnotationEntity = useSelector(getActiveAnnotation);
  const annotationsList: IAnnotationEntity[] = useSelector(getAnnotationsList);
  const removingAnnotation: boolean = useSelector(getRemovingAnnotation);
  const editingAnnotation: boolean = useSelector(getIsEditing);
  const pagesByViewport: number[] = useSelector(getPagesByViewport);
  const contextMenuActivePage = useSelector(getContextMenuActivePage);
  const rotationAngle: number = useSelector(getRotationAngle);
  const activeAction: AnnotateActionType | undefined = useSelector(getActiveAction);
  const pagesList: CustomAny[] = useSelector(getPagesList);
  const selectedPages: number[] = useSelector(getSelectedPages);
  const { file, setFile } = useContext(FileContext);
  const [pageSizesArray, setPageSizesArray] = useState<number[][]>([]);
  const [loadedPages, setLoadedPages] = useState<number[]>([]);
  const [clientHeight, setClientHeight] = useState<number>(10);
  const [scrollHeight, setScrollHeight] = useState<number>(100);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [oldMouseWheel, setOldMouseWheel] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(-1);
  const [scrollingToAnnotation, setScrollingToAnnotation] = useState(false);
  const forceUpdate = useForceUpdate();
  const documentRef = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(documentRef);
  const mouseWheel = useMouseWheel();
  const isDelPressed = useKeyPress('Delete')[0];
  const isBackspacePressed = useKeyPress('Backspace')[0];
  const listRef = useRef<List>(null);
  const listInnerRef = useRef<HTMLDivElement>(null);
  const prevScale = usePrevious(scale);

  const wheelEventListener = (e: WheelEvent) => {
    e.ctrlKey && e.preventDefault();
  };

  const touchEventListener = (e: TouchEvent) => {
    e.touches.length > 1 && e.preventDefault();
  };

  const shownPages: number[] = useMemo(() => {
    if (!singlePageView) {
      return Array.from(new Array(numPages), (_el, index) => index + 1);
    }
    switch (pageLayout) {
      case PageLayout.TwoPageView:
        if (Math.abs(page % 2) == 1 && page + 1 <= numPages) {
          return [page, page + 1];
        }

        if (page - 1 > 0) {
          return [page - 1, page];
        }

        return [page];
      case PageLayout.TwoPageViewGap:
        if (page === 1) {
          return [page];
        }

        if (page % 2 == 0 && page + 1 <= numPages) {
          return [page, page + 1];
        }

        if (page - 1 > 0) {
          return [page - 1, page];
        }

        return [page];
      default:
        return [page];
    }
  }, [numPages, singlePageView, page, pageLayout]);

  const pagesToRender: number[][] = useMemo(() => {
    if (singlePageView) {
      return [shownPages];
    }
    if (pageLayout === PageLayout.TwoPageView && !horizontalMode) {
      return _.chunk(shownPages, 2);
    }
    if (pageLayout === PageLayout.TwoPageViewGap && !horizontalMode) {
      const pages = [...shownPages];
      const firstPage = pages.shift() || -1;
      return [[firstPage], ..._.chunk(pages, 2)];
    }
    return shownPages.map((page) => [page]);
  }, [shownPages, horizontalMode, pageLayout, singlePageView]);

  useEffect(() => {
    if (documentRef && documentRef.current) {
      documentRef.current.addEventListener('wheel', wheelEventListener, { passive: false });
      documentRef.current.addEventListener('touchstart', touchEventListener, { passive: false });
      documentRef.current.addEventListener('touchmove', touchEventListener, { passive: false });
      documentRef.current.addEventListener('touchend', touchEventListener, { passive: false });
    }
  }, [documentRef]);

  useEffect(() => {
    db.getAll().then((allResult) => {
      if (allResult[0]) {
        dispatch(setCopiedAnnotation(allResult[0]));
      }
    });
    return () => {
      documentRef?.current?.removeEventListener('wheel', wheelEventListener);
      documentRef?.current?.removeEventListener('touchstart', touchEventListener);
      documentRef?.current?.removeEventListener('touchmove', touchEventListener);
      documentRef?.current?.removeEventListener('touchend', touchEventListener);
    };
  }, []);

  useEffect(() => {
    if (isDelPressed) {
      dispatch(setRemovingAnnotation(true));
    }
  }, [isDelPressed]);

  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isBackspacePressed && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      dispatch(setRemovingAnnotation(true));
    }
  }, [isBackspacePressed]);

  useEffect(
    useCallback(() => {
      if (
        removingAnnotation &&
        !editingAnnotation &&
        activeAnnotation &&
        !activeAnnotation.locked &&
        !activeAnnotation.readOnly
      ) {
        dispatch(setActiveAnnotation(null));
        const newAnnotationList: IAnnotationEntity[] = [];
        const indexToRemove = findAnnotationIndex(annotationsList, activeAnnotation);
        annotationsList.forEach((annotation, index) => {
          if (
            (annotation.idToReply === activeAnnotation.id || index === indexToRemove) &&
            annotation.actionType !== ActionType.Create
          ) {
            const newAnnotation = { ...annotation, actionType: ActionType.Delete };
            newAnnotationList.push(newAnnotation);
          } else if (annotation.idToReply !== activeAnnotation.id && index !== indexToRemove) {
            newAnnotationList.push(annotation);
          }
        });
        dispatch(initAnnotations(newAnnotationList));
        dispatch(rememberState(newAnnotationList));
      }
      dispatch(setRemovingAnnotation(false));
    }, [removingAnnotation, activeAnnotation, annotationsList, editingAnnotation]),
    [removingAnnotation],
  );

  const pageRefs: React.RefObject<HTMLDivElement>[] = useMemo(() => {
    if (!singlePageView) {
      return Array.from(new Array(numPages), () => React.createRef());
    }

    return [React.createRef()];
  }, [numPages, singlePageView, pageLayout]);

  const onPageInViewport = useCallback(
    (page: number, data: { isIntersecting: boolean; intersectionRatio: number }) => {
      const newPages = getPagesToLoad(loadedPages, page, numPages);
      if (newPages.length) {
        setLoadedPages([...loadedPages, ...newPages]);
      }
      if (singlePageView) return;

      if (data.isIntersecting) {
        dispatch(setPageByViewport({ index: page - 1, ratio: data.intersectionRatio }));
      } else {
        dispatch(setPageByViewport({ index: page - 1, ratio: 0 }));
      }
    },
    [loadedPages, singlePageView, pageLayout, pagesByViewport],
  );

  useEffect(
    useCallback(() => {
      if (singlePageView) return;
      const newPageNumber = pagesByViewport.indexOf(
        Math.max(...pagesByViewport.filter((ratio) => _.isNumber(ratio) && ratio > 0)),
      );
      if (newPageNumber > -1) {
        setTimeout(() => {
          dispatch(setPage(newPageNumber + 1));
        }, 100);
      }
    }, [pagesByViewport, singlePageView]),
    [pagesByViewport],
  );
  const onDocumentLoad = useCallback(async (data: PDFDocumentProxy) => {
    data.getMetadata().then(({ info }: { info: pdfjs.PDFInfo }) => {
      dispatch(setFileInfo(info));
    });
    dispatch(setNumPages(1));
    const pageSizes = await Promise.all(
      Array.from(new Array(data.numPages), (el, index) => index).map(async (index) => {
        const pageData = await data.getPage(index + 1);
        return [...pageData.view, pageData.view[2] - pageData.view[0], pageData.view[3] - pageData.view[1]];
      }),
    );
    setPageSizesArray(pageSizes);
    if (data.numPages === 0) {
      message.error(TEXT_MAP.ERROR.EMPTY_FILE_ALERT);
    }
    dispatch(setNumPages(data.numPages));
    const pages: CustomAny[] = [];
    for (let i = 0; i < data.numPages; i++) {
      pages.push({
        page: i + 1,
        width: pageSizes[i][4],
        height: pageSizes[i][5],
      });
    }
    setTimeout(() => {
      dispatch(setPages(pages));
    }, 0);
    dispatch(setFileStatus(FileProgressStatus.FINISH));
    dispatch(setOutlines(await buildTree(data as IPdfData)));
  }, []);

  const onLoadError = useCallback(() => {
    if (typeof file === 'string') {
      message.error('Failed to load PDF. Make sure the URL is correct and is publically accessible');
    }
    setFile(null);
  }, [file]);

  useEffect(
    useCallback(() => {
      if (activeAnnotation) {
        let pdfPage;
        const viewport = pageSizesArray[activeAnnotation.page] ? pageSizesArray[activeAnnotation.page].slice(0, 4) : [];
        if (singlePageView) {
          setTimeout(() => {
            dispatch(setPage(activeAnnotation.page + 1));
          }, 100);
          pdfPage = 0;
        } else {
          pdfPage = pagesToRender.findIndex((pages) => pages.includes(activeAnnotation.page + 1));
        }
        if (activeAction !== AnnotateActionType.Drawing && pdfPage !== -1) {
          setScrollingToAnnotation(true);
          let offset = 0;
          for (let i = 0; i < pdfPage; i++) {
            offset += getPagesSize(i);
          }
          if (listRef && listRef.current && listInnerRef && listInnerRef.current) {
            const offsetKey = horizontalMode ? 'scrollLeft' : 'scrollTop';
            const container = listInnerRef.current.parentElement?.parentElement;
            if (container) {
              listRef.current.scrollToItem(pdfPage);
              setTimeout(() => {
                container[offsetKey] = offset;
              }, 0);
            }
            setTimeout(() => {
              scrollIntoAnnotation(
                document.getElementById(`page-${activeAnnotation.page}`),
                container,
                viewport,
                activeAnnotation,
                scale,
                rotationAngle,
              );
            }, 0);
          }
          //scrollIntoAnnotation(pdfPage, viewport, activeAnnotation, scale, rotationAngle);
        }
      }
    }, [activeAnnotation, scale, singlePageView, rotationAngle, activeAction, pagesToRender, listRef, listInnerRef]),
    [activeAnnotation, scale, singlePageView, rotationAngle],
  );

  useEffect(
    useCallback(() => {
      if (listRef && listRef.current && listInnerRef && listInnerRef.current && scale !== prevScale) {
        const offsetKey = horizontalMode ? 'scrollLeft' : 'scrollTop';
        const container = listInnerRef.current.parentElement?.parentElement;
        if (container) {
          const offset = container[offsetKey];
          const newOffset = (offset / (prevScale || 1)) * scale;
          container[offsetKey] = newOffset;
          listRef.current.scrollTo(newOffset);
          listRef.current?.resetAfterIndex(0, true);
        }
      }
    }, [listRef, listInnerRef, documentRef, scale, prevScale, horizontalMode]),
    [scale, prevScale],
  );

  useEffect(
    useCallback(() => {
      listRef.current?.resetAfterIndex(0, true);
    }, [listRef]),
    [horizontalMode, pageSizesArray, pageLayout, singlePageView, rotationAngle],
  );

  useEffect(() => forceUpdate(), [showAnnotationsPanel]);

  useEffect(() => {
    if (scrollTop + clientHeight === scrollHeight && page < numPages && oldMouseWheel < mouseWheel && isHovering) {
      setOldMouseWheel(mouseWheel);
      setTimeout(() => {
        dispatch(setPage(page + 1));
      }, 100);
      scrollToPage(page + 1, 0);
      return;
    }
    if (scrollTop === 0 && page > 1 && oldMouseWheel > mouseWheel && isHovering) {
      setOldMouseWheel(mouseWheel);
      setTimeout(() => {
        dispatch(setPage(page - 1));
      }, 100);
      scrollToPage(page - 1, scrollHeight);
      return;
    }
    setOldMouseWheel(mouseWheel);
  }, [mouseWheel, oldMouseWheel, scrollTop, clientHeight, scrollHeight, isHovering, numPages, page]);

  const scrollToPage = useCallback((page: number, position: number): void => {
    setTimeout(() => {
      if (isLoaded(page)) {
        scrollToNumber(position + 1);
        scrollToNumber(position);
      }
    }, 30);
  }, []);

  useEffect(
    useCallback(() => {
      if (!selectedPages || selectedPages.length === 0) {
        return;
      }
      if (!singlePageView && pageRefs[selectedPages[0]] && pageRefs[selectedPages[0]].current) {
        scrollToPage(selectedPages[0], 0);
        pageRefs[selectedPages[0]].current?.scrollIntoView(horizontalMode ? false : selectedPages[0] !== numPages);
        dispatch(setPage(selectedPages[0] + 1));
        dispatch(setSelectedPages([]));
      } else if (singlePageView) {
        dispatch(setPage(selectedPages[0] + 1));
        dispatch(setSelectedPages([]));
      }
    }, [pageRefs, selectedPages, horizontalMode, singlePageView]),
    [pageRefs],
  );

  const isLoaded = useMemo(
    () => (pageNumber: number) => singlePageView || loadedPages.includes(pageNumber),
    [singlePageView, loadedPages],
  );

  const handleScroll = useCallback(
    (e) => {
      const element = e.target as HTMLDivElement;
      setClientHeight(element.clientHeight);
      setScrollHeight(element.scrollHeight);
      setScrollTop(element.scrollTop);
      const scrollOffset = horizontalMode ? element.scrollLeft : element.scrollTop;
      if (listRef && listRef.current && scrollOffset !== (listRef.current.state as CustomAny).scrollOffset) {
        listRef.current.scrollTo(horizontalMode ? element.scrollLeft : element.scrollTop);
      }
      if (!scrollingToAnnotation) {
        dispatch(setContextMenuActivePage({ triggerPage: contextMenuActivePage, value: -1 }));
      }
      setScrollingToAnnotation(false);
    },
    [singlePageView, contextMenuActivePage, scrollingToAnnotation, listRef, horizontalMode],
  );

  const onUpdate = useCallback(
    (element) => {
      setClientHeight(element.clientHeight);
      setScrollHeight(element.scrollHeight);
      setScrollTop(element.scrollTop);
    },
    [pageRefs],
  );

  const cardClassNames = useMemo(
    () =>
      classNames('pdf-viewer', {
        /*'pdf-viewer_row': horizontalMode,
        'pdf-viewer_double': pageLayout === PageLayout.TwoPageView || pageLayout === PageLayout.TwoPageViewGap,
        'pdf-viewer_double-gap': pageLayout === PageLayout.TwoPageViewGap,
        'pdf-viewer_single-page': singlePageView,*/
      }),
    [horizontalMode, pageLayout, singlePageView],
  );

  const onPageViewportUpdated = useCallback(
    (page: number, viewport: number[]) => {
      const sizesArray = [...pageSizesArray];
      sizesArray[page - 1] = [...viewport, viewport[2] - viewport[0], viewport[3] - viewport[1]];
      setPageSizesArray(sizesArray);
      const pages = [...pagesList];
      pages[page - 1] = {
        ...pages[page - 1],
        width: sizesArray[page - 1][4],
        height: sizesArray[page - 1][5],
      };
      dispatch(setPages(pages));
    },
    [pageSizesArray, pagesList],
  );

  const onCopyAnnotation = async (annotation: IAnnotationEntity) => {
    await db.clear();
    await db.add(annotation);
  };

  const onPinch = useCallback(
    ({ offset, event, type }: CustomAny) => {
      if (type === 'wheel') {
        if (event.deltaY < 0) {
          dispatch(setScale(zoomIn(scale)));
        } else if (event.deltaY > 0) {
          dispatch(setScale(zoomOut(scale)));
        }
      } else {
        const newScale =
          (Math.floor(Math.round(offset[0] * SCALE_ROUND_MULTIPLIER) / SCALE_STEP) * SCALE_STEP) /
          SCALE_ROUND_MULTIPLIER;
        dispatch(setScale(newScale));
      }
    },
    [scale, listInnerRef, horizontalMode],
  );

  useGesture(
    { onPinchEnd: onPinch },
    {
      target: documentRef,
      pinch: { scaleBounds: { min: 0.5, max: 4 } },
    },
  );

  const getPagesSize = useCallback(
    (index: number) => {
      const pages = pagesToRender[index];
      const maxWidth = _.max(pages.map((pageNumber) => pageSizesArray[pageNumber - 1]?.[4] || 0)) || A4Width;
      const maxHeight = _.max(pages.map((pageNumber) => pageSizesArray[pageNumber - 1]?.[5] || 0)) || A4Height;
      // If horizontal mode is set and need to swap width and height or vertical mode is set and no need
      // to swap width and height, then width should be taken as list item size.
      // Otherwise, height is the list item size.
      return (horizontalMode !== swapWidthAndHeight(rotationAngle) ? maxWidth : maxHeight) * scale + 8;
    },
    [pagesToRender, pageSizesArray, scale, rotationAngle],
  );

  const getPageWidth = useCallback(
    (pageNumber: number) => {
      const width = pageSizesArray[pageNumber - 1]?.[4] || A4Width;
      const height = pageSizesArray[pageNumber - 1]?.[5] || A4Height;
      return (swapWidthAndHeight(rotationAngle) ? height : width) * scale;
    },
    [pageSizesArray, horizontalMode, rotationAngle, scale],
  );

  const getPageHeight = useCallback(
    (pageNumber: number) => {
      const width = pageSizesArray[pageNumber - 1]?.[4] || A4Width;
      const height = pageSizesArray[pageNumber - 1]?.[5] || A4Height;
      return (swapWidthAndHeight(rotationAngle) ? width : height) * scale;
    },
    [pageSizesArray, horizontalMode, rotationAngle, scale],
  );

  useEffect(
    useCallback(() => {
      if (scrollIntoPage && scrollIntoPage !== page && !singlePageView) {
        const pagesIndex = pagesToRender.findIndex((pages) => pages.includes(scrollIntoPage));
        if (pagesIndex !== -1) {
          let offset = 0;
          for (let i = 0; i < pagesIndex; i++) {
            offset += getPagesSize(i);
          }
          if (listRef && listRef.current && listInnerRef && listInnerRef.current) {
            const offsetKey = horizontalMode ? 'scrollLeft' : 'scrollTop';
            const container = listInnerRef.current.parentElement?.parentElement;
            if (container) {
              listRef.current.scrollToItem(pagesIndex);
              setTimeout(() => {
                container[offsetKey] = offset;
              }, 0);
            }
          }
        }
        dispatch(setScrollIntoPage(0));
      }
    }, [scrollIntoPage, singlePageView, getPagesSize, pagesToRender, listRef, listInnerRef]),
    [scrollIntoPage],
  );

  return (
    <>
      <section ref={documentRef} className={cardClassNames}>
        <Document
          loading={<FileLoading />}
          noData={<NoData description={TEXT_MAP.ERROR.NO_FILE_SPECIFIED} />}
          file={file}
          onLoadSuccess={onDocumentLoad}
          onSourceError={onLoadError}
          onLoadError={onLoadError}
          options={{
            cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
        >
          {file && numPages === 0 ? <NoData description={TEXT_MAP.ERROR.EMPTY_FILE} /> : null}
          <CustomScroll
            autoHeight={!horizontalMode}
            autoHeightMax={horizontalMode ? undefined : documentRef.current?.clientHeight || 0}
            onScroll={handleScroll}
            onUpdate={onUpdate}
          >
            {!_.isEmpty(pageSizesArray) && (
              <List
                itemCount={pagesToRender.length}
                itemSize={getPagesSize}
                ref={listRef}
                innerRef={listInnerRef}
                style={{ overflow: 'none' }}
                height={horizontalMode ? '100%' : documentRef.current?.clientHeight || 0}
                overscanCount={5}
                width={horizontalMode ? documentRef.current?.clientWidth || 0 : '100%'}
                layout={horizontalMode ? 'horizontal' : 'vertical'}
                itemData={{
                  horizontal: horizontalMode,
                  pages: pagesToRender,
                  pageData: {
                    scale,
                    rotationAngle,
                    activePage,
                    onViewportUpdated: onPageViewportUpdated,
                    setActivePage,
                    onCopyAnnotation,
                    onPageInViewport,
                    getPageWidth,
                    getPageHeight,
                  },
                }}
              >
                {PageRenderer}
              </List>
            )}
          </CustomScroll>
        </Document>
        <div id={CASHED_IMAGES_CONTAINER_ID} style={{ display: 'none' }} />
      </section>
      <PdfViewerFooter>
        <Paginator />
      </PdfViewerFooter>
    </>
  );
};

export default memo(PdfViewer);
