import React, { createContext, FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIndexedDB } from 'react-indexed-db';
import { usePrevious, useTitle } from 'react-use';
import { message } from 'antd';
import printJS from 'print-js';

import { AnyObject, CustomAny, OrNull } from '../../types/generics';
import {
  setFileId,
  setPage,
  setAppLoading,
  setFileStatus,
  setPageNavigationMode,
  setRotationAngle,
} from '../../../store/viewerSettings/actions';
import { setHistory, setHistoryIndex } from '../../../store/historyStore/actions';
import { APP_NAME, FILE_DB_NAME, REACT_APP_MAX_FILE_SIZE } from '../../constants/application';
import { downloadFile, getFile, sendFile } from '../../../api/annotations/AnnotationsApiService';
import { getAnnotationsList } from '../../../store/annotations/selectors';
import { getHistory, getHistoryIndex } from '../../../store/historyStore/selectors';
import { IAnnotationEntity } from '../../../services/interfaces/IAnnotation';
import { initAnnotations, setActiveAnnotation, setCopiedAnnotation } from '../../../store/annotations/actions';
import {
  adjustAnnotationsWithActionTypes,
  saveAnnotations,
  saveOutlines,
  savePages,
} from '../../../services/annotationService';
import { base64ToFile, buildFile, fileToBase64, getFileData } from '../../helpers/fileHelper';
import { getStoredFileId } from '../../../store/viewerSettings/selectors';
import { FileProgressStatus } from '../../../store/enums/fileProgressStatus';
import { getPagesHistory, getPagesHistoryIndex, getPagesList } from '../../../store/pages/selectors';
import { clearPages } from '../../../store/pages/actions';
import { getFontsList } from '../../../store/fonts/selectors';
import { IPageAction } from '../../../services/interfaces/IPageActions';
import { ITreeNode } from '../../components/tree/interfaces/ITreeNode';
import { getOutlinesTree } from '../../../store/outlines/selectors';
import { applyActionsToOutlines } from '../../../services/pageService';
import { clearCachedImages } from '../../../services/imageDrawService';

import './FileContext.less';

export type TAttachedFile = OrNull<File | string>;

interface IFileContext {
  file: TAttachedFile;
  setFile: React.Dispatch<TAttachedFile>;
  saveFile(generateAStream: boolean): void;
  updatePages(generateAStream: boolean): void;
  printFile(generateAStream: boolean): void;
}

export const FileContext = createContext<IFileContext>({} as IFileContext);

const FileProvider: FC = (props) => {
  const db = useIndexedDB(FILE_DB_NAME);
  const dispatch = useDispatch();
  const storedFileId: number | undefined = useSelector(getStoredFileId);
  const fileAnnotations: IAnnotationEntity[] = useSelector(getAnnotationsList);
  const pageHistory: IPageAction[] = useSelector(getPagesHistory);
  const pages: CustomAny[] = useSelector(getPagesList);
  const pageHistoryIndex: number = useSelector(getPagesHistoryIndex);
  const outlines: ITreeNode[] = useSelector(getOutlinesTree);
  const [base64File, setBase64File] = useState<string[]>([]);
  const [file, setFile] = useState<TAttachedFile>();
  const history = useSelector(getHistory);
  const historyIndex = useSelector(getHistoryIndex);
  const prevHistoryIndex = usePrevious(historyIndex);
  const fonts = useSelector(getFontsList);
  useTitle(`${APP_NAME}${file?.name ? ': ' + file.name : ''}`);

  useEffect(() => {
    if (file) {
      dispatch(setAppLoading(true));
      dispatch(setPage(1));
    } else {
      dispatch(setFileStatus(FileProgressStatus.FINISH));
    }
  }, [file]);

  useEffect(() => {
    dispatch(setAppLoading(true));
    db.getAll().then((allResult) => {
      if (allResult[0]) {
        dispatch(setFileStatus(FileProgressStatus.UPLOADING));
        dispatch(setFileStatus(FileProgressStatus.DOWNLOADING_NEW_VERSION));
        dispatch(setFileStatus(FileProgressStatus.STORING_FILE_WITH_ANNOTATIONS));
        const fileStrArray: string[] = [];
        Object.keys(allResult[0]).forEach((key: string) => {
          if (key.includes('file[')) {
            const keyIndex = key.split('file[')[1].split(']')[0];
            fileStrArray[parseInt(keyIndex)] = allResult[0][key];
          }
        });
        dispatch(setFileStatus(FileProgressStatus.OPEN_FILE));
        setFile(base64ToFile(fileStrArray, allResult[0].fileData));
        setBase64File(fileStrArray);
        dispatch(setFileId(allResult[0].id));
        dispatch(setHistory(allResult[0].history));
        dispatch(setHistoryIndex(allResult[0].historyIndex));
      } else {
        dispatch(setAppLoading(false));
        dispatch(setFileStatus(FileProgressStatus.FINISH));
      }
    });
  }, []);

  useEffect(
    useCallback(() => {
      if (storedFileId && file) {
        const fileData = getFileData(file);
        const updateData: AnyObject = {
          id: storedFileId,
          filename: file.name,
          history,
          historyIndex,
          fileData,
        };
        base64File.forEach((str: string, index: number) => {
          updateData[`file[${index}]`] = str;
        });
        dispatch(initAnnotations(history[historyIndex] || []));
        db.update(updateData);
        if (
          prevHistoryIndex &&
          history.length > prevHistoryIndex &&
          historyIndex !== -1 &&
          prevHistoryIndex !== -1 &&
          history[historyIndex].length < history[prevHistoryIndex].length
        ) {
          dispatch(setActiveAnnotation(null));
        }
      }
    }, [storedFileId, base64File, history, historyIndex, prevHistoryIndex]),
    [history, historyIndex],
  );

  const clearFileStore = async () => {
    dispatch(setFileId(undefined));
    await db.clear();
  };

  const attachFile = async (file: TAttachedFile) => {
    if (file?.size > REACT_APP_MAX_FILE_SIZE * 1024 * 1024) {
      message.error(`File max size is ${REACT_APP_MAX_FILE_SIZE}Mb`);
      return;
    }
    try {
      dispatch(setAppLoading(true));
      await clearFileStore();
      if (!file) {
        return setFile(null);
      }
      dispatch(initAnnotations([]));
      const fileData = getFileData(file);
      dispatch(setFileStatus(FileProgressStatus.UPLOADING));
      const { localPathToHiddenAnnotationsFile: filePath, annotations } = await sendFile(file);
      dispatch(setFileStatus(FileProgressStatus.DOWNLOADING_NEW_VERSION));
      const newFile = await getFile(filePath);
      dispatch(setFileStatus(FileProgressStatus.STORING_FILE_WITH_ANNOTATIONS));
      const parsedFile = buildFile(newFile, fileData);
      const history = [adjustAnnotationsWithActionTypes(annotations) || []];
      const updateData: AnyObject = {
        annotations,
        filename: parsedFile.name,
        fileData,
        history: history,
        historyIndex: 0,
      };
      const base64 = await fileToBase64(parsedFile);
      setBase64File(base64);
      base64.forEach((str: string, index: number) => {
        updateData[`file[${index}]`] = str;
      });
      const fileId = await db.add(updateData);
      dispatch(setFileStatus(FileProgressStatus.OPEN_FILE));
      dispatch(setFileId(fileId));
      setFile(parsedFile);
      clearCachedImages();
      dispatch(setActiveAnnotation(null));
      dispatch(setCopiedAnnotation(undefined));
      dispatch(initAnnotations(history[0]));
      dispatch(setHistoryIndex(0));
      dispatch(setHistory(history));
      dispatch(setRotationAngle(0));
    } catch (e) {
      dispatch(setAppLoading(false));
      message.error(`${!e.hasMessage ? 'Open file error. ' : ''}${e.message}`);
      console.error(e);
    }
  };

  const saveFile = async (generateAStream: boolean) => {
    dispatch(setAppLoading(true));
    try {
      const fileData = getFileData(file);
      dispatch(setFileStatus(FileProgressStatus.UPLOADING));
      const newFileData = await saveAnnotations(file, fileAnnotations, fonts, generateAStream);
      const filePath = await saveOutlines(newFileData.localPathToOriginalFile, outlines);
      await downloadFile(filePath, file.name.split('.').slice(0, -1).join('.'));
      dispatch(setFileStatus(FileProgressStatus.DOWNLOADING_NEW_VERSION));
      const hiddenAnnotationsFilePath = await saveOutlines(newFileData.localPathToHiddenAnnotationsFile, outlines);
      const newFile = await getFile(hiddenAnnotationsFilePath);
      dispatch(setFileStatus(FileProgressStatus.STORING_FILE_WITH_ANNOTATIONS));
      const parsedFile = buildFile(newFile, fileData);
      await clearFileStore();
      const history = [adjustAnnotationsWithActionTypes(newFileData.annotations) || []];
      const updateData: AnyObject = {
        annotations: newFileData.annotations,
        filename: parsedFile.name,
        fileData,
        history,
        historyIndex: 0,
      };
      const base64 = await fileToBase64(parsedFile);
      setBase64File(base64);
      base64.forEach((str: string, index: number) => {
        updateData[`file[${index}]`] = str;
      });
      const fileId = await db.add(updateData);
      dispatch(setFileId(fileId));
      dispatch(setFileStatus(FileProgressStatus.OPEN_FILE));
      setFile(parsedFile);
      dispatch(setActiveAnnotation(null));
      dispatch(setCopiedAnnotation(undefined));
      dispatch(initAnnotations(history[0]));
      dispatch(setHistoryIndex(0));
      dispatch(setHistory(history));
    } catch (e) {
      dispatch(setAppLoading(false));
      message.error(`${!e.hasMessage ? 'Error while saving. ' : ''}${e.message}`);
      console.error(e);
    }
  };

  const updatePages = async (generateAStream: boolean) => {
    dispatch(setAppLoading(true));
    try {
      const fileData = getFileData(file);
      dispatch(setFileStatus(FileProgressStatus.UPLOADING));
      const fileWithNewAnnotationsData = await saveAnnotations(file, fileAnnotations, fonts, generateAStream);
      const newFileData = await savePages(
        fileWithNewAnnotationsData.localPathToHiddenAnnotationsFile,
        pageHistory.slice(0, pageHistoryIndex + 1),
      );
      dispatch(setFileStatus(FileProgressStatus.DOWNLOADING_NEW_VERSION));
      const filePath = await saveOutlines(
        newFileData.pathToFile,
        applyActionsToOutlines(outlines, pageHistory.slice(0, pageHistoryIndex + 1), pages.length),
      );
      const newFile = await getFile(filePath);
      dispatch(setFileStatus(FileProgressStatus.STORING_FILE_WITH_ANNOTATIONS));
      const parsedFile = buildFile(newFile, fileData);
      await clearFileStore();
      const history = [adjustAnnotationsWithActionTypes(newFileData.annotations) || []];
      const updateData: AnyObject = {
        annotations: newFileData.annotations,
        filename: parsedFile.name,
        fileData,
        history,
        historyIndex: 0,
      };
      const base64 = await fileToBase64(parsedFile);
      setBase64File(base64);
      base64.forEach((str: string, index: number) => {
        updateData[`file[${index}]`] = str;
      });
      const fileId = await db.add(updateData);
      dispatch(setFileId(fileId));
      dispatch(setFileStatus(FileProgressStatus.OPEN_FILE));
      setFile(parsedFile);
      clearCachedImages();
      dispatch(setActiveAnnotation(null));
      dispatch(initAnnotations(history[0]));
      dispatch(setHistoryIndex(0));
      dispatch(setHistory(history));
      dispatch(setPageNavigationMode(false));
      dispatch(clearPages());
    } catch (e) {
      dispatch(setAppLoading(false));
      message.error(`${!e.hasMessage ? 'Error while saving. ' : ''}${e.message}`);
      console.error(e);
    }
  };

  const printFile = async (generateAStream: boolean) => {
    dispatch(setAppLoading(true));
    try {
      const fileData = getFileData(file);
      dispatch(setFileStatus(FileProgressStatus.UPLOADING));
      const newFileData = await saveAnnotations(file, fileAnnotations, fonts, generateAStream);
      const newFile = await getFile(newFileData.localPathToOriginalFile);
      dispatch(setFileStatus(FileProgressStatus.DOWNLOADING_NEW_VERSION));
      const parsedFile = buildFile(newFile, fileData);
      const objectURL = URL.createObjectURL(parsedFile);
      await printJS({ printable: objectURL, type: 'pdf' });
      dispatch(setAppLoading(false));
    } catch (e) {
      dispatch(setAppLoading(false));
      message.error(`${!e.hasMessage ? 'Error while printing. ' : ''}${e.message}`);
      console.error(e);
    }
  };

  const context: IFileContext = {
    file,
    setFile: attachFile,
    saveFile,
    updatePages,
    printFile,
  };

  return <FileContext.Provider value={context}>{props.children}</FileContext.Provider>;
};

export default FileProvider;
