import { fabric } from 'fabric';
import { AnyFunction, CustomAny } from '../shared/types/generics';

const clone = fabric.util.object.clone;

const WavyLine = fabric.util.createClass(fabric.Line, {
  type: 'wavyLine',

  initialize(points: number[], options: fabric.ILineOptions) {
    options || (options = {});
    this.callSuper('initialize', points, options);

    this.set({
      hasBorders: false,
      hasControls: false,
    });
  },

  _render(ctx: CanvasRenderingContext2D) {
    const { x1, x2, y1, y2 } = this.calcLinePoints();
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    const vertical = x1 === x2;
    if (!vertical) {
      let inc = 1;
      let start = x1;
      const finish = x2;
      const stepX = 6;
      const stepY = 3;
      while (start < finish) {
        ctx.quadraticCurveTo(start + stepX / 2, y2 + stepY * inc, start + stepX, y2);
        start += stepX;
        inc *= -1;
      }
    } else {
      let inc = 1;
      let start = y1;
      const finish = y2;
      const stepX = 3;
      const stepY = 6;
      while (start < finish) {
        ctx.quadraticCurveTo(x2 + stepX * inc, start + stepY / 2, x2, start + stepY);
        start += stepY;
        inc *= -1;
      }
    }
    ctx.strokeStyle = this.stroke;
    this._renderStroke(ctx);
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      customProps: this.customProps,
    });
  },
});

WavyLine.fromObject = function (object: CustomAny, callback: AnyFunction) {
  function _callback(instance: CustomAny) {
    delete instance.points;
    callback && callback(instance);
  }
  const options = clone(object);
  options.points = [object.x1, object.y1, object.x2, object.y2];
  fabric.Object._fromObject('WavyLine', options, _callback, 'points');
};

export { WavyLine };
