import axios from 'axios';

import { CustomAny } from '../shared/types/generics';
import { buildFileFormData, buildFormData, handleDownload, handleError, handleResponse } from './helpers/apiHelper';

export const getRequest = (url: string, params = {}, headers = {}): Promise<CustomAny> => {
  return axios
    .get(url, { params, ...headers })
    .then(handleResponse)
    .catch(handleError);
};

export const postRequest = (url: string, data: CustomAny, config = {}): Promise<CustomAny> => {
  return axios
    .post(url, data, { headers: { 'Content-Type': 'application/json' }, ...config })
    .then(handleResponse)
    .catch(handleError);
};

export const postMultipartRequest = async (url: string, data = {}): Promise<CustomAny> => {
  const formData = buildFormData(data);
  return axios
    .post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(handleResponse)
    .catch(handleError);
};

export const postUploadRequest = async (url: string, files: File[] | File, data = {}): Promise<CustomAny> => {
  const formData = buildFileFormData(files, data);
  return axios
    .post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(handleResponse)
    .catch(handleError);
};

export const putRequest = (url: string, data: CustomAny): Promise<CustomAny> => {
  return axios
    .put(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
    .then(handleResponse)
    .catch(handleError);
};

export const downloadRequest = (url: string, filePath: string, fileName: string): Promise<CustomAny> => {
  return axios
    .post(url, filePath, { responseType: 'blob', headers: { 'Content-Type': 'application/json' } })
    .then(handleDownload(fileName))
    .catch(handleError);
};
