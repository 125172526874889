import React, { FC, useEffect, useState } from 'react';
import { Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import { getFileProgress, isAppLoading, isPageNavigationMode } from '../../../store/viewerSettings/selectors';
import { setAppLoading } from '../../../store/viewerSettings/actions';
import SettingsBar from '../settingsBar/SettingsBar';
import { AnnotateActionType } from '../../../store/enums/annotateActionType';
import { getActiveAction } from '../../../store/annotateSettings/selectors';
import ViewerToolbar from '../viewerToolbar/ViewerToolbar';
import PageNavigationToolbar from '../pageNavigationToolbar/PageNavigationToolbar';

import './Header.less';

const Header: FC = () => {
  const dispatch = useDispatch();
  const fileProgress = useSelector(getFileProgress);
  const appLoading = useSelector(isAppLoading);
  const pageNavigationMode = useSelector(isPageNavigationMode);
  const activeAction: AnnotateActionType | undefined = useSelector(getActiveAction);
  const [debouncedValue, setDebouncedValue] = useState(0);

  useDebounce(
    () => {
      setDebouncedValue(fileProgress);
    },
    500,
    [fileProgress],
  );
  useEffect(() => {
    if (debouncedValue >= 100) {
      dispatch(setAppLoading(false));
    }
  }, [debouncedValue]);
  return (
    <header className="flexcil-app-header">
      {pageNavigationMode && <PageNavigationToolbar />}
      {!pageNavigationMode && <ViewerToolbar />}
      {!pageNavigationMode && activeAction && <SettingsBar />}
      {appLoading && (
        <Progress
          className="flexcil-app-header__progress"
          percent={fileProgress}
          showInfo={false}
          status="active"
          strokeLinecap="square"
        />
      )}
    </header>
  );
};

export default Header;
