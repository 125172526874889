import React, { FC, memo, useMemo } from 'react';
import classNames from 'classnames';

import { AnyObject } from '../../../types/generics';
import PdfPage from '../page/Page';
import { IAnnotationEntity } from '../../../../services/interfaces/IAnnotation';

import './PageRenderer.less';

interface IPageData {
  pages: number[][];
  horizontal?: boolean;
  pageData: {
    scale: number;
    rotationAngle: number;
    activePage: number;
    onViewportUpdated(page: number, viewport: number[]): void;
    setActivePage(page: number): void;
    onCopyAnnotation(annotation: IAnnotationEntity): void;
    onPageInViewport?(page: number, data: { isIntersecting?: boolean; intersectionRatio?: number }): void;
    getPageWidth(page: number): number;
    getPageHeight(page: number): number;
  };
}

interface IPageRenderer {
  index: number;
  style: AnyObject;
  data: IPageData;
}

const PageRenderer: FC<IPageRenderer> = ({ index, style, data }) => {
  const pagesToRender = useMemo(() => {
    return data.pages[index];
  }, [data.pages, index]);

  return (
    <div style={{ ...style }}>
      <div className={classNames('pages-container', { 'pages-container_horizontal': data.horizontal })}>
        {pagesToRender.map((pageNumber, index) => (
          <PdfPage key={index} pageNumber={pageNumber} {...data.pageData} />
        ))}
      </div>
    </div>
  );
};

export default memo(PageRenderer);
