import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { getAnnotationsList } from '../../../store/annotations/selectors';
import { getColorAndOpacity, pdfColorToRgb, rgbToPDFColor, stringToRGB } from '../../helpers/colorHelper';
import { initAnnotations } from '../../../store/annotations/actions';
import { rememberState } from '../../../store/historyStore/actions';
import { AnnotationType } from '../../../store/enums/annotationType';
import FavoritesColorsDialog from '../colorDropDown/FavoritesColorsDialog';
import { AnnotateActionType } from '../../../store/enums/annotateActionType';

interface IColorEditor {
  id: string | null;
  page?: number;
  onClose(): void;
}

const ColorEditor: FC<IColorEditor> = (props) => {
  const dispatch = useDispatch();
  const annotations = useSelector(getAnnotationsList);
  const annotation = useMemo(
    () => annotations.find((annotation) => annotation.id === props.id) || null,
    [annotations, props.id],
  );
  const color = useMemo(() => {
    return annotation ? pdfColorToRgb(...annotation.colorRGB, annotation.opacity) : undefined;
  }, [annotation]);
  const [oldColor, setOldColor] = useState<string | undefined>(color);
  const hideOpacity = useMemo(() => {
    return (
      !!annotation &&
      annotation.textMarkupType !== AnnotateActionType.Highlight &&
      annotation.annotationType !== AnnotationType.Text
    );
  }, [annotation]);
  const visible = useMemo(() => !!annotation && annotation.page + 1 === props.page, [annotation, props.page]);

  useEffect(
    useCallback(() => {
      setOldColor(color);
    }, [color]),
    [props.id],
  );

  const onColorChange = useCallback(
    (color: string, newOpacity?: number) => {
      if (!annotation) {
        return;
      }
      let newAnnotationList = [...annotations];
      newAnnotationList = newAnnotationList.map((item) => {
        if (item.id === annotation.id) {
          const [rgb, opacity] = getColorAndOpacity(color);
          return {
            ...item,
            colorRGB: rgbToPDFColor(stringToRGB(rgb)),
            opacity: _.isNumber(newOpacity) ? newOpacity : opacity,
            color,
          };
        }
        return item;
      });
      dispatch(initAnnotations(newAnnotationList));
    },
    [annotations, annotation],
  );

  const onCancel = useCallback(() => {
    if (oldColor) {
      onColorChange(oldColor);
    }
    props.onClose();
  }, [oldColor]);

  const onSave = useCallback(() => {
    dispatch(rememberState(annotations));
    props.onClose();
  }, [annotations]);

  return (
    <FavoritesColorsDialog
      color={color}
      onChange={onColorChange}
      onOk={onSave}
      onCancel={onCancel}
      visible={visible}
      hideOpacity={hideOpacity}
    />
  );
};

export default ColorEditor;
