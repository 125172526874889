import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { IAnnotationEntity } from '../../../../services/interfaces/IAnnotation';
import {
  getAnnotations,
  getAnnotationsList,
  getNotDeletedAnnotationsList,
} from '../../../../store/annotations/selectors';
import AnnotationCard from '../../../components/annotationCard/AnnotationCard';
import { findAnnotationIndex } from '../../../../services/annotationService';
import { setActiveAnnotation } from '../../../../store/annotations/actions';
import AnnotationsGroupTitle from '../annotationsGroupTitle/AnnotationsGroupTitle';
import { AnnotationType } from '../../../../store/enums/annotationType';

interface IAnnotationsPanelListProps {
  onUpdate?(): void;
}

const AnnotationsPanelList: FC<IAnnotationsPanelListProps> = (props) => {
  const dispatch = useDispatch();
  const annotations: IAnnotationEntity[] = useSelector(getNotDeletedAnnotationsList);
  const allAnnotations: IAnnotationEntity[] = useSelector(getAnnotationsList);
  const { activeAnnotation } = useSelector(getAnnotations);
  const pages = useMemo(() => _.uniq(annotations.map((annotation) => annotation.page)), [annotations]);
  const activePage = useMemo(() => allAnnotations[activeAnnotation]?.page, [activeAnnotation, allAnnotations]);
  const [expandedPages, setExpandedPages] = useState<number[]>([]);
  const onPageToggle = useCallback((activeKey) => {
    props.onUpdate?.();
    setExpandedPages(activeKey.map((key: string) => parseInt(key)));
  }, []);
  const onCardClick = useCallback(
    (annotation: IAnnotationEntity) => () => {
      props.onUpdate?.();
      dispatch(setActiveAnnotation(findAnnotationIndex(allAnnotations, annotation)));
    },
    [allAnnotations],
  );
  useEffect(() => {
    props.onUpdate?.();
  }, [annotations]);
  useEffect(
    useCallback(() => {
      if (_.isNull(activeAnnotation) || !allAnnotations[activeAnnotation]) {
        return;
      }
      if (!expandedPages.includes(allAnnotations[activeAnnotation].page)) {
        setExpandedPages([...expandedPages, allAnnotations[activeAnnotation].page]);
      }
    }, [activeAnnotation, allAnnotations, expandedPages]),
    [activeAnnotation],
  );
  return (
    <div className="annotations-panel-list">
      {pages.length ? (
        <Collapse ghost activeKey={expandedPages} onChange={onPageToggle}>
          {pages
            .sort((page1, page2) => page1 - page2)
            .map((page) => (
              <Collapse.Panel
                header={<AnnotationsGroupTitle pageIndex={page} active={activePage === page} />}
                key={page}
              >
                {annotations
                  .filter(
                    (annotation) =>
                      annotation.page === page &&
                      (annotation.annotationType !== AnnotationType.Text || !annotation.idToReply),
                  )
                  .map((annotation, index) => (
                    <AnnotationCard
                      key={index}
                      annotation={annotation}
                      active={findAnnotationIndex(allAnnotations, annotation) === activeAnnotation}
                      replies={annotations.filter((reply) => reply.idToReply === annotation.id)}
                      onClick={onCardClick(annotation)}
                    />
                  ))}
              </Collapse.Panel>
            ))}
        </Collapse>
      ) : null}
    </div>
  );
};

export default AnnotationsPanelList;
