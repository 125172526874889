import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Select as AntSelect } from 'antd';
import { useKeyPress } from 'react-use';

import Button from '../../../components/button/Button';
import Select from '../../../components/select/Select';
import { ButtonType } from '../../../components/button/enums/buttonType';
import { SCALE_OPTIONS, SCALE_SELECT_WIDTH } from '../constants';
import { getScale, getPage } from '../../../../store/viewerSettings/selectors';
import { setScale } from '../../../../store/viewerSettings/actions';
import { SelectSize } from '../../../components/select/enums/SelectSize';
import { CustomScale } from '../toolbarLeft/enums/customScale';
import { fitToPage, fitToWidth } from './scaleHelper';
import { isHorizontalMode, getPageLayout, isSinglePageView } from '../../../../store/viewerSettings/selectors';
import { TOOLTIPS_MAP } from '../../../constants/application';
import { zoomIn, zoomOut } from '../../../helpers/pdfViewerHelper';

import { ReactComponent as Zoomout } from '../../../../assets/Zoomout.svg';
import { ReactComponent as ZoomIN } from '../../../../assets/ZoomIN.svg';
import { ReactComponent as FitToPage } from '../../../../assets/Menubar_FittoPage.svg';
import { ReactComponent as FitToWidth } from '../../../../assets/Menubar_FittoWidth.svg';

import './ScaleSelect.less';

const ScaleSelect: FC = () => {
  const dispatch = useDispatch();
  const scale: number = useSelector(getScale);
  const page: number = useSelector(getPage);
  const singlePageView: boolean = useSelector(isSinglePageView);
  const horizontalMode: boolean = useSelector(isHorizontalMode);
  const pageLayout: string = useSelector(getPageLayout);
  const zoomInDisabled = useMemo(() => scale >= SCALE_OPTIONS[SCALE_OPTIONS.length - 1].value, [scale]);
  const zoomOutDisabled = useMemo(() => scale <= SCALE_OPTIONS[0].value, [scale]);
  const isCtrlPressed = useKeyPress('Control')[0];
  const isMetaPressed = useKeyPress('Meta')[0];
  const isEqualPressed = useKeyPress('=')[0];
  const isMinusPressed = useKeyPress('-')[0];

  const { Option, OptGroup } = AntSelect;

  useEffect(
    useCallback(() => {
      if (isEqualPressed && (isCtrlPressed || isMetaPressed)) {
        dispatch(setScale(zoomIn(scale)));
      }
    }, [isEqualPressed, isCtrlPressed, scale]),
    [isEqualPressed, isCtrlPressed],
  );
  useEffect(
    useCallback(() => {
      if (isMinusPressed && (isCtrlPressed || isMetaPressed)) {
        dispatch(setScale(zoomOut(scale)));
      }
    }, [isMinusPressed, isCtrlPressed, scale]),
    [isMinusPressed, isCtrlPressed],
  );

  const handleScaleChange = useCallback(
    (value: number | string) => {
      if (value === CustomScale.fitToWidth) {
        const newValue = fitToWidth(scale, page, pageLayout, horizontalMode);
        return dispatch(setScale(+newValue));
      }
      if (value === CustomScale.fitToPage) {
        const newValue = fitToPage(scale, page, pageLayout, horizontalMode);
        return dispatch(setScale(+newValue));
      }
      dispatch(setScale(+value));
    },
    [scale, page, pageLayout, horizontalMode, singlePageView],
  );

  const handleZoomIn = useCallback(() => {
    if (zoomInDisabled) {
      return;
    }
    dispatch(setScale(zoomIn(scale)));
  }, [zoomInDisabled, scale]);

  const handleZoomOut = useCallback(() => {
    if (zoomOutDisabled) {
      return;
    }
    dispatch(setScale(zoomOut(scale)));
  }, [zoomOutDisabled, scale]);

  return (
    <div className="scale-select">
      <Select
        onChange={handleScaleChange}
        value={scale}
        size={SelectSize.Small}
        dropdownStyle={{ width: 165 }}
        dropdownMatchSelectWidth={false}
        listHeight={385}
        width={SCALE_SELECT_WIDTH}
      >
        <Option className="option-with-icon" value={CustomScale.fitToWidth}>
          <div className="scale-select-with-icon">
            <FitToWidth className="scale-select-icon" />
            Fit to Width
          </div>
        </Option>
        <Option className="option-with-icon" value={CustomScale.fitToPage}>
          <div className="scale-select-with-icon">
            <FitToPage className="scale-select-icon" />
            Fit to Page
          </div>
        </Option>
        {!SCALE_OPTIONS.find((option) => option.value === scale) ? (
          <Option className="hidden-option" value={scale}>
            {`${Math.floor(scale * 100)}%`}
          </Option>
        ) : null}
        <OptGroup label={<Divider style={{ margin: '5px 0' }} />}>
          {SCALE_OPTIONS.map((option, i) => (
            <Option key={i} value={option.value}>
              {option.title}
            </Option>
          ))}
        </OptGroup>
      </Select>
      <Button
        disabled={zoomOutDisabled}
        type={ButtonType.Text}
        icon={useMemo(
          () => (
            <Zoomout className="assets-icon assets-icon_center" />
          ),
          [],
        )}
        tooltip={TOOLTIPS_MAP.TOOLBAR.ZOOM_OUT}
        onClick={handleZoomOut}
      />
      <Button
        disabled={zoomInDisabled}
        type={ButtonType.Text}
        icon={useMemo(
          () => (
            <ZoomIN className="assets-icon assets-icon_center" />
          ),
          [],
        )}
        tooltip={TOOLTIPS_MAP.TOOLBAR.ZOOM_IN}
        onClick={handleZoomIn}
      />
    </div>
  );
};

export default ScaleSelect;
