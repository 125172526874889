export enum DefaultColors {
  Highlight = 'rgba(255,209,0,0.4)',
  Strikeout = 'rgba(255,20,20,1)',
  Underline = 'rgba(255,20,20,1)',
  Squiggly = 'rgba(255,20,20,1)',
  FreeText = 'rgba(0,0,0,1)',
  StickyNote = 'rgba(255,197,70)',
  Shape = 'rgba(255,255,255,1)',
  Image = 'rgba(255,255,255,1)',
  Drawing = 'rgba(255,20,20,1)',
  Eraser = 'rgba(255,255,255,1)',
}
