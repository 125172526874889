import { fabric } from 'fabric';
import _ from 'lodash';
import React from 'react';

import { TBbox } from '../types/bbox';
import { IAnnotationEntity, IRectangle } from '../../services/interfaces/IAnnotation';
import { DEFAULT_ROTATION_ANGLE_DELTA, MAX_ROTATION_ANGLE } from '../constants/application';
import { convertY } from './fabricCanvasHelper';
import { AnnotationType } from '../../store/enums/annotationType';
import { ContextMenuItemType } from '../components/canvasContextMenuZone/enum/ContextMenuItemType';
import { ContextMenuItem } from '../components/canvasContextMenuZone/interfaces/CanvasContextMenu';
import { adjustRectangleWithViewport, rotateRectangle } from '../../services/annotationService';

export const CONTEXT_MENU_PADDING = 10;
export const CONTEXT_MENU_ITEM_WIDTH = 40;
export const CONTEXT_MENU_DIVIDER_WIDTH = 11;
export const CONTEXT_MENU_HEIGHT = 46;

export const isRectsCross = (rect1: TBbox, rect2: TBbox): boolean => {
  return !(rect1[0] > rect2[2] || rect1[2] < rect2[0] || rect1[1] > rect2[3] || rect1[3] < rect2[1]);
};

export const concatBboxes = (bboxes: TBbox[], invert = false): TBbox => {
  let resultBbox: TBbox = bboxes[0];
  bboxes.forEach((bbox) => {
    resultBbox = [
      resultBbox[0] < bbox[0] ? resultBbox[0] : bbox[0],
      invert ? (resultBbox[1] > bbox[1] ? resultBbox[1] : bbox[1]) : resultBbox[1] < bbox[1] ? resultBbox[1] : bbox[1],
      resultBbox[2] > bbox[2] ? resultBbox[2] : bbox[2],
      invert ? (resultBbox[3] < bbox[3] ? resultBbox[3] : bbox[3]) : resultBbox[3] > bbox[3] ? resultBbox[3] : bbox[3],
    ];
  });
  return resultBbox;
};

export const concatFloatBboxes = (bboxes: TBbox[], floatNumber: number): TBbox[] => {
  const annotationBboxes: TBbox[] = [];

  bboxes.forEach((bbox) => {
    if (!bbox) return;
    if (!annotationBboxes.length) {
      return annotationBboxes.push(bbox);
    }
    const lastBbox = annotationBboxes[annotationBboxes.length - 1];
    if (Math.round(bbox[3] * floatNumber) === Math.round(lastBbox[3] * floatNumber)) {
      annotationBboxes[annotationBboxes.length - 1] = concatBboxes([lastBbox, bbox]);
    } else {
      annotationBboxes.push(bbox);
    }
  });

  return annotationBboxes;
};

export const getObjectId = (object: fabric.Object): string => {
  return object['annot_id' as keyof fabric.Object] || '';
};

export const setObjectId = (object: fabric.Object, id: string): void => {
  object['annot_id' as keyof fabric.Object] = id;
};

export const isObjectLocked = (object: fabric.Object): boolean => {
  return !!object['annot_locked' as keyof fabric.Object];
};

export const setObjectLocked = (object: fabric.Object, isLocked: boolean): void => {
  object['annot_locked' as keyof fabric.Object] = isLocked;
};

export const setTextBoxRectangle = (textbox: fabric.Textbox, rectangle: IRectangle): void => {
  textbox['rectangle' as keyof fabric.Object] = rectangle;
};

export const setImageFile = (image: fabric.Image, file: string): void => {
  image['file' as keyof fabric.Object] = file;
};

export const getImageFile = (image: fabric.Image): string => {
  return image['file' as keyof fabric.Object];
};

export const getObjectAnnotationType = (object: fabric.Object): string => {
  return object['annot_type' as keyof fabric.Object] || '';
};

export const setObjectAnnotationType = (object: fabric.Object, type: string): void => {
  object['annot_type' as keyof fabric.Object] = type;
};

export const swapWidthAndHeight = (rotationAngle: number): boolean => (rotationAngle / 90) % 2 !== 0;

export const updateRotationAngle = (oldRotationAngle: number, direction: number): number => {
  const newRotationAngle = (oldRotationAngle + DEFAULT_ROTATION_ANGLE_DELTA * direction) % MAX_ROTATION_ANGLE;
  return newRotationAngle < 0 ? newRotationAngle + MAX_ROTATION_ANGLE : newRotationAngle;
};

export const rotatePointOnCanvas = (
  rotationAngle: number,
  point: number[],
  viewport: number[],
  reverse = false,
): number[] => {
  const firstLeft = reverse && swapWidthAndHeight(rotationAngle) ? viewport[1] : viewport[0];
  const firstTop = reverse && swapWidthAndHeight(rotationAngle) ? viewport[0] : viewport[1];
  point[0] += firstLeft;
  point[1] = convertY(point[1], viewport, reverse ? rotationAngle : 0) + firstTop;
  point = rotatePoint(rotationAngle, point, viewport, reverse);
  const secondLeft = !reverse && swapWidthAndHeight(rotationAngle) ? viewport[1] : viewport[0];
  const secondTop = !reverse && swapWidthAndHeight(rotationAngle) ? viewport[0] : viewport[1];
  point[0] -= secondLeft;
  point[1] = convertY(point[1] - secondTop, viewport, !reverse ? rotationAngle : 0);
  return point;
};

export const rotatePointsOnCanvas = (
  rotationAngle: number,
  points: number[],
  viewport: number[],
  reverse = false,
): number[] => {
  return _.chain(points)
    .chunk(2)
    .map((point) => rotatePointOnCanvas(rotationAngle, point, viewport, reverse))
    .flatten()
    .value();
};

export const rotatePoint = (rotationAngle: number, point: number[], viewport: number[], reverse = false): number[] => {
  const rad = (rotationAngle * Math.PI) / 180;
  let x = point[0] * Math.cos(rad) + point[1] * Math.sin(rad);
  let y = -point[0] * Math.sin(rad) + point[1] * Math.cos(rad);
  switch (rotationAngle) {
    case 90:
      if (!reverse) {
        y += viewport[2] + viewport[0];
      } else {
        y += viewport[3] + viewport[1];
      }
      break;
    case 180:
      x += viewport[2] + viewport[0];
      y += viewport[3] + viewport[1];
      break;
    case 270:
      if (!reverse) {
        x += viewport[3] + viewport[1];
      } else {
        x += viewport[2] + viewport[0];
      }
      break;
    default:
      break;
  }
  return [x, y];
};

export const rotatePoints = (
  rotationAngle: number,
  points: number[],
  viewport: number[],
  reverse = false,
): number[] => {
  return _.chain(points)
    .chunk(2)
    .map((point) => rotatePoint(rotationAngle, point, viewport, reverse))
    .flatten()
    .value();
};

export const rotateViewport = (rotationAngle: number, viewport: number[]): number[] => {
  if ((rotationAngle / 90) % 2 === 0) {
    return [...viewport];
  }
  return [viewport[1], viewport[0], viewport[3], viewport[2]];
};

export const getReverseRotationAngle = (rotationAngle: number): number => {
  return (MAX_ROTATION_ANGLE - rotationAngle) % MAX_ROTATION_ANGLE;
};

export const getRotatedUnderlinePointsByBbox = (bbox: number[], rotationAngle: number): number[] => {
  switch (rotationAngle) {
    case 90:
      return [bbox[0], bbox[1], bbox[0], bbox[3]];
    case 180:
      return [bbox[0], bbox[1], bbox[2], bbox[1]];
    case 270:
      return [bbox[2], bbox[1], bbox[2], bbox[3]];
    case 0:
    default:
      return [bbox[0], bbox[3], bbox[2], bbox[3]];
  }
};

export const getRotatedStrikeoutPointsByBbox = (bbox: number[], rotationAngle: number): number[] => {
  switch (rotationAngle) {
    case 90:
    case 270:
      return [(bbox[0] + bbox[2]) / 2, bbox[1], (bbox[0] + bbox[2]) / 2, bbox[3]];
    case 0:
    case 180:
    default:
      return [bbox[0], (bbox[1] + bbox[3]) / 2, bbox[2], (bbox[1] + bbox[3]) / 2];
  }
};

export const checkIfNodeIsInsideContextMenu = (node: Node, page: number): boolean => {
  const contextMenu = document.querySelector(`.canvas-context-menu__menu.page_${page}`);
  const subMenus = document.querySelectorAll('.canvas-context-menu__subItem');
  const colorDialog = document.querySelector('.favorites-colors-dialog');
  const colorDialogWrap = document.querySelector('.favorites-colors-dialog-wrap');
  const ignoreOnDeselectElements = document.querySelectorAll('.ignore-on-deselect');
  return (
    (contextMenu && contextMenu.contains(node)) ||
    [...subMenus].some((subMenu) => subMenu.contains(node)) ||
    (colorDialog && colorDialog.contains(node)) ||
    (colorDialogWrap && colorDialogWrap.contains(node)) ||
    [...ignoreOnDeselectElements].some((el) => el.contains(node))
  );
};

export const checkIfNodeIsInsideAnnotationCard = (node: Node): boolean => {
  const annotationCards = document.querySelectorAll('.annotation-card');
  return [...annotationCards].some((el) => el.contains(node));
};

export const getPointsBBox = (paths: number[][]): { minX: number; minY: number; maxX: number; maxY: number } => {
  let maxX = Number.MIN_SAFE_INTEGER;
  let maxY = Number.MIN_SAFE_INTEGER;
  let minX = Number.MAX_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  _.flatten(paths).forEach((point, index) => {
    maxX = index % 2 === 0 ? Math.max(point, maxX) : maxX;
    maxY = index % 2 === 0 ? maxY : Math.max(point, maxY);
    minX = index % 2 === 0 ? Math.min(point, minX) : minX;
    minY = index % 2 === 0 ? minY : Math.min(point, minY);
  });
  return { minX, minY, maxX, maxY };
};

/**
 * Calculates values to scale and place the annotation so it fits the viewport
 * @param points Annotation points
 * @param x Original x to place annotation
 * @param y Original y to place annotation
 * @param viewport
 * @param rotationAngle
 * @returns {Array} Array containing the scale value as the first element and the point as the second
 */
export const getScaleAndPointToFitViewport = (
  points: number[][],
  [x, y]: [number, number],
  viewport: number[],
  rotationAngle: number,
): [number, [number, number]] => {
  const { minX, minY, maxX, maxY } = getPointsBBox(points);
  let width = Math.abs(maxX - minX);
  let height = Math.abs(maxY - minY);
  const canvasWidth = viewport[2] - viewport[0];
  const canvasHeight = viewport[3] - viewport[1];
  if (width < height && height > canvasHeight) {
    width = (canvasHeight * width) / height;
    height = canvasHeight;
  } else if (width >= height && width > canvasWidth) {
    height = (canvasWidth * height) / width;
    width = canvasWidth;
  }
  const [leftOffset, topOffset] = getOffsetByRotationAngle(width, height, rotationAngle);
  let newX = x + leftOffset;
  let newY = y + topOffset;
  if (x + leftOffset + width > canvasWidth) {
    newX = canvasWidth - width - leftOffset;
  }
  if (x + leftOffset < 0) {
    newX = 0;
  }
  if (y + topOffset + height > canvasHeight) {
    newY = canvasHeight - height - topOffset;
  }
  if (y + topOffset < 0) {
    newY = 0;
  }

  return [width / Math.abs(maxX - minX), [newX, newY]];
};

export const getOffsetByRotationAngle = (width: number, height: number, rotationAngle: number): [number, number] => {
  let leftOffset = 0;
  let topOffset = 0;
  switch (rotationAngle) {
    case 90:
      topOffset = -height;
      break;
    case 180:
      leftOffset = -width;
      topOffset = -height;
      break;
    case 270:
      leftOffset = -width;
      break;
    default:
      break;
  }
  return [leftOffset, topOffset];
};

/**
 * Updates all annotation points and coordinates to place annotation at [x, y]
 * @param annotation
 * @param x
 * @param y
 * @param viewport
 * @param rotationAngle
 */
export const moveAnnotationToPoint = (
  annotation: IAnnotationEntity,
  [x, y]: [number, number],
  viewport: number[],
  rotationAngle: number,
): IAnnotationEntity => {
  const newAnnotation = _.cloneDeep(annotation);
  const rectanglePoints = [
    [
      newAnnotation.rectangle.lowerLeftX,
      newAnnotation.rectangle.lowerLeftY,
      newAnnotation.rectangle.upperRightX,
      newAnnotation.rectangle.upperRightY,
    ],
  ];
  let [scale, coordinates] = getScaleAndPointToFitViewport(rectanglePoints, [x, y], viewport, rotationAngle);
  newAnnotation.rectangle = {
    upperRightX:
      Math.abs(newAnnotation.rectangle.upperRightX - newAnnotation.rectangle.lowerLeftX) * scale +
      coordinates[0] +
      viewport[0],
    upperRightY:
      convertY(
        coordinates[1] + Math.abs(newAnnotation.rectangle.lowerLeftY - newAnnotation.rectangle.upperRightY) * scale,
        viewport,
      ) + viewport[1],
    lowerLeftX: coordinates[0] + viewport[0],
    lowerLeftY: convertY(coordinates[1], viewport) + viewport[1],
  };

  if (newAnnotation.annotationType === AnnotationType.Ink && newAnnotation.inkList) {
    [scale, coordinates] = getScaleAndPointToFitViewport(newAnnotation.inkList, [x, y], viewport, rotationAngle);
    const inkList = [...newAnnotation.inkList];
    const { minX, maxY } = getPointsBBox(inkList);
    newAnnotation.inkList = inkList.map((item) => {
      return item.map((point, index) => {
        if (index % 2) {
          return (point - maxY) * scale + convertY(coordinates[1], viewport) + viewport[1];
        } else {
          return (point - minX) * scale + coordinates[0] + viewport[0];
        }
      });
    });
  } else if (newAnnotation.annotationType === AnnotationType.Triangle && newAnnotation.vertices) {
    [scale, coordinates] = getScaleAndPointToFitViewport([newAnnotation.vertices], [x, y], viewport, rotationAngle);
    const vertices = [...newAnnotation.vertices];
    const annHeight = Math.abs(vertices[1] - vertices[3]) * scale;
    const annWidth = Math.abs(vertices[4] - vertices[0]) * scale;
    vertices[0] = coordinates[0] + viewport[0];
    vertices[2] = coordinates[0] + annWidth / 2 + viewport[0];
    vertices[3] = convertY(coordinates[1], viewport) + viewport[1];
    vertices[4] = coordinates[0] + annWidth + viewport[0];
    vertices[5] = vertices[1] = convertY(coordinates[1] + annHeight, viewport) + viewport[1];
    newAnnotation.vertices = vertices;
  } else if (newAnnotation.annotationType === AnnotationType.Line && newAnnotation.linePoints) {
    [scale, coordinates] = getScaleAndPointToFitViewport([newAnnotation.linePoints], [x, y], viewport, rotationAngle);
    let linePoints = [...newAnnotation.linePoints];
    const top = Math.max(linePoints[1], linePoints[3]);
    const left = Math.min(linePoints[2], linePoints[0]);
    linePoints = linePoints.map((point, index) => {
      if (index % 2) {
        return (point - top) * scale + convertY(coordinates[1], viewport) + viewport[1];
      }
      return (point - left) * scale + coordinates[0] + viewport[0];
    });
    newAnnotation.linePoints = linePoints;
  }

  return newAnnotation;
};

export const getEventPosition = (
  pageX: number,
  pageY: number,
  clickRef: React.RefObject<HTMLDivElement>,
): { x: number; y: number } | null => {
  if (!clickRef || !clickRef.current) {
    return null;
  }
  const { left, top } = clickRef.current.getBoundingClientRect();
  const posX = left + window.pageXOffset;
  const posY = top + window.pageYOffset;
  const x = pageX - posX;
  const y = pageY - posY;
  return { x, y };
};

export const getMenuWidth = (menu: ContextMenuItem[]): number => {
  return (
    2 * CONTEXT_MENU_PADDING +
    _.sum(
      menu.map((item) =>
        item.type === ContextMenuItemType.DIVIDER ? CONTEXT_MENU_DIVIDER_WIDTH : CONTEXT_MENU_ITEM_WIDTH,
      ),
    )
  );
};

export const adjustContextMenuPosition = (
  contextMenuPosition: { x: number; y: number } | null,
  clickZoneRef: React.RefObject<HTMLDivElement>,
  menuWidth: number,
): [number, number] | undefined => {
  if (!contextMenuPosition) {
    return undefined;
  }
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  if (!clickZoneRef || !clickZoneRef.current) {
    return [contextMenuPosition.x, contextMenuPosition.y];
  }
  const { left, top } = clickZoneRef.current.getBoundingClientRect();
  const posX = left + window.pageXOffset;
  const posY = top + window.pageYOffset;
  let x = contextMenuPosition.x;
  if (posX + contextMenuPosition.x + menuWidth > viewportWidth) {
    x = viewportWidth - menuWidth - posX;
  }
  let y = contextMenuPosition.y;
  if (posY + contextMenuPosition.y + CONTEXT_MENU_HEIGHT > viewportHeight) {
    y = viewportHeight - CONTEXT_MENU_HEIGHT - posY;
  }
  return [x, y];
};

export const getAnnotationPosition = (
  originalRectangle: IRectangle,
  viewport: number[],
  rotationAngle: number,
): { left: number; top: number; width: number; height: number } => {
  let rectangle = rotateRectangle(originalRectangle, viewport, rotationAngle, false);
  rectangle = adjustRectangleWithViewport(rectangle, viewport, false, rotationAngle);
  const width = Math.abs(originalRectangle.upperRightX - originalRectangle.lowerLeftX);
  const height = Math.abs(originalRectangle.upperRightY - originalRectangle.lowerLeftY);
  let leftOffset = 0;
  let topOffset = 0;
  if (rotationAngle == 90) {
    leftOffset = height;
  }
  if (rotationAngle == 180) {
    leftOffset = width;
    topOffset = height;
  }
  if (rotationAngle == 270) {
    topOffset = width;
  }
  return {
    left: rectangle.lowerLeftX + leftOffset,
    top: convertY(rectangle.upperRightY, viewport, rotationAngle) + topOffset,
    width,
    height,
  };
};

export const getTransformToRotate = (angle: number): string => {
  let transform = `rotate(${angle}deg)`;
  switch (angle) {
    case DEFAULT_ROTATION_ANGLE_DELTA:
      transform += ' translateY(-100%)';
      break;
    case 2 * DEFAULT_ROTATION_ANGLE_DELTA:
      transform += ' translateY(-100%) translateX(-100%)';
      break;
    case 3 * DEFAULT_ROTATION_ANGLE_DELTA:
      transform += ' translateX(-100%)';
      break;
    default:
      break;
  }
  return transform;
};
