import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import Button from '../button/Button';
import { ButtonType } from '../button/enums/buttonType';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';
import { DefaultShapeLineStyle } from '../../helpers/colorHelper';
import ShapeStyleDialog from '../shapeStyleDialog/ShapeStyleDialog';
import { MAX_LINE_WIDTH } from '../../constants/application';
import { AnnotationType } from '../../../store/enums/annotationType';

import './DrawStylePicker.less';

interface IDrawStylePicker {
  style?: IShapeStyle;
  icon: React.ReactNode;
  button?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onChange?(style: IShapeStyle): void;
  onSave?(style: IShapeStyle): void;
  onCancel?(style: IShapeStyle): void;
}

const DrawStylePicker: FC<IDrawStylePicker> = (props) => {
  const { style = DefaultShapeLineStyle } = props;
  const tooltip = 'Pick line style';
  const pickerRef = useRef(null);
  const [displayPicker, setDisplayPicker] = useState(false);
  const [prevStyle, setPrevStyle] = useState<IShapeStyle | undefined>();
  useEffect(() => {
    setPrevStyle(style);
  }, []);
  const togglePicker = useCallback(() => {
    setDisplayPicker(!displayPicker);
  }, [displayPicker]);
  const onSave = useCallback(() => {
    props.onSave?.(style);
    setDisplayPicker(false);
  }, [style]);
  const onCancel = useCallback(
    (style: IShapeStyle) => {
      if (props.onCancel) {
        props.onCancel?.(style);
      } else if (prevStyle) {
        props.onSave?.(prevStyle);
      }
      setDisplayPicker(false);
    },
    [prevStyle],
  );
  useClickAway(pickerRef, () => setDisplayPicker(false));
  return (
    <div className={`draw-style-picker ${props.className || ''}`}>
      <Button
        type={ButtonType.Text}
        icon={props.icon}
        onClick={togglePicker}
        disabled={props.disabled}
        tooltip={tooltip}
      />
      <ShapeStyleDialog
        styles={style}
        onStyleChange={props.onChange}
        onSave={onSave}
        onCancel={onCancel}
        visible={displayPicker}
        maxLineWidth={MAX_LINE_WIDTH[AnnotationType.Ink]}
        hideBackground
      />
    </div>
  );
};

export default DrawStylePicker;
