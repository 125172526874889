import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';

import './PageGap.less';

interface IPageGap {
  index: number;
  activeIndex?: number;
  touchPosition?: { x: number; y: number };
  onSelect(index: number): void;
}

const PageGap: FC<IPageGap> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useMemo(() => {
    return classNames('page-gap', {
      'page-gap_active': props.index === props.activeIndex,
    });
  }, [props.index, props.activeIndex]);

  useEffect(
    useCallback(() => {
      const rect = ref?.current?.getBoundingClientRect();
      if (!rect || !props.touchPosition) {
        return;
      }
      const { x, y } = props.touchPosition;
      const { left, top, right, bottom } = rect;
      if (left < x && right > x && top < y && bottom > y) {
        props.onSelect(props.index);
      }
    }, [props.touchPosition, ref, props.onSelect, props.index]),
    [props.touchPosition],
  );

  const onMouseEnter = useCallback(() => {
    props.onSelect(props.index);
  }, [props.onSelect, props.index]);

  return <div className={classes} ref={ref} onMouseEnter={onMouseEnter} onMouseOver={onMouseEnter} />;
};

export default memo(PageGap);
