import { fabric } from 'fabric';
import _ from 'lodash';

import { CustomAny } from '../shared/types/generics';
import { IShapeStyle } from '../store/interfaces/IShapeStyle';
import { colorToPDFColor, pdfColorToRgb, rgbAdjustOpacity } from '../shared/helpers/colorHelper';
import { IPathEvent, IDrawServiceProps, ICanvas } from './interfaces/IFabric';
import { IAnnotationInk } from './interfaces/IAnnotation';
import { convertY } from '../shared/helpers/fabricCanvasHelper';
import { AnnotationType } from '../store/enums/annotationType';
import {
  getObjectAnnotationType,
  getObjectId,
  getPointsBBox,
  getReverseRotationAngle,
  rotatePoint,
  rotatePoints,
  swapWidthAndHeight,
} from '../shared/helpers/canvasHelper';

const OBJECT_TYPES_AVAILABLE_FOR_REMOVE = ['group', 'path', 'arrowLine', 'ellipse', 'polygon', 'rect'];

export class FreeDrawingService {
  handleRemoveAnnotations: (ids: string[]) => void;
  getObjectsByIntersection: (objects: fabric.Object[], points: number[][], canvas?: fabric.Canvas) => fabric.Object[];
  _points: number[][] = [[]];
  _pathIndex = 0;
  _scale = 1;
  _isDrawingMode = false;
  _handleSavePath = this._savePath.bind(this);
  _handleEraserPathCreated = this._eraserPathCreated.bind(this);
  _left = Number.MAX_SAFE_INTEGER;
  _top = Number.MAX_SAFE_INTEGER;
  _right = Number.MIN_SAFE_INTEGER;
  _bottom = Number.MIN_SAFE_INTEGER;
  _color: string | null = null;
  _width: number | null = null;
  _opacity: number | null = null;
  _typesToRemove: string[] = [];
  _rotationAngle = 0;

  constructor(props: IDrawServiceProps) {
    this.handleRemoveAnnotations = props.handleRemoveAnnotations || (() => {});
    this._rotationAngle = props.rotationAngle;
    this.getObjectsByIntersection = props.getObjectsByIntersection || (() => []);
    this._typesToRemove = props.typesToRemove || [];
  }

  setScale(scale: number): void {
    this._scale = scale;
  }

  setRotateAngle(angle: number): void {
    this._rotationAngle = angle;
  }

  updateStyle(canvas: fabric.Canvas, style: IShapeStyle): void {
    this._color = style.lineColor;
    this._width = style.lineWidth;
    this._opacity = style.opacity;
    canvas.freeDrawingBrush.color = rgbAdjustOpacity(style.lineColor, style.opacity);
    canvas.freeDrawingBrush.width = style.lineWidth;
  }

  startFreeDrawing(canvas: fabric.Canvas, style: IShapeStyle): void {
    canvas.freeDrawingBrush.color = rgbAdjustOpacity(style.lineColor, style.opacity);
    canvas.freeDrawingBrush.width = style.lineWidth;
    canvas.isDrawingMode = true;
    this._color = style.lineColor;
    this._width = style.lineWidth;
    this._opacity = style.opacity;
    this._isDrawingMode = true;
    canvas.on('path:created', this._handleSavePath);
  }

  saveDrawings(viewport: number[], page: number): IAnnotationInk | null {
    const annotation = this._createInkAnnotation(viewport, page);
    this._points = [[]];
    this._pathIndex = 0;
    this._left = Number.MAX_SAFE_INTEGER;
    this._top = Number.MAX_SAFE_INTEGER;
    this._right = Number.MIN_SAFE_INTEGER;
    this._bottom = Number.MIN_SAFE_INTEGER;
    return annotation;
  }

  stopFreeDrawing(canvas: fabric.Canvas, viewport: number[], page: number): IAnnotationInk | null {
    canvas.isDrawingMode = false;
    canvas.off('path:created', this._handleSavePath);
    const annotation = this._createInkAnnotation(viewport, page);
    this._points = [[]];
    this._pathIndex = 0;
    this._left = Number.MAX_SAFE_INTEGER;
    this._top = Number.MAX_SAFE_INTEGER;
    this._right = Number.MIN_SAFE_INTEGER;
    this._bottom = Number.MIN_SAFE_INTEGER;
    this._isDrawingMode = false;
    return annotation;
  }

  startEraserDrawing(canvas: fabric.Canvas): void {
    canvas.freeDrawingBrush.color = 'rgba(0, 0, 0, 0.25)';
    canvas.freeDrawingBrush.width = 10;
    canvas.isDrawingMode = true;
    canvas.on('path:created', this._handleEraserPathCreated);
  }

  stopEraserDrawing(canvas: fabric.Canvas): void {
    canvas.isDrawingMode = false;
    canvas.off('path:created', this._handleEraserPathCreated);
  }

  setTypesToRemove(types: string[]): void {
    this._typesToRemove = types;
  }

  createInkAnnotationObject(object: fabric.Group, page: number, viewport: number[]): IAnnotationInk | null {
    object.setCoords();
    object.getObjects().forEach((path) => path.setCoords());
    const paths = object.getObjects('path');
    const strokeWidth = paths[0].strokeWidth || 0;
    const scaleX = object.scaleX || 1;
    const scaleY = object.scaleY || 1;
    const groupLeft = (object.left || 0) + (object.flipX ? ((object.width || 0) - strokeWidth) * scaleX : 0);
    const groupTop = (object.top || 0) + (object.flipY ? ((object.height || 0) - strokeWidth) * scaleY : 0);
    const flipX = object.flipX ? -1 : 1;
    const flipY = object.flipY ? -1 : 1;
    const left = swapWidthAndHeight(this._rotationAngle) ? viewport[1] : viewport[0];
    const top = swapWidthAndHeight(this._rotationAngle) ? viewport[0] : viewport[1];
    const points = paths
      .map((path) =>
        (path as fabric.Path).path ? this._parseSvgPoints((path as fabric.Path).path as CustomAny[]) : [],
      )
      .filter((path) => path.length !== 0)
      .map((path) =>
        _.chain(path)
          .chunk(2)
          .map((points) => {
            return rotatePoint(
              getReverseRotationAngle(this._rotationAngle),
              [
                points[0] * flipX * scaleX + groupLeft + left,
                convertY(points[1] * flipY * scaleY + groupTop, viewport, this._rotationAngle) + top,
              ],
              viewport,
              true,
            );
          })
          .flatten()
          .value(),
      );
    if (points.length === 0) {
      return null;
    }
    const { minX, minY, maxX, maxY } = getPointsBBox(points);
    const [color, opacity] = colorToPDFColor(paths[0].stroke);
    const annotation: IAnnotationInk = {
      annotationType: AnnotationType.Ink,
      rectangle: {
        lowerLeftX: minX,
        upperRightX: maxX,
        lowerLeftY: minY,
        upperRightY: maxY,
      },
      colorRGB: color,
      opacity: opacity,
      width: paths[0].strokeWidth,
      inkList: points,
      page: page - 1,
    };
    const id = getObjectId(object);
    if (id) {
      annotation.id = id;
    }
    return annotation;
  }

  createPathObject(annotation: IAnnotationInk, viewport: number[]): fabric.Group | null {
    if (!annotation.inkList || annotation.inkList.length === 0) {
      return null;
    }
    const svgPaths: string[] = [];
    const paths = annotation.inkList
      ? annotation.inkList.map((path) =>
          _.chain(path)
            .chunk(2)
            .map((point) => rotatePoint(this._rotationAngle, [point[0], point[1]], viewport))
            .flatten()
            .value(),
        )
      : [];
    const { minX, maxY } = getPointsBBox(paths);
    const left = swapWidthAndHeight(this._rotationAngle) ? viewport[1] : viewport[0];
    const top = swapWidthAndHeight(this._rotationAngle) ? viewport[0] : viewport[1];
    paths
      .map((path) => path.map((point, index) => (index % 2 === 0 ? point - minX : maxY - point)))
      .forEach((path) => {
        svgPaths.push(` M ${path[0]} ${path[1]}`);
        _.chunk(path.slice(2), 2).forEach((point) => {
          svgPaths[svgPaths.length - 1] += ` L ${point[0]} ${point[1]}`;
        });
      });
    const group = new fabric.Group(
      svgPaths.map(
        (path) =>
          new fabric.Path(path, {
            stroke: pdfColorToRgb(...annotation.colorRGB, annotation.opacity),
            strokeWidth: annotation.width,
            fill: 'transparent',
            strokeLineCap: 'square',
            strokeLineJoin: 'miter',
            strokeUniform: true,
          }),
      ),
      {
        left: minX - left,
        top: convertY(maxY - top, viewport, this._rotationAngle),
      },
    );
    group.set({
      borderColor: '#ffc546',
      borderDashArray: [6, 6],
      borderOpacityWhenMoving: 1,
      borderScaleFactor: 2,
      cornerColor: '#ffffff',
      cornerStrokeColor: '#ffc546',
      transparentCorners: false,
      lockUniScaling: true,
      padding: 2,
    });
    group.setControlsVisibility({
      mtr: false,
      mr: false,
      ml: false,
      mt: false,
      mb: false,
    });
    return group;
  }

  _savePath(event: IPathEvent): void {
    const path = event.path;
    if (path) {
      this._points[this._pathIndex] = path.path ? this._parseSvgPoints(path.path) : [];
      const left = path.left || 0;
      const top = path.top || 0;
      const right = left + (path.width || 0);
      const bottom = top + (path.height || 0);
      this._left = this._left > left ? left : this._left;
      this._top = this._top > top ? top : this._top;
      this._right = this._right < right ? right : this._right;
      this._bottom = this._bottom < bottom ? bottom : this._bottom;
      this._pathIndex++;
    }
  }

  _eraserPathCreated(event: IPathEvent): void {
    const path = event.path;
    if (path) {
      const coordinates = path.path ? this._parseSvgPoints(path.path, 3) : [];
      const points = _.chunk(coordinates, 2);
      const canvas: ICanvas | undefined = path.canvas;
      canvas?.remove(path);
      const objects = [...(canvas?.getObjects() || [])].filter(
        (object) =>
          OBJECT_TYPES_AVAILABLE_FOR_REMOVE.includes(object.type || '') &&
          this._typesToRemove.includes(getObjectAnnotationType(object)),
      );
      const objectsToRemove = [...(this.getObjectsByIntersection(objects, points, canvas) || [])];
      const idsToRemove = objectsToRemove.map((object: fabric.Object) => getObjectId(object));
      canvas?._discardActiveObject?.();
      const selection = new fabric.ActiveSelection(objectsToRemove, {
        canvas: canvas,
      });
      selection.set({
        borderColor: 'transparent',
      });
      selection.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false,
        mtr: false,
      });
      canvas?._setActiveObject(selection);
      canvas?.requestRenderAll();
      this.handleRemoveAnnotations(idsToRemove);
    }
  }

  _createInkAnnotation(viewport: number[], page: number): IAnnotationInk | null {
    if (_.isEqual(this._points, [[]]) || _.isNull(this._color) || _.isNull(this._width) || _.isNull(this._opacity)) {
      return null;
    }
    const left = swapWidthAndHeight(this._rotationAngle) ? viewport[1] : viewport[0];
    const top = swapWidthAndHeight(this._rotationAngle) ? viewport[0] : viewport[1];
    const points = this._points
      .filter((path) => path.length !== 0)
      .map((path) =>
        _.chain(path)
          .chunk(2)
          .map((point) =>
            rotatePoint(
              getReverseRotationAngle(this._rotationAngle),
              [
                point[0] + left - (this._width || 0) / 2,
                convertY(point[1] - (this._width || 0) / 2, viewport, this._rotationAngle) + top,
              ],
              viewport,
              true,
            ),
          )
          .flatten()
          .value(),
      );
    if (points.length === 0) {
      return null;
    }
    const rectanglePoints = rotatePoints(
      getReverseRotationAngle(this._rotationAngle),
      [
        this._left,
        convertY(this._bottom, viewport, this._rotationAngle),
        this._right,
        convertY(this._top, viewport, this._rotationAngle),
      ],
      viewport,
      true,
    );
    return {
      annotationType: AnnotationType.Ink,
      rectangle: {
        lowerLeftX: Math.min(rectanglePoints[0], rectanglePoints[2]),
        upperRightX: Math.max(rectanglePoints[0], rectanglePoints[2]),
        lowerLeftY: Math.min(rectanglePoints[1], rectanglePoints[3]),
        upperRightY: Math.max(rectanglePoints[1], rectanglePoints[3]),
      },
      colorRGB: colorToPDFColor(this._color)[0],
      opacity: this._opacity,
      width: this._width,
      inkList: points,
      page: page - 1,
    };
  }

  _parseSvgPoints(path: CustomAny[], maxSegmentLength = 5): number[] {
    let prevPoints: [number, number] | null = null;
    return _.flatten(
      path.map((points: CustomAny[]) => {
        const nextPoints: [number, number] = [points[points.length - 2], points[points.length - 1]];
        let additionalPoints: number[] = [];
        if (prevPoints && prevPoints.length === 2 && points[0] === 'Q') {
          additionalPoints = this._splitSegment(
            prevPoints,
            nextPoints,
            [points[points.length - 4], points[points.length - 3]],
            maxSegmentLength,
          );
        }
        prevPoints = nextPoints;
        return [...additionalPoints, ...nextPoints];
      }),
    );
  }

  _getSegmentLength(start: [number, number], end: [number, number]): number {
    return Math.sqrt(Math.pow(start[0] - end[0], 2) + Math.pow(start[1] - end[1], 2));
  }

  _splitSegment(
    start: [number, number],
    end: [number, number],
    control: [number, number],
    maxLength: number,
  ): number[] {
    const length = this._getSegmentLength(start, end);
    if (length < maxLength) {
      return [...start, ...end];
    }
    const points: number[] = [];
    for (let t = 0; t < 1; t += (maxLength - 1) / length) {
      points.push((1 - t) * (1 - t) * start[0] + 2 * t * (1 - t) * control[0] + t * t * end[0]);
      points.push((1 - t) * (1 - t) * start[1] + 2 * t * (1 - t) * control[1] + t * t * end[1]);
    }
    points.push(...end);
    return points;
  }
}
