import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import useDrivePicker from 'react-google-drive-picker';
import classNames from 'classnames';
import _ from 'lodash';
import { useClickAway, usePrevious } from 'react-use';

import CanvasContextMenu from './canvasContextMenu/CanvasContextMenu';
import { getContextMenuActivePage } from '../../../store/viewerSettings/selectors';
import { setAppLoading, setContextMenuActivePage, setFileStatus } from '../../../store/viewerSettings/actions';
import { AnyObject } from '../../types/generics';
import { getActiveAction, getColor } from '../../../store/annotateSettings/selectors';
import { AnnotateActionType } from '../../../store/enums/annotateActionType';
import { getContrastColor, rgbAdjustOpacity, stringToRGB } from '../../helpers/colorHelper';
import {
  buildAnnotationContextMenuOptions,
  buildDefaultContextMenuItems,
  buildTextSelectionContextMenuOptions,
} from './helpers';
import { FileProgressStatus } from '../../../store/enums/fileProgressStatus';
import { fileToBase64 } from '../../helpers/fileHelper';
import {
  addColor,
  addColorByAction,
  setActiveAction,
  setColor,
  setColorByAction,
  setImageBase64,
} from '../../../store/annotateSettings/actions';
import {
  IMAGE_ACCEPT_TYPES,
  IMAGE_MIME_TYPES,
  REACT_APP_GOOGLE_DRIVE_API_KEY,
  REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
} from '../../constants/application';
import { getFileFromDisk, getOAuthToken } from '../../../services/gDriveService';
import { isSignedIn } from '../../../store/googleUser/selectors';
import { IAnnotationEntity } from '../../../services/interfaces/IAnnotation';
import { ShapeType } from '../../../store/enums/shapeType';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';
import {
  adjustContextMenuPosition,
  checkIfNodeIsInsideContextMenu,
  getEventPosition,
  getMenuWidth,
} from '../../helpers/canvasHelper';
import { getAnnotations } from '../../../store/annotations/selectors';

import './CanvasContextMenuZone.less';

interface CanvasContextMenuZoneProps {
  page: number;
  isAnnotationClicked: boolean;
  isSelectedTextClicked: boolean;
  copiedAnnotation: IAnnotationEntity | undefined;
  annotationData: IAnnotationEntity | undefined;
  shapeType: ShapeType | null;
  currentStyle: string | undefined;
  colorList: string[] | undefined;
  shapesList: IShapeStyle[] | undefined;
  drawingList: IShapeStyle[];
  drawStyleToAdd: IShapeStyle;
  annotateActionType: AnnotateActionType | undefined;
  activeQuadPoints: number[] | null;
  addFreeTextAnnotation({ x, y }: { x: number; y: number }): void;
  addStickyNoteAnnotation({ x, y }: { x: number; y: number }, color: string): void;
  onOpen({ x, y }: { x: number; y: number }): void;
  onSelectColor(color: string, index?: number): void;
  getContrastedColor(color: string): string;
  onSelectShapeStyle(shapeStyle: IShapeStyle): void;
  onDrawStyleSelect(shapeStyle: IShapeStyle, index: number): void;
  onAddShapeStyle(shapeStyle: IShapeStyle): void;
  onAddDrawStyle(shapeStyle: IShapeStyle): void;
  onAddColor(color: string): void;
  onPaste(copiedId: IAnnotationEntity | undefined, coords: { x: number; y: number }, activePage: number): void;
  onChangeColor(color: string): void;
  onCopy(annotationData?: IAnnotationEntity): void;
  onDelete(): void;
  onChangeDrawStyle(style: IShapeStyle): void;
  onCancelFreeTextChanges(): void;
  onCreateOutline(): void;
  createTextMarkupAnnotation(type: AnnotateActionType, color: string): void;
  clearClickedObjectState(): void;
}

const CanvasContextMenuZone: FC<CanvasContextMenuZoneProps> = (props) => {
  const dispatch = useDispatch();
  const [openPicker, data] = useDrivePicker();
  const [menuKey, setMenuKey] = useState(1);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const clickZoneRef = useRef<HTMLDivElement | null>(null);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);
  const contextMenuActivePage = useSelector(getContextMenuActivePage);
  const isGoogleSigned = useSelector(isSignedIn);
  const color: AnyObject = useSelector(getColor);
  const activeAction: AnnotateActionType | undefined = useSelector(getActiveAction);
  const { activeAnnotation } = useSelector(getAnnotations);
  const prevActiveAnnotation = usePrevious(activeAnnotation);
  const visible = useMemo(() => contextMenuActivePage === props.page, [contextMenuActivePage, props.page]);

  useEffect(
    useCallback(() => {
      if (_.isNull(activeAnnotation) && props.isAnnotationClicked) {
        onVisibleChange(false);
        props.clearClickedObjectState();
      }
    }, [activeAnnotation, prevActiveAnnotation, props.isAnnotationClicked]),
    [activeAnnotation],
  );

  const getContrastedColor = useCallback((color: string) => {
    const [r, g, b] = stringToRGB(color);
    return getContrastColor(r, g, b);
  }, []);
  useClickAway(clickZoneRef, (e) => {
    const isMenuClicked = e.target && checkIfNodeIsInsideContextMenu(e.target as Node, props.page);
    if (!isMenuClicked) {
      onVisibleChange(false);
      props.clearClickedObjectState();
    }
  });
  useClickAway(
    clickZoneRef,
    useCallback(
      (e: MouseEvent) => {
        const isMenuClicked = e.target && checkIfNodeIsInsideContextMenu(e.target as Node, props.page);
        if (!isMenuClicked && props.isSelectedTextClicked && props.activeQuadPoints) {
          onVisibleChange(true);
          setContextMenuPosition({ x: props.activeQuadPoints[0], y: props.activeQuadPoints[1] });
        }
      },
      [props.page, props.isSelectedTextClicked, props.activeQuadPoints],
    ),
    ['mouseup'],
  );
  const onVisibleChange = (visible: boolean) => {
    if (!visible) {
      props.clearClickedObjectState();
    }
    if (visible) {
      dispatch(setContextMenuActivePage({ triggerPage: props.page, value: props.page }));
    } else {
      dispatch(setContextMenuActivePage({ triggerPage: props.page, value: -1 }));
    }
  };
  const onItemClick = () => {
    onVisibleChange(false);
  };
  const onPaste = useCallback(() => {
    if (contextMenuPosition) {
      onVisibleChange(false);
      props.onPaste(props.copiedAnnotation, contextMenuPosition, contextMenuActivePage);
    }
  }, [contextMenuPosition, props.onPaste, props.copiedAnnotation, contextMenuActivePage]);

  const onCopy = useCallback(() => {
    onVisibleChange(false);
    if (props.annotationData) {
      props.onCopy(props.annotationData);
    }
  }, [props.annotationData, props.onCopy]);
  const onTextCopy = useCallback(() => {
    props.onCopy();
    onVisibleChange(false);
  }, [props.onCopy]);
  const onCancel = () => {
    onVisibleChange(false);
  };
  const onDelete = useCallback(() => {
    onVisibleChange(false);
    props.onDelete();
  }, [props.onDelete]);
  const onFreeTextClick = useCallback(() => {
    onVisibleChange(false);
    if (contextMenuPosition) {
      props.addFreeTextAnnotation(contextMenuPosition);
    }
  }, [props.addFreeTextAnnotation, contextMenuPosition]);
  const onFileUpload = useCallback(() => {
    onVisibleChange(false);
    inputRef.current?.click();
  }, [inputRef]);
  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      dispatch(setAppLoading(true));
      dispatch(setFileStatus(FileProgressStatus.UPLOADING));
      const file = e.target.files[0];
      const base64 = await fileToBase64(file);
      dispatch(setImageBase64(base64.join()));
      dispatch(setActiveAction(AnnotateActionType.Image));
      dispatch(setAppLoading(false));
      e.target.value = '';
    }
  };
  useEffect(() => {
    const getFile = async (): Promise<void> => {
      if (data && data.docs && data.docs.length > 0) {
        dispatch(setAppLoading(true));
        dispatch(setFileStatus(FileProgressStatus.UPLOADING));
        const fileId = data.docs[0].id;
        const file = await getFileFromDisk(fileId);
        const base64 = await fileToBase64(file);
        dispatch(setImageBase64(base64.join()));
        dispatch(setActiveAction(AnnotateActionType.Image));
        dispatch(setAppLoading(false));
      }
    };
    getFile();
  }, [data]);
  const onInitGooglePicker = () => {
    onVisibleChange(false);
    openPicker({
      clientId: REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
      developerKey: REACT_APP_GOOGLE_DRIVE_API_KEY,
      viewId: 'DOCS',
      token: getOAuthToken(),
      supportDrives: true,
      multiselect: false,
      viewMimeTypes: IMAGE_MIME_TYPES,
    });
  };
  const getActionColor = useCallback(
    (type: AnnotateActionType) => {
      return rgbAdjustOpacity(color[type].list[color[type].activeIndex]);
    },
    [color],
  );
  const onAddStickyNoteColorClick = useCallback(
    (color: string) => {
      onVisibleChange(false);
      if (contextMenuPosition) {
        props.addStickyNoteAnnotation(contextMenuPosition, color);
        dispatch(addColor(color));
      }
    },
    [contextMenuPosition, props.addStickyNoteAnnotation],
  );
  const onStickyNoteColorClick = useCallback(
    (color: string, index: number) => {
      onVisibleChange(false);
      if (contextMenuPosition) {
        props.addStickyNoteAnnotation(contextMenuPosition, color);
        dispatch(setColor(index));
      }
    },
    [contextMenuPosition, props.addStickyNoteAnnotation],
  );
  const onStickyNoteClick = useCallback(() => {
    onVisibleChange(false);
    if (contextMenuPosition) {
      props.addStickyNoteAnnotation(contextMenuPosition, getActionColor(AnnotateActionType.StickyNote));
    }
  }, [contextMenuPosition, props.addStickyNoteAnnotation, getActionColor]);
  const onAddTextMarkupColorClick = useCallback(
    (type: AnnotateActionType) => (color: string) => {
      onVisibleChange(false);
      if (contextMenuPosition) {
        props.createTextMarkupAnnotation(type, color);
        dispatch(addColorByAction({ actionType: type, color }));
      }
    },
    [contextMenuPosition, props.createTextMarkupAnnotation],
  );
  const onTextMarkupColorClick = useCallback(
    (type: AnnotateActionType) => (color: string) => {
      onVisibleChange(false);
      if (contextMenuPosition) {
        props.createTextMarkupAnnotation(type, color);
        dispatch(setColorByAction({ actionType: type, color }));
      }
    },
    [contextMenuPosition, props.createTextMarkupAnnotation],
  );
  const onTextMarkupClick = useCallback(
    (type: AnnotateActionType) => () => {
      onVisibleChange(false);
      if (contextMenuPosition) {
        props.createTextMarkupAnnotation(type, getActionColor(type));
      }
    },
    [contextMenuPosition, props.createTextMarkupAnnotation],
  );

  const getColorsList = useCallback(
    (type: AnnotateActionType) => {
      return color[type].list;
    },
    [color],
  );

  const handleContextMenuOpen = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (activeAction === AnnotateActionType.Eraser || activeAction === AnnotateActionType.Drawing) {
        return;
      }
      const position = getEventPosition(e.pageX, e.pageY, clickZoneRef);
      if (position) {
        setContextMenuPosition(position);
        onVisibleChange(true);
        props.onOpen(position);
      }
    },
    [clickZoneRef, activeAction, props.onOpen],
  );

  const handleMouseDown = useCallback(() => {
    if (visible && _.isNull(activeAnnotation)) {
      onVisibleChange(false);
      props.clearClickedObjectState();
    }
  }, [visible, activeAnnotation]);

  const handleMouseUp = useCallback(
    (e: React.MouseEvent) => {
      if (activeAction === AnnotateActionType.Eraser || activeAction === AnnotateActionType.Drawing) {
        return;
      }
      if (props.isAnnotationClicked || props.isSelectedTextClicked) {
        const position = getEventPosition(e.pageX, e.pageY, clickZoneRef);
        onVisibleChange(true);
        setContextMenuPosition(position);
      }
    },
    [visible, props.isAnnotationClicked, props.isSelectedTextClicked],
  );

  const onSelectColor = useCallback(
    (color: string, index: number) => {
      onVisibleChange(false);
      props.onSelectColor(color, index);
    },
    [props.onSelectColor],
  );

  const onSelectShapeStyle = useCallback(
    (style: IShapeStyle) => {
      onVisibleChange(false);
      props.onSelectShapeStyle(style);
    },
    [props.onSelectShapeStyle],
  );

  const onDrawStyleSelect = useCallback(
    (style: IShapeStyle, index: number) => {
      onVisibleChange(false);
      props.onDrawStyleSelect(style, index);
    },
    [props.onDrawStyleSelect],
  );

  const onAddColor = useCallback(
    (color: string) => {
      onVisibleChange(false);
      props.onAddColor(color);
    },
    [props.onAddColor],
  );

  const onAddShapeStyle = useCallback(
    (style: IShapeStyle) => {
      onVisibleChange(false);
      props.onAddShapeStyle(style);
    },
    [props.onAddShapeStyle],
  );

  const onAddDrawStyle = useCallback(
    (style: IShapeStyle) => {
      onVisibleChange(false);
      props.onAddDrawStyle(style);
    },
    [props.onAddDrawStyle],
  );

  const onCancelFreeTextChanges = useCallback(() => {
    onVisibleChange(false);
    props.onCancelFreeTextChanges();
  }, [props.onCancelFreeTextChanges]);

  const onCreateOutline = useCallback(() => {
    onVisibleChange(false);
    props.onCreateOutline();
  }, [props.onCreateOutline]);

  useEffect(() => {
    setMenuKey((menuKey) => menuKey + 1);
    if (visible && contextMenuPosition) {
      //props.onOpen(contextMenuPosition);
    }
  }, [visible, contextMenuPosition]);

  const defaultMenu = useMemo(
    () =>
      buildDefaultContextMenuItems({
        hasPaste: !!props.copiedAnnotation,
        disableGoogleDriveUpload: !isGoogleSigned,
        getColorsList,
        getActionColor,
        onPaste,
        onFreeTextClick,
        onImageFileUpload: onFileUpload,
        onInitGooglePicker,
        onImageClick: onItemClick,
        onStickyNoteClick,
        onAddStickyNoteColorClick,
        onStickyNoteColorClick,
      }),
    [
      isGoogleSigned,
      getColorsList,
      getActionColor,
      onFreeTextClick,
      onFileUpload,
      onAddStickyNoteColorClick,
      props.copiedAnnotation,
      contextMenuActivePage,
    ],
  );

  const annotationMenu = useMemo(
    () =>
      buildAnnotationContextMenuOptions({
        annotationType: props.annotationData?.annotationType,
        shapeType: props.shapeType,
        annotateActionType: props.annotateActionType,
        onChangeColor: props.onChangeColor,
        currentStyle: props.currentStyle,
        colorList: props.colorList,
        shapesList: props.shapesList,
        drawingList: props.drawingList,
        drawStyleToAdd: props.drawStyleToAdd,
        annotationId: props.annotationData?.id,
        onChangeDrawStyle: props.onChangeDrawStyle,
        onCancelFreeTextChanges,
        getContrastedColor,
        onCancel,
        onSelectColor,
        onAddColor,
        onSelectShapeStyle,
        onDrawStyleSelect,
        onAddShapeStyle,
        onAddDrawStyle,
        onCopy,
        onDelete,
      }),
    [
      props.annotationData,
      props.colorList,
      props.shapesList,
      props.annotateActionType,
      props.shapeType,
      props.onChangeDrawStyle,
      props.currentStyle,
      props.onChangeColor,
      onSelectColor,
      onAddColor,
      onSelectShapeStyle,
      onAddShapeStyle,
      onCopy,
      onDrawStyleSelect,
      onDelete,
      onAddDrawStyle,
      onCancel,
      onCancelFreeTextChanges,
    ],
  );

  const textSelectionMenu = useMemo(
    () =>
      buildTextSelectionContextMenuOptions({
        onCopy: onTextCopy,
        onCreateOutline,
        getColorsList,
        getActionColor,
        onAddTextMarkupColorClick,
        onTextMarkupColorClick,
        onTextMarkupClick,
      }),
    [
      onTextCopy,
      onCreateOutline,
      getColorsList,
      getActionColor,
      onAddTextMarkupColorClick,
      onTextMarkupColorClick,
      onTextMarkupClick,
    ],
  );

  const menu = useMemo(() => {
    if (!visible) {
      return [];
    }
    if (props.isAnnotationClicked) {
      return annotationMenu;
    }
    if (props.isSelectedTextClicked) {
      return textSelectionMenu;
    }
    return defaultMenu;
  }, [props.isAnnotationClicked, props.isSelectedTextClicked, annotationMenu, textSelectionMenu, defaultMenu, visible]);

  const menuWidth = useMemo(() => {
    return getMenuWidth(menu);
  }, [menu]);

  const offset: [number, number] | undefined = useMemo(() => {
    return adjustContextMenuPosition(contextMenuPosition, clickZoneRef, menuWidth);
  }, [contextMenuPosition, clickZoneRef, menuWidth]);

  return (
    <div onContextMenu={handleContextMenuOpen}>
      <Dropdown
        overlayClassName={classNames('context-menu-dropdown', { visible })}
        overlay={<CanvasContextMenu key={menuKey} page={props.page} menuItems={menu} />}
        destroyPopupOnHide
        visible={visible}
        align={{
          offset,
        }}
      >
        <div ref={clickZoneRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
          {props.children}
        </div>
      </Dropdown>
      <input
        ref={inputRef}
        className="fake-file-input"
        type="file"
        onChange={onFileChange}
        accept={IMAGE_ACCEPT_TYPES}
      />
    </div>
  );
};

export default CanvasContextMenuZone;
