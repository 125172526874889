export enum PointEndingStyle {
  Diamond = 'DIAMOND',
  Square = 'SQUARE',
  Circle = 'CIRCLE',
  OpenArrow = 'OPEN_ARROW',
  ClosedArrow = 'CLOSED_ARROW',
  Butt = 'BUTT',
  ROpenArrow = 'R_OPEN_ARROW',
  RClosedArrow = 'R_CLOSED_ARROW',
  Slash = 'SLASH',
  None = 'NONE',
}
