import { combineReducers } from '@reduxjs/toolkit';
import viewerSettings from './viewerSettings/reducers';
import annotateSettings from './annotateSettings/reducers';
import annotations from './annotations/reducers';
import googleUser from './googleUser/reducers';
import historyInfo from './historyStore/reducers';
import colors from './colorsStore/reducers';
import fonts from './fonts/reducers';
import pages from './pages/reducers';
import outlines from './outlines/reducers';

export default combineReducers({
  viewerSettings,
  annotateSettings,
  annotations,
  googleUser,
  historyInfo,
  colors,
  fonts,
  pages,
  outlines,
});
