import React, { FC, useCallback, useMemo, useState } from 'react';
import { CheckCircle, CircleFill } from 'react-bootstrap-icons';

import { DefaultShapeLineStyle, getContrastColor, rgbAdjustOpacity, stringToRGB } from '../../helpers/colorHelper';
import { ReactComponent as ZoomIN } from '../../../assets/ZoomIN.svg';
import { IShapeStyle } from '../../../store/interfaces/IShapeStyle';
import DrawStylePicker from '../drawStylePicker/DrawStylePicker';
import { MAX_COLOR_LIST_LENGTH } from '../../constants/application';

interface IDrawStylePickersContainer {
  activeStyles: IShapeStyle[];
  activeStyleIndex: number;
  onStyleChange(style: IShapeStyle): void;
  onStyleSelect(index: number): void;
  onAddStyle(style: IShapeStyle): void;
}

const DrawStylePickersContainer: FC<IDrawStylePickersContainer> = (props) => {
  const contrastedColor = useMemo(() => {
    const [r, g, b] = stringToRGB(props.activeStyles[props.activeStyleIndex].lineColor);
    return getContrastColor(r, g, b);
  }, [props.activeStyles, props.activeStyleIndex]);
  const colorPickerIcon = useCallback(
    (style: IShapeStyle, index: number) => (
      <div className="settings-bar__circle">
        <CircleFill size={24} color={rgbAdjustOpacity(style.lineColor, 1)} />
        {props.activeStyleIndex === index && <CheckCircle size={24} color={contrastedColor} />}
      </div>
    ),
    [props.activeStyleIndex, props.activeStyles],
  );
  const [styleToAdd, setStyleToAdd] = useState<IShapeStyle>(DefaultShapeLineStyle);
  const onCancelStyleAdd = () => {
    setStyleToAdd(DefaultShapeLineStyle);
  };
  const onAddStyle = useCallback(() => {
    props.onAddStyle(styleToAdd);
    setStyleToAdd(DefaultShapeLineStyle);
  }, [styleToAdd]);

  return (
    <>
      {props.activeStyles.map((style: IShapeStyle, index: number) =>
        props.activeStyleIndex === index ? (
          <DrawStylePicker
            className="settings-bar__color-picker"
            key={index}
            style={style}
            icon={colorPickerIcon(style, index)}
            onChange={props.onStyleChange}
            onCancel={props.onStyleChange}
          />
        ) : (
          <div
            className="settings-bar__color-picker settings-bar__color-picker_readonly"
            onClick={() => props.onStyleSelect(index)}
            key={index}
          >
            <CircleFill size={24} color={style.lineColor} />
          </div>
        ),
      )}
      {props.activeStyles.length < MAX_COLOR_LIST_LENGTH && (
        <DrawStylePicker
          className="settings-bar__color-picker"
          icon={<ZoomIN className="assets-icon assets-icon_center" />}
          style={styleToAdd}
          onChange={setStyleToAdd}
          onSave={onAddStyle}
          onCancel={onCancelStyleAdd}
        />
      )}
    </>
  );
};

export default DrawStylePickersContainer;
