import { PDFDocumentProxy, PDFTreeNode } from 'pdfjs-dist';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { AnyObject } from '../types/generics';
import { ITreeNode } from '../components/tree/interfaces/ITreeNode';
import { DestinationType } from '../../services/enums/destinationType';
import { ISelectedOutline } from '../../store/interfaces/IOutlines';

const DEFAULT_OUTLINE_TITLE = 'Outline';

export const INITIAL_SELECTED_ITEM: ISelectedOutline = {
  key: undefined,
  pos: null,
};

export interface IPdfData extends PDFDocumentProxy {
  getDestination(dest: string): AnyObject;
  getPageIndex(ref: AnyObject): number;
}

export const removeNodeFromTree = (tree: ITreeNode[], node: ITreeNode): ITreeNode[] => {
  return tree.map((item) => {
    if (_.isEqual(item, node)) {
      return {
        ...item,
        isRemoved: true,
      };
    }
    return { ...item, children: removeNodeFromTree(item.children, node) };
  });
};

export const insertNodeToTree = (
  tree: ITreeNode[],
  node: ITreeNode,
  path: number[],
  dropToGap: boolean,
  dropPosition: number,
): ITreeNode[] => {
  const newTree = [...tree];
  if (path.length !== 0) {
    const newItem = { ...newTree[path[0]] };
    newItem.children = insertNodeToTree(
      newItem.children,
      node,
      path.slice(1),
      dropToGap,
      path.length === 1 && path[0] === dropPosition ? 0 : dropPosition,
    );
    newTree[path[0]] = newItem;
    return newTree;
  }
  if (newTree.length === dropPosition) {
    return [...newTree, node];
  } else {
    newTree.splice(dropPosition === -1 ? 0 : dropPosition, 0, node);
    return newTree;
  }
};

export const filterTreeNodes = (tree: ITreeNode[], conditionCallback: (node: ITreeNode) => boolean): ITreeNode[] => {
  return tree
    .map((node) => ({ ...node }))
    .filter((item) => {
      item.children = filterTreeNodes(item.children, conditionCallback);
      return conditionCallback(item);
    });
};

export const createNewOutlineByTitleAndPage = (title: string, page: number): ITreeNode => ({
  title: title,
  page: page,
  destinationType: null,
  coordinates: null,
  key: uuid(),
  children: [],
});

export const createNewOutline = (pagesByViewport: number[]): ITreeNode => ({
  title: DEFAULT_OUTLINE_TITLE,
  page: pagesByViewport.indexOf(Math.max(...pagesByViewport.filter((ratio) => _.isNumber(ratio)))),
  destinationType: null,
  coordinates: null,
  key: uuid(),
  children: [],
});

export const updateTreeNode = (tree: ITreeNode[], node: ITreeNode): ITreeNode[] => {
  return tree.map((item) => {
    if (item.key === node.key) {
      return {
        ...node,
      };
    }
    return {
      ...item,
      children: updateTreeNode(item.children, node),
    };
  });
};

export const updateTreeNodePage = (tree: ITreeNode[], newPages: (number | null)[]): ITreeNode[] => {
  return tree.map((item) => {
    const index = newPages.indexOf(item.page);
    const newPage = index !== -1 ? index : -1;
    return {
      ...item,
      page: newPage,
      destinationType: newPage === item.page ? item.destinationType : null,
      coordinates: newPage === item.page ? item.coordinates : null,
      children: updateTreeNodePage(item.children, newPages),
    };
  });
};

export const buildTree = async (pdfData: IPdfData): Promise<ITreeNode[]> => {
  const outline = await pdfData.getOutline();
  return await Promise.all((outline || []).map(async (item) => await parseOutlineToTree(item, pdfData)));
};

const parseOutlineToTree = async (outline: PDFTreeNode, pdfData: IPdfData): Promise<ITreeNode> => {
  const destination = typeof outline.dest === 'string' ? await pdfData.getDestination(outline.dest) : outline.dest;
  const page = destination ? destination[0] : null;
  const destinationType = destination ? destination[1] : null;
  const coordinates = destination ? destination.slice(2) : null;
  let pageIndex = null;
  try {
    pageIndex = _.isNull(page) ? -1 : await pdfData.getPageIndex(page);
  } catch (e) {
    console.error('Failed to get page index by destination: ' + e);
  }
  return {
    title: outline.title,
    page: pageIndex,
    destinationType: destinationType ? DestinationType[destinationType.name as keyof typeof DestinationType] : null,
    coordinates: coordinates && coordinates.length > 0 ? coordinates : null,
    key: uuid(),
    children: await Promise.all(outline.items.map(async (item) => await parseOutlineToTree(item, pdfData))),
  };
};
