import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { Font, Variant } from '@samuelmeuli/font-manager';

import { concatBboxes, rotatePoint, swapWidthAndHeight } from '../shared/helpers/canvasHelper';
import {
  IAnnotation,
  IAnnotationEntity,
  IAnnotationFreeTextEntity,
  IAnnotationReply,
  IFreeTextPartStyle,
  IRectangle,
} from './interfaces/IAnnotation';
import { ActionType } from '../store/enums/actionType';
import { AnnotationType } from '../store/enums/annotationType';
import { ICreateAnnotationData } from './interfaces/ICreateAnnotationData';
import { AnyObject, CustomAny } from '../shared/types/generics';
import { TBbox } from '../shared/types/bbox';
import { TQuadPoints } from '../shared/types/quadPoints';
import { IAnnotationCardFormData } from '../shared/components/annotationCard/annotationCardForm/interfaces/IAnnotationCardFormData';
import { AnnotationCardFormFields } from '../shared/components/annotationCard/annotationCardForm/enums/AnnotationCardFormFields';
import { saveFile, updateOutlines, updatePages } from '../api/annotations/AnnotationsApiService';
import { IAnnotationsByActions } from '../api/interfaces/ISaveFileData';
import { ISaveFileResponse } from '../api/interfaces/ISaveFileResponse';
import { colorToPDFColor, pdfColorToRgb } from '../shared/helpers/colorHelper';
import { AnnotationFreeTextIntentType } from '../store/enums/annotationFreeTextIntentType';
import { IAnnotationCommentFormData } from '../shared/components/annotationComment/annotationCommentForm/interfaces/IAnnotationCommentFormData';
import { TextAnnotationName } from '../shared/components/annotationCard/enums/TextAnnotationName';
import { IPageAction } from './interfaces/IPageActions';
import { IUpdatePagesResponse } from '../api/interfaces/IUpdatePagesResponse';
import { TextStyle } from '../store/enums/annotateStyle';
import { FontVariant } from './enums/fontVariant';
import { ITreeNode } from '../shared/components/tree/interfaces/ITreeNode';
import { ShapeType } from '../store/enums/shapeType';
import { IShapeStyle } from '../store/interfaces/IShapeStyle';
import { PointEndingStyle } from '../store/enums/pointEndingStyle';
import { SquareCircleType } from '../store/enums/squareCircleType';
import { SHAPE_ANNOTATIONS } from '../shared/constants/application';

const roundMultiplier = 10000;

export const saveAnnotations = async (
  file: File,
  annotations: IAnnotationEntity[],
  fonts: Font[],
  generateAppearanceStream = false,
): Promise<ISaveFileResponse> => {
  const annotationsByActions: IAnnotationsByActions = {
    createAnnotations: [],
    updateAnnotations: [],
    deleteAnnotations: [],
  };
  annotations.forEach((annotation) => {
    const { actionType, ...clearedAnnotation } = annotation;
    if (annotation.annotationType === AnnotationType.FreeText) {
      // Add link for fonts used in Free Text annotations
      clearedAnnotation.textStyles = clearedAnnotation.textStyles?.map((style) => {
        return { ...style, ttfLink: getFontFile(fonts, style) || '' };
      });
      clearedAnnotation.borderWidth = clearedAnnotation.borderStyle ? clearedAnnotation.borderWidth : null;
      clearedAnnotation.borderColorRGB = clearedAnnotation.borderStyle ? clearedAnnotation.borderColorRGB : null;
    }
    let newActionType = actionType;
    // Remove replies that marked as flatten
    if (clearedAnnotation.idToReply && clearedAnnotation.flatten) {
      // Ignore flatten replies that are not saved yet
      if (isNaN(Number(clearedAnnotation.id))) {
        return;
      }
      newActionType = ActionType.Delete;
    }
    // Do not save replies for flatten annotations
    const replies = !clearedAnnotation.flatten ? findAnnotationReplies(annotations, clearedAnnotation.id) : [];
    if (clearedAnnotation.annotationType === AnnotationType.Stamp && clearedAnnotation.file) {
      clearedAnnotation.file = clearedAnnotation.file.replace(/^.+,/, '');
    }
    switch (newActionType) {
      case ActionType.Create:
        if (replies.length > 0) {
          clearedAnnotation.replies = replies;
        }
        // Ignore created replies as they will be in 'replies' field
        if (clearedAnnotation.idToReply && isNaN(Number(clearedAnnotation.id))) {
          return null;
        }
        // Remove custom id created on frontend to manipulate annotations
        return annotationsByActions.createAnnotations.push(_.omit(clearedAnnotation, 'id'));
      case ActionType.Update:
        return annotationsByActions.updateAnnotations.push(clearedAnnotation);
      case ActionType.Delete:
        return annotationsByActions.deleteAnnotations.push(clearedAnnotation);
    }
  });
  return saveFile(file, {
    annotationsByActions,
    generateAppearanceStream,
  });
};

export const savePages = async (path: string, pageActions: IPageAction[]): Promise<IUpdatePagesResponse> => {
  return await updatePages({
    path,
    pageActions,
  });
};

export const saveOutlines = async (filePath: string, outlines: ITreeNode[]): Promise<string> => {
  return await updateOutlines({
    localPathToFile: filePath,
    outlineItems: outlines,
  });
};

export const findAnnotationReplies = (annotations: IAnnotationEntity[], id?: string): IAnnotationReply[] => {
  if (!id || !isNaN(Number(id))) {
    return [];
  }
  return annotations
    .filter((reply) => reply.idToReply === id)
    .map((reply) => ({
      annotationType: AnnotationType.Text,
      creationDateInMillis: reply.creationDateInMillis,
      title: reply.title,
      content: reply.content,
    }));
};

// TODO: make more flexible creation of all kinds of annotations
export const createAnnotation = (data: ICreateAnnotationData): IAnnotationEntity | null => {
  const {
    id = uuid(),
    quadPoints,
    page,
    textMarkupType,
    color,
    colorRGB,
    rectangle,
    type,
    enterEditing = false,
    opacity,
  } = data;
  const time = new Date();
  const annotationRect = quadPoints
    ? createAnnotationRectangle(concatBboxes(parseQPointsToBboxList(quadPoints), true))
    : rectangle;
  if (!annotationRect || (!color && !colorRGB)) {
    return null;
  }
  const [rgb, colorOpacity] = color ? colorToPDFColor(color) : [colorRGB, 1];
  const annotation: IAnnotationEntity = {
    ...data,
    id,
    modified: false,
    actionType: ActionType.Create,
    annotationType: type,
    colorRGB: rgb || [1, 1, 1],
    creationDateInMillis: time.getTime(),
    modifiedDateInMillis: time.getTime(),
    page,
    rectangle: annotationRect,
    opacity: _.isNumber(opacity) ? opacity : colorOpacity,
  };

  if (enterEditing) {
    annotation.enterEditing = enterEditing;
  }

  if (type === AnnotationType.FreeText) {
    annotation.annotationFreeTextIntentType = AnnotationFreeTextIntentType.itFreeText;
  } else {
    annotation.textMarkupType = textMarkupType;
  }

  return annotation;
};

export const createShapeAnnotation = ({
  page,
  rectangle,
  shapeType,
  shapeStyle,
  points,
  title,
}: {
  page: number;
  rectangle: IRectangle;
  shapeType: ShapeType;
  shapeStyle: IShapeStyle;
  points: number[] | null;
  title: string;
}): IAnnotationEntity => {
  const time = new Date();
  let type = AnnotationType.Line;
  if (shapeType === ShapeType.Triangle) {
    type = AnnotationType.Triangle;
  } else if (shapeType === ShapeType.Square || shapeType === ShapeType.Circle) {
    type = AnnotationType.SquareCircle;
  }
  const rgb = colorToPDFColor(shapeStyle.lineColor)[0];
  const annotation: IAnnotationEntity = {
    id: uuid(),
    modified: false,
    title,
    actionType: ActionType.Create,
    annotationType: type,
    page,
    rectangle,
    opacity: shapeStyle.opacity,
    colorRGB: rgb,
    width: shapeStyle.lineWidth,
    creationDateInMillis: time.getTime(),
    modifiedDateInMillis: time.getTime(),
  };
  if (type === AnnotationType.Line) {
    if (shapeType === ShapeType.ArrowLine) {
      annotation.endPointEndingStyle = PointEndingStyle.OpenArrow;
    } else if (shapeType === ShapeType.TwoArrowsLine) {
      annotation.startPointEndingStyle = PointEndingStyle.OpenArrow;
      annotation.endPointEndingStyle = PointEndingStyle.OpenArrow;
    }
    if (points) {
      annotation.linePoints = points;
    }
  } else if (type === AnnotationType.SquareCircle) {
    annotation.squareCircleType = shapeType === ShapeType.Square ? SquareCircleType.Square : SquareCircleType.Circle;
    annotation.interiorColor = shapeStyle.backgroundColor ? colorToPDFColor(shapeStyle.backgroundColor)[0] : null;
  } else if (type === AnnotationType.Triangle) {
    if (points) {
      annotation.vertices = points;
    }
    annotation.interiorColor = shapeStyle.backgroundColor ? colorToPDFColor(shapeStyle.backgroundColor)[0] : null;
  }
  return annotation;
};

export const createComment = (annotation: IAnnotationEntity): IAnnotationEntity => {
  const time = new Date();
  return {
    ...annotation,
    id: _.uniqueId(TextAnnotationName.Comment),
    modified: false,
    annotationType: AnnotationType.Text,
    actionType: ActionType.Create,
    textAnnotationName: TextAnnotationName.Comment,
    content: '',
    creationDateInMillis: time.getTime(),
    modifiedDateInMillis: time.getTime(),
  };
};

export const createAnnotationRectangle = (bbox: number[]): IRectangle => {
  return {
    lowerLeftX: bbox[0],
    lowerLeftY: bbox[3],
    upperRightX: bbox[2],
    upperRightY: bbox[1],
  };
};

export const editAnnotation = (
  annotation: IAnnotationEntity,
  updateFormData: IAnnotationCardFormData,
): IAnnotationEntity | false => {
  const color = updateFormData[AnnotationCardFormFields.Color];
  const [colorRGB, opacity] = colorToPDFColor(color);
  const time = new Date();
  let modified = true;
  if (
    annotation.title === updateFormData[AnnotationCardFormFields.Title] &&
    annotation.content === updateFormData[AnnotationCardFormFields.Content] &&
    !annotation.flatten === !updateFormData[AnnotationCardFormFields.Flatten] &&
    _.isEqual(annotation.colorRGB, colorRGB) &&
    annotation.opacity === opacity
  ) {
    modified = false;
  }
  const editedAnnotation = {
    ...annotation,
    actionType: annotation.actionType === ActionType.Create ? annotation.actionType : ActionType.Update,
    modified: annotation.modified || modified,
    modifiedDateInMillis: modified ? time.getTime() : annotation.modifiedDateInMillis,
    title: updateFormData[AnnotationCardFormFields.Title] || '',
    content:
      annotation.annotationType === AnnotationType.FreeText
        ? annotation.content
        : updateFormData[AnnotationCardFormFields.Content] || '',
    flatten: updateFormData[AnnotationCardFormFields.Flatten],
    enterEditing: false,
    color,
  };
  if (!SHAPE_ANNOTATIONS.includes(annotation.annotationType) && annotation.annotationType !== AnnotationType.Ink) {
    editedAnnotation.opacity = opacity;
    editedAnnotation.colorRGB = annotation.annotationType === AnnotationType.FreeText ? annotation.colorRGB : colorRGB;
  }
  return editedAnnotation;
};

export const editShapeStyle = (annotation: IAnnotationEntity, shapeStyle: IShapeStyle): IAnnotationEntity => {
  const interiorColor = shapeStyle.backgroundColor ? colorToPDFColor(shapeStyle.backgroundColor)[0] : null;
  const colorRGB = colorToPDFColor(shapeStyle.lineColor)[0];
  const editedAnnotation = {
    ...annotation,
    opacity: shapeStyle.opacity,
    width: shapeStyle.lineWidth,
    colorRGB,
  };
  if (interiorColor) {
    editedAnnotation.interiorColor = interiorColor;
  }
  return editedAnnotation;
};

export const getAnnotationShapeStyle = (annotation: IAnnotationEntity): IShapeStyle => {
  return {
    backgroundColor: annotation.interiorColor ? pdfColorToRgb(...annotation.interiorColor, 1) : '',
    lineColor: pdfColorToRgb(...annotation.colorRGB, 1),
    lineWidth: annotation.width || 0,
    opacity: annotation.opacity,
  };
};

export const editComment = (
  annotation: IAnnotationEntity,
  updateFormData: IAnnotationCommentFormData,
): IAnnotationEntity | false => {
  if (
    annotation.title === updateFormData[AnnotationCardFormFields.Title] &&
    annotation.content === updateFormData[AnnotationCardFormFields.Content]
  ) {
    return false;
  }
  const time = new Date();
  return {
    ...annotation,
    actionType: annotation.actionType === ActionType.Create ? annotation.actionType : ActionType.Update,
    modified: true,
    modifiedDateInMillis: time.getTime(),
    title: updateFormData[AnnotationCardFormFields.Title] || '',
    content: updateFormData[AnnotationCardFormFields.Content] || '',
  };
};

export const removeAnnotation = (
  originList: (IAnnotationEntity | IAnnotationFreeTextEntity)[],
  removeIndex: number,
): (IAnnotationEntity | IAnnotationFreeTextEntity)[] => {
  let newList;
  if (originList[removeIndex].actionType !== ActionType.Create) {
    newList = originList.map((annotation, index) => {
      if (index !== removeIndex) {
        return annotation;
      }
      return {
        ...annotation,
        actionType: ActionType.Delete,
      };
    });
  } else {
    newList = [...originList];
    newList.splice(removeIndex, 1);
  }

  return newList;
};

export const removeAnnotationsByIds = (
  originList: (IAnnotationEntity | IAnnotationFreeTextEntity)[],
  ids: string[],
): { newAnnotations: (IAnnotationEntity | IAnnotationFreeTextEntity)[]; removed: number } => {
  const newList: (IAnnotationEntity | IAnnotationFreeTextEntity)[] = [];
  let removed = 0;
  originList.forEach((annotation) => {
    if (annotation.id && ids.includes(annotation.id)) {
      removed++;
      if (annotation.actionType !== ActionType.Create) {
        newList.push({
          ...annotation,
          actionType: ActionType.Delete,
        });
      }
    } else {
      newList.push(annotation);
    }
  });
  return {
    newAnnotations: newList,
    removed,
  };
};

export const findAnnotationIndexByObject = (annotationList: IAnnotationEntity[], data: AnyObject): number => {
  return annotationList.findIndex((annotation) => _.isEqual(annotation.id, data.get('annot_id')));
};

export const findAnnotationIndex = (
  annotationList: IAnnotationEntity[],
  data: AnyObject,
  type?: AnnotationType,
): number => {
  if (type === AnnotationType.FreeText || data.annotationType === AnnotationType.FreeText) {
    return findFreeTextAnnotationIndexByFields(annotationList, data);
  }
  if ((type === AnnotationType.Text || data.annotationType === AnnotationType.Text) && data.idToReply) {
    return findTextAnnotationIndexByFields(annotationList, data);
  }
  return findAnnotationIndexByFields(annotationList, data);
};

export const findAnnotationIndexByFields = (annotationList: IAnnotationEntity[], data: AnyObject): number => {
  if (data.id) {
    return _.findIndex(annotationList, { id: data.id });
  }
  const annotationsWithRoundedQuadPoints = annotationList.map((annotation) => {
    if (!annotation.quadPoints) {
      return annotation;
    }
    return {
      ...annotation,
      quadPoints: annotation.quadPoints.map((point) => Math.round(point * roundMultiplier)),
    };
  });
  return _.findIndex(annotationsWithRoundedQuadPoints, {
    ...data,
    quadPoints: data.quadPoints.map((point: number) => Math.round(point * roundMultiplier)),
  });
};

export const findTextAnnotationIndexByFields = (annotationList: IAnnotationEntity[], data: AnyObject): number => {
  if (data.id) {
    return _.findIndex(annotationList, { id: data.id });
  }
  return _.findIndex(
    annotationList,
    (annotation) =>
      _.isEqual(annotation.rectangle, data.rectangle) &&
      (annotation.content === data.content || annotation.content === data.text) &&
      annotation.idToReply === data.idToReply,
  );
};

export const findFreeTextAnnotationIndexByFields = (annotationList: IAnnotation[], data: AnyObject): number => {
  if (data.id) {
    return _.findIndex(annotationList, { id: data.id });
  }
  return _.findIndex(
    annotationList,
    (annotation) =>
      _.isEqual(annotation.rectangle, data.rectangle) &&
      (annotation.content === data.content || annotation.content === data.text),
  );
};

export const parseQPointsToBboxList = (quadPoints: TQuadPoints): TBbox[] => {
  const bboxList: TBbox[] = [];
  const quadPointsCopy = [...quadPoints];
  while (quadPointsCopy[0]) {
    const bboxCoords = quadPointsCopy.splice(0, 8);
    bboxList.push([
      Math.min(bboxCoords[0], bboxCoords[6]),
      Math.max(bboxCoords[1], bboxCoords[7]),
      Math.max(bboxCoords[0], bboxCoords[6]),
      Math.min(bboxCoords[1], bboxCoords[7]),
    ]);
  }

  return bboxList;
};

export const parseBboxesToQPoints = (bboxes: TBbox[], height = 0): TQuadPoints => {
  let quadPoints: TQuadPoints = [];
  bboxes.forEach((bbox) => {
    if (height) {
      bbox[1] = (bbox[1] - height) * -1;
      bbox[3] = (bbox[3] - height) * -1;
    }
    quadPoints = [
      ...quadPoints,
      Math.round(bbox[0] * roundMultiplier) / roundMultiplier,
      Math.round(bbox[1] * roundMultiplier) / roundMultiplier,
      Math.round(bbox[2] * roundMultiplier) / roundMultiplier,
      Math.round(bbox[1] * roundMultiplier) / roundMultiplier,
      Math.round(bbox[0] * roundMultiplier) / roundMultiplier,
      Math.round(bbox[3] * roundMultiplier) / roundMultiplier,
      Math.round(bbox[2] * roundMultiplier) / roundMultiplier,
      Math.round(bbox[3] * roundMultiplier) / roundMultiplier,
    ];
  });

  return quadPoints;
};

export const adjustQPointsWithViewport = (qpoints: TQuadPoints, viewport: number[], add = true): TQuadPoints => {
  return qpoints.map((point, index) => {
    if (index % 2) {
      const newPoint = add ? point + viewport[1] : point - viewport[1];
      return Math.round(newPoint * roundMultiplier) / roundMultiplier;
    } else {
      const newPoint = add ? point + viewport[0] : point - viewport[0];
      return Math.round(newPoint * roundMultiplier) / roundMultiplier;
    }
  });
};

export const convertAnnotationYPoints = (
  annotation: IAnnotationEntity,
  viewport: number[],
  rotationAngle: number,
): IAnnotationEntity => {
  const convertedAnnotation = { ...annotation };
  const height = swapWidthAndHeight(rotationAngle) ? viewport[2] - viewport[0] : viewport[3] - viewport[1];
  convertedAnnotation.rectangle.upperRightY = height - convertedAnnotation.rectangle.upperRightY;
  convertedAnnotation.rectangle.lowerLeftY = height - convertedAnnotation.rectangle.lowerLeftY;
  if (convertedAnnotation.linePoints) {
    convertedAnnotation.linePoints = convertedAnnotation.linePoints.map(convertYArrayPoint.bind(null, height));
  }
  if (convertedAnnotation.vertices) {
    convertedAnnotation.vertices = convertedAnnotation.vertices.map(convertYArrayPoint.bind(null, height));
  }
  return convertedAnnotation;
};

export const convertYArrayPoint = (height: number, point: number, index: number): number => {
  if (index % 2 === 0) {
    return point;
  }
  return height - point;
};

export const adjustAnnotationPointsWithViewport = (
  annotation: IAnnotationEntity,
  viewport: number[],
  add = true,
  rotationAngle = 0,
): IAnnotationEntity => {
  const adjustedAnnotation = { ...annotation };
  adjustedAnnotation.rectangle = adjustRectangleWithViewport(
    adjustedAnnotation.rectangle,
    viewport,
    add,
    rotationAngle,
  );
  if (adjustedAnnotation.linePoints) {
    adjustedAnnotation.linePoints = adjustedAnnotation.linePoints.map(
      adjustArrayPoint.bind(null, viewport, add, rotationAngle),
    );
  }
  if (adjustedAnnotation.vertices) {
    adjustedAnnotation.vertices = adjustedAnnotation.vertices.map(
      adjustArrayPoint.bind(null, viewport, add, rotationAngle),
    );
  }
  return adjustedAnnotation;
};

export const rotateAnnotationPoints = (
  annotation: IAnnotationEntity,
  viewport: number[],
  rotationAngle: number,
): IAnnotationEntity => {
  const adjustedAnnotation = { ...annotation };
  adjustedAnnotation.rectangle = rotateRectangle(adjustedAnnotation.rectangle, viewport, rotationAngle);
  if (adjustedAnnotation.linePoints) {
    adjustedAnnotation.linePoints = _.chain(adjustedAnnotation.linePoints)
      .chunk(2)
      .map((point) => rotatePoint(rotationAngle, [point[0], point[1]], viewport))
      .flatten()
      .value();
  }
  if (adjustedAnnotation.vertices) {
    adjustedAnnotation.vertices = _.chain(adjustedAnnotation.vertices)
      .chunk(2)
      .map((point) => rotatePoint(rotationAngle, [point[0], point[1]], viewport))
      .flatten()
      .value();
  }
  return adjustedAnnotation;
};

export const rotateRectangle = (
  rectangle: IRectangle,
  viewport: number[],
  rotationAngle: number,
  reverse = false,
): IRectangle => {
  const minPoint = rotatePoint(rotationAngle, [rectangle.lowerLeftX, rectangle.lowerLeftY], viewport, reverse);
  const maxPoint = rotatePoint(rotationAngle, [rectangle.upperRightX, rectangle.upperRightY], viewport, reverse);
  return {
    lowerLeftX: Math.min(minPoint[0], maxPoint[0]),
    lowerLeftY: Math.min(minPoint[1], maxPoint[1]),
    upperRightX: Math.max(minPoint[0], maxPoint[0]),
    upperRightY: Math.max(minPoint[1], maxPoint[1]),
  };
};

export const adjustArrayPoint = (
  viewport: number[],
  add = true,
  rotationAngle: number,
  point: number,
  index: number,
): number => {
  const left = swapWidthAndHeight(rotationAngle) ? viewport[1] : viewport[0];
  const top = swapWidthAndHeight(rotationAngle) ? viewport[0] : viewport[1];
  if (index % 2 === 0) {
    return add ? point + left : point - left;
  }
  return add ? point + top : point - top;
};

export const adjustRectangleWithViewport = (
  rectangle: IRectangle,
  viewport: number[],
  add = true,
  rotationAngle = 0,
): IRectangle => {
  const left = swapWidthAndHeight(rotationAngle) ? viewport[1] : viewport[0];
  const top = swapWidthAndHeight(rotationAngle) ? viewport[0] : viewport[1];
  return {
    lowerLeftX: add ? rectangle.lowerLeftX + left : rectangle.lowerLeftX - left,
    lowerLeftY: add ? rectangle.lowerLeftY + top : rectangle.lowerLeftY - top,
    upperRightX: add ? rectangle.upperRightX + left : rectangle.upperRightX - left,
    upperRightY: add ? rectangle.upperRightY + top : rectangle.upperRightY - top,
  };
};

export const adjustAnnotationsWithActionTypes = (annotations: IAnnotation[]): IAnnotationEntity[] => {
  return annotations.map((annotation) => {
    return {
      ...annotation,
      actionType: ActionType.Update,
    };
  });
};

export const removeAllAnnotations = (originList: IAnnotationEntity[]): IAnnotationEntity[] => {
  return originList
    .filter((annotation) => annotation.actionType !== ActionType.Create)
    .map((annotation) => {
      if (annotation.readOnly || annotation.locked) {
        return annotation;
      }
      return {
        ...annotation,
        actionType: ActionType.Delete,
      };
    });
};
export const getDeletedAnnotations = (
  annotations: (IAnnotationEntity | IAnnotationFreeTextEntity)[],
): (IAnnotationEntity | IAnnotationFreeTextEntity)[] => {
  return _.filter(annotations, (annotation) => annotation.actionType === ActionType.Delete);
};

export const getFontFile = (fonts: Font[], styles: IFreeTextPartStyle): string | null => {
  const font = fonts.find((f) => f.family === styles.fontName);
  if (!font || !font.variants || !font.files) {
    console.error('There is not enough data to save this font: ', font);
    return null;
  }
  let variant = '';
  if (
    styles.fontWeight === TextStyle.Bold &&
    styles.fontStyle === TextStyle.Italic &&
    !variant &&
    font.variants.includes(FontVariant.BoldAndItalic as Variant)
  ) {
    variant = FontVariant.BoldAndItalic;
  }
  if (styles.fontWeight === TextStyle.Bold && !variant && font.variants.includes(FontVariant.Bold as Variant)) {
    variant = FontVariant.Bold;
  }
  if (styles.fontStyle === TextStyle.Italic && !variant && font.variants.includes(FontVariant.Italic as Variant)) {
    variant = FontVariant.Italic;
  }
  if (!variant && font.variants.includes(FontVariant.Regular as Variant)) {
    variant = FontVariant.Regular;
  }
  if (!variant && font.variants.length > 0) {
    variant = font.variants[0];
  }
  if (!variant) {
    return null;
  }
  return (font.files as CustomAny)[variant];
};

export const getAnnotationTypeByShapeType = (shapeType: ShapeType): AnnotationType => {
  switch (shapeType) {
    case ShapeType.Line:
    case ShapeType.ArrowLine:
    case ShapeType.TwoArrowsLine:
      return AnnotationType.Line;
    case ShapeType.Square:
    case ShapeType.Circle:
      return AnnotationType.SquareCircle;
    case ShapeType.Triangle:
      return AnnotationType.Triangle;
    default:
      return AnnotationType.Line;
  }
};

export const getShapeTypeByAnnotation = (annotation: IAnnotationEntity): ShapeType | null => {
  switch (annotation.annotationType) {
    case AnnotationType.Line:
      if (lineHasStartArrow(annotation) && lineHasEndArrow(annotation)) {
        return ShapeType.TwoArrowsLine;
      }
      if (lineHasStartArrow(annotation) || lineHasEndArrow(annotation)) {
        return ShapeType.ArrowLine;
      }
      return ShapeType.Line;
    case AnnotationType.SquareCircle:
      if (annotation.squareCircleType === SquareCircleType.Square) {
        return ShapeType.Square;
      }
      return ShapeType.Circle;
    case AnnotationType.Triangle:
      return ShapeType.Triangle;
    default:
      return null;
  }
};

export const lineHasStartArrow = (annotation: IAnnotationEntity): boolean => {
  return !!annotation.startPointEndingStyle && annotation.startPointEndingStyle !== PointEndingStyle.None;
};

export const lineHasEndArrow = (annotation: IAnnotationEntity): boolean => {
  return !!annotation.endPointEndingStyle && annotation.endPointEndingStyle !== PointEndingStyle.None;
};
