import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';

import { IViewerSettings } from '../interfaces/IViewerSettings';
import { setItem, getItem } from '../../services/localStorageService';
import {
  LS_SHOW_ANNOTATIONS,
  LS_PAGE_LAYOUT,
  LS_HORIZONTAL_MODE,
  LS_ROTATION_ANGLE,
} from '../../shared/constants/localStorageKeys';
import { PageLayout } from '../../shared/layouts/toolbar/toolbarLeft/enums/pageLayout';
import {
  setFileId,
  setNumPages,
  setPage,
  setPageTransitionMode,
  setHorizontalMode,
  setScale,
  setScrollIntoPage,
  toggleShowAnnotationsPanel,
  setAppLoading,
  setFileStatus,
  setPageLayout,
  setFileInfo,
  setPageNavigationMode,
  setAnnotationPanelActiveTab,
  setRotationAngle,
  setContextMenuActivePage,
} from './actions';
import { AnnotationPanelTab } from '../enums/annotationPanelTab';

const initialState: IViewerSettings = {
  fileId: undefined,
  singlePageView: true,
  scale: 1,
  numPages: 0,
  page: 0,
  scrollIntoPage: 0,
  showAnnotationsPanel: false,
  annotationsPanelActiveTab: AnnotationPanelTab.Thumbnails,
  appLoading: true,
  completedSteps: [],
  horizontalMode: getItem(LS_HORIZONTAL_MODE) || false,
  pageLayout: getItem(LS_PAGE_LAYOUT) || PageLayout.OnePageView,
  fileInfo: undefined,
  pageNavigationMode: false,
  rotationAngle: getItem(LS_ROTATION_ANGLE) || 0,
  contextMenuActivePage: -1,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setFileId, (state, action) => {
      state.fileId = action.payload;
    })
    .addCase(setPageTransitionMode, (state, action) => {
      state.singlePageView = action.payload;
    })
    .addCase(setHorizontalMode, (state, action) => {
      state.horizontalMode = action.payload;
    })
    .addCase(setPageLayout, (state, action) => {
      state.pageLayout = action.payload;
    })
    .addCase(setScale, (state, action) => {
      state.scale = action.payload;
    })
    .addCase(setNumPages, (state, action) => {
      state.numPages = action.payload;
    })
    .addCase(setPage, (state, action) => {
      state.page = action.payload;
    })
    .addCase(setScrollIntoPage, (state, action) => {
      state.scrollIntoPage = action.payload;
    })
    .addCase(toggleShowAnnotationsPanel, (state, action) => {
      const newToggledValue = typeof action.payload === 'boolean' ? action.payload : !state.showAnnotationsPanel;
      setItem(LS_SHOW_ANNOTATIONS, newToggledValue);
      state.showAnnotationsPanel = newToggledValue;
    })
    .addCase(setAppLoading, (state, action) => {
      if (action.payload !== state.appLoading) {
        state.completedSteps = [];
      }
      state.appLoading = action.payload;
    })
    .addCase(setFileStatus, (state, action) => {
      state.completedSteps = _.uniq([...state.completedSteps, action.payload]);
    })
    .addCase(setFileInfo, (state, action) => {
      state.fileInfo = action.payload;
    })
    .addCase(setPageNavigationMode, (state, action) => {
      state.pageNavigationMode = action.payload;
    })
    .addCase(setAnnotationPanelActiveTab, (state, action) => {
      state.annotationsPanelActiveTab = action.payload;
    })
    .addCase(setRotationAngle, (state, action) => {
      state.rotationAngle = action.payload;
      setItem(LS_ROTATION_ANGLE, state.rotationAngle);
    })
    .addCase(setContextMenuActivePage, (state, action) => {
      if (action.payload.value === -1 && action.payload.triggerPage !== state.contextMenuActivePage) {
        return;
      }
      state.contextMenuActivePage = action.payload.value;
    });
});
