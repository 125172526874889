import { createSelector } from 'reselect';

import { IRootState } from '../interfaces/IRootState';
import { IAnnotations } from '../interfaces/IAnnotations';
import { IAnnotationEntity } from '../../services/interfaces/IAnnotation';
import { ActionType } from '../enums/actionType';

export const getAnnotations = (state: IRootState): IAnnotations => state.annotations;

export const getAnnotationsList = createSelector(
  getAnnotations,
  (annotations: IAnnotations) => annotations.annotations,
);
export const getActiveAnnotation = createSelector(
  getAnnotations,
  (annotations: IAnnotations) => annotations.annotations[annotations.activeAnnotation] || null,
);
export const getCopiedAnnotation = createSelector(
  getAnnotations,
  (annotations: IAnnotations) => annotations.copiedAnnotation || undefined,
);
export const getNotDeletedAnnotationsList = createSelector(getAnnotationsList, (annotations: IAnnotationEntity[]) =>
  annotations.filter((annotation) => annotation.actionType !== ActionType.Delete),
);
