import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Color } from 'react-color';

import { ButtonType } from '../../button/enums/buttonType';
import Button from '../../button/Button';
import ColorSelector from '../colorSelector/ColorSelector';
import { OrNull } from '../../../types/generics';
import { rgbToString } from '../../../helpers/colorHelper';

import './ColorGrid.less';
import { ReactComponent as ZoomIN } from '../../../../assets/ZoomIN.svg';

interface IColorGripProps {
  colors: string[];
  selected?: string;
  onChange?(color: string): void;
  onAdd?(color?: string): void;
}

const ColorGrid: FC<IColorGripProps> = (props) => {
  const [addColorVisible, setAddColorVisible] = useState(false);
  const [newColorValue, setNewColorValue] = useState<OrNull<Color>>({ rgb: { r: 255, g: 255, b: 255 } });

  const onAddClick = useCallback(() => {
    setAddColorVisible(true);
  }, []);
  const onAddColor = useCallback(() => {
    const { rgb } = newColorValue;
    props.onAdd?.(rgbToString(rgb.r, rgb.g, rgb.b));
    setAddColorVisible(false);
  }, [newColorValue]);
  const onCancelColor = useCallback(() => {
    setAddColorVisible(false);
  }, []);
  const onSelectColor = useCallback(
    (color: string) => (e: React.MouseEvent | React.TouchEvent) => {
      e.stopPropagation();
      props.onChange?.(color);
    },
    [props.onChange],
  );

  return (
    <div className="color-grid">
      {props.colors.map((color: string, index) => (
        <div
          key={index}
          onTouchEnd={onSelectColor(color)}
          onClick={onSelectColor(color)}
          className={classNames('color-grid__color-rect', {
            'color-grid__color-rect_selected': color === props.selected,
          })}
          style={{ backgroundColor: color }}
        />
      ))}
      {props.onAdd && (
        <div className="color-grid__add-color" onMouseDown={(e) => e.stopPropagation()}>
          <Button
            type={ButtonType.Default}
            icon={<ZoomIN className="assets-icon assets-icon_center" />}
            onClick={onAddClick}
          />
          <ColorSelector
            color={newColorValue}
            onOk={onAddColor}
            onCancel={onCancelColor}
            visible={addColorVisible}
            onChange={setNewColorValue}
          />
        </div>
      )}
    </div>
  );
};

export default ColorGrid;
