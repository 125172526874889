import { createSelector } from 'reselect';

import { IRootState } from '../interfaces/IRootState';
import { IGoogleUser } from '../interfaces/IGoogleUser';

export const getGoogleUser = (state: IRootState): IGoogleUser => state.googleUser;

export const isSignedIn = createSelector(getGoogleUser, (user: IGoogleUser) => user.isSigned);
export const getUserName = createSelector(getGoogleUser, (user: IGoogleUser) => user.name);
export const getUserImage = createSelector(getGoogleUser, (user: IGoogleUser) => user.image_url);
