import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import { useKeyPress } from 'react-use';

import Button from '../../components/button/Button';
import { ButtonType } from '../button/enums/buttonType';
import { AnnotateActionType, AnnotateActionTypeLabel } from '../../../store/enums/annotateActionType';
import { getNumPages, getStoredFileId } from '../../../store/viewerSettings/selectors';
import { getActiveAction, getIsEditing } from '../../../store/annotateSettings/selectors';
import { setActiveAction, setImageBase64 } from '../../../store/annotateSettings/actions';
import { getColor } from '../../../store/annotateSettings/selectors';
import { AnyObject } from '../../types/generics';
import { HOTKEYS_MAP, TAG_NAMES } from '../../constants/application';
import { setActiveAnnotation } from '../../../store/annotations/actions';

import './DrawToolsPanel.less';

import { ReactComponent as Shape } from '../../../assets/Top_shape.svg';
import { ReactComponent as Eraser } from '../../../assets/Top_eraser.svg';
import { ReactComponent as Pencil } from '../../../assets/Pencil.svg';

const DrawToolsPanel: FC = () => {
  const dispatch = useDispatch();

  const isShapePressed = useKeyPress('p')[0];
  const isDrawingPressed = useKeyPress('d')[0];
  const isEraserPressed = useKeyPress('e')[0];
  const activeAction: AnnotateActionType | undefined = useSelector(getActiveAction);
  const storedFileId = useSelector(getStoredFileId);
  const isEditing = useSelector(getIsEditing);
  const color: AnyObject = useSelector(getColor);
  const numPages: number = useSelector(getNumPages);
  const disabled = useMemo(() => !storedFileId || numPages === 0, [storedFileId, numPages]);

  const onShapeClick = useCallback(
    () => disabled || toggleAction(AnnotateActionType.Shape),
    [activeAction === AnnotateActionType.Shape, disabled],
  );
  const onEraserClick = useCallback(
    () => disabled || toggleAction(AnnotateActionType.Eraser),
    [activeAction === AnnotateActionType.Eraser, disabled],
  );
  const onDrawingClick = useCallback(
    () => disabled || toggleAction(AnnotateActionType.Drawing),
    [activeAction === AnnotateActionType.Drawing, disabled],
  );

  const toggleAction = useCallback(
    (type: AnnotateActionType) => {
      dispatch(setImageBase64(null));
      if (activeAction === type) {
        return dispatch(setActiveAction(undefined));
      }
      if ([AnnotateActionType.Drawing, AnnotateActionType.Eraser].includes(type)) {
        dispatch(setActiveAnnotation(null));
      }
      return dispatch(setActiveAction(type));
    },
    [activeAction],
  );
  // Key press events
  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isShapePressed && !isEditing && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      dispatch(setActiveAction(AnnotateActionType.Shape));
    }
  }, [isShapePressed, isEditing]);
  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isDrawingPressed && !isEditing && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      dispatch(setActiveAction(AnnotateActionType.Drawing));
    }
  }, [isDrawingPressed, isEditing]);
  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isEraserPressed && !isEditing && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      dispatch(setActiveAction(AnnotateActionType.Eraser));
    }
  }, [isEraserPressed, isEditing]);

  const getButtonType = (type: AnnotateActionType) => (type === activeAction ? ButtonType.Primary : ButtonType.Text);

  return (
    <div className="draw-tools-panel">
      <Button
        ghost={getButtonType(AnnotateActionType.Drawing) === ButtonType.Primary}
        type={getButtonType(AnnotateActionType.Drawing)}
        icon={useMemo(
          () => (
            <Pencil
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': 'white',
                  '--stroke-color': 'none',
                } as React.CSSProperties
              }
            />
          ),
          [color],
        )}
        onClick={onDrawingClick}
        disabled={disabled}
        tooltip={useMemo(
          () => (
            <>
              {AnnotateActionTypeLabel.Drawing}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.DRAWING}
              </Typography.Text>
            </>
          ),
          [],
        )}
      />
      <Button
        ghost={getButtonType(AnnotateActionType.Eraser) === ButtonType.Primary}
        type={getButtonType(AnnotateActionType.Eraser)}
        icon={useMemo(
          () => (
            <Eraser
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': 'white',
                  '--stroke-color': 'white',
                } as React.CSSProperties
              }
            />
          ),
          [color],
        )}
        onClick={onEraserClick}
        disabled={disabled}
        tooltip={useMemo(
          () => (
            <>
              {AnnotateActionTypeLabel.Eraser}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.ERASER}
              </Typography.Text>
            </>
          ),
          [],
        )}
      />
      <Button
        ghost={getButtonType(AnnotateActionType.Shape) === ButtonType.Primary}
        type={getButtonType(AnnotateActionType.Shape)}
        icon={useMemo(
          () => (
            <Shape
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': 'white',
                  '--stroke-color': 'none',
                } as React.CSSProperties
              }
            />
          ),
          [color],
        )}
        onClick={onShapeClick}
        disabled={disabled}
        tooltip={useMemo(
          () => (
            <>
              {AnnotateActionTypeLabel.Shape}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.SHAPE}
              </Typography.Text>
            </>
          ),
          [],
        )}
      />
    </div>
  );
};

export default DrawToolsPanel;
