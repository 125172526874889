import { AnnotationType } from '../../store/enums/annotationType';
import { AnnotateActionType } from '../../store/enums/annotateActionType';

export const APP_NAME = 'Flexcil';

export const FILE_DB_NAME = 'file';
export const COPIED_ANNOTATION_DB_NAME = 'copied-annotation';

export const MAX_FILE_SIZE_MB = 50;

export const REACT_APP_GOOGLE_DRIVE_API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY || '';
export const REACT_APP_GOOGLE_DRIVE_CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID || '';
export const REACT_APP_GOOGLE_FONT_API_KEY = process.env.REACT_APP_GOOGLE_FONT_API_KEY || '';
export const REACT_APP_MAX_FILE_SIZE =
  Number.parseInt(process.env.REACT_APP_MAX_FILE_SIZE_MB || '') || MAX_FILE_SIZE_MB;

// button tooltips

export const TOOLTIPS_MAP = {
  TOOLBAR: {
    SIDE_MENU: 'Side Menu',
    SETTINGS: 'Viewer Option',
    OPEN: 'Open new PDF',
    SAVE: 'Save and Download PDF',
    UNDO: 'Undo',
    REDO: 'Redo',
    ZOOM_IN: 'Zoom In',
    ZOOM_OUT: 'Zoom Out',
    PROPERTIES: 'Properties',
    DELETE_ALL: 'Delete All Annotations',
    FULLSCREEN: 'Full Screen',
    PAGE_NAVIGATION: 'Page Navigation',
    ADD_PAGE: 'Add Page',
    COPY: 'Copy',
    PASTE: 'Paste',
    DELETE_PAGE: 'Delete',
    PRINT: 'Print',
    RESTORE_ANNOTATIONS: 'Restore Annotations',
    ROTATE_CLOCKWISE: 'Rotate Clockwise',
    ROTATE_COUNTERCLOCKWISE: 'Rotate Counterclockwise',
    COPY_ANNOTATION: 'Copy to clipboard',
    CREATE_OUTLINE: 'Create Outline',
  },
  SIDE_MENU: {
    THUMBNAILS: 'Thumbnails',
    OUTLINES: 'Outlines',
    ANNOTATIONS: 'Annotations',
    EDIT: 'Edit',
    COMMENT: 'Comment',
    DELETE: 'Delete',
    SAVE: 'Save',
    CANCEL: 'Cancel',
  },
  ADD_FILE_DIALOG: {
    ENTER_URL: 'Or enter a URL',
    OPEN_DRIVE_FILE: 'Open file from Google Drive?',
  },
};

export const HOTKEYS_MAP = {
  UNDO: 'Ctrl Z',
  REDO: 'Ctrl X',
  COPY: 'Ctrl C',
  PASTE: 'Ctrl V',
  UNDERLINE: 'U',
  HIGHLIGHT: 'H',
  STRIKEOUT: 'K',
  SQUIGGLY: 'G',
  FREE_TEXT: 'T',
  STICKY_NOTE: 'S',
  SHAPE: 'P',
  IMAGE: 'I',
  DRAWING: 'D',
  ERASER: 'E',
};

// Texts

export const TEXT_MAP = {
  COMMON: {
    CANCEL: 'Cancel',
    OK: 'OK',
    DONE: 'Done',
    CLOSE: 'Close',
    DELETE: 'Delete',
    EDIT: 'Edit',
  },
  CONFIRM_RESTORE_ANNOTATIONS: 'Are you sure you want to discard changes you made to the annotations?',
  ACTION_CANNOT_BE_UNDONE: 'This action cannot be undone.',
  INFO: 'File information',
  COLORS: {
    BASIC: 'Basic colors',
    FAVORITES: 'Favorite colors',
    OPACITY: 'Opacity',
  },
  VIEWER_SETTINGS: {
    PAGE_TRANSITION: 'Page transition',
    SCROLL_DIRECTION: 'Scroll Direction',
    PAGE_LAYOUT: 'Page Layout',
    ANNOTATION_OPTIONS: 'Annotation options',
    GENERATE_STREAM: 'Generate appearance stream',
    DEFAULT_TITLE: 'Default annotation title',
  },
  ERROR: {
    DEFAULT_ERROR: 'Error occurred',
    NO_FILE_SPECIFIED: 'No PDF file specified',
    EMPTY_FILE: 'PDF file contains no pages',
    EMPTY_FILE_ALERT: 'This PDF file contains no pages',
    REMOVE_ALL_PAGES_ERROR: 'You cannot delete all pages. At least one page must remain',
  },
  LINE: {
    STYLE: 'Line Style',
    WIDTH: 'Line Width',
  },
  OUTLINES: {
    ADD_ITEM: 'Add Item',
    EMPTY_OUTLINE_ERROR: 'You cannot create outline with empty title',
  },
  SHAPE: {
    LINE: 'Line',
    BACKGROUND: 'Background',
  },
  IMAGE: {
    FROM_COMPUTER: 'My Computer',
    FROM_GOOGLE_DRIVE: 'Google Drive',
    LOAD_ERROR: 'The image could not be loaded',
  },
};

export const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 24, 48, 60, 72, 96];

export const SYMBOLS_TO_LOAD_FONTS = 'tфіの그他τộथीআא';

export const TAG_NAMES = {
  TEXTAREA: 'TEXTAREA',
  INPUT: 'INPUT',
};

export const SHAPE_ANNOTATIONS = [AnnotationType.Line, AnnotationType.SquareCircle, AnnotationType.Triangle];

export const TEXT_SELECT_ACTION_TYPES = [
  AnnotateActionType.Highlight,
  AnnotateActionType.Squiggly,
  AnnotateActionType.Strikeout,
  AnnotateActionType.Underline,
];

export const IMAGE_MIME_TYPES = 'image/jpg,image/jpeg,image/png,image/bmp,image/gif';
export const IMAGE_ACCEPT_TYPES = '.jpg,.jpeg,.png,.bmp,.gif';

export const DOUBLE_CLICK_DELAY = 500;

export const MAX_LINE_WIDTH = {
  [AnnotationType.Markup]: 0,
  [AnnotationType.TextMarkup]: 0,
  [AnnotationType.FreeText]: 0,
  [AnnotationType.Text]: 0,
  [AnnotationType.Line]: 12,
  [AnnotationType.SquareCircle]: 12,
  [AnnotationType.Triangle]: 12,
  [AnnotationType.Stamp]: 0,
  [AnnotationType.Ink]: 50,
};

export const DEFAULT_ROTATION_ANGLE_DELTA = 90;
export const MAX_ROTATION_ANGLE = 360;

export const MAX_COLOR_LIST_LENGTH = 10;

export const SCALE_ROUND_MULTIPLIER = 100;
export const SCALE_STEP = 10;

export const ACTIONS_TO_PREVENT_TEXT_SELECTION = [
  AnnotateActionType.FreeText,
  AnnotateActionType.Shape,
  AnnotateActionType.StickyNote,
];
