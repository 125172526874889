import React, { FC, memo, useEffect } from 'react';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useSelector } from 'react-redux';

import Header from './shared/layouts/header/Header';
import PdfViewer from './shared/layouts/pdfViewer/PdfViewer';
import FileProvider from './shared/layouts/fileContext/FileContext';
import LoadingOverlay from './shared/layouts/loadingOverlay/LoadingOverlay';
import ViewerSideBar from './shared/layouts/viewerSideBar/ViewerSideBar';
import FontLoader from './shared/components/fontLoader/FontLoader';
import { isPageNavigationMode } from './store/viewerSettings/selectors';
import ThumbnailsViewer from './shared/layouts/thumbmailViewer/ThumbnailsViewer';

import './App.less';

const KEYS_TO_IGNORE = ['=', '-'];

const App: FC = () => {
  const pageNavigationMode = useSelector(isPageNavigationMode);

  const keyboardEventsListener = (e: KeyboardEvent) => {
    KEYS_TO_IGNORE.includes(e.key) && e.ctrlKey && e.preventDefault();
  };
  useEffect(() => {
    window.addEventListener('keypress', keyboardEventsListener, { passive: false });
    window.addEventListener('keyup', keyboardEventsListener, { passive: false });
    window.addEventListener('keydown', keyboardEventsListener, { passive: false });
    return () => {
      window.removeEventListener('keypress', keyboardEventsListener);
      window.removeEventListener('keyup', keyboardEventsListener);
      window.removeEventListener('keydown', keyboardEventsListener);
    };
  }, []);

  return (
    <div className="flexcil-app">
      <FileProvider>
        <Layout>
          <FontLoader />
          <Header />
          {!pageNavigationMode ? (
            <Layout hasSider>
              <ViewerSideBar />
              <Content>
                <PdfViewer />
                <LoadingOverlay />
              </Content>
            </Layout>
          ) : (
            <Layout>
              <Content>
                <ThumbnailsViewer />
              </Content>
            </Layout>
          )}
        </Layout>
      </FileProvider>
    </div>
  );
};

export default memo(App);
