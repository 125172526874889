import { gapi } from 'gapi-script';
import { REACT_APP_GOOGLE_DRIVE_API_KEY, REACT_APP_GOOGLE_DRIVE_CLIENT_ID } from '../shared/constants/application';
import { CustomAny } from '../shared/types/generics';
import { IGoogleUser } from '../store/interfaces/IGoogleUser';

// Array of API discovery doc URLs for APIs
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/drive.readonly';

export const initGDriveClient = (onSigninUpdate: (userData: IGoogleUser) => void): void => {
  gapi.load('client:auth2', initClient.bind(null, onSigninUpdate));
};

/**
 *  Initializes the API client library and sets up sign-in state
 *  listeners.
 */
export const initClient = (onSigninUpdate: (userData: IGoogleUser) => void): void => {
  gapi.client
    .init({
      apiKey: REACT_APP_GOOGLE_DRIVE_API_KEY,
      clientId: REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    })
    .then(
      () => {
        const onSignedIn = (isSigned: boolean) => {
          return onSigninUpdate({
            isSigned: gapi.auth2.getAuthInstance().isSignedIn.get(),
            name: isSigned ? gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getName() : null,
            image_url: isSigned ? gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getImageUrl() : null,
          });
        };
        // Listen for sign-in state changes.
        gapi.auth2.getAuthInstance().isSignedIn.listen(onSignedIn);

        // Handle the initial sign-in state.
        onSignedIn(gapi.auth2.getAuthInstance().isSignedIn.get());
      },
      (error: Error) => {
        console.error(error);
      },
    );
};

/**
 *  Sign in the user upon button click.
 */
export const signIn = (): void => {
  gapi.auth2.getAuthInstance().signIn();
};

/**
 *  Sign out the user upon button click.
 */
export const signOut = (): void => {
  gapi.auth2.getAuthInstance().signOut();
};

export const retrieveAllFiles = (): Promise<CustomAny> =>
  new Promise((resolve) => {
    const listRequestProps = {
      q: 'mimeType="application/pdf"',
      pageSize: 25,
      fields:
        'nextPageToken, files(id, name, trashed, modifiedTime, size, webContentLink, mimeType, shared, ownedByMe)',
    };
    const retrievePageOfFiles = (request: CustomAny, result: CustomAny): void => {
      request.execute((resp: CustomAny): void => {
        result = result.concat(resp.files);
        const nextPageToken = resp.nextPageToken;
        if (nextPageToken) {
          request = gapi.client.drive.files.list({
            ...listRequestProps,
            pageToken: nextPageToken,
          });
          retrievePageOfFiles(request, result);
        } else {
          resolve(result);
        }
      });
    };

    const initialRequest = gapi.client.drive.files.list(listRequestProps);
    retrievePageOfFiles(initialRequest, []);
  });

/**
 * Print a file's metadata.
 *
 * @param {String} fileId ID of the file.
 */
export const getFileFromDisk = (fileId: string): Promise<CustomAny> => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    const accessToken = gapi.auth.getToken().access_token;
    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        resolve(xhr.response);
      }
    };
    xhr.open('GET', 'https://www.googleapis.com/drive/v3/files/' + fileId + '?alt=media', true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.send();
  });
};

export const getOAuthToken = (): string => gapi.auth.getToken().access_token;
