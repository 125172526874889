import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu, Typography, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import useDrivePicker from 'react-google-drive-picker';

import Button from '../../components/button/Button';
import { ButtonType } from '../button/enums/buttonType';
import { AnnotateActionType, AnnotateActionTypeLabel } from '../../../store/enums/annotateActionType';
import { getNumPages, getStoredFileId } from '../../../store/viewerSettings/selectors';
import { getActiveAction, getColor, getIsEditing, getIsImageEmpty } from '../../../store/annotateSettings/selectors';
import { setActiveAction, setImageBase64 } from '../../../store/annotateSettings/actions';
import { AnyObject } from '../../types/generics';
import { useKeyPress } from 'react-use';
import {
  HOTKEYS_MAP,
  IMAGE_ACCEPT_TYPES,
  IMAGE_MIME_TYPES,
  REACT_APP_GOOGLE_DRIVE_API_KEY,
  REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
  TAG_NAMES,
  TEXT_MAP,
} from '../../constants/application';
import { rgbAdjustOpacity } from '../../helpers/colorHelper';
import { getFileFromDisk, getOAuthToken } from '../../../services/gDriveService';
import { fileToBase64 } from '../../helpers/fileHelper';
import { setAppLoading, setFileStatus } from '../../../store/viewerSettings/actions';
import { FileProgressStatus } from '../../../store/enums/fileProgressStatus';
import { setActiveAnnotation } from '../../../store/annotations/actions';

import './NoteToolsPanel.less';

import { ReactComponent as FreeText } from '../../../assets/FreeText.svg';
import { ReactComponent as StickyNote } from '../../../assets/StickyNoteStroke.svg';
import { ReactComponent as Image } from '../../../assets/Top_Image.svg';
import { ReactComponent as UploadIcon } from '../../../assets/Upload.svg';
import { ReactComponent as GoogleDrive } from '../../../assets/GoogleDrive.svg';

interface INoteToolsPanel {
  googleFileUploadEnabled: boolean;
}

const NoteToolsPanel: FC<INoteToolsPanel> = (props) => {
  const dispatch = useDispatch();
  const [openPicker, data] = useDrivePicker();

  const isFreeTextPressed = useKeyPress('t')[0];
  const isStickyNotePressed = useKeyPress('s')[0];
  const isImagePressed = useKeyPress('i')[0];
  const activeAction: AnnotateActionType | undefined = useSelector(getActiveAction);
  const storedFileId = useSelector(getStoredFileId);
  const isEditing = useSelector(getIsEditing);
  const isImageEmpty = useSelector(getIsImageEmpty);
  const color: AnyObject = useSelector(getColor);
  const numPages: number = useSelector(getNumPages);
  const disabled = useMemo(() => !storedFileId || numPages === 0, [storedFileId, numPages]);
  const [imageMenuVisible, setImageMenuVisible] = useState<boolean>(false);

  useEffect(() => {
    if (activeAction !== AnnotateActionType.Image) {
      setImageMenuVisible(false);
      dispatch(setImageBase64(null));
    }
  }, [activeAction]);

  useEffect(
    useCallback(() => {
      if (!isImageEmpty && activeAction !== AnnotateActionType.Image) {
        dispatch(setActiveAction(AnnotateActionType.Image));
      } else if (!imageMenuVisible && isImageEmpty && activeAction === AnnotateActionType.Image) {
        dispatch(setActiveAction(undefined));
      }
    }, [imageMenuVisible, isImageEmpty, activeAction]),
    [imageMenuVisible, isImageEmpty],
  );

  const onImageClick = useCallback(() => {
    if (!isImageEmpty && activeAction === AnnotateActionType.Image) {
      dispatch(setActiveAction(undefined));
    }
  }, [imageMenuVisible, isImageEmpty, activeAction]);

  const onFreeTextClick = useCallback(
    () => disabled || toggleAction(AnnotateActionType.FreeText),
    [activeAction === AnnotateActionType.FreeText, disabled],
  );
  const onStickyNotesClick = useCallback(
    () => disabled || toggleAction(AnnotateActionType.StickyNote),
    [activeAction === AnnotateActionType.StickyNote, disabled],
  );

  const getActionColor = useCallback(
    (type: AnnotateActionType) => {
      return rgbAdjustOpacity(color[type].list[color[type].activeIndex], 1);
    },
    [color],
  );

  const toggleAction = useCallback(
    (type: AnnotateActionType) => {
      dispatch(setImageBase64(null));
      if (activeAction === type) {
        return dispatch(setActiveAction(undefined));
      }
      return dispatch(setActiveAction(type));
    },
    [activeAction],
  );
  // Key press events
  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isFreeTextPressed && !isEditing && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      dispatch(setActiveAction(AnnotateActionType.FreeText));
    }
  }, [isFreeTextPressed, isEditing]);
  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isStickyNotePressed && !isEditing && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      dispatch(setActiveAction(AnnotateActionType.StickyNote));
    }
  }, [isStickyNotePressed, isEditing]);
  useEffect(() => {
    const activeTagName = document.activeElement?.tagName || '';
    if (isImagePressed && !isEditing && ![TAG_NAMES.TEXTAREA, TAG_NAMES.INPUT].includes(activeTagName)) {
      setImageMenuVisible(true);
      dispatch(setActiveAction(AnnotateActionType.Image));
    }
  }, [isImagePressed, isEditing]);

  useEffect(() => {
    const getFile = async (): Promise<void> => {
      if (data && data.docs && data.docs.length > 0) {
        dispatch(setAppLoading(true));
        dispatch(setFileStatus(FileProgressStatus.UPLOADING));
        const fileId = data.docs[0].id;
        const file = await getFileFromDisk(fileId);
        const base64 = await fileToBase64(file);
        dispatch(setImageBase64(base64.join()));
        dispatch(setActiveAction(AnnotateActionType.Image));
        dispatch(setAppLoading(false));
        dispatch(setActiveAnnotation(null));
      }
    };
    getFile();
  }, [data]);

  const getButtonType = (type: AnnotateActionType) => (type === activeAction ? ButtonType.Primary : ButtonType.Text);

  const onFileUploaded = async (info: UploadChangeParam) => {
    dispatch(setAppLoading(true));
    dispatch(setFileStatus(FileProgressStatus.UPLOADING));
    const file = (info.file.originFileObj || info.file) as File;
    const base64 = await fileToBase64(file);
    dispatch(setImageBase64(base64.join()));
    dispatch(setActiveAction(AnnotateActionType.Image));
    dispatch(setAppLoading(false));
    dispatch(setActiveAnnotation(null));
  };

  const initGooglePicker = () => {
    openPicker({
      clientId: REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
      developerKey: REACT_APP_GOOGLE_DRIVE_API_KEY,
      viewId: 'DOCS',
      token: getOAuthToken(),
      supportDrives: true,
      multiselect: false,
      viewMimeTypes: IMAGE_MIME_TYPES,
    });
  };

  const imageUploadMenu = useMemo(
    () => (
      <Menu>
        <Upload
          showUploadList={false}
          multiple={false}
          beforeUpload={() => false}
          customRequest={() => {}}
          accept={IMAGE_ACCEPT_TYPES}
          onChange={onFileUploaded}
        >
          <Menu.Item key={TEXT_MAP.IMAGE.FROM_COMPUTER} icon={<UploadIcon />}>
            <Typography.Text>{TEXT_MAP.IMAGE.FROM_COMPUTER}</Typography.Text>
          </Menu.Item>
        </Upload>
        <Menu.Item
          key={TEXT_MAP.IMAGE.FROM_GOOGLE_DRIVE}
          icon={<GoogleDrive />}
          disabled={!props.googleFileUploadEnabled}
          onClick={initGooglePicker}
        >
          <Typography.Text>{TEXT_MAP.IMAGE.FROM_GOOGLE_DRIVE}</Typography.Text>
        </Menu.Item>
      </Menu>
    ),
    [props.googleFileUploadEnabled],
  );

  return (
    <div className="note-tools-panel">
      <Button
        ghost={getButtonType(AnnotateActionType.FreeText) === ButtonType.Primary}
        type={getButtonType(AnnotateActionType.FreeText)}
        icon={
          <FreeText
            className="assets-icon assets-icon_center assets-icon_colorized"
            style={
              {
                '--fill-color': 'white',
                '--stroke-color': 'none',
              } as React.CSSProperties
            }
          />
        }
        onClick={onFreeTextClick}
        disabled={disabled}
        tooltip={useMemo(
          () => (
            <>
              {AnnotateActionTypeLabel.FreeText}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.FREE_TEXT}
              </Typography.Text>
            </>
          ),
          [],
        )}
      />
      <Dropdown
        overlay={imageUploadMenu}
        visible={imageMenuVisible}
        onVisibleChange={setImageMenuVisible}
        trigger={['click']}
        disabled={disabled}
      >
        <Button
          ghost={getButtonType(AnnotateActionType.Image) === ButtonType.Primary}
          type={getButtonType(AnnotateActionType.Image)}
          icon={
            <Image
              className="assets-icon assets-icon_center assets-icon_colorized"
              style={
                {
                  '--fill-color': 'white',
                  '--stroke-color': 'none',
                } as React.CSSProperties
              }
            />
          }
          onClick={onImageClick}
          disabled={disabled}
          tooltip={useMemo(
            () => (
              <>
                {AnnotateActionTypeLabel.Image}
                <Typography.Text className="tooltip_secondary" type="secondary">
                  &nbsp;{HOTKEYS_MAP.IMAGE}
                </Typography.Text>
              </>
            ),
            [],
          )}
        />
      </Dropdown>
      <Button
        ghost={getButtonType(AnnotateActionType.StickyNote) === ButtonType.Primary}
        type={getButtonType(AnnotateActionType.StickyNote)}
        icon={useMemo(
          () => (
            <StickyNote
              className="assets-icon assets-icon_center assets-icon_colorized assets-icon_sticky"
              style={
                {
                  '--fill-color': getActionColor(AnnotateActionType.StickyNote),
                  '--stroke-color': 'white',
                  width: '25px',
                } as React.CSSProperties
              }
            />
          ),
          [getActionColor],
        )}
        onClick={onStickyNotesClick}
        disabled={disabled}
        tooltip={useMemo(
          () => (
            <>
              {AnnotateActionTypeLabel.StickyNote}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.STICKY_NOTE}
              </Typography.Text>
            </>
          ),
          [],
        )}
      />
    </div>
  );
};

export default NoteToolsPanel;
