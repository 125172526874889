import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Page } from 'react-pdf';
import { PDFPageProxy, TextItem } from 'react-pdf/dist/Page';
import { useSelector } from 'react-redux';
import { useIntersection } from 'use-intersection';
import _ from 'lodash';
import classNames from 'classnames';
import { usePrevious } from 'react-use';
import { Spin } from 'antd';

import { A4Height, A4Width } from '../../toolbar/scaleSelect/scaleHelper';
import { getTransformToRotate, swapWidthAndHeight } from '../../../helpers/canvasHelper';
import { MAX_ROTATION_ANGLE } from '../../../constants/application';
import { isLoadingFonts } from '../../../../store/fonts/selectors';
import FabricCanvas from '../../fabricCanvas/FabricCanvas';
import { IAnnotationEntity } from '../../../../services/interfaces/IAnnotation';
import { useMounted } from '../../../hooks/useIsMounted';

interface IPage {
  pageNumber: number;
  scale: number;
  rotationAngle: number;
  activePage: number;
  onViewportUpdated(page: number, viewport: number[]): void;
  setActivePage(page: number): void;
  onCopyAnnotation(annotation: IAnnotationEntity): void;
  onPageInViewport?(page: number, data: { isIntersecting?: boolean; intersectionRatio?: number }): void;
  getPageWidth(page: number): number;
  getPageHeight(page: number): number;
}

const PdfPage: FC<IPage> = (props) => {
  const loadingFonts: boolean = useSelector(isLoadingFonts);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const intersectionRef = useRef(null);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [angle, setAngle] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [textFields, setTextFields] = useState<TextItem[]>([]);
  const [canvasViewport, setCanvasViewport] = useState<number[]>([0, 0, A4Width, A4Height]);
  const [updateImage, setUpdateImage] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [intersectionRatio, setIntersectionRatio] = useState(0);
  const prevCanvasViewport = usePrevious(canvasViewport);
  const isMounted = useMounted();

  useIntersection(
    intersectionRef,
    {
      threshold: [0, 0.2, 0.4, 0.5, 0.6, 0.8, 1],
    },
    useCallback(
      (entry) => {
        if (!isMounted()) return;
        if (isIntersecting !== entry.isIntersecting) {
          setIsIntersecting(entry.isIntersecting);
        }
        if (intersectionRatio !== entry.intersectionRatio) {
          setIntersectionRatio(entry.intersectionRatio);
        }
      },
      [isIntersecting, intersectionRatio, isMounted],
    ),
  );
  useEffect(
    useCallback(() => {
      return () => {
        props.onPageInViewport?.(props.pageNumber, { isIntersecting: false, intersectionRatio: 0 });
      };
    }, [props.onPageInViewport]),
    [],
  );

  const setPageData = useCallback(
    (items: TextItem[], canvasView: number[], viewport: number[]) => {
      if (!isMounted()) return;
      setTextFields(items);
      setCanvasViewport([...canvasView]);
      setImageHeight(
        swapWidthAndHeight(props.rotationAngle)
          ? Math.round(viewport[2] - viewport[0])
          : Math.round(viewport[3] - viewport[1]),
      );
      setImageWidth(
        swapWidthAndHeight(props.rotationAngle)
          ? Math.round(viewport[3] - viewport[1])
          : Math.round(viewport[2] - viewport[0]),
      );
    },
    [isMounted, props.rotationAngle],
  );

  const onPageLoaded = async (page: PDFPageProxy) => {
    setImageHeight(0);
    setImageWidth(0);
    const { items } = await page.getTextContent();
    const viewport = [...page.view];
    const canvasView = viewport;
    if ([90, 270].includes(page.rotate)) {
      [canvasView[2], canvasView[3]] = [canvasView[3], canvasView[2]];
    }
    setTimeout(() => {
      setPageData(items as TextItem[], canvasView, viewport);
    }, 100);
  };
  useEffect(() => {
    isMounted() && setUpdateImage(true);
  }, [props.pageNumber, isMounted]);
  const onRenderSuccess = useCallback(() => {
    if (canvasRef && canvasRef.current && (!image || updateImage) && isMounted()) {
      setImage(canvasRef.current.toDataURL());
      setAngle(props.rotationAngle);
      setUpdateImage(false);
    }
  }, [canvasRef, image, props.rotationAngle, props.pageNumber, updateImage, isMounted]);
  useEffect(() => {
    if (prevCanvasViewport && !_.isEqual(canvasViewport, prevCanvasViewport)) {
      props.onViewportUpdated(props.pageNumber, canvasViewport);
    }
  }, [canvasViewport, prevCanvasViewport]);
  useEffect(() => {
    props.onPageInViewport?.(props.pageNumber, { isIntersecting, intersectionRatio });
  }, [isIntersecting, intersectionRatio]);

  const transform = useMemo(() => {
    return getTransformToRotate((props.rotationAngle - angle + MAX_ROTATION_ANGLE) % MAX_ROTATION_ANGLE);
  }, [props.rotationAngle, angle]);

  const loadingPreview = useMemo(() => {
    return (
      <div
        className={classNames('page-container_loading', { visible: !image })}
        style={{ width: props.getPageWidth(props.pageNumber), height: props.getPageHeight(props.pageNumber) }}
      >
        <Spin />
      </div>
    );
  }, [image, props.pageNumber, props.getPageWidth, props.getPageHeight]);

  const imagePreview = useMemo(() => {
    return image ? (
      <img
        alt=""
        className="page-container_preview"
        style={{
          width: imageWidth * props.scale,
          height: imageHeight * props.scale,
          transform,
        }}
        src={image}
      />
    ) : null;
  }, [image, imageWidth, imageHeight, transform, props.scale]);

  return (
    <div id={`page-${props.pageNumber}`} className="page-container" ref={intersectionRef}>
      {loadingPreview}
      {imagePreview}
      {!loadingFonts ? (
        <FabricCanvas
          viewport={canvasViewport}
          page={props.pageNumber}
          activePage={props.activePage}
          textFields={textFields}
          setActivePage={props.setActivePage}
          onCopyAnnotation={props.onCopyAnnotation}
        />
      ) : null}
      {props.pageNumber !== -1 ? (
        <Page
          canvasRef={canvasRef}
          pageNumber={props.pageNumber}
          scale={props.scale}
          rotate={props.rotationAngle}
          loading=""
          renderAnnotationLayer={false}
          renderTextLayer={false}
          onLoadSuccess={onPageLoaded}
          onRenderSuccess={onRenderSuccess}
        />
      ) : null}
    </div>
  );
};

export default memo(PdfPage);
