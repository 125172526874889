export enum AnnotationType {
  Markup = 'MARKUP',
  TextMarkup = 'TEXT_MARKUP',
  FreeText = 'FREE_TEXT',
  Text = 'TEXT',
  Line = 'LINE',
  SquareCircle = 'SQUARE_CIRCLE',
  Triangle = 'TRIANGLE',
  Stamp = 'STAMP',
  Ink = 'INK',
}
