import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TreeSelect } from 'antd';
import { Plus } from 'react-bootstrap-icons';

import { getActiveAction, getLineStyle } from '../../../store/annotateSettings/selectors';
import { CustomAny } from '../../types/generics';
import LineStyleItem from './lineStyleItem/LineStyleItem';
import { ILineStyle } from './interfaces/ILineStyle';
import LineStyleDialog from '../lineStyleDialog/LineStyleDialog';
import { addLineStyle, selectLineStyle, setLineStyle } from '../../../store/annotateSettings/actions';

import './LineStyleSelect.less';
import { MAX_COLOR_LIST_LENGTH } from '../../constants/application';

const LineStyleSelect: FC = () => {
  const dispatch = useDispatch();
  const lineStyle = useSelector(getLineStyle);
  const activeAction = useSelector(getActiveAction);
  const lineStyleList = useMemo(() => {
    return (lineStyle as CustomAny)[activeAction || ''].list || [];
  }, [lineStyle, activeAction]);
  const activeLineStyleIndex = useMemo(() => {
    return (lineStyle as CustomAny)[activeAction || ''].activeIndex || 0;
  }, [lineStyle, activeAction]);
  const noBorderStyleIndex = useMemo(() => {
    return lineStyleList.findIndex((lineStyleItem: ILineStyle) => lineStyleItem.borderStyle === null);
  }, [lineStyleList]);
  const [openLineStyleDialog, setOpenLineStyleDialog] = useState<boolean>(false);
  const [addingStyle, setAddingStyle] = useState<boolean>(false);
  const [styleToEdit, setStyleToEdit] = useState<number>(-1);

  const onSelect = (value: number) => {
    if (value === -1) {
      setOpenLineStyleDialog(true);
      setAddingStyle(true);
    } else {
      dispatch(selectLineStyle(value));
    }
  };

  const onEdit = (index: number) => {
    setStyleToEdit(index);
    setOpenLineStyleDialog(true);
  };

  const onCancel = () => {
    setOpenLineStyleDialog(false);
    setAddingStyle(false);
    setStyleToEdit(-1);
  };

  const saveStyle = useCallback(
    (lineStyle: ILineStyle) => {
      if ((!lineStyle.borderStyle || !lineStyle.borderColor || !lineStyle.borderWidth) && noBorderStyleIndex !== -1) {
        onSelect(noBorderStyleIndex);
      } else {
        if (addingStyle) {
          dispatch(addLineStyle(lineStyle));
        } else {
          dispatch(setLineStyle({ style: lineStyle, index: styleToEdit !== -1 ? styleToEdit : lineStyleList.length }));
        }
      }
      setOpenLineStyleDialog(false);
      setAddingStyle(false);
      setStyleToEdit(-1);
    },
    [addingStyle, lineStyleList, styleToEdit, noBorderStyleIndex],
  );

  const items = useMemo(() => {
    const list = lineStyleList.map((lineStyle: ILineStyle, index: number) => ({
      title: <LineStyleItem key={index} index={index} lineStyle={lineStyle} onEdit={() => onEdit(index)} />,
      value: index,
      isLeaf: true,
      showArrow: false,
    }));
    if (list.length < MAX_COLOR_LIST_LENGTH) {
      list.push({
        title: (
          <div className="add-item">
            <Plus size={20} />
          </div>
        ),
        value: -1,
        isLeaf: true,
        showArrow: false,
      });
    }
    return list;
  }, [lineStyleList]);

  return (
    <div>
      <TreeSelect
        className="line-style-select"
        dropdownClassName="ignore-on-deselect"
        value={activeLineStyleIndex}
        treeData={items}
        onSelect={onSelect}
      />
      <LineStyleDialog
        visible={openLineStyleDialog}
        style={styleToEdit !== -1 ? lineStyleList[styleToEdit] : null}
        onCancel={onCancel}
        onOk={saveStyle}
      />
    </div>
  );
};

export default LineStyleSelect;
