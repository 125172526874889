import React, { FC, useMemo } from 'react';
import { Dropdown as AntdDropdown } from 'antd';

import DropdownMenu from './DropdownMenu';
import { IDropdownItem } from './interfaces/IDropdownItem';
import { AnyObject } from '../../types/generics';

interface IDropdownProps {
  items?: IDropdownItem[];
  visible?: boolean;
  onVisibleChange?(isVisible: boolean): void;
}

const Dropdown: FC<IDropdownProps> = (props) => {
  const additionalProps = useMemo(() => {
    const addProps: AnyObject = {};
    if (typeof props.visible === 'boolean') {
      addProps.visible = props.visible;
    }
    return addProps;
  }, [props.visible]);
  return (
    <AntdDropdown
      overlay={<DropdownMenu items={props.items} />}
      trigger={['click']}
      arrow
      onVisibleChange={props.onVisibleChange}
      {...additionalProps}
    >
      {props.children}
    </AntdDropdown>
  );
};

export default Dropdown;
