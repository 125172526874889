import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IAnnotationComment } from './interfaces/IAnnotationComment';
import { IAnnotationEntity } from '../../../services/interfaces/IAnnotation';
import { getAnnotationsList } from '../../../store/annotations/selectors';
import AnnotationCommentView from './annotationCommentView/AnnotationCommentView';
import AnnotationCommentForm from './annotationCommentForm/AnnotationCommentForm';
import { IAnnotationCommentFormData } from './annotationCommentForm/interfaces/IAnnotationCommentFormData';
import { editComment, findAnnotationIndex, removeAnnotation } from '../../../services/annotationService';
import { deleteAnnotation, setActiveAnnotation, updateAnnotation } from '../../../store/annotations/actions';
import { rememberState } from '../../../store/historyStore/actions';

import './AnnotationComment.less';

import { ReactComponent as Comment } from '../../../assets/Comment.svg';

const AnnotationComment: FC<IAnnotationComment> = (props) => {
  const dispatch = useDispatch();
  const cardRef = useRef(null);
  const annotationsList: IAnnotationEntity[] = useSelector(getAnnotationsList);
  const [isEdit, setIsEdit] = useState(props.isNew);
  const isEditDisabled = useMemo(
    () => props.annotation.readOnly || props.annotation.locked || props.annotation.lockedContents,
    [props.annotation],
  );
  const isDeleteDisabled = useMemo(() => props.annotation.readOnly || props.annotation.locked, [props.annotation]);
  const creationDate = useMemo(() => {
    if (!props.annotation.creationDateInMillis) {
      return '';
    }
    return new Date(props.annotation.creationDateInMillis).toLocaleString();
  }, [props.annotation]);
  const onEditClick = useCallback(() => setIsEdit(true), []);
  const onEditSubmit = useCallback(
    (formData: IAnnotationCommentFormData) => {
      const index = findAnnotationIndex(annotationsList, props.annotation);
      const annotation = editComment(props.annotation, formData);
      setIsEdit(false);
      if (props.isNew) {
        props.clearNewReply?.(props.annotation.id || '');
      }
      if (!annotation) {
        return;
      }
      dispatch(
        updateAnnotation({
          index,
          annotation,
        }),
      );
      const newAnnotationList = [...annotationsList];
      newAnnotationList[index] = annotation;
      dispatch(rememberState(newAnnotationList));
    },
    [props.annotation, annotationsList, props.isNew, props.clearNewReply],
  );
  const onEditCancel = useCallback(() => {
    setIsEdit(false);
    if (props.isNew) {
      props.clearNewReply?.(props.annotation.id || '');
      const index = findAnnotationIndex(annotationsList, props.annotation);
      if (props.annotation.idToReply) {
        dispatch(deleteAnnotation(index));
        dispatch(rememberState(removeAnnotation(annotationsList, index) as IAnnotationEntity[]));
      }
    }
  }, [props.isNew, props.clearNewReply, annotationsList]);
  const onDeleteClick = useCallback(() => {
    if (props.annotation.readOnly || props.annotation.locked) {
      return;
    }
    dispatch(setActiveAnnotation(null));
    const index = findAnnotationIndex(annotationsList, props.annotation);
    if (props.annotation.idToReply) {
      dispatch(deleteAnnotation(index));
      dispatch(rememberState(removeAnnotation(annotationsList, index) as IAnnotationEntity[]));
    }
  }, [props.annotation, annotationsList]);

  return (
    <div className="annotation-comment" ref={cardRef}>
      <Comment className="annotation-comment__icon" />
      {isEdit || props.isNew ? (
        <AnnotationCommentForm creationDate={creationDate} onSubmit={onEditSubmit} onCancel={onEditCancel} {...props} />
      ) : (
        <AnnotationCommentView
          creationDate={creationDate}
          onEditClick={!isEditDisabled ? onEditClick : undefined}
          onDeleteClick={!isDeleteDisabled ? onDeleteClick : undefined}
          {...props}
        />
      )}
    </div>
  );
};

export default memo(AnnotationComment);
