export enum AnnotateStyle {
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
}

export enum AnnotateStyleLabel {
  Bold = 'Bold',
  Italic = 'Italic',
  Underline = 'Underline',
}

export enum TextStyle {
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Normal = 'normal',
}
