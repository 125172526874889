import { createReducer } from '@reduxjs/toolkit';

import { saveFonts, setLoadingFonts } from './actions';
import { IFonts } from '../interfaces/IFonts';

const initialState: IFonts = {
  list: [],
  loadingFonts: true,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(saveFonts, (state, action) => {
      state.list = action.payload;
    })
    .addCase(setLoadingFonts, (state, action) => {
      state.loadingFonts = action.payload;
    });
});
