import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Divider, Modal, Typography } from 'antd';
import { useKeyPress } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Check2, ExclamationTriangle, ThreeDots } from 'react-bootstrap-icons';
import classNames from 'classnames';

import Toolbar from '../toolbar/Toolbar';
import Button from '../../components/button/Button';
import { ButtonType } from '../../components/button/enums/buttonType';
import { ReactComponent as SideMenu } from '../../../assets/Top_Sidemenu.svg';
import { HOTKEYS_MAP, TEXT_MAP, TOOLTIPS_MAP } from '../../constants/application';
import Dropdown from '../../components/dropdown/Dropdown';
import { ReactComponent as PageOptions } from '../../../assets/Pageoption.svg';
import ScaleSelect from '../toolbar/scaleSelect/ScaleSelect';
import {
  getFileInfo,
  getNumPages,
  getPageLayout,
  getRotationAngle,
  getStoredFileId,
  isAppLoading,
  isHorizontalMode,
  isShowAnnotationsPanel,
  isSinglePageView,
} from '../../../store/viewerSettings/selectors';
import { getDefaultTitle, isGenerateAStream } from '../../../store/annotateSettings/selectors';
import {
  setAppLoading,
  setHorizontalMode,
  setPageLayout,
  setPageNavigationMode,
  setPageTransitionMode,
  setRotationAngle,
  toggleShowAnnotationsPanel,
} from '../../../store/viewerSettings/actions';
import { IDropdownItem } from '../../components/dropdown/interfaces/IDropdownItem';
import { DropdownItemType } from '../../components/dropdown/enum/DropdownItemType';
import { PageTransition } from '../toolbar/toolbarLeft/enums/pageTransition';
import { ScrollDirection } from '../toolbar/toolbarLeft/enums/scrollDirection';
import { PageLayout } from '../toolbar/toolbarLeft/enums/pageLayout';
import { setDefaultTitle, setGenerateAStream } from '../../../store/annotateSettings/actions';
import TextMarkupPanel from '../../components/textMarkupPanel/TextMarkupPanel';
import NoteToolsPanel from '../../components/noteToolsPanel/NoteToolsPanel';
import { ReactComponent as RedoIcon } from '../../../assets/redo.svg';
import { redo, rememberState, restore, undo } from '../../../store/historyStore/actions';
import { ReactComponent as Navigation } from '../../../assets/Navigation.svg';
import DialogAttachFile from '../toolbar/dialogAttachFile/DialogAttachFile';
import { AnyObject, OrNull } from '../../types/generics';
import { buildFile } from '../../helpers/fileHelper';
import { getFileFromDisk, initGDriveClient } from '../../../services/gDriveService';
import { IPdfInfoCardData } from '../../components/pdfInfoCard/interfaces/IPdfInfoCardData';
import PdfInfoCard from '../../components/pdfInfoCard/PdfInfoCard';
import { deleteAllAnnotations, setActiveAnnotation } from '../../../store/annotations/actions';
import { removeAllAnnotations } from '../../../services/annotationService';
import { buildMainAppMenuItems } from '../../components/dropdown/helpers';
import { ActionType } from '../../../store/enums/actionType';
import { IGoogleUser } from '../../../store/interfaces/IGoogleUser';
import { setUser } from '../../../store/googleUser/actions';
import { FileContext } from '../fileContext/FileContext';
import { IPdfInfo } from '../../../store/interfaces/IPdfInfo';
import { getUserImage, isSignedIn } from '../../../store/googleUser/selectors';
import { getAnnotationsList } from '../../../store/annotations/selectors';
import { getHistoryIndex, getHistoryLength } from '../../../store/historyStore/selectors';
import { getOutlinesTree } from '../../../store/outlines/selectors';
import { ITreeNode } from '../../components/tree/interfaces/ITreeNode';
import DrawToolsPanel from '../../components/drawToolsPanel/DrawToolsPanel';
import { updateRotationAngle } from '../../helpers/canvasHelper';
import ModalTitle from '../../components/modalTitle/ModalTitle';

import './ViewerToolbar.less';

const ViewerToolbar: FC = () => {
  const dispatch = useDispatch();

  const { file, setFile, saveFile, printFile } = useContext(FileContext);
  const numPages: number = useSelector(getNumPages);
  const fileInfo: IPdfInfo | undefined = useSelector(getFileInfo);
  const isCtrlPressed = useKeyPress('Control')[0];
  const isZPressed = useKeyPress('z')[0];
  const isXPressed = useKeyPress('x')[0];
  const isGoogleSigned = useSelector(isSignedIn);
  const userImage = useSelector(getUserImage);
  const annotations = useSelector(getAnnotationsList);
  const generateAStream: boolean = useSelector(isGenerateAStream);
  const storedFileId = useSelector(getStoredFileId);
  const historyIndex = useSelector(getHistoryIndex);
  const historyLength = useSelector(getHistoryLength);
  const showAnnotationsPanel: boolean = useSelector(isShowAnnotationsPanel);
  const singlePageView: boolean = useSelector(isSinglePageView);
  const horizontalMode: boolean = useSelector(isHorizontalMode);
  const pageLayout: string = useSelector(getPageLayout);
  const defaultTitle: string = useSelector(getDefaultTitle);
  const appLoading: boolean = useSelector(isAppLoading);
  const outlines: ITreeNode[] = useSelector(getOutlinesTree);
  const rotationAngle: number = useSelector(getRotationAngle);
  const [isSignDisabled, setIsSignDisabled] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const [uploadDialogOpened, setUploadDialogOpened] = useState(false);
  const [attachedFile, setAttachedFile] = useState<OrNull<string | File>>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const disabled = useMemo(() => !storedFileId || numPages === 0, [storedFileId, numPages]);
  const isRestoreDisabled = useMemo(() => historyLength <= 1, [historyLength]);
  const onUploadClick = useCallback(() => {
    setMenuVisible(false);
    setUploadDialogOpened(true);
  }, []);

  const onShowAnnotationsClick = useCallback(() => dispatch(toggleShowAnnotationsPanel()), []);
  const settingsItems: IDropdownItem[] = useMemo(
    () => [
      {
        type: DropdownItemType.Group,
        title: TEXT_MAP.VIEWER_SETTINGS.PAGE_TRANSITION,
        children: [
          {
            type: DropdownItemType.Default,
            title: PageTransition.PageByPage,
            icon: (
              <Check2 size={18} className={classNames('dropdown-icon', { 'dropdown-icon_hidden': !singlePageView })} />
            ),
            active: singlePageView,
            onClick: () => dispatch(setPageTransitionMode(true)),
          },
          {
            type: DropdownItemType.Default,
            title: PageTransition.ContinuousPages,
            icon: (
              <Check2 size={18} className={classNames('dropdown-icon', { 'dropdown-icon_hidden': singlePageView })} />
            ),
            active: !singlePageView,
            onClick: () => dispatch(setPageTransitionMode(false)),
          },
        ],
      },
      {
        type: DropdownItemType.Group,
        title: TEXT_MAP.VIEWER_SETTINGS.SCROLL_DIRECTION,
        children: [
          {
            type: DropdownItemType.Default,
            title: ScrollDirection.Vertical,
            icon: (
              <Check2 size={18} className={classNames('dropdown-icon', { 'dropdown-icon_hidden': horizontalMode })} />
            ),
            active: !horizontalMode,
            onClick: () => dispatch(setHorizontalMode(false)),
          },
          {
            type: DropdownItemType.Default,
            title: ScrollDirection.Horizontal,
            icon: (
              <Check2 size={18} className={classNames('dropdown-icon', { 'dropdown-icon_hidden': !horizontalMode })} />
            ),
            active: horizontalMode,
            onClick: () => dispatch(setHorizontalMode(true)),
          },
        ],
      },
      {
        type: DropdownItemType.Group,
        title: TEXT_MAP.VIEWER_SETTINGS.PAGE_LAYOUT,
        children: [
          {
            type: DropdownItemType.Default,
            title: PageLayout.OnePageView,
            icon: (
              <Check2
                size={18}
                className={classNames('dropdown-icon', {
                  'dropdown-icon_hidden': pageLayout !== PageLayout.OnePageView,
                })}
              />
            ),
            active: pageLayout === PageLayout.OnePageView,
            onClick: () => dispatch(setPageLayout(PageLayout.OnePageView)),
          },
          {
            type: DropdownItemType.Default,
            title: PageLayout.TwoPageView,
            icon: (
              <Check2
                size={18}
                className={classNames('dropdown-icon', {
                  'dropdown-icon_hidden': pageLayout !== PageLayout.TwoPageView,
                })}
              />
            ),
            active: pageLayout === PageLayout.TwoPageView,
            onClick: () => dispatch(setPageLayout(PageLayout.TwoPageView)),
          },
          {
            type: DropdownItemType.Default,
            title: PageLayout.TwoPageViewGap,
            icon: (
              <Check2
                size={18}
                className={classNames('dropdown-icon', {
                  'dropdown-icon_hidden': pageLayout !== PageLayout.TwoPageViewGap,
                })}
              />
            ),
            active: pageLayout === PageLayout.TwoPageViewGap,
            onClick: () => dispatch(setPageLayout(PageLayout.TwoPageViewGap)),
          },
        ],
      },
      {
        type: DropdownItemType.Group,
        title: TEXT_MAP.VIEWER_SETTINGS.ANNOTATION_OPTIONS,
        children: [
          {
            type: DropdownItemType.Default,
            title: TEXT_MAP.VIEWER_SETTINGS.GENERATE_STREAM,
            icon: (
              <Check2
                size={18}
                className={classNames('dropdown-icon', {
                  'dropdown-icon_hidden': !generateAStream,
                })}
              />
            ),
            active: generateAStream,
            onClick: () => dispatch(setGenerateAStream(!generateAStream)),
          },
        ],
      },
      {
        type: DropdownItemType.Group,
        title: TEXT_MAP.VIEWER_SETTINGS.DEFAULT_TITLE,
        children: [
          {
            type: DropdownItemType.String,
            value: defaultTitle,
            onChange: (value: string) => dispatch(setDefaultTitle(value)),
          },
        ],
      },
    ],
    [singlePageView, defaultTitle, generateAStream, horizontalMode, pageLayout],
  );
  const onUploadCancel = useCallback(() => setUploadDialogOpened(false), []);
  const onUploadOk = useCallback(
    async (fileData?: AnyObject) => {
      if (fileData?.id) {
        setUploadDialogOpened(false);
        dispatch(setAppLoading(true));
        setFile(
          buildFile(await getFileFromDisk(fileData.id), {
            name: fileData.name,
            type: fileData.mimeType,
            lastModified: new Date(fileData.modifiedTime).getTime(),
          }),
        );
      }
      setUploadDialogOpened(false);
    },
    [attachedFile],
  );
  const handleFileChange = useCallback((file) => {
    setAttachedFile(file);
    if (typeof file !== 'string') {
      setUploadDialogOpened(false);
      setFile(file);
    }
  }, []);
  const onSave = useCallback(() => {
    try {
      setMenuVisible(false);
      saveFile(generateAStream);
    } catch (e) {
      console.error(e);
    }
  }, [annotations, generateAStream, outlines]);

  const onShowProperties = useCallback(() => {
    if (isModalOpen) {
      return;
    }
    const pdfInfo: IPdfInfoCardData = {
      name: file?.name,
      size: file?.size,
      title: fileInfo?.Title,
      creationDate: fileInfo?.CreationDate,
      modificationDate: fileInfo?.ModDate,
      creator: fileInfo?.Creator,
      author: fileInfo?.Author,
      pdfFormatVersion: fileInfo?.PDFFormatVersion,
      numPages,
    };
    Modal.info({
      title: TEXT_MAP.INFO,
      icon: null,
      content: <PdfInfoCard pdfInfo={pdfInfo} />,
      width: 500,
      zIndex: 1100,
      onOk() {
        setIsModalOpen(false);
      },
    });
    setIsModalOpen(true);
  }, [file, fileInfo, numPages, isModalOpen]);

  const onDeleteAll = useCallback(() => {
    dispatch(setActiveAnnotation(null));
    dispatch(deleteAllAnnotations());
    dispatch(rememberState(removeAllAnnotations(annotations)));
  }, [annotations]);

  const onFullScreen = () => {
    const elem = document.body;
    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        console.error(err);
      });
    } else {
      document.exitFullscreen();
    }
  };

  const onPrint = useCallback(() => {
    setMenuVisible(false);
    printFile(generateAStream);
  }, [annotations, generateAStream]);

  const onRestoreAnnotations = useCallback(() => {
    if (isModalOpen) {
      return;
    }
    Modal.confirm({
      className: 'modal-remove',
      title: <ModalTitle title={TEXT_MAP.CONFIRM_RESTORE_ANNOTATIONS} irreversible />,
      icon: <ExclamationTriangle size={24} />,
      okText: 'Restore',
      cancelText: 'Cancel',
      zIndex: 1100,
      onOk: () => {
        setIsModalOpen(false);
        dispatch(restore());
      },
      onCancel: () => {
        setIsModalOpen(false);
      },
    });
    setIsModalOpen(true);
  }, [isModalOpen]);

  const onRotate = useCallback(
    (direction: number) => () => {
      dispatch(setRotationAngle(updateRotationAngle(rotationAngle, direction)));
    },
    [rotationAngle],
  );

  const menuItems: IDropdownItem[] = useMemo(
    () =>
      buildMainAppMenuItems({
        isGoogleSigned,
        isSignDisabled,
        onUploadClick,
        disabled,
        removeAllDisabled: annotations.filter((annotation) => annotation.actionType !== ActionType.Delete).length === 0,
        onSave,
        userImage,
        onShowProperties,
        onDeleteAll,
        onFullScreen,
        onPrint,
        onRestoreAnnotations,
        isRestoreDisabled,
        onRotate,
      }),
    [
      isGoogleSigned,
      isSignDisabled,
      onUploadClick,
      disabled,
      onSave,
      userImage,
      fileInfo,
      numPages,
      onShowProperties,
      annotations,
      onPrint,
      onRestoreAnnotations,
      isRestoreDisabled,
      onRotate,
    ],
  );

  useEffect(() => {
    if (isCtrlPressed && isZPressed) {
      dispatch(undo());
    }
  }, [isCtrlPressed, isZPressed]);
  useEffect(() => {
    if (isCtrlPressed && isXPressed) {
      dispatch(redo());
    }
  }, [isCtrlPressed, isXPressed]);

  useEffect(() => {
    initGDriveClient(onSignin);
  }, []);

  const onSignin = async (userData: IGoogleUser) => {
    setIsSignDisabled(false);
    if (userData.isSigned) {
      dispatch(setUser(userData));
    } else {
      dispatch(setUser({ isSigned: false }));
    }
  };

  const left = useMemo(
    () => (
      <>
        <Button
          type={ButtonType[showAnnotationsPanel ? 'Default' : 'Text']}
          icon={<SideMenu className="assets-icon assets-icon_center" />}
          toggled={showAnnotationsPanel}
          onClick={onShowAnnotationsClick}
          tooltip={TOOLTIPS_MAP.TOOLBAR.SIDE_MENU}
        />
        <Dropdown items={settingsItems}>
          <Button
            type={ButtonType.Text}
            icon={<PageOptions className="assets-icon assets-icon_center" />}
            tooltip={TOOLTIPS_MAP.TOOLBAR.SETTINGS}
          />
        </Dropdown>
        <Divider type="vertical" />
        <ScaleSelect />
      </>
    ),
    [showAnnotationsPanel, settingsItems, onShowAnnotationsClick],
  );

  const center = useMemo(
    () => (
      <div className="toolbar-center__annotate-panel">
        <TextMarkupPanel />
        <Divider type="vertical" />
        <NoteToolsPanel googleFileUploadEnabled={isGoogleSigned} />
        <Divider type="vertical" />
        <DrawToolsPanel />
      </div>
    ),
    [isGoogleSigned],
  );

  const right = useMemo(
    () => (
      <>
        <Button
          type={ButtonType.Text}
          icon={<RedoIcon className="assets-icon assets-icon_center _flipped" />}
          onClick={() => dispatch(undo())}
          disabled={historyIndex <= 0}
          tooltip={
            <>
              {TOOLTIPS_MAP.TOOLBAR.UNDO}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.UNDO}
              </Typography.Text>
            </>
          }
        />
        <Button
          type={ButtonType.Text}
          icon={<RedoIcon className="assets-icon assets-icon_center" />}
          onClick={() => dispatch(redo())}
          disabled={historyIndex === -1 || historyIndex === historyLength - 1}
          tooltip={
            <>
              {TOOLTIPS_MAP.TOOLBAR.REDO}
              <Typography.Text className="tooltip_secondary" type="secondary">
                &nbsp;{HOTKEYS_MAP.REDO}
              </Typography.Text>
            </>
          }
        />
        <Divider type="vertical" />
        <Button
          type={ButtonType.Text}
          disabled={appLoading || !file || numPages === 0}
          icon={<Navigation className="assets-icon assets-icon_center" />}
          onClick={() => dispatch(setPageNavigationMode(true))}
          tooltip={TOOLTIPS_MAP.TOOLBAR.PAGE_NAVIGATION}
        />
        <Dropdown items={menuItems} visible={menuVisible} onVisibleChange={setMenuVisible}>
          <Button type={ButtonType.Text} icon={<ThreeDots size={18} className="assets-icon assets-icon_center" />} />
        </Dropdown>
        <DialogAttachFile
          visible={uploadDialogOpened}
          onOk={onUploadOk}
          onCancel={onUploadCancel}
          onFileChange={handleFileChange}
        />
      </>
    ),
    [
      historyIndex,
      historyLength,
      menuItems,
      menuVisible,
      setMenuVisible,
      uploadDialogOpened,
      onUploadOk,
      onUploadCancel,
      handleFileChange,
      appLoading,
    ],
  );

  return <Toolbar left={left} center={center} right={right} />;
};

export default memo(ViewerToolbar);
