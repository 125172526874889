import { postUploadRequest, downloadRequest, postRequest, postMultipartRequest } from '../api';
import { AnnotationsPaths } from './AnnotationsPaths';
import { ISendFileResponse } from '../interfaces/ISendFileResponse';
import { ISaveFileData } from '../interfaces/ISaveFileData';
import { ISaveFileResponse } from '../interfaces/ISaveFileResponse';
import { IUpdatePagesData } from '../interfaces/IUpdatePagesData';
import { ISaveOutlinesData } from '../interfaces/ISaveOutlinesData';
import { IUpdatePagesResponse } from '../interfaces/IUpdatePagesResponse';

export const sendFile = (file: File): Promise<ISendFileResponse> => postUploadRequest(AnnotationsPaths.SendFile, file);

export const getFile = (localPathToFile: string): Promise<File> =>
  postRequest(AnnotationsPaths.GetFile, localPathToFile, { responseType: 'blob' });

export const saveFile = (files: File, saveData: ISaveFileData): Promise<ISaveFileResponse> =>
  postUploadRequest(AnnotationsPaths.Save, files, { ...saveData });

export const downloadFile = (filePath: string, fileName: string): Promise<File> =>
  downloadRequest(AnnotationsPaths.GetFile, filePath, fileName);

export const updatePages = (saveData: IUpdatePagesData): Promise<IUpdatePagesResponse> =>
  postMultipartRequest(AnnotationsPaths.UpdatePages, { ...saveData });

export const updateOutlines = (saveData: ISaveOutlinesData): Promise<string> =>
  postRequest(AnnotationsPaths.UpdateOutlines, { ...saveData });
