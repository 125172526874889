import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { IAnnotationCard } from './interfaces/IAnnotationCard';
import AnnotationCardView from './annotationCardView/AnnotationCardView';
import AnnotationCardForm from './annotationCardForm/AnnotationCardForm';
import { IAnnotationCardFormData } from './annotationCardForm/interfaces/IAnnotationCardFormData';
import { createComment, editAnnotation, findAnnotationIndex } from '../../../services/annotationService';
import { IAnnotationEntity } from '../../../services/interfaces/IAnnotation';
import {
  addAnnotation,
  initAnnotations,
  setActiveAnnotation,
  updateAnnotation,
} from '../../../store/annotations/actions';
import { rememberState } from '../../../store/historyStore/actions';
import { getAnnotationsList } from '../../../store/annotations/selectors';
import { setRemovingAnnotation } from '../../../store/annotateSettings/actions';
import AnnotationComment from '../annotationComment/AnnotationComment';
import { scrollToCard } from '../../helpers/pdfViewerHelper';
import { AnnotationType } from '../../../store/enums/annotationType';
import { ActionType } from '../../../store/enums/actionType';

import './AnnotationCard.less';

const AnnotationCard: FC<IAnnotationCard> = (props) => {
  const dispatch = useDispatch();
  const cardRef = useRef(null);
  const annotationsList: IAnnotationEntity[] = useSelector(getAnnotationsList);
  const [isEdit, setIsEdit] = useState(false);
  const [newReplies, setNewReplies] = useState<string[]>([]);
  const isEditDisabled = useMemo(
    () => props.annotation.readOnly || props.annotation.locked || props.annotation.lockedContents,
    [props.annotation],
  );
  const isDeleteDisabled = useMemo(() => props.annotation.readOnly || props.annotation.locked, [props.annotation]);
  const creationDate = useMemo(() => {
    if (!props.annotation.creationDateInMillis) {
      return '';
    }
    return new Date(props.annotation.creationDateInMillis).toLocaleString();
  }, [props.annotation]);
  const onEditClick = useCallback(() => setIsEdit(true), []);
  const onEditSubmit = useCallback(
    (formData: IAnnotationCardFormData) => {
      const index = findAnnotationIndex(annotationsList, props.annotation);
      const annotation = editAnnotation(props.annotation, formData);
      setIsEdit(false);
      if (!annotation) {
        return;
      }
      let newAnnotationList = [...annotationsList];
      if (annotation.flatten) {
        newAnnotationList = newAnnotationList.map((reply) => {
          if (reply.idToReply === annotation.id) {
            return { ...reply, flatten: true };
          }
          return reply;
        });
      }
      newAnnotationList[index] = annotation;
      dispatch(initAnnotations(newAnnotationList));
      dispatch(rememberState(newAnnotationList));
    },
    [props.annotation, annotationsList],
  );
  const onEditCancel = useCallback(() => {
    if (
      props.annotation.annotationType === AnnotationType.Text &&
      props.annotation.actionType === ActionType.Create &&
      props.annotation.enterEditing
    ) {
      const newAnnotationList = annotationsList.filter((annotation) => annotation.id !== props.annotation.id);
      dispatch(initAnnotations(newAnnotationList));
      dispatch(rememberState(newAnnotationList));
    }
    setIsEdit(false);
  }, [props.annotation, annotationsList]);
  const onDeleteClick = useCallback(() => {
    const index = findAnnotationIndex(annotationsList, props.annotation);
    dispatch(setActiveAnnotation(index));
    dispatch(setRemovingAnnotation(true));
  }, [props.annotation, annotationsList]);
  const onCommentClick = useCallback(() => {
    const reply = createComment(props.annotation);
    if (props.annotation.id) {
      reply.idToReply = props.annotation.id;
      dispatch(addAnnotation(reply));
      dispatch(rememberState([...annotationsList, reply]));
    } else {
      const index = findAnnotationIndex(annotationsList, props.annotation);
      const parent = { ...props.annotation };
      const newAnnotationsList = [...annotationsList];
      newAnnotationsList[index] = {
        ...newAnnotationsList[index],
        ...parent,
        modified: true,
      };
      dispatch(updateAnnotation({ annotation: newAnnotationsList[index], index }));
      dispatch(rememberState(newAnnotationsList));
    }
    if (reply.id) {
      setNewReplies([...newReplies, reply.id]);
    }
  }, [props.annotation, annotationsList, newReplies]);

  const isNewReply = useMemo(() => (reply: IAnnotationEntity) => _.includes(newReplies, reply.id || ''), [newReplies]);

  const clearNewReply = useCallback(
    (id: string) => {
      setNewReplies(newReplies.filter((reply) => reply !== id));
    },
    [newReplies],
  );

  useEffect(() => {
    if (props.annotation.enterEditing) {
      setIsEdit(true);
    }
  }, [props.annotation]);

  useEffect(() => {
    if (props.active && cardRef.current) {
      scrollToCard(cardRef.current as unknown as HTMLDivElement);
    }
  }, [props.active]);

  return (
    <div className="annotation-card" ref={cardRef}>
      {isEdit ? (
        <AnnotationCardForm creationDate={creationDate} onSubmit={onEditSubmit} onCancel={onEditCancel} {...props} />
      ) : (
        <AnnotationCardView
          creationDate={creationDate}
          onEditClick={!isEditDisabled ? onEditClick : undefined}
          onDeleteClick={!isDeleteDisabled ? onDeleteClick : undefined}
          onCommentClick={onCommentClick}
          {...props}
        />
      )}
      {props.replies.map((reply, index) => (
        <AnnotationComment key={index} annotation={reply} isNew={isNewReply(reply)} clearNewReply={clearNewReply} />
      ))}
    </div>
  );
};

export default memo(AnnotationCard);
