import React, { FC, useMemo, memo } from 'react';
import { Typography } from 'antd';
import { FileEarmarkPdf } from 'react-bootstrap-icons';

import { TEXT_MAP } from '../../constants/application';

import './NoData.less';

interface INoDataProps {
  icon?: React.ReactNode;
  description?: string;
}

const NoData: FC<INoDataProps> = (props) => {
  const { description = TEXT_MAP.ERROR.DEFAULT_ERROR } = props;
  const icon = useMemo(() => (props.icon ? props.icon : <FileEarmarkPdf size={54} />), [props.icon]);

  return (
    <section className="pdf-no-data">
      {icon}
      <Typography.Paragraph type="secondary">{description}</Typography.Paragraph>
    </section>
  );
};

export default memo(NoData);
