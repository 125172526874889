import { fabric } from 'fabric';

import { AnyFunction, CustomAny } from '../shared/types/generics';

const clone = fabric.util.object.clone;

export interface IArrowLineOptions extends fabric.ILineOptions {
  startArrow?: boolean;
  endArrow?: boolean;
}

const ArrowLine = fabric.util.createClass(fabric.Line, {
  type: 'arrowLine',
  startArrow: false,
  endArrow: false,

  initialize: function (points: number[], options: IArrowLineOptions) {
    options || (options = {});
    this.callSuper('initialize', points, options);
    this.startArrow = options.startArrow;
    this.endArrow = options.endArrow;
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      customProps: this.customProps,
    });
  },

  _render: function (ctx: CanvasRenderingContext2D) {
    ctx.save();
    ctx.beginPath();
    const p = this._applyArrowOffset(this.calcLinePoints());
    const origStrokeStyle = ctx.strokeStyle;
    ctx.lineWidth = this.strokeWidth;
    ctx.strokeStyle = this.stroke || ctx.fillStyle;
    ctx.moveTo(p.x1, p.y1);
    ctx.lineTo(p.x2, p.y2);
    ctx.scale(1 / this.scaleX, 1 / this.scaleY);
    ctx.stroke();
    ctx.strokeStyle = origStrokeStyle;
    ctx.lineWidth = 1;
    ctx.restore();

    // do not render if width/height are zeros or object is not visible
    if (this.width === 0 || this.height === 0 || !this.visible) {
      return;
    }

    if (this.endArrow) {
      this._drawArrow(ctx, -1);
    }

    if (this.startArrow) {
      this._drawArrow(ctx, 1);
    }
  },

  _drawArrow(ctx: CanvasRenderingContext2D, sign: number) {
    const xDiff = this.x2 * this.scaleX - this.x1 * this.scaleX;
    const yDiff = this.y2 * this.scaleY - this.y1 * this.scaleY;
    const angle = Math.atan2(yDiff, xDiff);
    const arrowLength = this.strokeWidth * 9;
    const arrowAngle = (30 * Math.PI) / 180;
    ctx.save();
    ctx.scale(1 / this.scaleX, 1 / this.scaleY);
    ctx.translate(
      (-sign * (this.x2 * this.scaleX - this.x1 * this.scaleX)) / 2,
      (-sign * (this.y2 * this.scaleY - this.y1 * this.scaleY)) / 2,
    );
    ctx.rotate(angle);
    ctx.beginPath();
    ctx.moveTo(
      sign * this.strokeWidth + sign * Math.cos(arrowAngle) * arrowLength,
      sign * Math.sin(arrowAngle) * arrowLength,
    );
    ctx.lineTo(sign * this.strokeWidth, 0);
    ctx.lineTo(
      sign * this.strokeWidth + sign * Math.cos(arrowAngle) * arrowLength,
      -sign * Math.sin(arrowAngle) * arrowLength,
    );
    ctx.lineWidth = this.strokeWidth;
    ctx.strokeStyle = this.stroke;
    ctx.stroke();
    ctx.restore();
  },

  _applyArrowOffset(p: { x1: number; y1: number; x2: number; y2: number }): {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  } {
    const pWithOffset = { ...p };
    if (this.startArrow) {
      const a = Math.sqrt(p.x1 * this.scaleX * p.x1 * this.scaleX + p.y1 * this.scaleY * p.y1 * this.scaleY);
      const offsetX = (this.strokeWidth * p.x1 * this.scaleX) / a;
      const offsetY = (this.strokeWidth * p.y1 * this.scaleY) / a;
      pWithOffset.x1 = (p.x1 * this.scaleX - offsetX) / this.scaleX;
      pWithOffset.y1 = (p.y1 * this.scaleY - offsetY) / this.scaleY;
    }
    if (this.endArrow) {
      const a = Math.sqrt(p.x2 * this.scaleX * p.x2 * this.scaleX + p.y2 * this.scaleY * p.y2 * this.scaleY);
      const offsetX = (this.strokeWidth * p.x2 * this.scaleX) / a;
      const offsetY = (this.strokeWidth * p.y2 * this.scaleY) / a;
      pWithOffset.x2 = (p.x2 * this.scaleX - offsetX) / this.scaleX;
      pWithOffset.y2 = (p.y2 * this.scaleY - offsetY) / this.scaleY;
    }
    return pWithOffset;
  },

  calcPoints() {
    const matrix = (this.ownMatrixCache.value || []).slice(0, 4);
    const newX1 = matrix[0] * this.x1 + matrix[1] * this.y1;
    const newY1 = matrix[2] * this.x1 + matrix[3] * this.y1;
    const newX2 = matrix[0] * this.x2 + matrix[1] * this.y2;
    const newY2 = matrix[2] * this.x2 + matrix[3] * this.y2;

    return [
      this.left + (this.flipX ? -newX2 : newX1),
      this.top + (this.flipY ? -newY2 : newY1),
      this.left + (this.flipX ? -newX1 : newX2),
      this.top + (this.flipY ? -newY1 : newY2),
    ];
  },
});

ArrowLine.fromObject = function (object: CustomAny, callback: AnyFunction) {
  function _callback(instance: CustomAny) {
    delete instance.points;
    callback && callback(instance);
  }
  const options = clone(object);
  options.points = [object.x1, object.y1, object.x2, object.y2];
  fabric.Object._fromObject('ArrowLine', options, _callback, 'points');
};

export { ArrowLine };
