import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';

import { IAnnotations } from '../interfaces/IAnnotations';
import {
  addAnnotation,
  initAnnotations,
  setActiveAnnotation,
  updateAnnotation,
  deleteAnnotation,
  deleteAllAnnotations,
  setCopiedAnnotation,
} from './actions';
import { removeAnnotation, removeAllAnnotations } from '../../services/annotationService';
import { IAnnotationEntity } from '../../services/interfaces/IAnnotation';

const initialState: IAnnotations = {
  annotations: [],
  activeAnnotation: null,
  copiedAnnotation: undefined,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(initAnnotations, (state, action) => {
    state.annotations = [...action.payload];
    if (!_.isEqual(state.annotations, action.payload)) {
      state.activeAnnotation = null;
    }
  });
  builder.addCase(addAnnotation, (state, action) => {
    state.annotations.push(action.payload);
  });
  builder.addCase(setActiveAnnotation, (state, action) => {
    state.activeAnnotation = action.payload;
  });
  builder.addCase(setCopiedAnnotation, (state, action) => {
    if (action.payload) {
      state.copiedAnnotation = { ...action.payload };
    }
  });
  builder.addCase(updateAnnotation, (state, action) => {
    state.annotations = [...state.annotations];
    state.annotations[action.payload.index] = action.payload.annotation;
  });
  builder.addCase(deleteAnnotation, (state, action) => {
    state.annotations = removeAnnotation(state.annotations, action.payload) as IAnnotationEntity[];
  });
  builder.addCase(deleteAllAnnotations, (state) => {
    state.annotations = removeAllAnnotations(state.annotations);
  });
});
